section#checkout {
	@include section_reset;
	background-color: color(white);
	overflow: hidden;
	padding-bottom: 220px; // Account for CVC tooltip positioning on wider screens
	.form_compact {
		@include breakpoint(zero, tablet-l) {
			padding-top: 10px;
		}
		@include breakpoint(tablet-l) {
			padding-top: 30px;
		}
		&.share_url {
			padding-top: 2em;
		}
	}
	&.login {
		.form_compact {
			padding-top: 0;
		}
		.content_wrapper {
			margin: 0 auto;
			max-width: 350px;
			@include breakpoint(zero, mobile-s) {
				padding: 0 20px;
				max-width: none;
			}
			@include breakpoint(mobile-s, mobile-l) {
				padding: 0 40px;
			}
		}
		.link {
			@extend .h7_sans;
			color: color(gray);
			border-bottom: 0px;
		}
	}
	.login_header {
		padding: 0;
		text-align: center;
		p:last-of-type {
			margin-bottom: 2em;
		}
		.title {
			@extend .h3_serif;
			color: color(gray, x-dark);
			margin-top: 2em;
			position: relative;
			&:after {
				border-top: 1px solid color(gray, x-dark);
				content: '';
				display: block;
				height: 0px;
				margin: 38px auto 40px auto;
				width: 25px;
				@include breakpoint(zero, desktop) {
					margin: 15px auto;
				}
			}
		}
	}
}

#checkout_data {
	display: none;
}

.cart_overview {
	@include svg-background;
	background-color: color(blue);
	background-image: svg(home-bg-pattern-geometric-curves);
	background-position: center center;
	background-size: cover;
	padding: 10px;
	> .inner {
		background-color: color(white);
		padding: 10px;
	}
	.cart_items {
		width: 100%;
		@include breakpoint(zero, desktop-l) {
			margin-left: auto;
			margin-right: auto;
			max-width: 250px;
		}
	}
	.checkout_pitch_wrapper, .no_pitch_wrapper {
		@include breakpoint(zero, desktop-l) {
			display: none;
		}
	}
	.product {
		@include breakpoint(zero, desktop-l) {
			display: none;
		}
	}
	&.monthly_total {
		margin-top:20px;
		.cart_items {
			margin: 0 auto -10px;
			max-width: 250px;
		}
		.box_title {
			padding:15px 0 25px;
			text-align:center;
			border-bottom: 1px solid color(gray, x-light);
			.name {
				@extend .h6_sans;
				color: color(blue);
				font-weight: 700;
			}
			.fs_p_note {
				margin:5px 0 0;
			}
		}
	}
}

.checkout_account {
	@include clearfix;
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-bottom: 2em;
	margin-left: auto;
	margin-right: auto;
	max-width: 300px;
	@include breakpoint(zero, tablet-l) {
		display: none;
	}
	h2,
	a {
		display: inline-block;
	}
	h2 {
		@include gutter-l {
			margin-right: 3em;
		}
		@include gutter-s {
			margin-right: 2em;
		}
	}
	.btn_new {
		min-width: 21em;
		&:last-of-type {
			margin-top: 1em;
		}
	}
}

.checkout_account_condensed {
	@include clearfix;
	margin-top: 2em;
	margin-bottom: 2em;
	.title {
		@include font_size(fs_p_note);
		color: color(gray, light);
		float: left;
		font-weight: bold;
		height: 40px;
		letter-spacing: 0.1em;
		line-height: 40px;
		text-transform: uppercase;
	}
	.btn_new.small {
		float: right;
		&:last-of-type {
			float: none;
			margin-top: 1em;
			width: 100%;
		}
	}
	.clear {
		clear: both;
	}
	@include breakpoint(tablet-l) {
		display: none;
	}
}

// Checkout Credit Card Icons
.checkout_cc_icons {
	height: 35px;
	margin-bottom: 3em;
	padding: 0;
	text-align: center;
	.visa, .mastercard, .amex, .discover {
		opacity: .3;
	}
	&.visa .visa {
		opacity: 1;
	}
	&.mastercard .mastercard {
		opacity: 1;
	}
	&.amex .amex {
		opacity: 1;
	}
	&.discover .discover {
		opacity: 1;
	}
	li {
		$img: '../img/home/icons-checkout-cc';
		background: url($img+'.png') no-repeat;
		display: inline-block;
		height: 35px;
		margin-left: .25em;
		margin-right: .25em;
		padding: 0;
		transition: opacity .2s ease-out;
		width: 56px;
		&.visa {
			background-position: 0 0;
		}
		&.mastercard {
			background-position: -76px 0;
		}
		&.amex {
			background-position: -152px 0;
		}
		&.discover {
			background-position: -228px 0;
		}
		@include retina {
			background-image: url($img+'@2x.png');
			background-size: auto 35px;
		}
	}
}

// Custom Grid for checkout
.checkout_grid {
	$gutter: 2;
	@include clearfix;

	&.column_1 {
		max-width:1280px * .4;
		margin-left:auto;
		margin-right:auto;
	}

	.col_min,
	.col_max {
		box-sizing: border-box;
		display: block;
		vertical-align: top;
		width: 100%;
		// share one column sizing / breakpoints up to tablet-l
		@include breakpoint(mobile-l, mobile-xl) {
			padding-left: width(gutter-outer-s);
			padding-right: width(gutter-outer-s);
		}
		@include breakpoint(mobile-xl, tablet-s) {
			padding-left: width(gutter-outer-s) * 2;
			padding-right: width(gutter-outer-s) * 2;
		}
		@include breakpoint(tablet-s, tablet) {
			padding-left: width(gutter-outer-s) * 3;
			padding-right: width(gutter-outer-s) * 3;
		}
		@include breakpoint(tablet, tablet-l) {
			padding-left: width(gutter-outer-s) * 4;
			padding-right: width(gutter-outer-s) * 4;
		}
	}

	.col_min {
		@include breakpoint(zero, tablet-l) {
			&:nth-of-type(n+1) {
				fieldset:first-of-type {
					margin-top: 3em;
				}
			}
		}
		@include breakpoint(tablet-l, desktop-l) {
			float: left;
			width: 50%;
			&.single {
				width:100%;
				padding:0;
			}
			&:nth-child(1) {
				padding-left: width(gutter-outer-s) * 2;
				padding-right: width(gutter-outer-s) / 2;
			}
			&:nth-child(2) {
				padding-left: width(gutter-outer-s) / 2;
				padding-right: width(gutter-outer-s) * 2;
			}
		}
		@include breakpoint(desktop-l) {
			float: left;
			width: 30%;
			&.single {
				width:100%;
				padding:0;
			}
			padding-right: $gutter+em;
		}
		fieldset:last-of-type {
			margin-bottom: 0;
		}
	}

	.col_max {
		padding-top: 1.5em;
		@include breakpoint(tablet-l, desktop-l) {
			clear: both;
			padding-left: width(gutter-outer-s) * 2;
			padding-right: width(gutter-outer-s) * 2;
		}
		@include breakpoint(desktop-l) {
			float: left;
			padding-top: 0;
			width: 40%;
			&.single {
				width:100%;
				padding:0;
			}
		}
	}
}

// .checkout_header styling in _layout.scss

.checkout_hint {
	bottom: 0;
	display: inline-block;
	position: absolute;
	right: 0;
	text-align: center;
	width: 50%;
}

.checkout_message {
	$width: 500px;
	margin-left: width(gutter-outer-s);
	margin-right: width(gutter-outer-s);
	max-width: $width;
	text-align: center;
	@include breakpoint($width + width(gutter-outer-s) * 2, false, false) {
		margin-left: auto;
		margin-right: auto;
	}
	p:last-of-type {
		margin-bottom: 2em;
	}
}

// Main checkout nav styling in _layout.scss. This is for positioning.
.checkout_nav {
	position: relative;
}

.checkout_pitch {
	background-color: color(white, near);
	cursor: pointer;
	padding-bottom: 2em;
	padding-top: 2em;
	text-align: center;
	// mobile only
	&.mobile_pitch {
		@include breakpoint(zero, desktop-l) {
			margin-bottom: 1em;
		}
		@include breakpoint(desktop-l) {
			display: none;
		}
	}
	// when inside a table
	.checkout_pitch_wrapper & {
		padding-bottom: 0;
		padding-top: 0;
	}
	.content {
		background-color: color(white, near);
	}
	.currency {
		margin-right: 0;
	}
	.intro {
		@extend .h7_sans;
		color: color(gray, light);
	}
	.savings {
		@extend .h4_serif;
		color: color(black, near);
	}
}

.checkout_pitch_wrapper {
	background-color: color(white, near);
}

.checkout_sticky_cart {
	margin-bottom: 3em;
	width: auto;
	z-index: z-index(sticky-nav);
	@include breakpoint(mobile-xl, tablet-s) {
		padding-left: width(gutter-outer-s) * 2;
		padding-right: width(gutter-outer-s) * 2;
	}
	@include breakpoint(tablet-s, tablet) {
		padding-left: width(gutter-outer-s) * 3;
		padding-right: width(gutter-outer-s) * 3;
	}
	@include breakpoint(tablet, tablet-l) {
		padding-left: width(gutter-outer-s) * 4;
		padding-right: width(gutter-outer-s) * 4;
	}
	@include breakpoint(tablet-l, desktop-l) {
		padding-left: width(gutter-outer-s) * 2;
		padding-right: width(gutter-outer-s) * 2;
	}
	@include breakpoint(desktop-l) {
		display: none;
	}
	&.stuck {
		margin-bottom: 0;
		padding-left: 0;
		padding-right: 0;
		width: auto;
		> .outer {
			margin-bottom: 0;
		}
		@include breakpoint(zero, mobile-xl) {
			width: 100%;
			> .outer {
				width: 100%;
			}
		}
		@include breakpoint(mobile-xl, tablet-s) {
			left: width(gutter-outer-s) * 3;
			right: width(gutter-outer-s) * 3;
		}
		@include breakpoint(tablet-s, tablet) {
			left: width(gutter-outer-s) * 4;
			right: width(gutter-outer-s) * 4;
		}
		@include breakpoint(tablet, tablet-l) {
			left: width(gutter-outer-s) * 4 + width(gutter-outer-l);
			right: width(gutter-outer-s) * 4 + width(gutter-outer-l);
		}
		@include breakpoint(tablet-l, desktop-l) {
			left: width(gutter-outer-s) * 2 + width(gutter-outer-l);
			right: width(gutter-outer-s) * 2 + width(gutter-outer-l);
		}
	}
	&.open {
		.details {
			display: block;
		}
		.header_group {
			.toggle {
				&:before {
					transform: rotate(-180deg);
				}
			}
		}
	}
	> .outer {
		@include svg-background;
		background-color: color(blue);
		background-image: svg(home-bg-pattern-geometric-curves);
		background-position: center center;
		background-size: cover;
		box-sizing: border-box;
		padding: 10px;
		position: relative;
		width: 100%;
		@include breakpoint(zero, mobile-xl) {
			margin-bottom: width(gutter-outer-s);
			margin-left: width(gutter-outer-s) * -1;
			margin-right: width(gutter-outer-s) * -1;
			width: calc(100% + #{ width(gutter-outer-s) * 2 });
		}
	}
	> .outer > .inner {
		background-color: color(white);
		padding: 1em;
	}
	header {
		@include clearfix;
		@include section_reset;
		overflow: hidden;
		> .intro {
			float: left;
			margin-right: 1em;
		}
		> .price, > .quantity {
			float: right;
			margin-left: 1em;
		}
	}
	.cart_items {
		margin-left: auto;
		margin-right: auto;
		max-width: 400px;
		width: 100%;
		@include breakpoint(tablet-l) {
			margin-bottom: width(gutter-outer-s);
			margin-top: width(gutter-outer-s);
			max-width: 426px;
		}
	}
	.details {
		display: none;
	}
	.header_group {
		.subtitle {
			@extend .h7_sans;
			color: color(gray);
			font-weight: normal;
			line-height: 1em;
		}
		.title {
			@extend .h6_sans;
			line-height: 1em;
			margin-bottom: .5em;
		}
		.toggle {
			color: color(blue);
			cursor: pointer;
			&:before {
				@extend %caret_down;
				background-image: svg(home-chevron-down-small-blue);
				margin-right: .2em;
			}
		}
	}
}

.full_pricing {
	@include breakpoint(zero, desktop-l) {
		display: none;
	}
}
.checkout_success {
	max-width:500px;
	margin:auto;
	padding-top: 10px;
	@include breakpoint(tablet) {
		padding-top:30px;
	}
	@include breakpoint(desktop) {
		padding-top:50px;
	}
	.inner {
		padding:2em;
	}
	.order_summary {
		margin-bottom:1em;
		padding:1.5em;
		background-color:color(white, near);
		p {
			margin-bottom:0;
		}
	}
	.cart_overview {
		h4 {
			&:after {
				border-top: 1px solid color(gray, x-dark);
				content: '';
				display: block;
				height: 0px;
				margin: 38px auto 40px auto;
				width: 25px;
			}
		}
		.social_nav {
			margin:3em 0 1em;
		}
	}
	.mobile_title {
		@extend .h3_serif;
		color: color(gray, x-dark);
		text-align: center;
		@include breakpoint(tablet-l) {
			display: none;
		}
	}
}
