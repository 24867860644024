// alphabetical by class, animation

@keyframes arrow-link {
    0% {
        opacity: 0;
        transform: translateX(-75%);
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes arrow-link-left {
    0% {
        opacity: 0;
        transform: translateX(75%);
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes countdown {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes fade_in_down {
	0% {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fade_in_out {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fade_in_up {
	0% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fade_out_down {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
}


@keyframes fade_out_up {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translate3d(0, -33%, 0);
	}
}

@keyframes loader {
	0% {
		transform: rotate(106deg);
	}
	92% {
		transform: rotate(1180deg);
	}
	100% {
		transform: rotate(1186deg);
	}
}

@keyframes pulse {
	0% {
		transform: scale3d(1, 1, 1);
	}
	50% {
		transform: scale3d(1.05, 1.05, 1.05);
	}
	100% {
		transform: scale3d(1, 1, 1);
	}
}

@keyframes shake {
	0%, 50%, 85%, 100% {
		transform: translate3d(0, 0, 0);
	}
	55%, 65%, 75% {
	  transform: translate3d(-10px, 0, 0);
	}
	60%, 70%, 80% {
	  transform: translate3d(10px, 0, 0);
	}
}

@keyframes spinner {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	75% {
		transform: scale(1);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes statement_card_enter {
    0% {
        transform: perspective(800px) rotateX(-90deg);
    }
    22% {
        transform: perspective(800px) rotateX(20deg);
    }
    44% {
        transform: perspective(800px) rotateX(-9deg);
    }
    66% {
        transform: perspective(800px) rotateX(3deg);
    }
    100% {
        transform: perspective(800px) rotateX(0deg);
    }
}
