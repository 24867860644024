.pull_quote {
	@include section_reset;
	margin-bottom: 50px;
	overflow: hidden;
	text-align: center;
	@include breakpoint(tablet) {
		margin-bottom: 75px;
	}
	&__margin_bottom_xl {
		margin-bottom: 125px;
		@include breakpoint(tablet) {
			margin-bottom: 250px;
		}
	}
	.copy {
		@include font_size(fs_h5);
		line-height: 1.3em;
		margin-top: .8em;
	}
	.heading {
		@include font_size(fs_h3_small);
		color: color(black);
		font-weight: 700;
		line-height: 1.2em;
		margin-bottom: 0;
		text-transform: none;
	}
	.lockup {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-left: auto;
		margin-right: auto;
		margin-top: 1.75em;
		max-width: 250px;
		.quote_avatar {
			width: 85px;
		}
		.quote_author {
			@include font_size(fs_h6_small);
			padding-bottom: 14px;
			text-align: left;
			.byline_header {
				color: color(black);
			}
		}
	}
}
