// management team page
$team_outside_margin: 80px;
body.page-id-18 .content {
	background-color: color(white);
}
.team_billboard {
	background: url(../img/company/management/management-team-billboard-bg.jpg) repeat-x center top;
	background-size: cover;
	clear: both;
	overflow: hidden;
	padding: 50px 0;
	@include breakpoint(mobile-xl) {
		padding: 70px 0;
	}
	@include breakpoint(desktop) {
		padding: 90px 0;
	}
	.title {
		@extend .h1_sans;
		color: color(white);
		text-transform: uppercase;
		// our version of proxima's line heights get wonky, this tries to sort for it
		.light + .name {
			margin-top: .03em;
		}
		// our version of proxima's line heights get wonky, this tries to sort for it
		.name + .light {
			margin-top: -.02em;
		}
		.name {
			font-weight: 900;
			line-height: .8em;
			transform: translateY(.01em); // our version of proxima's line heights get wonky, this tries to sort for it
		}
	}
}
.team_tile {
	@include clearfix;
	padding-bottom: 10px;
	padding-top: width(gutter-inner-l);
	&:last-child {
		padding-bottom: 0;
	}
	@include breakpoint(tablet) {
		padding-bottom: 50px;
	}
	@include breakpoint(desktop) {
		padding-bottom: 300px;
		padding-top: 0;
	}
	@include breakpoint(desktop-xl) {
		padding-bottom: 400px;
	}
	.team_column {
		.title {
			@extend .h6_sans;
			clear: both;
			text-transform: uppercase;
		}
		&.secondary {
			width: 100%;
			@include breakpoint(tablet) {
				width: 50%;
			}
			@include breakpoint(tablet-l) {
				width: 44%;
			}
			@include breakpoint(desktop) {
				width: 50%;
			}
			.title {
				display: none;
				padding: 40px 0 50px;
				// show team title for desktop
				@include breakpoint(desktop) {
					display: block;
				}
			}
		}
		&.primary {
			@include breakpoint(tablet) {
				width: 50%;
			}
			@include breakpoint(tablet-l) {
				width: 56%;
			}
			@include breakpoint(desktop) {
				padding-top: 100px;
				width: 50%;
			}
			p {
				@extend .p_extra;
			}
			.title {
				padding: 10px 0;
				// show team title for mobile
				display: block;
				@include breakpoint(desktop) {
					display: none;
				}
			}
		}
		.picture_container {
			position: relative;
			@include breakpoint(mobile-xl, tablet) {
				max-width: calc(50% + 20px);
			}
			@include breakpoint(desktop) {
				// move the manager image to overlap billboard
				margin-bottom: -50%;
				transform: translateY(-50%);
			}
			&:after {
				border-top: 1px solid color(gray, light);
				content: "";
				display: block;
				height: 1px;
				position: absolute;
				top: 50%;
				width: 160px;
				@include breakpoint(zero, desktop) {
					// turn off picture line accents on mobile
					border-top: 0;
				}
			}
			.picture {
				@include constrain-proportions(100%, 100%);
				img {
					position: absolute;
				}
			}
		}
		.first {
			.picture {
				@include breakpoint(zero, desktop) {
					// removes the corner accent on the first and last photos
					clip-path: none;
				}
				@extend %clip_path_first;
			}
			&:after {
				// removes the horizontal line to the left of the first tile picture
				border-top: 0px;
			}
		}
		.last {
			.picture {
				@include breakpoint(zero, desktop) {
					clip-path: none;
				}
				@extend %clip_path_last;
			}
		}
	}
	@include breakpoint(tablet) {
		&:nth-child(odd) {
			.content_container {
				padding-right: 25px;
			}
			.picture_container {
				&:after {
					left: -160px;
				}
			}
			.quote_box {
				&:after {
					left: -20px;
				}
			}
			.secondary_wrapper {
					margin-left: 25px;
				@include breakpoint(desktop) {
					margin-right: $team_outside_margin;
				}
			}
			.team_column {
				float: right;
			}
		}
		&:nth-child(even) {
			.content_container {
				padding-left: 25px;
			}
			.picture_container {
				&:after {
					right: -160px;
				}
			}
			.secondary_wrapper {
				margin-right: 25px;
				@include breakpoint(desktop) {
					margin-left: $team_outside_margin;
				}
			}
			.team_column {
				float: left;
			}
			.title {
				@include breakpoint(desktop) {
					text-align: right;
				}
			}
			.quote_box {
				float: right;
				&:after {
					right: -20px;
				}
				.quote {
					padding: 35px;
					text-align: left;
					// the following styling keeps the quote text from overlapping with custom icons by character limit
					&.char_2 {
						padding-left: 50px;
					}
					&.char_3 {
						padding-left: 85px;
					}
					&.char_4 {
						padding-left: 100px;
					}
					&,
					&.char_2,
					&.char_3,
					&.char_4 {
						@include breakpoint(desktop-l) {
							padding: 30px 145px 30px 45px;
						}
					}
				}
			}
		}
	}
	.quote_box {
		background-color: color(gray, xxx-light);
		position: relative;
		@include breakpoint(zero, desktop) {
			margin: 20px 0 0;
			width: 100%;
		}
		&:after {
			border-top: 0;
			content: "";
			display: block;
			height: 1px;
			top: 40%;
			position: absolute;
			width: 145px;
			@include breakpoint(desktop-l) {
				border-top: 1px solid color(gray, light);
			}
		}
		.quote {
			@extend .h4_serif;
			color: color(gray, x-dark);
			font-style: italic;
			padding: 35px;
			@include breakpoint(desktop-l) {
				padding: 30px 45px 30px 145px;
			}
		}
		.quote_icon {
			@extend .h3_sans;
			color: color(blue);
			font-weight: bold;
			left: -10px;
			position: absolute;
			top: 15%;
			&.custom {
				@include breakpoint(zero, desktop) {
					left: -10px;
					top: 2px;
				}
			}
			&.quotes {
				font-size: 72px;
				top: 9%;
				@include breakpoint(zero, desktop) {
					left: -10px;
					top: -7%;
				}
			}
			&.twitter {
				width: 24px;
				path,
				rect {
					fill: color(blue);
				}
				@include breakpoint(zero, desktop) {
					left: -7px;
					top: -3%;
					width: 35px;
				}
			}
		}
	}
	.title_container {
		clear: both;
		display: inline-block;
		margin: 25px 0 0;
		@include breakpoint(desktop) {
			margin-top: 10px;
		}
		.social_links {
			@include clearfix;
			float: left;
			margin: 1px 0 0 8px;
			padding: 0;
			@include breakpoint(tablet) {
				margin: 3px 0 0 15px;
			}
			li {
				float: left;
				list-style: none;
				height: 14px;
				padding: 0 2px;
				position: relative;
				text-align: center;
				width: 18px;
				@include breakpoint(tablet) {
					height: 16px;
					padding: 0 5px;
				}
				svg {
					height: 100%;
					width: 100%;
					path,
					rect {
						fill: color(gray);
					}
				}
				&:hover {
					path,
					rect {
						fill: color(blue);
					}
				}

			}
		}
		.name {
			@extend .h3_sans;
			color: color(blue);
			display: inline-block;
			float: left;
			text-transform: none;
		}
	}
}
.vp_container {
	clear: both;
	padding: 40px 0;
	@include breakpoint(tablet) {
		padding-top: 200px;
		padding-bottom: 90px;
	}
}
.vp_team {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: left;
	@include breakpoint(tablet-l) {
		padding-left: 40px;
	}
	@include breakpoint(desktop) {
		margin: 0 $team_outside_margin;
	}
	.member {
		background-position: top center;
		background-size: cover;
		display: block;
		flex-basis: calc(100% - 20px);
		flex-grow: 1;
		height: 500px;
		margin: 10px 0;
		position: relative;
		&:last-child {
			margin-bottom: 0;
		}
		// the range has to be explicitly cut off at tablet-l to prevent having to redefine the nth child for 2n + 1 in larger formats
		@include breakpoint(mobile-xl, tablet-l) {
			flex-basis: calc(50% - 40px);
			height: 400px;
			margin: 20px;
			max-width: calc(50% - 40px);
			&:nth-child(2n + 1) {
				margin-left: 0;
			}
			&:nth-child(2n + 2) {
				margin-right: 0;
			}
		}
		@include breakpoint(tablet-l) {
			flex-basis: calc(33.33% - 40px);
			height: 400px;
			margin: 20px;
			max-width: calc(33.33% - 40px);
			&:nth-child(3n + 1) {
				margin-left: 0;
			}
			&:nth-child(3n + 3) {
				margin-right: 0;
			}
		}
	}
	.member_content {
		background-color: color(gray, xxx-light);
		bottom: 25px;
		left: -20px;
		padding: 14px 15px;
		position: absolute;
		width: 203px;
	}
	.name,
	.title {
		margin: 0;
	}
	.name {
		@include font_size(fs_p_extra);
		color: color(blue);
		font-weight: 600;
		text-transform: none;
	}
	.title {
		font-family: font(serif);
letter-spacing: letter-spacing(serif);
		@include font_size(fs_p);
		color: color(blue, muted);
		font-style: italic;
		padding-top: 10px;
	}
}
.ml_container {
	clear: both;
	padding-bottom: 40px;
	@include breakpoint(tablet) {
		background: url(../img/company/management/dna-bg.jpg);
		background-position: center bottom;
		background-repeat: repeat-x;
		padding-bottom: 200px;
	}
}
.market_header {
	@extend .h2_sans;
	color: color(gray, dark);
	margin-bottom: 25px;
	text-align: center;
	text-transform: uppercase;
	@include breakpoint(tablet) {
		margin-bottom: 29px;
		margin-top: 0;
	}
	@include breakpoint(desktop) {
		margin-bottom: 78px;
		margin-top: 90px;
	}
	.light {
		color: color(gray, dark);
	}
	.name {
		color: color(blue);
		font-weight: 900;
		line-height: .8em;
		transform: translateY(.01em); // our version of proxima's line heights get wonky, this tries to sort for it
		sup {
			top:-.7em;
			font-size:50%;
		}
	}
}
.market_leaders {
	margin-bottom: 40px;
	@include breakpoint(tablet) {
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;
		margin-left: 80px;
	}
	.leader {
		background-position: top center;
		background-size: cover;
		display: block;
		height: 500px;
		margin-bottom: 20px;
		@include breakpoint(mobile-xl, tablet) {
			height: 400px;
			max-width: calc(50% + 20px);
		}
		@include breakpoint(tablet) {
			height: 400px;
			margin-bottom: 20px;
			margin-left: 0;
			margin-right: 40px;
			margin-top: 20px;
			min-width: 250px;
		}
		@include breakpoint(tablet-l) {
			margin-left: 40px;
		}
		@include breakpoint (desktop) {
			min-width: 330px;
		};
	}
	.leader_content {
		@include breakpoint(tablet) {
			margin-top: 100px;
		}
		p {
			margin-top: 40px;
			@include breakpoint(tablet) {
				margin-top: 0;
			}
		}
	}
	.name,
	.title {
		margin: 0;
	}
	.name {
		@include font_size(fs_p_extra);
		color: color(blue);
		font-weight: 600;
		text-transform: none;
	}
	.title {
		font-family: font(serif);
letter-spacing: letter-spacing(serif);
		@include font_size(fs_p);
		color: color(blue, muted);
		display: inline-block;
		font-style: italic;
		padding-top: 10px;
		position: relative;
		@include breakpoint(tablet) {
			padding-bottom: 70px;
			&:after {
				background-color: color(black, near);
				bottom: 0;
				content: '';
				height: 1px;
				left: 0;
				position: absolute;
				transform: translate3d(-40px, -35px, 0);
				width: calc(100% + 40px);
			}
		}
	}
}
