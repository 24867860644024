.dist_container {
	margin-bottom: 20px;
	text-align: center;
	transform: translate3d(0, -25px, 0);
	.avatar {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		border: 1px solid color(white);
		border-radius: 50%;
		height: 50px;
		margin: 0 auto 25px;
		width: 50px;
	}
	.dist_pretitle {
		color: color(gray, light);
		font-family: font(serif);
		font-style: italic;
		margin-bottom: 5px;
	}
	.dist_name {
		font-weight: 700;
		letter-spacing: .1em;
		text-transform: uppercase;
	}
}
