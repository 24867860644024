.lead_gen_container {
	background: color(white);
	overflow: hidden;
	min-height: 100vh;
}

.lg_btn {
	width: 100%;
	margin-bottom: 20px;
	&.lg_btn_extra_margin {
		margin-bottom: 30px;
	}
}

.lg_content {
	margin-bottom: 55px;
	@include breakpoint(mobile-xl) {
		margin-bottom: 150px;
	}
}

.lg_cta_container {
	background: url('../img/lead-generation/cta-bg.jpg') no-repeat center center;
	background-size: cover;
	@include breakpoint(mobile-xl) {
		margin: 0 auto;
		max-width: 460px;
		padding: 10px;
	}
	.lg_cancel {
		line-height: 40px;
		color: color(gray, x-light);
		cursor: pointer;
		font-size: 12px;
		font-weight: 600;
		letter-spacing: .075em;
		text-transform: uppercase;
		.emphasis {
			color: color(black, near);
		}
	}
	.lg_cta_content {
		background-color: color(white);
		text-align: center;
		@include breakpoint(mobile-xl) {
			padding: 50px;
		}
		@include breakpoint(tablet-l) {
			padding: 80px 50px;
		}
	}
	.lg_copy {
		@include font_size(fs_p_small);
		color: color(blue, muted);
		margin-bottom: 30px;
	}
	.lg_title {
		color: color(black, near);
		font-family: font(serif);
		font-size: 24px;
		font-weight: 100;
		margin-bottom: 30px;
		@include breakpoint(mobile-xl) {
			font-size: 28px;
		}
	}
}

.lg_footer {
	.lg_copyright {
		@include font_size(fs_p_note);
		color: color(gray, light);
		text-align: center;
	}
}

.lg_header {
	z-index:10;
//	background: color(white) url('../img/lead-generation/header-bg.png') repeat-x top center;
//	background-size: auto 150px;
//	overflow: hidden;
//	padding-top: 15px;
//	@include breakpoint(mobile-xl) {
//		padding-top: 30px;
//	}
//	.lg_dist_avatar {
//		background-position: center center;
//		background-repeat: no-repeat;
//		background-size: cover;
//		border-radius: 50%;
//		height: 40px;
//		margin: 0 auto 10px;
//		transition: height, width 0.2s ease-in;
//		width: 40px;
//		@include breakpoint(mobile-xl) {
//			height: 45px;
//			margin: 0 auto 50px;
//			width: 45px;
//		}
//		&.avatar_big {
//			height: 90px;
//			width: 90px;
//			@include breakpoint(mobile-xl) {
//				height: 75px;
//				width: 75px;
//			}
//		}
//	}
}

.lg_horizontal_rule {
	background-color: color(gray, x-light);
	display: none;
	height: 1px;
	margin-bottom: 50px;
	position: relative;
	width: 100%;
	@include breakpoint(mobile-xl) {
		display: block;
	}
	&:before {
		background-color: color(gray, x-light);
		content: "";
		left: -50%;
		height: 1px;
		position: absolute;
		top: 0;
		width: 50%;
	}
}

.lg_page_title {
	color: color(blue, muted);
	font-family: font(sans);
	font-size: 22px;
	font-weight: 100;
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
	@include breakpoint(mobile-xl) {
		font-size: 28px;
	}
	@include breakpoint(tablet) {
		font-size: 32px;
		margin-top:1.5em;
//		text-align: left;
	}
}

.lg_product_tiles {
	display: flex;
	flex-direction: column;
	@include breakpoint(tablet) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	@include breakpoint(desktop-l) {
		margin: 0 -80px;
	}
	@include breakpoint(max-width-with-gutters) {
		margin: 0 -175px;
	}
	.lg_details {
		font-family: font(serif);
		font-weight: 100;
	}
	.lg_product_tile {
		align-items: flex-end;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		box-sizing: border-box;
		color: color(white);
		display: flex;
		font-size: 12px;
		min-height: 100px;
		margin-bottom: 20px;
		padding: 15px;
		text-decoration: none;
		@include breakpoint(mobile-xl) {
			font-size: 24px;
			height: 150px;
		}
		@include breakpoint(tablet) {
			flex-basis: calc(50% - 10px);
			height: 190px;
			padding: 30px 40px;
		}
	}
	.lg_product {
		letter-spacing: .05em;
		text-transform: uppercase;
	}
}

.lg_row {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	@include breakpoint(tablet-l) {
		flex-direction: row;
	}
	.lg_column {
		@include breakpoint(tablet-l) {
			flex: 1;
		}
		@include breakpoint(tablet-l) {
			&:nth-child(2n+1) {
				padding-right: 30px;
			}
			&:nth-child(2n+2) {
				padding-left: 30px;
			}
		}
		p {
			@include font_size(fs_p_extra);
			color: color(blue, muted);
			line-height: 1.75em;
			margin: 0 0 20px;
		}
	}
}

.lg_subtitle {
	@include font_size(fs_h4);
	color: color(black);
	font-weight: 600;
	letter-spacing: 0.2em;
	margin-bottom: 50px;
	text-align: center;
	text-transform: uppercase;
}

.lg_video_container {
	margin:20px 0 30px;
	@include breakpoint(mobile-xl) {
		margin: 0;
		padding: 30px 0 100px;
	}
}

.lg_video_cta_text {
	@include font_size(fs_h4);
	color: color(blue, muted);
	font-weight: 200;
	text-align: center;
}

.lg_bottom_rule {
	border-bottom: 1px solid color(gray, x-light);
	margin-bottom: 60px;
	padding-bottom: 50px;
}

// slides
.lg_slide {
	padding: 0 30px;
	position: absolute;
	opacity: 0;
	transform: translate3d(100%, 0, 0);
	visibility: hidden;
	@include breakpoint(desktop) {
		margin: 0 auto;
		padding: 0;
		width: 960px;
	}
	&.show {
		opacity: 1;
		position: relative;
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}
	&.transition {
		transition: visibility 0s, opacity .5s .1s, transform .5s easing(ease-out-back) .1s;
	}
}

.lg_slide_form {
	.lg_cta_content {
		@include breakpoint(mobile-xl) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}
}
