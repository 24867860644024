// styles
.pdp_label_product_grid {
	.bg {
		background-color: #f2f4f5;
		height: 100%;
		left: 50%;
		margin: auto;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: height .2s ease-out, top .2s ease-out, width .2s ease-out;
		width: 100%;
		z-index: 3;
		@include breakpoint(tablet) {
			border-radius: 5px;
		};
	}
	.product_list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		list-style: none;
		margin-bottom: 60px;
		margin-left: -20px;
		margin-right: -20px;
		margin-top: 0;
		padding: 0;
		@include breakpoint(mobile-xl) {
			margin-left: 0;
			margin-right: 0;
		}
		// @include breakpoint(tablet) {
		// 	justify-content: flex-start;
		// 	margin-left: 0;
		// 	margin-right: 0;
		// }
		.product {
			box-sizing: border-box;
			margin-bottom: 4px;
			// min-height: 200px;
			position: relative;
			width: calc(50% - 2px);
			z-index: 10;
			@include breakpoint(zero, tablet) {
				// make the tile heights all consistent on mobile
				display: flex;
			}
			@include breakpoint(mobile-xl) {
				margin-bottom: 10px;
				width: calc(50% - 5px);
			}
			// @include breakpoint(tablet) {
			// 	height: 1px; // old safari calc child can't inherit parent height bug fix?
			// 	margin-bottom: 16px;
			// 	margin-right: 16px;
			// 	width: 280px;
			// }
			// @include breakpoint(max-width-with-gutters) {
			// 	width: 308px;
			// }
			.link {
				height: 100%;
				left: 0;
				opacity: 0;
				pointer-events: auto;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
		.product_content {
			@include font_smoothing;
			padding-bottom: 35px;
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 40px;
			pointer-events: none;
			position: relative;
			text-align: center;
			z-index: 4;
			height: calc(100% - 5px);
			box-sizing: border-box;
			@include breakpoint(tablet) {
				padding-bottom: 35px;
				padding-top: 25px;
			}
		}
		.product_flag {
			@include font_size(fs_p_note_extra);
			background-color: color(blue);
			border-radius: 3px;
			color: color(white);
			display: inline-block;
			font-weight: 600;
			letter-spacing: .175em;
			line-height: 1.8em;
			margin-bottom: 15px;
			padding-left: .75em;
			padding-right: .75em;
			text-transform: uppercase;
			white-space: nowrap;
			@include breakpoint(tablet) {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				left: 50%;
				margin-bottom: 0;
				position: absolute;
				top: 0;
				transform: translate3d(-50%, 0, 0);
				transition: top .2s ease-out;
			}
		}
		.product_image {
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 170px;
			padding-bottom: 10px;
			padding-top: 10px;
			transition: opacity .5s ease-out .1s, transform .2s ease-out;
			@include breakpoint(tablet) {
				padding-bottom: 30px;
				padding-top: 30px;
			}
		}
		.product_info {
			box-sizing: border-box;
			cursor: pointer;
			position: relative;
			transition: height .2s ease-out;
			z-index: 3;
			@include breakpoint(zero, tablet) {
				width: 100%;
			}
			@include breakpoint(tablet) {
				height: 100%;
			}
		}
		.product_subtitle,
		.product_title {
			@include font_size(fs_p);
			transition: transform .2s ease-out;
		}
		.product_subtitle {
			color: color(gray, light);
			display: none;
		}
		.product_title {
			color: color(gray, x-dark);
			font-weight: 600;
			margin-bottom: 20px;
			@include breakpoint(tablet) {
				margin-bottom: 0;
			}
		}
	}
}

.pdp_billboard {
	@include section_reset;
	background-color: white;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow-y: hidden;
	&, &.axio {
		padding-bottom: 0;
		padding-top: 0;
	}
	@include nav-condensed {
		&, &.axio {
			padding-bottom: 0;
			padding-top: 1em;
		}
	}
	@include breakpoint(tablet) {
		&, &.axio {
			padding-bottom: 4em;
			padding-top: 4em;
		}
	}
	@include vertical-breakpoint(1080px, false, false) {
		height: calc(100vh - #{ height(nav-full-extra) + height(pre-header) + height(pdp-tabs) });
		&, &.axio {
			padding-bottom: 0;
			padding-top: 0;
		}
		.content_wrapper {
			@include vertical-center(-3%);
		}
	}
	&.activated_essentials {
		.title {
			@include breakpoint(zero, mobile) {
				font-size: 42px;
			}
			@include breakpoint(mobile, desktop) {
				font-size: 56px;
			}
			@include breakpoint(desktop, desktop-xl) {
				font-size: 74px;
			}
			@include breakpoint(desktop-xl) {
				font-size: 84px;
			}
		}
	}
	&.axio {
		background-image: url(../img/products/pdp-billboard-bg-axio.jpg);
		background-position: top right;
		@include breakpoint(tablet) {
			background-position: center bottom;
		}
	}
	&.bundles {
//		background-image: url('../img/products/pdp-billboard-bg-bundles.jpg');
//		background-size: auto 100%;
		.title {
			@include breakpoint(zero, mobile) {
				font-size: 42px;
			}
			@include breakpoint(mobile, desktop) {
				font-size: 56px;
			}
			@include breakpoint(desktop, desktop-xl) {
				font-size: 74px;
			}
			@include breakpoint(desktop-xl) {
				font-size: 84px;
			}
		}
	}
	&.petandim {
		background-image: url(../img/products/pdp-billboard-bg-petandim-mobile.jpg);
		background-position: center -50px;
		background-size: 768px auto;
		@include breakpoint(tablet) {
			background-size: cover;
			background-image: url(../img/products/pdp-billboard-bg-petandim.jpg);
			background-position: center bottom;
		}
		.title {
			// This is to prevent Synergizer from cutting off when the page shrinks
			@include breakpoint(mobile, tablet-l) {
				font-size: 64px;
			}
			@include breakpoint(tablet-l, desktop) {
				font-size: 78px;
			}
			@include breakpoint(desktop, desktop-xl) {
				font-size: 96px;
			}
		}
	}
	&.lifevantage {
		background-image: url(../img/products/pdp-billboard-bg-omega-mobile.jpg);
		background-position: center top;
		@include breakpoint(tablet) {
			background-size: cover;
			background-image: url(../img/products/pdp-billboard-bg-omega.jpg);
			background-position: center bottom;
		}
	}
	&.physiq {
		background-image: url(../img/products/pdp-billboard-bg-physiq.jpg);
		background-position: top right;
		@include breakpoint(tablet) {
			background-position: center bottom;
		}
	}
	&.protandim,
	&.pro-tw {
		background-color: #00041f;
		background-image: url(../img/products/pdp-billboard-bg-protandim-nrf2.jpg);
		&.protandim_nad {
			background-color: #00041f;
			background-image: url(../img/products/pdp-billboard-bg-protandim-nad.jpg);
		}
		&.protandim_nrf1 {
			background-color: #07060b;
			background-image: url(../img/products/pdp-billboard-bg-protandim-nrf1.jpg);
		}
		&.protandim_tri {
			background-color: #010623;
			background-image: url(../img/products/pdp-billboard-bg-protandim-tri.jpg);
		}
		background-size: auto 100%;
		@include breakpoint(zero, tablet) {
			background-position: 20% center;
		}
		@include breakpoint(tablet) {
			background-position: center center;
		}
		@include aspect-ratio-min(8, 6) {
			background-size: auto 130%;
		}
		@include aspect-ratio-min(9, 6) {
			background-size: auto 160%;
		}
		@include aspect-ratio-min(10, 6) {
			background-size: auto 190%;
		}
		@include aspect-ratio-min(11, 6) {
			background-size: auto 220%;
		}
		@media only screen and (min-width: 768px) and (max-width: 1024px) and (max-height: 1024px) {
			background-size: auto 150%;
		}
		.btn_new {
			@extend .btn_new.reverse;
		}
		.title {
			color: color(white);
			// This is to prevent Synergizer from cutting off when the page shrinks
			@include breakpoint(zero, mobile) {
				font-size: 42px;
			}
			@include breakpoint(mobile, desktop) {
				font-size: 56px;
			}
			@include breakpoint(desktop, desktop-xl) {
				font-size: 74px;
			}
			@include breakpoint(desktop-xl) {
				font-size: 84px;
			}
			.name {
				color: color(white);
			}
		}
		.about,
		.pretitle,
		.price,
		.subtitle,
		.size {
			color: color(white);
		}
		.controls {
			.returns {
				color:rgba(255,255,255,0.8);
				&:hover {
					color:color(white);
					text-decoration:underline;
				}
			}
		}
	}
	&.truescience {
		background-image: url(../img/products/pdp-billboard-bg-truescience.jpg);
		background-position: top right;
		@include breakpoint(tablet) {
			background-position: center bottom;
		}
		.pretitle {
			@include font_size(fs_p_extra);
			color: color(gray, light);
			@include font-smoothing;
			letter-spacing: .1em;
			margin-bottom: 30px;
		}
		.subtitle {
			@include font_size(fs_p);
			color: color(gray, light);
			@include font-smoothing;
			font-weight: normal;
			letter-spacing: .05em;
			text-transform: none;
		}
		.title {
			@include font_size(fs_h1_small);
		}
	}
	.about {
		@include font-smoothing;
		font-family: font(serif);
		letter-spacing: letter-spacing(serif);
		@include font_size(fs_h5);
		font-weight: 300;
		max-width: 22em;
	}
	.content {
		background-color: transparent;
		@include breakpoint(zero, tablet) {
			padding-bottom: 2em;
		}
		@include breakpoint(tablet) {
			width: 55%;
		}
		@include breakpoint(desktop-l) {
			width: 45%;
		}
	}
	.content_wrapper {
		align-items: center;
		display: flex;
		@include nav-condensed {
			flex-wrap: wrap;
		}
	}
	.controls {
		margin-top: 1em;
		transform: translate3d(0, 0, 0);
		@include nav-condensed {
			display: none;
		}
		.spinner {
			margin-right: 10px;
		}
	}
	.custom_select_tight {
		width: 55%;
		// @include breakpoint(zero, mobile-xl) {
		// 	width: 100%;
		// }
		@include nav-condensed {
			display: none;
		}
	}
	.hero {
		text-align: center;
		@include breakpoint(zero, tablet) {
			max-height: 325px;
			width: 100%;
		}
		@include breakpoint(tablet) {
			height: 100%;
			width: 45%;
			max-height: calc(100vh - #{ height(nav-full) + height(pre-header) + 100 });
		}
		@include breakpoint(desktop-l) {
			width: 55%;
		}
		&.product_id_error {
			img:first-child {
				display: block;
			}
		}
		img {
			display: none;
			width: auto;
			@include breakpoint(zero, tablet) {
				margin-left: auto;
				margin-right: auto;
			}
			@include breakpoint(tablet) {
				@include aspect-ratio-min(4, 3) {
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
		.selected {
			display: block;
		}
	}
	.legal_copy {
		@include font_size(fs_p_note_extra);
		font-family: font(sans);
		letter-spacing: 0;
	}
	.price {
		@include font-smoothing;
		@include font_size(fs_h5);
		color: color(gray, dark);
		font-weight: 100;
		letter-spacing: .05em;
		margin-bottom: 1.3em;
		text-transform: uppercase;
		@include nav-condensed {
			display: none;
		}
		.amount {
			font-weight: bold;
		}
	}
	.size {
		@include font-smoothing;
		@extend .bottom_rule;
		font-family: font(serif);
		font-weight: 300;
		letter-spacing: letter-spacing(serif);
	}
	.pdp_promo_label {
		@include font_size(fs_p_small);
		display: inline;
		background-color: color(yellow);
		color: color(gray, light);
		padding-left: .5em;
		padding-right: .5em;
		line-height: 1.2em;
		text-transform: uppercase;
		&.lto {
			background-color: #ff6666;
			color: color(white);
		}
	}
	.pretitle {
		letter-spacing:0.15em;
		text-transform:uppercase;
		margin-bottom:1em;
		font-weight: 600;
	}
	.title {
		@extend .h1_sans;
		color: color(gray, dark);
		margin-bottom: .2em;
		text-transform: uppercase;
		// our version of proxima's line heights get wonky, this tries to sort for it
		.light + .name {
			margin-top: .03em;
		}
		// our version of proxima's line heights get wonky, this tries to sort for it
		.name + .light {
			margin-top: -.02em;
		}
		.name {
			color: color(blue);
			font-weight: 900;
			line-height: .8em;
			transform: translateY(.01em); // our version of proxima's line heights get wonky, this tries to sort for it
			sup {
				top:-.7em;
				font-size:50%;
			}
		}
	}
	.subtitle {
		letter-spacing:0.05em;
		text-transform:none;
		margin-top:-.5em;
		margin-bottom:1em;
		font-weight: 400;
		color:color(gray, light);
	}
}

.controls {
	.returns {
		display:block;
		margin-top:10px;
		padding-top:.8em;
		padding-bottom:.8em;
		color:rgba(0,0,0,0.8);
		text-decoration:none;
		clear:both;
		&:hover {
			color:color(black);
			text-decoration:underline;
		}
		#quick_shop & {
			color:color(gray);
		}
	}
}

.pdp_mobile_controls {
	@extend %mobile_product_controls;
	height: 50px;
	position: relative;
	&.open {
		.menu {
			transform: translate3d(0, 0, 0);
			transition-delay: 0;
			visibility: visible;
		}
		.heading {
			a.action {
				&:after {
					transform: translate3d(0, 0, 0);
				}
			}
		}
	}
	.heading {
		a.action {
			&:after {
				background-color: color(blue, muted);
				background-image: svg(home-close);
				background-position: right center;
				background-repeat: no-repeat;
				content: '';
				cursor: pointer;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				transform: translate3d(100%, 0, 0);
				transition: transform .4s easing(ease-out-expo);
				width: 100%;
			}
		}
	}
	.menu {
		position: absolute;
		top: 50px;
		transform: translate3d(0, -100%, 0);
		transition: transform .4s easing(ease-out-expo), visibility 0 .4s;
		visibility: hidden;
		width: 100%;
		z-index: 1;
	}
	.controls {
		.returns {
			text-align:right;
		}
	}
}

.pdp_tab_nav {
	@extend %pdp_nav_shadow;
	background: color(white);
	height: 40px;
	overflow: hidden;
	.content_wrapper {
		position: relative;
	}
	.controls {
		position: absolute;
		right: 0;
		top: 0;
		@include gutter-s {
			right: width(gutter-outer-s) * -1;
		}
		@include gutter-l {
			right: width(gutter-outer-l) * -1;
		}
		@include nav-full {
			right: 0;
		}
//		a {
//			@extend .btn_new, .btn_new.small;
//		}
	}
	.logo {
		display: none;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		@include nav-full {
				display: block;
		}
		img {
			display: block;
			height: 40px;
			width: auto;
		}
		&.protandim,
		&.pro-tw {
			background: url(../img/products/tab-nav-protandim-logo.png) no-repeat center top;
			background-size: 115px 40px;
			height: 40px;
			width: 115px;
		}
		&.axio {
			background: url(../img/products/tab-nav-axio-logo.png) no-repeat center top;
			background-size: 49px 40px;
			height: 40px;
			width: 49px;
		}
		&.physiq {
			background: url(../img/products/tab-nav-physiq-logo.png) no-repeat center top;
			background-size: 75px 40px;
			height: 40px;
			width: 75px;
		}
		&.truescience {
			background: url(../img/products/tab-nav-truescience-logo.png) no-repeat center top;
			background-size: 125px 40px;
			height: 40px;
			width: 125px;
		}
		&.petandim {
			background: url(../img/products/tab-nav-canine-logo.png) no-repeat center top;
			background-size: 145px 40px;
			height: 40px;
			width: 158px;
		}
	}
	.select_wrapper {
		select {
			width: 80%;
		}
	}
}

.pdp_tab_section {
	@include section_reset;
	@include vertical-pad;
	background-color: transparent;
	p {
		@extend .p_extra;
		color: color(gray);
		margin-top: 0;
	}
	ul {
		@extend .fs_p;
		margin-left: 0;
		line-height: 1.5em;
		li {
			margin-bottom: 0.5em;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.accreditation_list {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		.accreditation_item {
			list-style: none;
			margin-bottom: 20px;
			position: relative;
			@include breakpoint(zero, mobile-xl) {
				flex-basis: calc(33% - 10px);
				margin-right: 15px;
				&:nth-child(3n + 3) {
					margin-right: 0;
				}
			}
			@include breakpoint(mobile-xl, tablet-l) {
				flex-basis: calc(50% - 10px);
				margin-right: 20px;
				&:nth-child(2n + 2) {
					margin-right: 0;
				}
			}
			@include breakpoint(tablet-l) {
				flex-basis: calc(33% - 10px);
				margin-right: 15px;
				&:nth-child(3n + 3) {
					margin-right: 0;
				}
			}
			&:hover {
				.img {
					filter: grayscale(0);
					opacity: 1;
				}
			}
		}
		.img {
			filter: grayscale(100%);
			height: auto;
			opacity: .6;
			transition: filter .2s ease-out, opacity .2s ease-out;
			width: 100%;
		}
	}
	.benefits_list {
		@extend .ul_extra;
		color: color(gray);
		@include nav-full {
			max-width: 90%;
		}
	}
	.border_divider {
		border-bottom: 1px solid color(gray, light);
		border-top: 1px solid color(gray, light);
		padding: 40px 0 10px;
		margin-bottom: 40px;
		position: relative;
	}
	.content_wrapper_narrow {
		border-bottom: 1px solid color(gray, x-light);
		@include vertical-pad('padding', false, true);
		body.global_market &,
		body.sister_market & {
			border-bottom: 0;
		}
	}
	.opening_paragraph {
		margin-bottom: 75px;
	}
	.patents {
		@extend .p_extra;
		margin:0;
		padding:2em 0 0 0;
		li {
			position:relative;
			margin:0 auto;
			padding-bottom:3em;
			list-style:none;
			@include breakpoint(tablet-l) {
				float:left;
				width:50%;
			}
			strong {
				text-transform:uppercase;
			}
			&:before {
				@include svg-background;
				content: '';
				height: 10px;
				left: 0;
				opacity: 0.8;
				position: absolute;
				top: -1em;
				width: 22px;
			}
			&:nth-of-type(1):before {
				background-image: svg(home-decima-001-black);
			}
			&:nth-of-type(2):before {
				background-image: svg(home-decima-002-black);
			}
			&:nth-of-type(3):before {
				background-image: svg(home-decima-003-black);
			}
			&:nth-of-type(4):before {
				background-image: svg(home-decima-004-black);
			}
			&:nth-of-type(5):before {
				background-image: svg(home-decima-005-black);
			}
			&:nth-of-type(6):before {
				background-image: svg(home-decima-006-black);
			}
			&:nth-of-type(7):before {
				background-image: svg(home-decima-007-black);
			}
			&:nth-of-type(8):before {
				background-image: svg(home-decima-008-black);
			}
			&:nth-of-type(9):before {
				background-image: svg(home-decima-009-black);
			}
			&:nth-of-type(10):before {
				background-image: svg(home-decima-010-black);
			}
		}
	}
	.pdp_fact_label {
		display: none;
		&.selected {
			display: block;
		}
	}
	.pdp_tab_img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		@include gutter-l {
			margin-left: 0;
			margin-right: 0;
//			max-width: 95%;
		}
		& + p {
				margin-top: 2em;
		}
	}
	.pdp_ingredients {
		display: none;
		&.selected {
			display: block;
		}
	}
	.grid {
		@include vertical-pad('margin', true, false, .5);
		&.tight {
			margin-top: 0;
		}
		&:first-child {
			margin-top: 0;
		}
	}
	.legal_container {
		position: relative;
	}
	.legal_copy {
		@include font_size(fs_p_note_extra);
	}
	.number {
		@extend .h1_sans;
		@extend .bottom_rule;
		color: color(blue);
		margin-top: 47px;
		&:before {
			@extend %top_dash;
		}
		&:after, &:before {
			background-color: color(blue);
			border-color: color(blue);
		}
		&:after {
			margin-bottom: .4em;
			margin-top: .4em;
			opacity: .6;
			width: 3em;
		}
	}
	.slider {
		margin-bottom: 60px;
		.col_1_2 {
			@include breakpoint(mobile-s, mobile-xl) {
				width: 100%;
			}
			@include breakpoint(mobile-xl) {
				width: 50%;
			}
		}
	}
	.slider_copy {
		p {
			@include font_size(fs_h5);
			font-family: font(serif);
			letter-spacing: 0.05em;
			@include breakpoint(mobile-s, mobile-xl) {
				margin-left: 0;
				margin-top: 30px;
			}
			@include breakpoint(mobile-xl) {
				margin-left: 40px;
				padding-top: 28px;
			}
			@include breakpoint(tablet) {
				margin-top: 40px;
				padding-top: 38px;
			}
			@include breakpoint(desktop) {
				margin-left: 40px;
				margin-top: 90px;
				padding-top: 38px;
			}
			&:before {
				@extend %top_dash;
				border-color: color(blue);
				width: 1.75em;
			}
		}
		.bottom_rule {
			&:after {
				background-color: color(blue);
				margin-top: 1.6em;
			}
		}
		.sans {
			font-family: font(sans);
			line-height: 1px;
		}
	}
	.study_copy {
		@include nav-full {
			max-width: 90%;
		}
	}
	.study_stat {
		@extend .h5_serif;
		color: color(blue);
		padding-bottom: 1.5em;
		.legal_copy {
			margin-top: 3.5em;
			max-width: 30em;
		}
	}
	.study_sup {
		font-size: .5em;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 0;
		top: -.7em;
	}
	.sub_title {
		@extend .h5_sans;
		margin-bottom: 1em;
		margin-top: 2em;
		@include breakpoint(580px, false, false) {
			&:first-of-type {
				margin-top: 0;
			}
		}
	}
	.left .sub_title:first-of-type {
		margin-top: 0;
	}

	.title {
		@extend .h3_serif;
		color: color(blue);
		margin-bottom: .5em;
		text-transform: none;
	}
}

.pdp_tab_sections {
	@extend %pdp_nav_shadow;
	background-color: color(white);
	%svg_number {
		margin-bottom: 5px;
		opacity: .5;
		width: 20px;
		height: 10px;
	}
	.one {
		@extend %svg_number;
		background-image: svg(home-decima-001-black);
	}
	.two {
		@extend %svg_number;
		background-image: svg(home-decima-002-black);
	}
}

.pdp_recommended_products {
	.banner_cta {
		&:after {
			background-color: color(gray);
		}
	}
	.wrapper {
		color: color(gray);
	}
}

.striken {
	text-decoration: line-through;
}

.price_row {
	display: flex;
	text-transform: uppercase;
	justify-content: flex-start;
	white-space: nowrap;
	.price_column {
		padding: 15px;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			margin-left:5px;
		}
	}
	.price_label {
		font-size: 12px;
		margin-bottom: 5px;
		text-transform: uppercase;
	}
	.price_value {
		font-size: 20px;
		font-weight: 700;
		.small {
			font-size: .625em;
			font-weight: 400;
			padding-left: 2px;
			text-transform: uppercase;
		}
	}
	.price_savings {
		border: 2px solid color(gray, x-light);
//		flex: none;
		transform: translate3d(0, -2px, 0)
	}
}
