.ruled_list {
	margin: 0;
	padding: 0;
	.dist_info {
		.subtitle {
			color: color(gray);
			font-family: font(serif);
			font-size: 14px;
			font-style: italic;
			text-align: left;
		}
		.title {
			color: color(blue);
			font-size: 14px;
			font-weight: 700;
			margin-bottom: 5px;
			text-transform: uppercase;
			&.secondary {
				color: color(gray, dark);
				font-weight: 400;
				text-transform: capitalize;
			}
		}
	}
	.dist_thumb {
		background-image: url('../img/profile-default.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 50%;
		height: 30px;
		margin-left: 15px;
		margin-right: 15px;
		overflow: hidden;
		width: 30px;
	}
	.list_item_container {
		align-items: center;
		display: flex;
		padding-bottom: 10px;
		padding-top: 10px;
		width: 100%;
		&[rv-on-click] {
			background-color: color(white);
			cursor: pointer;
			transition: background-color .2s ease-out;
			&:hover,
			&:active {
				background-color: color(white, near);
			}
		}
	}
	.ruled_list_item {
		border-bottom: 1px solid color(gray, xx-light);
		list-style: none;
		position: relative;
		&:first-child {
			border-top: 1px solid color(gray, xx-light);
		}
		&:hover {
			.ui_icon_chevron {
				fill: color(blue);
			}
		}
	}
	.ui_icon_chevron {
		bottom: 0;
		margin: auto;
		position: absolute;
		right: 15px;
		top: 0;
		transition: fill .5s ease-out;
	}
}
