// home (alphabetical by element, id, class, then animations)

// base styles
#home_billboard {
	.blurb,
	.subheader {
		// add .shadow to give subtle drop shadow
		&.shadow {
			text-shadow: 0 0 5px rgba(0, 6, 1, 1);
		}
	}
	.blurb,
	.secondary_controls {
		@include breakpoint(zero, tablet) {
			 max-width: 70%;
		}
		@include breakpoint(tablet, tablet-l) {
				max-width: 60%;
		}
		@include breakpoint(tablet-l) {
			 max-width: 480px;
		}
	}
	.header_group {
		@include breakpoint(zero, tablet-l) {
			max-width: 82%;
		}
	}
	.full_bg {
		background-position: center center;
		background-size: auto 100%;
		@include breakpoint(desktop) {
			@include vertical-breakpoint(0px, 768px, false) {
				background-size: auto 150%;
			}
			@include vertical-breakpoint(768px, 1024px, false) {
				background-size: auto 125%;
			}
		}
	}
	.header {
		// add .tight to hide the line above on smaller viewports
		&.tight {
			@include breakpoint(zero, tablet) {
				&:before {
					display: none;
				}
			}
		}
	}
	.product {
		transform: translate3d(0, 0, 0);
		transition: transform .2s ease-out;
		&:hover {
			transform: translate3d(0, -10px, 0);
		}
	}
	.sub_header {
		// use .grouping for mobile lines
		@include breakpoint(zero, tablet) {
			.grouping {
				display: block;
			}
		}
	}
}

// biohack billboard
#home_billboard.biohack {
	.full_bg {
		position: absolute;
		.img {
			background-image: url(../img/home/billboard-biohack.jpg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			width: 100%;
		}
	}
	.header::before {
		display: none;
	}
}

// bundles billboard
#home_billboard.bundles {
	background-color: color(white, near);
	text-align: center;
	.blurb {
		color: color(gray);
		strong {
			color: color(gray, dark);
		}
	}
	.blurb,
	.secondary_controls {
		margin-left: auto;
		margin-right: auto;
		max-width: 27em;
	}
	.header_group {
		@include breakpoint(zero, tablet-l) {
			max-width: 100%;
		}
	}
	 .full_bg {
		position: absolute;
		.img {
			background-image: url(../img/home/billboard-bundles.jpg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			width: 100%;
			.jp & {
				background-image: url(../../lifevantage-jp/img/home/billboard-bundles.jpg);
			}
		}
	}
	.header {
		color: color(blue);
		margin-bottom: .25em;
		.light {
			color: color(gray, dark);
		}
		&::before {
			display: none;
		}
	}
	.header_group {
		margin-left: auto;
		margin-right: auto;
	}
}

// dual protandim billboard
#home_billboard.dual {
	background-color: transparent;
	overflow: visible;
	&.scrolled {
		// scroll hint
		.full_bg:after {
			opacity: 0;
		}
	}
	.blurb {
		@include breakpoint(zero, tablet) {
			.line {
				display: inline;
			}
		}
	}
	.content_wrapper {
		z-index: 5;
	}
	.dual_wrapper {
		height: 100%;
		left: 0;
		margin-left: auto;
		margin-right: auto;
		max-width: 1600px;
		position: absolute;
		right: 0;
		width: 100%;
	}
	.full_bg {
		position: absolute;
		// scroll hint
		&:after {
			transition: opacity .5s ease-out;
			z-index: 5;
		}
		.bg {
			z-index: 1;
			&:after, &:before {
				background-image: none;
				content: '';
				display: block;
				position: absolute;
				height: 100%;
				top: 0;
				width: 50%;
				z-index: 2;
			}
			&:before {
				background-color: #000419;
				left: 0;
			}
			&:after {
				background-color: #101010;
				left: 50%;
			}
		}
		.img {
			background-image: url('../img/home/billboard-dual-bg.jpg');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: auto 100%;
			height: 100%;
			position: absolute;
			width: 100%;
			z-index: 3;
			@include breakpoint(zero, tablet) {
				left: 12%;
			}
		}
	}
	.header {
		margin-bottom: .25em;
	}
	.hero {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: auto 100%;;
		height: 100%;
		opacity: 1;
		position: absolute;
		transform: rotateX(0) translate3d(0, 0, 0);
		transition: opacity .5s ease-out, transform .5s ease-out;
		width: 100%;
		z-index: 4;
		@include breakpoint(zero, tablet) {
			left: 12%;
		}
		&.staged {
			opacity: 0;
		}
	}
	.hero_nrf1 {
		background-image: url('../img/home/billboard-dual-nrf1.png');
		transition-delay: .1s;
		&.float {
			animation-delay: .1s;
		}
		&.float {
			animation: right_float 7s ease-in-out alternate infinite;
		}
		&.staged {
			transform: rotateX(10deg) translate3d(2vh, 2vh, 0);
		}
	}
	.hero_nrf2 {
		background-image: url('../img/home/billboard-dual-nrf2.png');
		&.float {
			animation: left_float 7s ease-in-out alternate infinite;
		}
		&.staged {
			transform: rotateX(-10deg) translate3d(-2vh, -2vh, 0);
		}
	}
	.secondary_controls {
		text-align: left;
	}
}

// Hair Care billboard
#home_billboard.hair-care {
	background-color: transparent;
	overflow: visible;
	&.scrolled {
		// scroll hint
		.full_bg:after {
			opacity: 0;
		}
	}
	.blurb {
		color: color(gray, base);
		@include breakpoint(zero, tablet) {
			.line {
				display: inline;
			}
		}
	}
	.content_wrapper {
		z-index: 5;
		.header_group {
			color: color(blue, base);
		}
	}
	.full_bg {
		position: absolute;
		// scroll hint
		&:after {
			transition: opacity .5s ease-out;
			z-index: 5;
		}
		.bg {
			z-index: 1;
			&:after {
				// custom color to match image background
				background-color: #efefef;
				content:"";
				height: 100%;
				position: absolute;
				width: 100%;
			}
		}
		.img {
			background-image: url('../img/home/billboard-truescience-hair-care-system-bg.jpg');
			background-position: top center;
			background-repeat: no-repeat;
			background-size: auto;
			filter: blur(1px);
			height: 100%;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 102%;
			z-index: 3;
			@include breakpoint(zero, desktop) {
				filter: blur(5px);
				opacity: .25;
			}
		}
	}
	.header {
		@include font-size(fs_h1_small);
		margin-bottom: .25em;
		&:before {
			border-color: color(blue, base);
		}
	}
	.hero {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: auto 100%;;
		height: 100%;
		opacity: 1;
		position: absolute;
		transform: translate3d(0, 0, 0);
		transition: opacity .5s ease-out, transform .5s ease-out;
		width: 100%;
		z-index: 4;
		@include breakpoint(tablet, desktop-l) {
			left: 4%;
		}
		@include breakpoint(mobile-xl, tablet) {
			left: 10%;
		}
		&.staged {
			opacity: 0;
		}
	}
	.hero_haircare {
		background-image: url('../img/home/billboard-truescience-hair-care-system-hero.png');
		transition-delay: .1s;
		&.float {
			animation: vertical_float 7s ease-in-out alternate infinite;
			animation-delay: .1s;
			@include breakpoint(zero, tablet) {
				animation: none;
			}
		}
		&.staged {
			transform: translate3d(0, 2vh, 0);
		}
	}
	.secondary_controls {
		text-align: left;
	}
}


// nrf1 billboard
#home_billboard.nrf2 {
	background-color: #000d26;
	.full_bg {
		background-image: url('../img/home/billboard-nrf2.jpg');
	}
}
#home_billboard.nrf1 {
	background-color: #050409;
	.full_bg {
		background-image: url('../img/home/billboard-nrf1.jpg');
	}
}

// PhysIQ billboard
#home_billboard.physiq {
	background-color: #8da7b8;
	.full_bg {
		background-image: url('../img/home/billboard-physiq.jpg');
	}
}

// tighter focus billboard
#home_billboard.tighter_focus {
	background-color: #001d40;
	.full_bg {
		background-image: url('../img/home/billboard-teaser.jpg');
	}
	.particles {
		background-position: center 90px;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		@include breakpoint(tablet-s) {
			background-position: center 75%;
			background-size: contain;
		}
		@include breakpoint(desktop) {
			background-position: center 90px;
		}
	}
	.particles_1 {
		animation: particle_1 3s linear alternate infinite;
		background-image: url('../img/home/billboard-particles-1.png');
	}
	.particles_2 {
		animation: particle_2 4s linear alternate infinite;
		background-image: url('../img/home/billboard-particles-2.png');
	}
	.particles_3 {
		animation: particle_3 3s linear alternate infinite;
		background-image: url('../img/home/billboard-particles-3.png');
	}
}
// TrueScience Billboard
#home_billboard.truescience {
	background-color: #050409;
	.full_bg {
		background-size: cover;
		background-image: url('../img/home/billboard-texture.jpg');
	}
}

// vitality billboard
#home_billboard.vitality {
	.content_wrapper {
		z-index: 5;
	}
	.full_bg {
		position: absolute;
		.img {
			background-image: url(../img/home/billboard-vitality.jpg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			width: 100%;
		}
	}
	.header::before {
		display: none;
	}
	.hero {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: auto 100%;;
		height: 100%;
		opacity: 1;
		position: absolute;
		transform: rotateX(0) translate3d(0, 0, 0);
		transition: opacity .5s ease-out, transform .5s ease-out;
		width: 100%;
		z-index: 3;
		@include breakpoint(zero, tablet) {
			left: 12%;
		}
		&.staged {
			opacity: 0;
		}
	}
	.vitality-stack {
		background-image: url(../img/home/feature-vitality-stack-new-probio.png);
	}
}

// animations
@keyframes right_float {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(10px, 1.5vh, 0);
	}
}
@keyframes left_float {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-5px, -2.5vh, 0);
	}
}

@keyframes particle_1 {
	0% {
		top: 0;
	}
	100% {
		top: -20px;
	}
}

@keyframes particle_2 {
	0% {
		top: -15px;
	}
	100% {
		top: 0;
	}
}

@keyframes particle_3 {
	0% {
		opacity: .7;
		transform: 0;
	}
	100% {
		opacity: 1;
		transform: -16px;
	}
}
@keyframes vertical_float {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(0, 1.5vh, 0);
	}
}
