// sections (alphabetical by element, id, class)

section {
	body.home & {
		position: relative;
		z-index: z-index(above-billboard);
	}
}

#condensed_cart {
	background-color: color(white);
	display: none;
	height: 100%;
	min-height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: z-index(condensed-cart);
	&.open {
		display: block;
	}
	.cart {
		&.has-distributor {
			border-top: 1px solid color(gray, x-light);
			margin-top: 20px;
		}
		.share_cart {
			margin-bottom:0;
		}
	}
	.distributor {
		position: relative;
		text-align: center;
		transform: translate3d(0, 0, 0);
		z-index: 1;
		&.open {
			.info {
				display: block;
			}
			.who {
				.name {
					&:after {
						transform: rotate(-180deg);
					}
				}
			}
		}
		.actions {
			@extend .h6_sans;
			margin-bottom: 1em;
			a {
				color: color(blue);
				padding-left: .25em;
				padding-right: .25em;
				text-decoration: none;
			}
		}
		.avatar {
			@extend .avatar_rounded;
			height: 40px;
			margin-left: auto;
			margin-right: auto;
			transform: translateY(-20px);
			width: 40px;
		}
		.info {
			display: none;
		}
		.find_your_sponsor {
			@include font_size(fs_p);
			font-family: font(serif);
			font-style: italic;
			text-decoration: none;
		}
		.stats {
			@extend .ul_reset;
			@extend .p;
			a {
				color: color(blue);
				text-decoration: none;
			}
		}
		.who {
			margin-top: -20px;
			.intro {
				@include font_size(fs_p);
				color: color(gray, light);
				font-family: font(serif);
				font-style: italic;
				line-height: 2em;
			}
			.name {
				@extend .h6_sans;
				&:after {
					@extend %caret_down;
					margin-left: .2em;
				}
			}
		}
	}
	.nav {
		@include clearfix;
		height: 40px;
		a {
			@extend .h6_sans;
			color: color(white);
			cursor: pointer;
			display: block;
			float: left;
			height: 100%;
			line-height: 40px;
			position: relative;
			text-align: center;
			text-decoration: none;
			width: 50%;
			&:after {
				@include svg-background;
				content: '';
				display: block;
				height: 40px;
				opacity: .4;
				position: absolute;
				top: 0;
				transition: all .2s easing(ease-in-out-quad);
				width: 40px;
			}
			&:hover {
				&:after {
					opacity: 1;
				}
			}
		}
		.back {
			background-color: color(black, near);
			&:after {
				background-image: svg(home-chevron-left-white);
				left: 0;
			}
		}
		.checkout {
			background-color: color(blue);
			&:after {
				background-image: svg(home-chevron-right);
				right: 0;
			}
		}
	}
}

#condensed_nav {
	height: 100%;
	@include nav-full {
		display: none;
	}
	&.open {
		.bar {
			.toggle_drawer {
				&:after {
					transform: rotate(-45deg);
				}
				&:before {
					transform: rotate(45deg);
				}
				span {
					opacity: 0;
				}
			}
		}
	}
	.bar {
		height: height(nav-condensed);
		position: relative;
		#header.filled &, &.filled {
			background-color: color(white);
			.logo {
				svg {
					fill: color(blue);
				}
			}
			.toggle_drawer {
				&:after, &:before, span {
					background-color: color(black, near);
				}
			}
		}
		#header.docked.filled & {
			box-shadow: 0 6px 6px -6px rgba(0, 0, 0, .2);
		}
	}
	.cart_nav {
		@include vertical-center;
		height: height(nav-condensed);
		position: absolute;
		right: 0;
	}
	.logo {
		@include vertical-center;
		height: 21px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 4px;
		width: 120px;
		#header.dark_links & {
			svg {
				fill: color(black);
			}
		}
	}
	.toggle_drawer {
		@include vertical-center;
		cursor: pointer;
		height: 16px;
		position: absolute;
		width: 20px;
		&:after, &:before, span {
			background-color: color(white);
			content: '';
			height: 2px;
			left: 0;
			position: absolute;
			transition: all .2s easing(ease-out-quad);
			width: 100%;
			#header.dark_links & {
				background-color: color(black);
			}
		}
		&:after {
			bottom: 0;
			transform-origin: left bottom;
		}
		&:before {
			top: 0;
			transform-origin: left top;
		}
		span {
			@include tir;
			display: block;
			top: 7px;
		}
	}
}

#condensed_nav_drawer {
	color: color(white);
	display: none;
	height: 100%;
	left: 0;
	min-height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	top: 0;
	z-index: z-index(condensed_nav_drawer);
	min-width:250px;
	&.open {
		display: block;
	}
	&.no_scroll {
		overflow: hidden;
	}
	.group {
		@include font_size(fs_p);
		margin-bottom: .25em;
		body.global_market &,
		body.sister_market & {
			display: none;
			&.allowed,
			&.home,
			&.products {
				display: block;
			}
		}
		a {
			display:block;
			line-height:3.3em;
		}
		&.has-children {
			position:relative;
			.header {
				cursor: pointer;
				display: block;
				position: relative;
				line-height:3.3em;
				&:after {
					content: '';
					float: right;
					width: 30px;
					height: 15px;
					opacity: .4;
					// @include vertical-center;
					position: absolute;
					right: 0;
					top: 50%;
					margin-top:-8px;
					transition: all .2s easing(ease-in-out-quad);
					@include svg-background;
					background-image: svg(home-chevron-down);
				}
			}
		}
		&.open {
			margin-bottom: 2.5em;
			.group_nav {
				height: auto;
				overflow: visible;
				margin-top:.5em;
			}
			a {
				line-height:2.5em;
			}
			.header {
				color: color(white);
				&:after {
					opacity: 1;
					transform: rotate(180deg);
				}
			}
		}
	}
	.group_nav {
		@include font_size(fs_p);
		height: 0;
		letter-spacing: letter-spacing(sans, open);
		line-height: 1.5em;
		list-style: none outside;
		overflow: hidden;
		margin: 0;
		padding: 0;
		text-transform: uppercase;
		a {
			text-decoration: none;
			transition: color .2s easing(ease-in-out-quad);
			&, &:focus, &:visited {
				color: color(white);
			}
			&:hover, &:active {
				color: color(blue, x-light);
			}
		}
		li {
			// margin-top: 1em;
		}
	}
	.header {
		@extend .h6_sans;
		color: color(blue, light);
		text-decoration: none;
	}
	.join_us {
		body.global_market &,
		body.sister_market & {
			display: none;
		}
		// @TODO remove after Austria Market launch
		body.at & {
			display: block;
		}
	}
	.main {
		background-color: color(blue);
		box-sizing: border-box;
		min-height: 100vh;
		min-height: calc(100vh - #{height(pre-header)});
		padding: width(gutter-outer-s);
		padding-bottom: width(gutter-outer-s) + 60; // compensate for ios share bar
	}
	.pre {
		background-color: color(black, near);
		height: height(pre-header);
	}
}

#footer {
	@include clearfix;
	background-color: color(white);
	padding-bottom: 2rem;
	padding-left: 0; // temp main.css override
	padding-right: 0; // temp main.css override
	body.google_translate_enabled & {
		@include breakpoint(zero, tablet-l) {
			padding-bottom: calc(2rem + 40px);
		}
	}
	li {
		float: none; // temp naming collision override from main.css
		line-height: 1em;
		a {
			line-height:1.4;
			font-weight: 400; // temp naming collision override from main.css
			padding:6px 0; // temp naming collision override from main.css
		}
	}
	.about {
		@extend .note;
		color: color(gray, light);
		line-height: 1.2em;
		@include breakpoint(tablet) {
			float: right;
		}
		.wrap {
			position: relative;
			@include breakpoint(zero, tablet) {
				text-align: center;
			}
			@include breakpoint(tablet) {
				display: flex;
				text-align: right;
			}
			&.dsa {
				@include breakpoint(tablet) {
					padding-right: 50px;
				}
				&:after {
					background: url(../img/dsa-logo@2x.png) center center no-repeat;
					background-size: 100%;
					content: '';
					display: block;
					height: 33px;
					width: 37px;
					@include breakpoint(zero, tablet) {
						margin-left: auto;
						margin-right: auto;
						margin-top: 2em;
					}
					@include breakpoint(tablet) {
						position: absolute;
						right: 0;
						top: -2px;
					}
				}
			}
			.dbd {
				margin-left: auto;
				margin-right: auto;
				transform:translateY(-4px);
				width: 10em;
				@include breakpoint(zero, tablet) {
					margin-top: 2em;
				}
			}
			.dsaa {
				margin-left: auto;
				margin-right: auto;
				padding-left: 10px;
				@include breakpoint(zero, tablet) {
					margin-top: 2em;
				}
			}
		}
	}
	.disclaimer {
		@extend .note;
		color: color(gray, light);
		padding-bottom: 1em;
		text-align: center;
		&.en {
			max-width: 37em;
		}
		&.es {
			max-width: 42em;
		}
		@include breakpoint(tablet) {
			padding-bottom: 0;
			text-align: left;
		}
	}
	.extra {
		@include breakpoint(tablet) {
			float: left;
			padding-bottom: 0;
		}
	}
	.extra_nav {
		@extend .note;
		padding-bottom: 3rem;
		text-align: center;
		@include breakpoint(tablet) {
			padding-bottom: 1em;
			text-align: left;
		}
		a {
			display:inline-block;
			line-height:2.5em;
			text-decoration: none;
			white-space:nowrap;
			transition: color .2s easing(ease-out-quad);
			@include breakpoint(zero, mobile-l) {
				margin-left: .25em;
				margin-right: .25em;
			}
			@include breakpoint(mobile-l, tablet) {
				margin-left: .75em;
				margin-right: .75em;
			}
			@include breakpoint(tablet) {
				margin-right: 1.5em;
			}
			&, &:focus, &:visited {
				color: color(gray, light);
			}
			&:hover, &:active {
				color: color(gray);
			}
		}
		br {
			@include breakpoint(zero, tablet) {
				display: none;
			}
		}
	}
	.final {
		@include section_reset;
		background-color: color(white);
		overflow: visible;
	}
	.footer_helix {
		background-color: color(white);
		position: relative;
		@include breakpoint(zero, tablet) {
			margin-bottom: 2rem;
		}
		@include breakpoint(tablet) {
			margin-bottom: 4rem;
		}
		.dark, .light {
			@include breakpoint(zero, tablet) {
				@include constrain-proportions(200%, 9%);
				background-size: 200%;
				left: -50%;
			}
			@include breakpoint(tablet) {
				@include constrain-proportions(100%, 4.5%);
				background-position: center center;
				background-repeat: repeat-x;
				background-size: 100%;
			}
		}
		.dark {
			background-image: url(../img/home/footer-helix-dark.png);
		}
		.light {
			background-image: url(../img/home/footer-helix-light.png);
			left: 0;
			top: 0;
			position: absolute;
		}
	}
	.social_nav {
		margin-bottom: 2rem;
		@include breakpoint(desktop-l) {
			float: right;
			text-align: right;
		}
	}
	.social_and_sitemap {
		@include section_reset;
		background-color: color(white);
		display: block; // temp, to ensure this block only shows inside of #footer
		padding-top: 4rem;
		body.global_market &,
		body.sister_market & {
			display: none;
		}
	}
	.sitemap_nav {
		display:flex;
		flex-wrap: wrap;
		@include clearfix;
		@include breakpoint(zero, tablet) {
			padding-bottom: 2rem;
		}
		@include breakpoint(tablet, desktop-l) {
			padding-bottom: 4rem;
		}
		@include breakpoint(desktop-l) {
			float: left;
			width: calc(100% - 310px);
		}
		a {
			display:block;
			text-decoration: none;
			transition: color .2s easing(ease-out-quad);
			&, &:focus, &:visited {
				color: color(black, near);
			}
			&:hover, &:active {
				color: color(blue);
			}
		}
		.group {
			flex: 1;
			box-sizing: border-box;
//			float: left;
			@include breakpoint(zero, tablet) {
				flex-basis: 50%;
				flex-grow: 1;
				&:nth-child(4) {
					flex-grow: 2;
				}
				&:nth-child(2n+1) {
					clear: both;
				}
			}
			@include breakpoint(tablet, desktop-l) {
				flex-basis: 25%;
				flex-grow: 1;
				&:nth-child(4) {
					flex-grow: 2;
				}
				&:nth-child(4n+1) {
					clear: both;
				}
			}
			@include breakpoint(desktop-l) {
				flex-grow: 1;
				padding-right: width(gutter-inner-l) * 3;
				&:nth-child(4) {
					flex-grow: 2;
				}
			}
			@include breakpoint(desktop-xl) {
				padding-right: width(gutter-inner-l) * 4;
			}
		}
		.group_nav {
			@include font_size(fs_p_note_extra);
			margin: 1.5em 0 4em 0;
			padding: 0;
		}
		.group_nav_item {
			// margin-top: .75em;
		}
	}
}

//app section in footer
.app_footer {
	@include section_reset;
	margin-top: 30px;
	overflow: visible;
	.app_icons {
		display: flex;
		justify-content: space-between;
	}
	.footer_app_list {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		padding: 0;
		@include breakpoint(tablet) {
			flex-direction: row;
		}
		li {
			&:nth-child(n+2) {
				margin-top: 20px;
				@include breakpoint(tablet) {
					margin-top: 0;
				}
			}
			@include breakpoint(tablet) {
				display: inline;
				margin-right: 20px;
			}
		}
	}
	.app_solo {
		align-self: baseline;
		@include breakpoint(tablet) {
			align-self: center;
			margin-right: 12px;
		}
	}
}
// Basic social nav styles so they can be used globally
// Cascade through your local class to customize beyond this
.social_nav {
	text-align: center;
	a {
		display: inline-block;
		height: 20px;
		margin-left: 8px;
		margin-right: 8px;
		width: 20px;
		@include breakpoint(mobile-l) {
			height: 24px;
			margin-left: 12px;
			margin-right: 12px;
			width: 24px;
		}
		&:hover {
			animation: flash .2s easing(ease-out-back);
		}
	}
	svg {
		fill: color(black, near);
		height: 100%;
		transition: fill .2s easing(ease-out-quad);
		width: 100%;
		&:hover {
			fill: color(blue);
		}
	}
}

#full_nav {
	height: 100%;
	transform: translate3d(0, 0, 0);
	@include nav-condensed {
		display: none;
	}
	#header.filled &, &.filled {
		&.shadow {
			box-shadow: 0 6px 6px -6px rgba(0, 0, 0, .2);
		}
		.distributor, .distributor.reverse {
			line-height: 1em;
			a {
				&, &:focus, &:visited {
					color: color(black, near);
					&:before {
						background-image: svg(home-thin-arrow-right-black);
					}
				}
				&:hover, &:active {
					color: color(blue);
					&:before {
						background-image: svg(home-thin-arrow-right-blue);
					}
				}
			}
		}
		.top_nav {
			background-color: color(white);
			transition-delay: 0;
			transition-duration: .2s;
		}
	}
	.distributor {
		// @include vertical-center-3d;
		// (using transform for vertically centering is causing a hover glitch, go old school on it's arse)
		@extend .h6_sans_small;
		margin-top: -20px;
		position: absolute;
		right: 0;
		top: 50%;
		body.global_market &,
		body.sister_market & {
			display: none;
		}
		// @TODO remove after Austria Market launch
		body.at & {
			display: block;
		}
		&.dark_links {
			a {
				&, &:focus, &:visited {
					color: color(black, near);
				}
				&:before {
					background-image: svg(home-thin-arrow-right-black);
				}
				&:hover, &:active {
					color: color(black, near);
					&:before {
						background-image: svg(home-thin-arrow-right-black);
					}
				}
			}
		}
		&.reverse {
			a {
				&, &:focus, &:visited {
					color: color(white);
				}
				&:before {
					background-image: svg(home-thin-arrow-right-white);
				}
			}
		}
		a {
			@extend .arrow_link_black;
			padding-bottom: 1em;
			padding-top: 1em;
			position: relative;
			text-decoration: none;
			transition: color .2s easing(ease-out-quad);
			&, &:focus, &:visited {
				color: color(white, near);
			}
			&:before {
				top: 12px;
				background-image: svg(home-thin-arrow-right-white);
			}
			&:hover, &:active {
				color: color(white);
				&:before {
					background-image: svg(home-thin-arrow-right-white);
				}
			}
			@include breakpoint(zero, desktop) {
				padding-right: 0;
				&:before {
					display: none;
				}
			}
		}
	}
	#header.filled &, &.filled {
		.distributor {
			line-height: 1em;
			a {
				&, &:hover, &:active {
					color: color(blue);
					&:before {
						background-image: svg(home-thin-arrow-right-blue);
					}
				}
			}
		}
		.quick_nav_chevron {
			fill: color(gray);
		}
		.top_nav {
			background-color: color(white);
			transition-delay: 0;
			transition-duration: .2s;
		}
	}
	.quick_nav_chevron {
		$quick-nav-width: 10px;
		fill: color(white);
		height: $quick-nav-width;
		margin-left: $quick-nav-width;
		width: $quick-nav-width;
	}
	.logo {
		@include vertical-center;
		display: block;
		height: 25px;
		left: 0;
		position: absolute;
		width: 140px;
		z-index: 1;
		&:not(.hover_blue) {
			&:hover {
				svg {
					fill: color(black, near);
				}
			}
		}
		&.dark_links {
			svg {
				fill: color(blue);
			}
		}
		&.hover_blue {
			&:hover {
				svg {
					fill: color(blue, light);
				}
			}
		}
		#header.filled &, &.filled {
			&:hover {
				svg {
					fill: color(black, near);
				}
			}
			svg {
				fill: color(blue);
				transition-delay: 0;
			}
		}
	}
	.main {
		@include vertical-center;
		@include font-smoothing;
		text-align: center;
		@include breakpoint(zero, desktop) {
			padding-left: 100px;
		}
		a[data-behavior] {
			cursor: pointer;
		}
		.category {
			@extend .h6_sans_small;
			margin-left: 1em;
			margin-right: 1em;
			padding-bottom: 1em;
			padding-top: 1em;
			position: relative;
			text-decoration: none;
			transition: color .2s ease-out;
			@include breakpoint(desktop-l) {
				margin-left: 2em;
				margin-right: 2em;
			}
			&, &:focus, &:visited {
				color: color(white);
			}
			&:hover, &:active {
				// ie10+ won't trigger transition end without a change in value (i think, this fixies it at least)
				@include ie10-up {
					color: #fcfcfc;
				}
				&:after {
					opacity: 1;
					width: 100%;
				}
				&:before {
					opacity: 1;
				}
			}
			&:after {
				background-color: color(white);
				bottom: .5em;
				content: '';
				height: 1px;
				left: 0;
				opacity: .5;
				position: absolute;
				transition: width .2s easing(ease-in-out-quad);
				width: 1.5em;
			}
			&:before {
				@include svg-background;
				content: '';
				height: 10px;
				left: 0;
				opacity: .5;
				position: absolute;
				top: -.1em;
				width: 15px;
			}
			#header.filled &, &.filled,
			#header.dark_links, &.dark_links {
				&, &.products, &.opportunity, &.shop, &:focus, &:active {
					color: color(gray);
				}
				&.selected, &:hover, &:active {
					color: color(black, near);
				}
				&:after {
					background-color: color(blue);
				}
				.quick_nav_chevron {
					fill: color(gray);
				}
			}

		}
		.item {
			display: inline;
			position: relative;
			body.global_market &,
			body.sister_market & {
				display: none;
				&.allowed,
				&.products {
					display: inline;
				}
			}
			&:nth-of-type(1) {
				.category:before {
					background-image: svg(home-decima-01-white);
				}
				#header.filled & .category:before, .category.filled:before, #header.dark_links & .category:before {
					background-image: svg(home-decima-01-black);
				}
			}
			&:nth-of-type(2) {
				.category:before {
					background-image: svg(home-decima-02-white);
				}
				#header.filled & .category:before, .category.filled:before, #header.dark_links & .category:before {
					background-image: svg(home-decima-02-black);
				}
			}
			&:nth-of-type(3) {
				.category:before {
					background-image: svg(home-decima-03-white);
				}
				#header.filled & .category:before, .category.filled:before, #header.dark_links & .category:before {
					background-image: svg(home-decima-03-black);
				}
			}
			&:nth-of-type(4) {
				.category:before {
					background-image: svg(home-decima-04-white);
				}
				#header.filled & .category:before, .category.filled:before, #header.dark_links & .category:before {
					background-image: svg(home-decima-04-black);
				}
			}
			&:nth-of-type(5) {
				.category:before {
					background-image: svg(home-decima-05-white);
				}
				#header.filled & .category:before, .category.filled:before, #header.dark_links & .category:before {
					background-image: svg(home-decima-05-black);
				}
			}
			.category::before {
				body.global_market &,
				body.sister_market & {
					display: none;
				}
			}
		}
	}
	.top_nav {
		height: 100%;
		transition: all .15s easing(ease-out-quad) .3s;
		position: relative;
		z-index: z-index(header-top-nav);
	}
}

.google_translate_container {
	box-sizing: border-box;
	align-items: center;
	display: flex;
	height: 40px;
	justify-content: center;
	left: 0;
	margin: auto;
	right: 0;
	z-index: 40;
	@include breakpoint(zero, tablet-l) {
		background-color: color(black, near);
		bottom: 0;
		position: fixed;
		width: 100%;
	}
	@include breakpoint(tablet-l) {
		position: absolute;
		top: 0;
		width: 350px;
	}
}

#header {
	@include clearfix;
	padding: 0;
	position: absolute;
	transform: translateY(0);
	transition: all .2s ease-out;
	width: 100%;
	z-index: z-index(header);
	@include nav-condensed {
		height: height(nav-condensed);
		body.admin-bar & {
			top: height(wp-admin-bar-mobile);
			&.docked {
				top: 0;
			}
		}
	}
	@include nav-full {
		height: height(nav-full);
		@include vertical-breakpoint(1000px, false, false) {
			height: height(nav-full-extra);
		}
		body.admin-bar & {
			top: height(wp-admin-bar-desktop) + height(pre-header);
			&.docked {
				top: height(wp-admin-bar-desktop);
			}
		}
	}
	&.docked {
		box-shadow: 0 6px 6px -6px rgba(0, 0, 0, .2);
		position: fixed;
		top: 0;
		@include nav-full {
			height: height(nav-full) - 20;
			@include vertical-breakpoint(1000px, false, false) {
				height: height(nav-full-extra) - 20;
			}
		}
	}
	&.positioned {
		position: absolute;
		transition-duration: 0;
	}
	.content_wrapper {
		height: 100%;
		position: relative;
	}
	.logo {
		a {
			@include tir;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
		svg {
			fill: color(white);
			pointer-events: none;
			transition: .2s easing(ease-in-out-quad) .1s;
		}
	}
	&.scroll_hide {
		transform: translateY(-200%);
	}
}

#overlay {
	background-color: color(overlay);
	height: 100%;
	left: 0;
	opacity: 0;
	position: fixed;
	top: 0;
	transition: opacity .2s ease-out, visibility .2s;
	visibility: hidden;
	width: 100%;
	z-index: z-index(overlay);
	&.pointer {
		cursor: pointer;
	}
	&.show {
		opacity: 1;
		transition: opacity .2s ease-out;
		visibility: visible;
	}
	.btn_container {
		left: 50%;
		opacity: 0;
		position: fixed;
		top: 50%;
		transform: translate3d(-50%, 0, 0);
		@include breakpoint(zero, mobile-xl) {
			width: 80%;
		}
		&.open {
			opacity: 1;
		}
	}
	.btn_new.stealth {
		left:-50%;
		position: relative;
		@include breakpoint(zero, mobile-xl) {
			left: -40%;
		}
	}
}

#page {
	@include clearfix;
	&.outset_header {
		@include nav-condensed {
			padding-top: height(nav-condensed);
		}
		@include nav-full {
			padding-top: height(nav-full);
			@include vertical-breakpoint(1000px, false, false) {
				padding-top: height(nav-full-extra);
			}
		}
	}
}

#pre_header {
	@include clearfix;
	background-color: color(black, near);
	display: none;
	height: height(pre-header);
	position: relative;
	z-index: z-index(pre-header);
	@include nav-full {
		display: block;
	}
	body.admin-bar & {
		top: height(wp-admin-bar-mobile);
		@include breakpoint(wp-admin-bar-desktop) {
			top: height(wp-admin-bar-desktop);
		}
	}
	&.menu_open {
		.cart_nav.open, .distributor_nav.open {
			.title {
				opacity: 1;
			}
		}
		.current, .title {
			opacity: .5;
		}
	}
	.cart_nav {
		float: right;
	}
	.market_selector_nav {
		float: left;
	}
	.distributor_nav {
		float: right;
	}
	.nfr_nav {
		float: right;
	}
	.divider {
		background-color: color(gray, x-dark);
		height: height(pre-header);
		position: relative;
		width: 1px;
		&.left {
			float: left;
		}
		&.right {
			float: right;
		}
	}
	.join_us {
		body.global_market &,
		body.sister_market & {
			display: none;
		}
		// @TODO remove after Austria Market launch
		body.at & {
			display: block;
		}
	}
	.login {
		body.global_market &,
		body.sister_market & {
			&::after {
				display: none;
			}
		}
	}
	.resources_nav {
		body.global_market &,
		body.sister_market & {
			display: none;
		}
	}
	.title {
		@extend .h7_sans;
		color: color(white);
		cursor: pointer;
		font-weight: 400;
		@include font-smoothing;
		height: 40px;
		line-height: 40px;
		overflow: hidden;
		transition: all .2s easing(ease-out-expo);
		white-space: nowrap;
		&.noicon {
			text-decoration:none;
			&:hover {
//				color:#fff;
			}
		}
	}
	.title_pad {
		padding-left: width(gutter-outer-s);
		padding-right: width(gutter-outer-s);
	}
	.your_distributor .user_nav {
		body.global_market &,
		body.sister_market & {
			display: none;
		}
	}
}

#purpose_statement {
	@include section_reset;
	color: color(white);
	@include font-smoothing;
	overflow: hidden;
	position: relative;
	@include breakpoint(tablet) {
		height: 600px;
	}
	body.global_market &,
	body.sister_market & {
		display: none;
	}
	&:after {
		background-image: linear-gradient( transparentize(color(black), 1), transparentize(color(black), .5) );
		bottom: 0;
		content: '';
		height: 50%;
		display: block;
		pointer-events: none;
		position: absolute;
		width: 100%;
	}
	.content_wrapper {
		position: relative;
		z-index: 1;
		@include breakpoint(tablet) {
			@include vertical-center;
		}
	}
	.control {
		transition: color .2s easing(ease-out-quad);
		&:hover, &:active {
			color: white;
			&:before {
				background-image: svg(home-thin-arrow-right-white);
			}
		}
	}
	.controls {
		@extend .h6_sans;
		line-height: 1em;
		margin-top: 3em;
		@include breakpoint(zero, tablet) {
			padding-bottom: 4em;
		}
	}
	.full_bg {
		@extend .parallax_bg;
		background-image: url(../img/home/footer-purpose-statement.jpg);
	}
	.purpose {
		flex: 0 0 100%;
		@include ie9 {
			float: left;
		}
		@include ie9-up {
			width: 100%;
		}
		@include breakpoint(tablet) {
			flex: 1 1 66%;
			@include ie9-up {
				width: 66%;
			}
		}
	}
	.section_title {
		color: color(white);
		position: relative;
		text-shadow:0 1px 2px rgba(0,0,0,0.3), 0px 0px 35px rgba(0,0,0,0.8);
			@include breakpoint(desktop-xl) {
			text-shadow:none;
		}
		@include breakpoint(tablet) {
			position: absolute;
			width: 100%;
		}
	}
	.statement {
		color: color(white);
		font-weight: 400;
		@include breakpoint(tablet) {
			max-width: 18em;
		}
	}
	.about {
		@include font_size(fs_h4);
		margin-bottom: 1em;
		margin-top: 1em;
		@include breakpoint(tablet) {
			max-width: 32em;
		}
	}
	.sub_statement {
		margin-bottom: 1em;
		@include breakpoint(tablet) {
			max-width: 22em;
		}
	}
	.video {
		flex: 0 0 100%;
		@extend .h6_sans;
		text-align: center;
		@include ie9 {
			float: left;
		}
		@include ie9-up {
			width: 100%;
		}
		@include breakpoint(zero, tablet) {
			padding-bottom: 2em;
		}
		@include breakpoint(tablet) {
			flex: 1 1 33%;
			@include ie9-up {
				width: 33%;
			}
		}
		.play_video {
			margin-left: auto;
			margin-right: auto;
		}
	}
	.wrapper {
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding-bottom:4em;
		@include breakpoint(tablet) {
			padding-bottom:0;
		}
		@include ie9 {
			clear: both;
			display: block;
		}
	}
}

#shiftable {
	clear: both;
	// overflow-x: hidden;
	position: relative;
	z-index: z-index(shiftable);
	@include nav-condensed {
		transition: transform .2s easing(ease-out-expo);
		// &.open {
		// 	transition-timing-function: easing(ease-in-expo);
		// }
		&.left {
			transform: translate(-100%);
		}
	}
	// @chai, commenting out, not sure if this is needed
	// @include nav-full {
	// 	transform: none !important;
	// }
}

.banner_ctas {
	@include section_reset;
	background-color: color(white);
	padding-bottom: width(gutter-inner-s);
	padding-top: width(gutter-inner-s);
	z-index: z-index(above-billboard);
	@include breakpoint(tablet) {
		padding-bottom: width(gutter-inner-l);
		padding-top: width(gutter-inner-l);
	}
	@include breakpoint(desktop) {
		padding-bottom: width(gutter-inner-l) * 2;
	}
	em {
		font-family: font(serif);
letter-spacing: letter-spacing(serif);
		font-size: .75em;
		font-style: italic;
		font-weight: 300;
		text-transform: lowercase;
	}
	strong {
		text-transform: none;
	}
	.banner_cta {
		display: block;
		left: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
		@include constrain-proportions(100%, 100%);
		&:after {
			background-color: color(white);
			bottom: width(gutter-inner-s);
			content: '';
			height: 1px;
			left: width(gutter-inner-s);
			position: absolute;
			transition: width .25s easing(ease-out-back);
			width: 0;
			@include gutter-l {
				bottom: width(gutter-inner-l);
				left: width(gutter-inner-l);
			}
		}
		&.black {
			&:after {
				background-color: color(black);
			}
			.wrapper {
				color: color(black);
			}
		}
		&.gray {
			&:after {
				background-color: color(gray);
			}
			.wrapper {
				color: color(gray);
			}
		}
		&:hover {
			&:after {
				transition-delay: .1s;
				width: 40%;
				width: calc(50% - #{width(gutter-inner-s)});
				@include gutter-l {
					width: calc(50% - #{width(gutter-inner-l)});
				}
			}
			.wrapper {
				transform: translateY( width(gutter-inner-s) * -1 );
				transition-delay: 0;
				@include gutter-l {
					transform: translateY( width(gutter-inner-l) * -1 );
				}
			}
		}
	}
	.full_bg {
		transform: translate3d(0, 0, 0);
	}
	.grid {
		@extend .flex_grid_row;
		justify-content: center;
		margin-left: width(gutter-inner-s) / -2;
		margin-right: width(gutter-inner-s) / -2;
		@include breakpoint(tablet) {
			margin-left: width(gutter-inner-l) / -2;
			margin-right: width(gutter-inner-l) / -2;
		}
	}
	.grid_unit {
		@extend .flex_grid_unit_4;
		@include constrain-proportions(100%, 100%);
		max-height: 320px;
		max-width: 320px;
		position: relative;
		&:first-of-type {
			.full_bg {
				@include clip-path(polygon(7% 0%, 100% 0, 100% 100%, 0 100%, 0% 7%));
			}
		}
		&:last-of-type {
			.full_bg {
				@include clip-path(polygon(0 0, 100% 0, 100% 93%, 93% 100%, 0 100%));
			}
		}
	}
	.header {
		@extend .h4_sans;
		font-weight: 100;
	}
	.pre_header {
		@include font_size(fs_p);
		margin-bottom: .25em;
		@at-root .banner_ctas.pdp_recommended_products .pre_header {
			text-transform:uppercase;
			strong {
				text-transform:uppercase;
			}
		}
	}
	.title {
		@extend .h5_sans;
		margin-bottom: 2em;
		margin-top: 1em;
		text-align: center;
	}
	.wrapper {
		bottom: 0;
		color: color(white);
		@include font-smoothing;
		padding: width(gutter-inner-s);
		left: 0;
		position: absolute;
		transition: all .2s easing(ease-out-quad) .1s;
		transform: translateY(0);
		@include gutter-l {
			padding: width(gutter-inner-l);
		}
	}
}

.billboard {
	@include section_reset;
	background-color: color(black, near);
	color: color(white);
	overflow: hidden;
	position: relative;
	z-index: z-index(billboard);
	&__inner_shadow_top {
		position: relative;
		&::after {
			background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
			content: '';
			height: 300px;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 4;
		}
	}
	&.full {
		height: 100vh;
	}
	&.half {
		height: 50vh;
	}
	.hide {
		display: none;
	}
	&.big_intro {
		height: 100vh;
		max-height: 880px;
		min-height: 700px;
		html.ios & {
			height: calc(100vh - 60px);
		}
		@include vertical-breakpoint(0, 460px, false) {
			html.ios &, & {
				height: auto;
				padding-bottom: 4em;
				padding-top: 6em;
			}
		}
		@include nav-full {
			height: calc(100vh - #{ height(pre-header) });
		}
		.content_wrapper {
			@include vertical-breakpoint(0, 460px, false) {
				position: relative;
			}
			@include vertical-breakpoint(460px, false, false) {
				@include vertical-center;
			}
		}
		.full_bg {
			@extend .billboard_scroll_hint;
			height: 100vh;
			max-height: 880px + height(pre-header);
			@include vertical-breakpoint(0, 460px, false) {
				&:after {
					display: none;
				}
			}
			@include vertical-breakpoint(460px, false, false) {
				html.ios & {
					height: calc(100vh - 60px);
				}
			}
			@include vertical-breakpoint(880px, false, false) {
				&:after {
					display: none;
				}
			}
		}
	}
	&.intro {
		@include nav-condensed {
			height: calc(100vh - #{ height(nav-condensed) });
			html.ios & {
				height: calc(100vh - #{ height(nav-condensed) + 60px });
			}
			.content_wrapper {
				@include vertical-center(-20%);
			}
			.full_bg {
				height: 100vh;
				html.ios & {
					height: calc(100vh - 60px);
				}
			}
			@include vertical-breakpoint(560px, false, false) {
				height: 560px;
				&:after {
					display: none;
				}
				.content_wrapper {
					@include vertical-center;
				}
			}
		}
		@include nav-full {
			height: 600px;
			.content_wrapper {
				@include vertical-center;
			}
			.full_bg {
				height: 600px + height(pre-header) + height(nav-full-extra);
				&:after {
					display: none;
				}
			}
		}
		.full_bg {
			@extend .billboard_scroll_hint;
			background-position: center top;
		}
		.header {
			max-width: 70%;
		}
	}
	&.scroll_hint {
		.full_bg {
			@extend .billboard_scroll_hint;
		}
	}
	&.sneak {
		height: 90vh;
		min-height:650px;
		.content_wrapper {
			@include vertical-center(-3%);
			@include vertical-breakpoint(400px, 724px, false) {
				top: 50%;
			}
			@include vertical-breakpoint(725px, false, false) {
				top: 50%;
			}
			@include aspect-ratio-min(7, 5) {
				top: 57%;
				@include vertical-breakpoint(800px, false, false) {
					top: 50%;
				}
			}
			@include tablet-landscape {
				top: 56%;
			}
		}
		&.product_shot {
			.product {
				display: block;
				position: absolute;
				z-index: 1;
				height: 120%;
				left: 82%;
				top: -8%;
				@include breakpoint(mobile-xl) {
					height: 100%;
					top: 0;
				}
				@include breakpoint(tablet-s) {
					left: auto;
					right: 0;
				}
				@include breakpoint(desktop) {
					right: 7%;
				}
				@include breakpoint(desktop-l) {
					right: 17%;
				}
				@include vertical-breakpoint(10px, 825px, false) {
					@include breakpoint(tablet) {
//						top: -65px;
					}
				}
				&.truescience {
					@include breakpoint(tablet-s) {
						right: auto;
						left: 70%;
					}
					@include breakpoint(desktop) {
						left: auto;
						right: -20%;
					}
					@include breakpoint(desktop-l) {
						left: auto;
						right: 0;
					}
				}
			}
		}
		.thumb {
			// (assumes an image sized to 550x430px)
			display: none;
			height: 84px;
			margin-top: 1em;
			width: 110px;
			@include vertical-breakpoint(420px, false, false) {
				display: block;
				& + .sub_header {
					margin-top: 0;
				}
			}
			@include vertical-breakpoint(1000px, false, false) {
				margin-top: 2em;
			}
			@include breakpoint(mobile) {
				height: 105px;
				width: 137px;
			}
			@include breakpoint(mobile-l) {
				height: 140px;
				width: 183px;
			}
			@include breakpoint(desktop) {
				height: 210px;
				width: 275px;
			}
			@include aspect-ratio-min(4, 3) {
				display: none;
				& + .sub_header {
					margin-top: .75em;
				}
				@include vertical-breakpoint(1000px, false, false) {
					display: block;
					& + .sub_header {
						margin-top: 0;
					}
				}
			}
			&:hover {
				img {
					transform: translateY(-5px);
				}
			}
			img {
				height: 100%;
				transform: translateY(0);
				transition: all .2s ease-out;
				width: 100%;
			}
		}
	}
	.blurb {
//		@extend .h5_serif;
		margin-bottom: 0;
	}
	.content_wrapper {
		&.middle {
			text-align:center;
		}
		@include vertical-center(-3%);
		@include vertical-breakpoint(400px, 600px, false) {
			top: 50%;
		}
		@include vertical-breakpoint(725px, false, false) {
			top: 45%;
		}
		@include aspect-ratio-min(7, 4) {
			top: 57%;
			@include vertical-breakpoint(800px, false, false) {
				top: 50%;
			}
		}
		@include tablet-landscape {
			top: 56%;
		}
	}
	.control {
		color: color(white);
		@extend .split_underline_link_white;
	}
	.controls {
		flex: 1 1;
		@include ie9 {
			float: left;
		}
	}
	.full_bg {
		pointer-events: none;
		position: fixed;
		transform: translate3d(0, 0, 0);
		&:before {
			background-image: linear-gradient( transparentize(color(black), .5), transparentize(color(black), 1) ) ;
			content: '';
			height: 33%;
			display: block;
			position: absolute;
			width: 100%;
		}
		// windows phone doesn't appear to play well with translating fixed elements (when mobile drawer is open)
		@include mobile-ie10-up {
			position: absolute;
		}
	}
	.header {
		@extend .h1_sans;
		position: relative;
		margin-bottom:.5em;
		&:before {
			border-top: 4px solid color(white);
			content: '';
			left: 0em;
			position: absolute;
			top: -.4em;;
			width: .35em;
			@include breakpoint(tablet) {
				display: none;
				@include vertical-breakpoint(850px, false, false) {
					display: block;
				}
			}
		}
	}
	.middle .header {
		&:before {
			display:none;
			border:0px none;
		}
	}
	.play_video {
		margin-right: 15px;
		@include breakpoint(zero, mobile) {
			display:none;
		}
		@include ie9 {
			float: left;
		}
		@include breakpoint(mobile-xl) {
			margin-right: 25px;
			flex: 0 0 40px;
			@include ie9 {
				width: 40px;
			}
		}
		@include breakpoint(tablet) {
			margin-right: 25px;
			flex: 0 0 50px;
			@include ie9 {
				width: 50px;
			}
		}
		@include breakpoint(desktop) {
			margin-right: 30px;
			flex: 0 0 60px;
			@include ie9 {
				width: 60px;
			}
		}
	}
	.sub_header {
		@extend .h4_serif;
		margin-top: .75em;
		position: relative;
		&:after {
			background-color: color(white);
			content: '';
			display: block;
			margin-bottom: .75em;
			margin-top: .75em;
			height: 1px;
			transform: translate3d(0, 0, 0);
			width: 14em;
			@include vertical-breakpoint(500px, false, false) {
				margin-bottom: 1.25em;
				margin-top: 1.25em;
			}
		}
	}
	.middle .sub_header {
		&:after {
			margin-left:auto;
			margin-right:auto;
			opacity:.4;
		}
	}
	.secondary_controls {
		@include breakpoint(zero, tablet-s) {
			text-align:center;
		}
		@include ie9 {
			clear: both;
			position: relative;
			top: 2em;
		}
		a {
			display:inline-block;
		}
		a:nth-child(n+2) {
			margin-top:2em;
			clear:left;
			@include breakpoint(tablet-s) {
				margin-left:1.5em;
			}
		}
		@include breakpoint(zero, tablet-s) {
			.control {
				padding:0 1em;
			}
		}
		&:before {
			@extend .h4_serif;
			// background-color: color(white);
			content: '';
			display: block;
			// height: 1px;
			// margin-bottom: .75em;
			margin-top: .75em;
			// width: 12em;
			@include vertical-breakpoint(500px, false, false) {
				// margin-bottom: 1.25em;
				margin-top: 1.25em;
			}
		}
	}
	.video_group {
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		flex-direction: row;
		@include ie9 {
			clear: both;
			display: block;
		}
	}
}

.billboard_scroll_hint {
	&:after {
		background: url(../img/home/scroll-down-lv.gif) center center no-repeat;
		background-size: 100%;
		bottom: 7%;
		content: '';
		height: 48px;
		left: 0;
		margin: 0 auto;
		position: absolute;
		right: 0;
		width: 16px;
	}
}

.cart {
	background-color: color(white);
	overflow: hidden;
	@include nav-condensed {
		margin-left: width(gutter-outer-s);
		margin-right: width(gutter-outer-s);
	}
	&.items_only {
		margin-left: 0;
		margin-right: 0;
		.cart_items {
			.product:last-of-type {
				td, th {
					border-bottom: 0;
					// padding-bottom: 0;
				}
			}
		}
	}
	.controls {
		&.continue {
			.start {
				display: none;
			}
		}
		&.start {
			.continue {
				display: none;
			}
		}
	}
	.empty {
		@include nav-condensed {
			padding: 3em 0;
			.instruction {
				margin-left: auto;
				margin-right: auto;
				max-width: 20em;
			}
		}
		text-align: center;
		.header {
			@extend .h4_serif, .micro_rule;
			color: color(gray, x-dark);
		}
		.instruction {
			@extend .h7_sans;
			color: color(gray, light);
			margin-bottom: 3em;
			margin-top: 3em;
		}
		@include nav-full {
			padding: 2.5em 2em;
		}
	}
	.share_cart {
		margin-top: 20px;
		text-align:center;
		background-color:color(white, near);
		input {
			&:not([type=submit]):not([type=checkbox]) {
				width:100%;
				font-size:.8rem;
				height:2.3rem;
				padding:.6rem 1em;
			}
		}
		.share_btn {
			@extend .h6_sans_small;
			display:block;
			color:color(blue);
			cursor: pointer;
			padding:15px 2em;
			text-decoration:none;
			&:hover {
				color:color(blue, dark);
				svg {
					fill:color(blue, dark);
				}
			}
			svg {
				width:14px;
				height:1em;
				line-height:1em;
				fill:color(blue);
				margin-bottom: -1px;
				@include breakpoint(tablet-l) {
					width:15px;
					margin-bottom: -2px;
				}
				@include breakpoint(desktop) {
					width:16px;
				}
			}
		}
		.share_url {
			display: none;
			overflow: hidden;
			padding: 15px 20px 25px;
			&.show {
				display: flex;
			}
			p {
				@include font_size(fs_p_note);
				margin:0 0 5px;
				line-height:1;
			}
			.autoselect {
				// prevents zoom on iOS
				font-size: 16px!important;
				user-select: all;
			}
			.share_column {
				margin-right: 10px;
				&:last-child {
					margin-right: 0;
				}
				&.link {
					flex: 1;
					line-height: 35px;
					max-width: 360px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					@include breakpoint(tablet-l) {
						line-height: 40px;
					}
				}
			}
			.share_via {
				background-color: color(blue);
				border: 0;
				height: 35px;
				line-height: 35px;
				padding: 0 10px;
				@include breakpoint(tablet) {
					height: 39px;
					line-height: 39px;
				}
				.no-touch & {
					&:hover {
						background-color: color(blue, dark);
					}
				}
				svg {
					fill: color(white);
					vertical-align:middle;
					width: 20px;
				}
			}
		}
	}
}

.cart_items {
	border-collapse: separate;
	border-spacing: 0 10px;
	margin-bottom: -10px;
	margin-top: -10px;
	width: 100%;
	@include nav-condensed {
		width: 100%;
	}
	a {
		text-decoration: none;
	}
	.checkout_pitch_wrapper {
		border-bottom: 10px solid color(white);
		border-top: 10px solid color(white);
	}
	.description {
		font-family: font(serif);
		letter-spacing: letter-spacing(serif);
		@include font_size(fs_p);
	}
	.name {
		@extend .h6_sans;
		color: color(gray, x-dark);
		font-weight: 400;
		letter-spacing: .1em;
		.category {
			color: color(blue);
			font-weight: 700;
		}
	}
	.name_and_description {
		position: relative;
	}
	.name_and_description, .price {
		@include nav-full {
			line-height: 22px;
		}
	}
	.price {
		&.strike {
			text-decoration: line-through;
			.amount, .type {
				color: color(gray, x-light);
			}
		}
		.amount {
			@include font_size(fs_h6);
			display: block;
			font-weight: 700;
			letter-spacing: .02em;
			@include nav-full {
				min-width: 4.5em;
			}
		}
		.type {
			@extend .h7_sans;
			color: color(gray, dark);
			display: block;
			font-weight: 400;
			letter-spacing: .1em;
		}
	}
	.product {
		td, th {
			border-bottom: 1px solid color(gray, x-light);
			padding-bottom: 10px;
		}
		@include nav-condensed {
			.price:last-of-type {
				padding-right: 0;
				width: 1%;
			}
			.row_both {
				vertical-align: middle;
				padding: 20px 0;
			}
			.row_bottom {
				padding-bottom: 20px;
				padding-top: 0;
				vertical-align: top;
			}
			.row_top {
				border-color: color(white);
				padding-bottom: 10px;
				padding-top: 20px;
				vertical-align: bottom;
			}
		}
	}
	.quantity {
		// min-width: 70px;
		position: relative;
		text-align: center;
		white-space: nowrap;
		@include nav-full {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.remove {
		@extend .h7_sans;
		color: color(gray, dark);
		cursor: pointer;
		font-weight: 400;
		left: 0;
		margin-left: auto;
		margin-right: auto;
		position: absolute;
		right: 0;
		transform: translateY(6px);
		&:hover {
			color: color(blue);
		}
	}
	.remove_x {
		@extend %remove_x;
		height: 30px;
		position: absolute;
		right: 0;
		width: 30px;
	}
	.shipping, .subtotal, .tax, .total {
		td, th {
			line-height: 1.5em;
			vertical-align: top;
			.cart_overview & {
				line-height: 1.2em;
				vertical-align: middle;
			}
		}
		.label {
			@include font_size(fs_h6);
			display: block;
			font-weight: 700;
			text-transform: uppercase;
			.cart_overview & {
				text-indent: 10px;
			}
		}
	}
	.spinner {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 70px;
	}
	.subtotal {
		td, th {
			border-top: 10px solid color(white);
		}
		.checkout_pitch {
			vertical-align: middle;
		}
	}
	.thumbnail {
		@include breakpoint(zero, mobile) {
			height: 60px;
			width: 60px;
		}
		@include breakpoint(mobile, tablet-l) {
			height: 75px;
			width: 75px;
		}
		@include nav-full {
			height: 90px;
			width: 90px;
		}
	}
	.total {
		td, th {
			color: color(black, near);
			border-bottom: 10px solid color(white);
		}
	}
	.totals {
		.amount {
			.calculating {
				@extend .inline_spinner;
			}
		}
		.shipping, .subtotal, .tax {
			.label {
				font-weight: 400;
			}
		}
	}
}

.cart_nav {
	display: none;
	height: 100%;
	&.enabled {
		display: block;
	}
	.status_bar {
		background: color(blue);
		content: '';
		display: block;
		height: 2px;
		left: 0;
		bottom: 0;
		position: absolute;
		transform: translateX(-100%);
		width: 100%;
		&.countdown {
			animation: countdown 5s linear;
			animation-fill-mode: forwards;
		}
	}
	#pre_header &.open {
		.cart {
			transform: translateX(0);
			transition: transform .2s easing(ease-out-expo);
			visibility: visible;
		}
		.title {
			color: color(gray, x-dark);
			&:after {
				transform: translate3d(0, 0, 0);
			}
			.quantity {
				background-color: color(white, near);
				color: color(gray, x-dark);
				transition-duration: .1s;
			}
		}
	}
	.cart {
		max-width: 500px;
		padding: 2em;
		position: absolute;
		right: 0;
		top: height(pre-header);
		transform: translateX(100%);
		transition: transform .2s easing(ease-out-expo), visibility .2s;
		visibility: hidden;
	}

	.title {
		@include nav-condensed {
			.quantity {
				background-color: color(blue);
				box-sizing: border-box;
				color: color(white);
				border-radius: 10px;
				font-size: 11px;
				font-weight: bold;
				height: 20px;
				line-height: 20px;
				min-width: 20px;
				padding-left: .3em;
				padding-right: .3em;
				position: absolute;
				right: -1px;
				text-align: center;
				top: 10px;
				&.empty {
					display: none;
				}
			}
			.text {
				@include svg-background;
				@include tir;
				background-image: svg(home-cart-white);
				cursor: pointer;
				display: block;
				height: height(nav-condensed);
				width: 40px;
				#header.filled &, #header .bar.filled & {
					background-image: svg(home-cart-dark);
				}
			}
		}
		@include nav-full {
			overflow: hidden;
			position: relative;
			z-index: 1;
			&:after {
				background-color: color(white);
				content: '';
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				transform: translate3d(101%, 0, 0);
				transition: transform .2s easing(ease-out-expo);
				width: 100%;
				z-index: 2;
			}
			&:hover {
				.quantity {
					background-color: lighten(color(gray, x-dark), 10%);
				}
			}
			span {
				position: relative;
				z-index: 3;
			}
			.quantity {
				background-color: color(gray, x-dark);
				border-radius: 0;
				letter-spacing: 0;
				margin-left: .5em;
				padding: .5em .7em;
				transition: background-color .2s ease-out;
			}
		}
	}
}

.cart_nav_card {
	@include font_size(fs_h6);
	background-color: color(white, near);
	box-sizing: border-box;
	margin-top: 15px;
	padding: 1em 1.75em;
}

.market_selector_nav {
	height: 100%;
	position: relative;
	width: 42px;
	.pop_dialog_box {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%
	}
}

.distributor_nav {
	&.open {
		.menu {
			transform: translateX(0);
		}
		.title {
			background-color: color(blue);
			// position: fixed;
			&:before {
				background-image: svg(home-close);
			}
		}
	}
	.menu {
		background-color: color(blue);
		color: color(white);
		@include font-smoothing(false);
		height: 100%;
		overflow-y: auto;
		position: fixed;
		right: 0;
		top: height(pre-header);
		transform: translateX(100%);
		transition: all .2s easing(ease-out-expo);
		z-index: z-index(pre-header-distributor-menu);
		.wrapper {
			@include gutter-s {
				padding: width(gutter-outer-s);
			}
			@include gutter-l {
				padding: width(gutter-outer-l);
			}
		}
	}
	.title {
		overflow: hidden;
		position: relative;
		text-align: right;
		&.checkout {
			.checkout {
				transform: translate3d(-1px, 0, 0);
			}
		}
		&:hover {
			.checkout {
				background-color: color(blue, dark);
			}
			&:before {
				opacity: 1;
			}
		}
		.checkout {
			background-color: color(blue);
			background-image: svg(home-chevron-right);
			background-position: right center;
			background-repeat: no-repeat;
			background-size: 30px 40px;
			color: color(white);
			cursor: pointer;
			display: block;
			font-weight: 700;
			height: 100%;
			position: absolute;
			right: 0;
			text-align: center;
			transform: translate3d(100%, 0, 0);
			transition: transform .2s easing(ease-out-expo), background-color .2s ease-out;
			top: 0;
			width: 100%;
		}
		&:before {
			@include svg-background;
			background-image: svg(home-chevron-down);
			content: '';
			float: right;
			height: height(pre-header);
			opacity: .4;
			transition: all .2s easing(ease-in-out-quad);
			width: 30px;
		}
	}
}

.helix {
	.helix_blue {
		fill: color(blue, light);
	}
	.helix_gray {
		fill: color(gray, x-light);
	}
}

.page_app .content {
	background-color: transparent;
}

.page_biohacking_101 .content,
.page_biohackers .content,
.page_destination_ea .content,
.page_gain_an_edge .content,
.page_opportunity .content,
.page_rsl .content {
	background-color: color(white);
}

.quick_faq {
	@include section_reset;
	@include vertical-pad;
	background: color(white);
	ul {
		padding: 0;
		margin: 0;
		overflow: hidden;
	}
	li {
		float: left;
		list-style: none;
		width: 100%;
		@include breakpoint(tablet) {
			float: left;
			padding-right: 5%;
			width: 29.33%;
			&:last-child {
				padding-right: 0;
			}
		}
	}
	.answer {
		@include font_size(fs_h6);
		color: color(gray, light);
		line-height: 1.5em;
		@include breakpoint(tablet) {
			margin-bottom: 0;
		}
	}
	.footer_columns {
		border-bottom: 1px solid;
		border-top: 1px solid;
		border-color: color(gray, x-light);
		display: block;
		margin: 50px 0;
		padding: 40px 0;
		position: relative;
	}
	.icon {
		position: absolute;
		text-align: center;
		top: -9px;
		left: 0;
		width: 100%;
		.circle {
			background-color: color(gray, x-light);
			border-radius: 50%;
			color: color(white);
			padding: 4px 12px;
		}
		.outter_circle {
			background-color: color(white);
			padding: 0 15px;
		}
	}
	.question {
		@extend .h6_sans;
		color: color(gray);
		@include font-smoothing;
		line-height: 1.5em;
	}
}

.page_products .content {
	background-color: color(white);
}

.resources_nav {
	.group_nav {
		@extend .note;
		letter-spacing: letter-spacing(sans, open);
		list-style: none outside;
		margin: 1.5em 0 4em 0;
		padding: 0;
		text-transform: uppercase;
		a {
			display:block;
			line-height:2.3em;
			text-decoration: none;
			transition: color .1s easing(ease-in-out-quad);
			&, &:focus, &:visited {
				color: color(white);
			}
			&:hover, &:active {
				color: color(blue, x-light);
			}
		}
	}
	.header {
		@extend .h7_sans;
		color: color(blue, light);
	}
}

.smart_start_teaser {
	background: color(white);
	min-height: 0;
	padding: 0;
	.about {
		@include font_size(fs_p_extra);
		line-height: 1.85em;
		margin-bottom: 0;
	}
	.bg-primary, .bg-secondary {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.bg-primary {
		background: #f7f7f7 url(../img/products/cat-detail-physiq-bg-primary.jpg) no-repeat left bottom;
		@include breakpoint(zero, desktop) {
			background-size: 100% auto;
		}
		@include breakpoint(desktop, desktop-xl) {
			background-size: 425px auto;
		}
	}
	.bg-secondary {
		background: url(../img/products/cat-detail-physiq-bg-secondary.png) no-repeat right top;
		@include breakpoint(zero, mobile-l) {
			background-size: 75% auto;
		}
	}
	.content_wrapper {
		@extend %border_box;
		padding: 65px 0;
		position: relative;
	}
	.control {
		margin-top: 1em;
		@include breakpoint(tablet) {
			margin-top: 2em;
		}
	}
	.content {
		background-color: transparent;
		@include gutter-l {
			padding-left: width(gutter-outer-l);
			padding-right: width(gutter-outer-l);
		}
		@include breakpoint(zero, desktop) {
			padding: 0 width(gutter-outer-s) 125%;
		}
		@include breakpoint(desktop, desktop-xl) {
			padding-left: 400px;
		}
		@include breakpoint(desktop-xl) {
			padding-left: 650px;
		}
		.control {
			clear: both;
		}
	}
	.description {
		@extend .p_extra;
		@include breakpoint(desktop) {
			max-width: 26em;
		}
	}
	.sub_title {
		@extend .h3_serif;
		color: color(gray);
		margin-top: 1.5em;
		text-transform: none;
	}
	.title {
		@extend .h3_serif;
		color: color(blue);
		margin-bottom: .75em;
		text-transform: none;
	}
	.truncate {
		@extend %responsive_content_tablet;
	}
}

.social_and_sitemap {
	display: none; // this is temp to ensure that this block only appears within #footer
}

.statement_cards {
	@include section_reset;
	background-color: color(white);
	padding-bottom: 70px;
	text-align: center;
	@include breakpoint(mobile-xl) {
		padding-bottom: 120px;
	}
	@include breakpoint(tablet) {
		padding-bottom: 170px;
	}
	@include breakpoint(desktop) {
		padding-bottom: 220px;
	}
	.content_wrapper {
		position: relative;
	}
	.full_bg {
		background-image: url(../img/home/statement-cards-bg.jpg);
		&:after {
			background-color: color(white);
			content: '';
			display: block;
			left: width(gutter-inner-s);
			height: calc(100% - #{width(gutter-inner-s) * 2});
			position: absolute;
			top: width(gutter-inner-s);
			width: calc(100% - #{width(gutter-inner-s) * 2});
			@include breakpoint(tablet) {
				left: width(gutter-inner-l);
				height: calc(100% - #{width(gutter-inner-l) * 2});
				top: width(gutter-inner-l);
				width: calc(100% - #{width(gutter-inner-l) * 2});
			}
		}
	}
	.helix {
		@include vertical-center;
		position: absolute;
		@include breakpoint(zero, tablet) {
			@include constrain-proportions(200%, 26%);
			left: -50%;
		}
		@include breakpoint(tablet) {
			@include constrain-proportions(100%, 13%);
		}
		svg {
			height: 100%;
			left: 0;
			top: 0;
			position: absolute;
			width: 100%;
		}
	}
	.statement {
		margin-left: auto;
		margin-right: auto;
		width: 87%;
		&:first-of-type {
			&:before {
				border-bottom: 1px solid color(gray, x-light);
				content: '';
				display: block;
				height: 1px;
				margin: 0 auto 1.5em;
				width: 2.5em;
			}
		}
	}
	.statements {
		position: relative;
		@include breakpoint(tablet) {
			@include vertical-center;
		}
	}
	.statement_card {
		margin-left: auto;
		margin-right: auto;
		max-width: 960px;
		opacity: 1;
		padding-bottom: 70px;
		padding-top: 70px;
		position: relative;
		transform: perspective(400px) rotateX(-90deg);
		@include breakpoint(tablet) {
			height: 500px;
			padding-bottom: 0;
			padding-top: 0;
		}
		&.enter {
			animation: statement_card_enter 1.25s easing(ease-out-quint) normal forwards;
		}
	}
	.statement_card_container {
		overflow: hidden;
		position: relative;
	}
}

.user_nav {
	margin-top:-1em;
	margin-bottom: 2rem;
	&.border_top {
		&:before {
			background-color: color(white);
			content: '';
			display: block;
			height: 1px;
			opacity: .2;
		}
	}
	a {
		@extend .h6_sans;
		display: block;
		@include font-smoothing;
		// margin-bottom: 1.5em;
		// margin-top: 1.5em;
		line-height:3.75em;
		position: relative;
		text-decoration: none;
		padding-right: 45px;
		&:first-of-type {
			margin-top: 0;
		}
		&, &:focus, &:visited {
			color: color(white);
		}
		&:hover, &:active {
			color: color(white);
			&:before {
				animation: arrow-link .25s easing(ease-out-back);
				opacity: 1;
			}
		}
		&:after {
			background-color: color(white);
			content: '';
			display: block;
			width:100%;
			height: 1px;
			opacity: .2;
			padding-right: 45px;
			// margin-top: 1em;
		}
		&:before {
			@include svg-background;
			background-image: svg(home-arrow-right-white);
			display: block;
			height: 20px;
			content: '';
			opacity: .2;
			right: 0;
			position: absolute;
			top: 50%;
			width: 34px;
			margin-top:-10px;
		}
	}
}

.your_distributor {
	display: none;
	float: left;
	height: 100%;
	&.open {
		.close {
			display: block;
		}
		.menu {
			transform: translateX(0);
		}
	}
	&.enabled {
		display: block;
	}
	.close {
		background-color: color(blue);
		background-image: svg(close);
		cursor: pointer;
		display: none;
		height: 11px;
		left: 0;
		padding-bottom: 15px;
		padding-top: 14px;
		position: absolute;
		top: height(pre-header) * -1;
		width: 16px;
		&:hover {
			&:after {
				opacity: 1;
			}
		}
		&:after {
			@include svg-background;
			background-image: svg(home-close);
			content: '';
			display: block;
			height: height(pre-header);
			left: 0;
			margin: auto;
			opacity: .4;
			position: absolute;
			right: 0;
			top: 0;
			transition: all .2s easing(ease-in-out-quad);
			width: 30px;
		}
	}
	.full_name {
		color: color(white);
		font-weight: 700;
	}
	.menu {
		// background-color: color(blue);
		background-color: transparent;
		color: color(white);
		@include font-smoothing(false);
		height: 100%;
		left: 0;
		min-width: 250px;
		position: fixed;
		transform: translateX(-100%);
		transition: all .2s easing(ease-out-expo);
		top: height(pre-header);
		z-index: z-index(pre-header-your-distributor);
		.about {
			margin-bottom: 3em;
		}
		.avatar {
			@extend .avatar_rounded;
			height: 60px;
			margin-top: 3em;
			width: 60px;
		}
		.find_your_sponsor {
			@extend .note;
			color: color(white, near);
			line-height: 2.3em;
		}
		.full_name {
			@extend .h6_sans;
			color: color(white);
			margin-bottom: .5em;
		}
		.header {
			@extend .h7_sans;
			color: color(blue, light);
			margin-bottom: 1.7em;
		}
		.phone_number, .rank {
			@extend .note;
			color: color(white, near);
			line-height: 2.3em;
		}
		.wrapper {
			background-color: color(blue);
			@include gutter-s {
				padding: width(gutter-outer-s);
				height: calc(100vh - #{ height(pre-header) + (width(gutter-outer-s) * 2) });
			}
			@include gutter-l {
				padding: width(gutter-outer-l);
				height: calc(100vh - #{ height(pre-header) + (width(gutter-outer-l) * 2) });
			}
		}
	}
	.phone_number {
		&.disabled {
			display: none;
		}
	}
	.title {
		.avatar {
			float: left;
			height: height(pre-header);
			width: height(pre-header);
		}
		.intro {
			float: left;
		}
	}
}
.ea_template {
	.content_wrapper_narrow {
		margin:0;
		@include breakpoint(narrow-max-width-with-gutters) {
			margin: 0 auto;
		}
	}
	h3 {
		font-family: font(sans);
		@include font_size(fs_h5);
		font-weight: bold;
		@include font-smoothing;
		letter-spacing: .2em;
		text-transform: uppercase;
//		margin: 40px 0 0;
	}
	p {
		@extend .p_extra;
	}
	ol, ul {
		margin:1.5em 0;
		padding-left:30px;
		li {
			margin-bottom:.825em;
		}
	}
	hr {
		margin-top:50px;
		margin-bottom:50px;
		border-color:color(gray, x-light);
	}
}
.ea_title {
	@include font_size(fs_h1_small);
	text-transform: uppercase;
	margin:0;
	line-height:.9em;
	&.over_image {
		position: absolute;
		z-index: 2;
		left: 0px;
		//bottom: 0px;
		margin: 0;
		padding: 0.4em 0.67em;
		box-sizing: border-box;
		@include breakpoint(zero,mobile-l) {
			font-size: 24px;
		}
		@include breakpoint(tablet-s) {
			padding: 1em 0.67em;
		}
		&.center {
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&.white {
		color:color(white);
	}
	&.black {
		color:color(black, near);
	}
	&.gray {
		color:color(gray);
	}
	&.blue_dark {
		color:color(blue, dark);
	}
	&.blue_light {
		color:color(blue, light);
	}
	&.top {
		top:0px;
	}
	&.bottom {
		bottom:0px;
	}
	&.center {
		right: 0;
		margin: 0 auto;
		text-align: center;
	}
}
.ea_column {
	background-color: #f7f7f7;
	padding: 30px;
}
.ea_box {
	margin-right: auto;
	margin-left: auto;
	max-width:480px;
	padding: 2em;
	border: 1px solid #DDD;
	//background-color: #f7f7f7;
}
.announcement_gradient {
	position: relative;
	&.grad:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		display: block;
		height: 100%;
		width: 100%;
	}
	&.top.grad:after {
		background: -moz-linear-gradient(-45deg, rgba(0,0,0,0.14) 0%, rgba(0,0,0,0.14) 30%, rgba(0,0,0,0) 100%);
		background: -webkit-linear-gradient(-45deg, rgba(0,0,0,0.14) 0%,rgba(0,0,0,0.14) 30%,rgba(0,0,0,0) 100%);
		background: linear-gradient(135deg, rgba(0,0,0,0.14) 0%,rgba(0,0,0,0.14) 30%,rgba(0,0,0,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#24000000', endColorstr='#00000000',GradientType=1 );
	}
	&.bottom.grad:after {
		background: -moz-linear-gradient(45deg, rgba(0,0,0,0.14) 0%, rgba(0,0,0,0.14) 30%, rgba(0,0,0,0) 100%);
		background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.14) 0%,rgba(0,0,0,0.14) 30%,rgba(0,0,0,0) 100%);
		background: linear-gradient(45deg, rgba(0,0,0,0.14) 0%,rgba(0,0,0,0.14) 30%,rgba(0,0,0,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#24000000', endColorstr='#00000000',GradientType=1 );
	}
	&.center.grad:after {
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 50%,rgba(0,0,0,0) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 50%,rgba(0,0,0,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000',GradientType=0 );
	}
}
ul.save_dates_list {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		margin: 0;
		padding: 20px 0;
		border-bottom: 1px solid #ccc;
		&:last-child {
			border-bottom:0;
		}
		@include breakpoint(tablet-s) {
			padding: 30px 0;
		}
		@include breakpoint(tablet) {
			padding: 40px 0;
		}
		@include breakpoint(tablet-l) {
			padding: 50px 0;
		}
		h3 {
			margin-bottom:0;
			color:color(gray,light);
			strong {
				color:color(black);
			}
		}
	}
}
// Temp - New Enrollment Packs page
.flex {
	@extend %flex_grid;
	.grid_4 {
		@extend %flex_grid_unit;
//		background-color:color(white, near);
		box-shadow:0 5px 30px rgba(0,0,0,0.15);
		@include breakpoint(tablet-s) {
			margin-left: 10px;
			margin-right: 10px;
			flex-basis: calc(50% - 20px);
		}
		@include breakpoint(desktop) {
			flex-basis: calc(25% - 20px);
		}
		.img_wrap {
			display:block;
//			padding:5px;
			text-align:center;
		}
		.wrapper {
			padding:0 1.5em 1em;
			h3 {
				margin-bottom:1.2em;
				text-align:center;
			}
			.contents {
				@include font_size(fs_p_small);
				line-height:1.2;
				list-style-type:none;
				margin:0;
				padding-left:0;
				li {
					margin-bottom:1em;
					&:last-child {
						margin-bottom:0;
					}
				}
			}
		}
	}
	.grid_3 {
		@extend .grid_4;
		@include breakpoint(desktop) {
			flex-basis: calc(33% - 20px);
		}
	}
}
//downcount css
.timer {
	background: color(gray, x-dark);
	min-height: 0;
	padding: 40px 20px;
	text-align: center;

	ul.countdown {
		display: block;
		list-style: none;
		margin: 0;
		padding: 0;
	}
	ul.countdown li {
		display: inline-block;
	}
	ul.countdown li span {
		color: color(white);
		display: inline-block;
		font-size: 1.75em;
		line-height: 1;
		min-width: 2.65ex;
		padding: 0px 15px;
		@include breakpoint(mobile-l) {
			padding: 0px 25px;
		}
		@include breakpoint(mobile-xl) {
			padding: 0px 38px;
		}
	}
	ul.countdown li.seperator {
		border-left: 1px solid rgba(255, 255, 255, 0.1);
		font-size: 2.85em;
		font-weight: lighter;
		line-height: 1em;
		overflow: hidden;
		text-indent: -99999px;
		vertical-align: top;
		width: 0;
	}
	ul.countdown li p {
		font-family: font(serif);
letter-spacing: letter-spacing(serif);
		color: color(gray, dark);;
		font-size: 1em;
		font-style: italic;
		font-weight: 100;
		margin: 0px;
		text-transform:capitalize;
	}
	p {
		color: color(white);
		font-size: 1.5em;
		font-weight: 100;
		margin-top: 0.6em;
	}
}

// header link color overrides
#header.gold_links:not(.filled):not(.docked) #full_nav {
	.main {
		a.company,
		a.opportunity,
		a.products,
		a.science,
		a.shop {
			color: color(rank_pro_10);
		}
	}
	.logo.gold_links {
		&:hover svg {
			fill: color(rank_pro_10);
		}
	}
}
