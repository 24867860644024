//
// image with tiles
//

.image_with_tiles {
	@include section_reset;
	align-items: center;
	box-sizing: border-box;
	display: flex;
	// height: 60vh;
	justify-content: center;
	min-height: 790px;
	padding: 40px 20px 20px;
	position: relative;
	.bg-img {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
	.heading {
		@include font_size(fs_h3_small);
		@include font-smoothing;
		color: #2f3133;
		font-weight: 600;
		line-height: 1.15em;
	}
	.paragraph {
		@include font_size(fs_h5);
		color: color(gray, dark);
	}
	.tile_grid {
		display: grid;
		grid-column-gap: 40px;
		grid-template-columns: 1fr;
		justify-content: center;
		z-index: 1;
		@include breakpoint(tablet-l) {
			grid-template-columns: repeat(3, 3fr);
		}
		.grid_item {
			background-color: rgba(255, 255, 255, .7);
			backdrop-filter: blur(5px);
			max-width: 300px;
			padding: 20px 20px 5px;
			@include breakpoint(zero, tablet-l) {
				margin-bottom: 20px;
			}
		}
	}
}
