.video_page {
	background: color(white);
	overflow: hidden;
	.checkout_header {
		&.min {
			@include breakpoint(zero, mobile-xl) {
				display:none;
			}
		}
	}
}

.ve_footer {
	.ve_copyright {
		@include font_size(fs_p_note);
		color: color(gray, light);
		text-align: center;
	}
}

