.market_lists {
	display: none;
	@include breakpoint(tablet-s) {
		padding: 30px 35px 0;
	}
}

.market_selector {
	background: #ebeef2;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	position: relative;
	@include breakpoint(tablet-s) {
		max-width: 430px;
	}
	&.open {
		.market_lists,
		.modal_body_small {
			display: block;
		}
		.market_selector_toggle .chevron {
			transform: rotate(270deg);
		}
	}
	.accordion_drawer.open {
		border-bottom: 1px solid color(gray, x-light);
		.drawer_title {
			border-bottom: 0;
		}
	}
	.market_list {
		list-style: none;
		margin: 0;
		padding-bottom: 10px;
		padding-left: 0;
		.market_item {
			@include font-size(fs_p_small);
			padding-bottom: 7px;
			padding-left: 36px;
			padding-right: 30px;
			padding-top: 7px;
			a {
				color: color(black, near);
				display: block;
				text-decoration: none;
				&:hover {
					color: color(blue);
				}
			}
		}
	}
	.modal_body_small {
		display: none;
	}
}

.market_selector_toggle {
	@include font_size(fs_p_small);
	border-top: 1px solid color(gray, x-light);
	color: color(gray, dark);
	cursor: pointer;
	font-weight: 600;
	letter-spacing: .075em;
	padding: 15px 30px;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	&:hover {
		background-color: #e4e6ea;
	}
	&::selection {
		color: color(gray, dark);
		background: transparent;
	}
	.chevron {
		bottom: 0;
		fill: color(gray, dark);
		height: 15px;
		position: absolute;
		margin-bottom: auto;
		margin-top: auto;
		right: 22px;
		top: 0;
		transform: rotate(90deg);
		transition: transform .2s ease-out;
		width: 15px;
	}
}
