// layout (alphabetical by element, placeholder, id, class)

body {
	background-color: color(white);
}

hr.pad {
	@include vertical-pad('margin');
}

%border_box { // done as a placeholder to avoid collision with css .border_box
	box-sizing: border-box;
}

%caret_down {
	@include svg-background;
	background-image: svg(home-chevron-down-small-black);
	content: '';
	height: 7px;
	transition: transform .2s ease-out;
	width: 11px;
	display: inline-block;
}

%clip_path_first {
	clip-path: polygon(7% 0, 100% 0, 100% 100%, 0 100%, 0 7%);
}

%clip_path_last {
	clip-path: polygon(0 0, 100% 0, 100% 93%, 93% 100%, 0 100%);
}

%section_reset { // temp while <section> has global styles
	min-height: 0;
	overflow: auto;
	padding: 0;
}

.action_sheet {
	background-color: color(white);
	border-radius: 10px;
	margin-left: auto;
	margin-right: auto;
	max-width: 400px;
	overflow: hidden;
	.action_sheet_title {
		@include font_size(fs_p_extra);
		background-color: #878f9a;
		color: color(white);
		letter-spacing: .2em;
		padding-bottom: 20px;
		padding-top: 20px;
		text-align: center;
		text-transform: uppercase;
	}
	.magnific_btn {
		background-color: color(white);
		border-top: 1px solid color(gray, xx-light);
		color: color(gray, x-dark);
		cursor: pointer;
		display: block;
		letter-spacing: .2em;
		padding-bottom: 20px;
		padding-top: 20px;
		position: relative;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		transition: background-color .2s ease-out, color .2s ease-out;
		width: 100%;
		&:hover {
			background-color: color(blue);
			color: color(white);
		}
		&__close {
			background-color: #e8e9eb;
			color: #939191;
			&:hover {
				background-color: color(gray, xx-light);
				color: color(gray);
			}
		}
	}
}

.app_tooltip {
	display: block;
	opacity: 0;
	position: relative;
	transform: translate3d(0, 20px, 0);
	transition: opacity .2s ease-out, transform .2s ease-out, visibility .3s;
	visibility: hidden;
	&.hide {
		display: none;
	}
}

.app_tooltip_close {
	background-image: svg(home-close-dark);
	display: block;
	height: 48px;
	opacity: 0.4;
	overflow: hidden;
	position: absolute;
	right: -12px;
	text-indent: 100%;
	top: -5px;
	transition: opacity .4s easing(ease-out-back);
	white-space: nowrap;
	width: 48px;

	&:hover {
		opacity: 0.8;
	}
}

.app_tooltip_visible {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: opacity .2s ease-out .2s, transform .2s ease-out .2s, visibility 0;
	visibility: visible;
}

.arrow_link_black, .arrow_link_blue, .arrow_link_white {
	display: inline-block;
	line-height: 1em;
	padding-right: 55px;
	position: relative;
	&:before {
		@include svg-background;
		display: block;
		height: 10px;
		content: '';
		line-height: 1em;
		margin-top: 2px;
		position: absolute;
		right: 0;
		top: -2px;
		width: 40px;
	}
	&.small {
		padding-right: 35px;
		&:before {
			width: 30px;
			height: 15px;
		}
	}
	&:hover {
		&:before {
			animation: arrow-link .25s easing(ease-out-back);
		}
	}
}

.arrow_link_left_blue {
	color: color(blue);
	display: inline-block;
	line-height: 1em;
	padding-left: 55px;
	position: relative;
	&:before {
		@include svg-background;
		background-image: svg(home-thin-arrow-left-blue);
		display: block;
		height: 10px;
		content: '';
		line-height: 1em;
		margin-top: 2px;
		position: absolute;
		left: 0;
		top: -2px;
		width: 40px;
	}
	&:hover {
		&:before {
			animation: arrow-link-left .25s easing(ease-out-back);
		}
	}
}

.arrow_link_black {
	&:before {
		background-image: svg(home-thin-arrow-right-black);
	}
}

.arrow_link_blue {
	color: color(blue);
	&:before {
		background-image: svg(home-thin-arrow-right-blue);
	}
}

.arrow_link_hover_only {
	&:before {
		opacity: 0;
	}
	&:hover:before {
		opacity: 1;
	}
}

.arrow_link_white {
	&:before {
		background-image: svg(home-thin-arrow-right-white);
	}
}

.avatar {
	@extend %border_box;
	background-color: color(gray);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	&.placeholder {
		background-image: url(../img/placeholder-avatar.png);
		// unset naming collision properties
		border: 0;
		min-height: 0;
	}
}

.avatar_rounded {
	border: 2px solid color(white);
	border-radius: 50%;
}

.bleed_wrapper {
	margin: 0 auto;
	max-width: 1600px;
	width: 100%;
}

.bottom_rule {
	position: relative;
	&:after {
		background-color: color(gray, x-light);
		content: '';
		display: block;
		height: 1px;
		margin-bottom: .75em;
		margin-top: .75em;
		width: 6em;
		@include breakpoint(zero, mobile-xl) {
			width: 4.75em;
		}
	}
}

.checkout_header {
	text-align: center;
	.distributor {
		@extend .h7_sans;
	}
	.distributor_name {
		color: color(gray, x-dark);
	}
	.glue, .intro {
		color: color(gray, light);
	}
	.title {
		@extend .h3_serif;
	}
	@include breakpoint(zero, tablet-l) {
		.distributor {
			margin-bottom: 10px;
			margin-top: 30px;
		}
		.distributor_name, .intro {
			display: block;
		}
		.glue {
			display: none;
		}
		.intro {
			font-family: font(serif);
			font-style: italic;
			font-weight: normal;
			letter-spacing: 0;
			text-transform: none;
		}
		.title {
			display: none;
		}
	}
	@include breakpoint(tablet-l) {
		padding: 10px 0 20px 0;
		.title {
			color: color(gray, x-dark);
			margin-top: 2em;
			position: relative;
			&:after {
				border-top: 1px solid color(gray, x-dark);
				content: '';
				display: block;
				height: 0px;
				margin: 38px auto 40px auto;
				width: 25px;
			}
		}
	}
	&.min {
		position:relative;
		z-index:1;
		background-color:color(white);
		padding-top:40px;
		padding-right:30px;
		padding-left:30px;
		@include breakpoint(1340px, false, false) {
			max-width:1280px;
			margin:auto;
			padding-right:0;
			padding-left:0;
		}
		.logo {
			position:absolute;
			left:0;
			top:50%;
			transform:translateY(-50%);
			width: 120px;
			svg {
				display:block;
				fill: color(blue);
			}
			@include breakpoint(zero, mobile-xl) {
				display:none;
			}
			@include breakpoint(tablet) {
				width:140px;
			}
		}
		.distributor {
			margin:0;
			.distributor_name {
				display:block;
				margin:0 auto;
				@include breakpoint(mobile-xl) {
					max-width:180px;
				}
				@include breakpoint(tablet) {
					max-width:300px;
				}
				@include breakpoint(desktop) {
					max-width:400px;
				}
			}
		}
	}
}

.checkout_nav {
	@include clearfix;
	background-color: color(black);
	height: height(pre-header);

	> .avatar {
		@extend .avatar_rounded;
		height: 50px;
		left: 50%;
		margin-left: -25px;
		position: absolute;
		top: 35%;
		width: 50px;
	}

	> a {
		text-decoration: none;
	}

	.text {
		@extend .h7_sans;
		color: color(white);
		font-weight: 400;
		height: 40px;
		line-height: 42px;
		@include breakpoint(zero, tablet-s) {
			display: none;
		}
	}
	&.min {
		.logo {
			position:absolute;
			left:15px;
			top:50%;
			transform:translateY(-50%);
			width: 80px;
			vertical-align:bottom;
			svg {
				display:block;
				fill: color(white);
			}
			@include breakpoint(mobile-xl) {
				display:none;
			}
		}
	}
}

.checkout_nav_block {
	display: block;
	height: 40px;
	overflow: hidden;
	&:first-child {
		border-right: 1px solid color(gray, x-dark);
		float: left;
	}
	&:last-child {
		border-left: 1px solid color(gray, x-dark);
		float: right;
	}
	&.chevron_link_back {
		&:before {
			@include svg-background;
			background-image: svg(home-chevron-left-white);
			content: '';
			display: block;
			float: left;
			height: 40px;
			opacity: 0.5;
			margin-left: 5px;
			margin-right: 5px;
			transition: opacity 0.2s ease-out;
			width: 30px;
		}
		&:hover:before {
			opacity: 1;
		}
		.text {
			padding-right: 20px;
		}
	}
	&.secure_checkout {
		&:after {
			@include svg-background;
			background-image: svg(home-lock);
			content: '';
			display: block;
			float: right;
			height: 40px;
			opacity: 1;
			margin-left: 5px;
			margin-right: 5px;
			width: 30px;
		}
		.text {
			padding-left: 20px;
		}
	}
}

.currency {
	margin-right: .1em;
}

.dialog_box {
	max-width: 30em;
	margin-right:auto;
	margin-left:auto;
	.content_wrapper {
		padding: 30px;
		box-sizing: border-box;
		background-color: color(white);
	}
	.controls {
		padding-top: 10px;
		text-align: center;
	}
	.message {
		@extend .p_extra;
		color: color(gray);
		padding-bottom: 10px;
		.light {
			font-weight: 400;
		}
		.line {
			display: inline;
		}
	}
	.title {
		@extend .h6_sans;
		color: color(blue);
		padding-bottom: 20px;
		text-align: center;
	}
}

.dialog_box_form {
	@extend .dialog_box;
	&.submitted {
		.form {
			display: none;
		}
		.completed {
			display: block;
		}
	}
	.completed {
		display: none;
	}
}

.grid {
	& [class*='col_'] {
		float: left;
	}
	.col_1_2 {
		@include gutter-l {
//			padding-right: 2%;
//			width: 48%;
		}
		.gutter & {
			@extend %border_box;
			@include gutter-l {
				width: 50%;
				&:nth-child(2n+1) {
					padding-right: width(gutter-inner-l) / 2;
				}
				&:nth-child(2n+2) {
					padding-left: width(gutter-inner-l) / 2;
					padding-right: 0;
				}
			}
		}
	}
	.col_1_3 {
		@include gutter-l {
			padding-right: 3%;
			width: 33%;
		}
		.gutter & {
			@extend %border_box;

			@include gutter-l {
				width: 100%;
				&:nth-child(2n+1) {
					padding-right: width(gutter-inner-l) / 2;
				}
				&:nth-child(2n+2) {
					padding-left: width(gutter-inner-l) / 2;
					padding-right: width(gutter-inner-l) / 2;
				}
				&:nth-child(3n+3) {
					padding-left: width(gutter-inner-l) / 2;
					padding-right: 0;
				}
			}
		}
	}
}

.content_wrapper {
	margin: 0 width(gutter-outer-s);
	max-width: width(max-width);
	@include gutter-l {
		margin: 0 width(gutter-outer-l);
	}
	@include breakpoint(max-width-with-gutters) {
		margin: 0 auto;
	}
}

.content_wrapper_full {
	margin: 0 auto;
	max-width: width(max-width);
	width: 100%;
}

.content_wrapper_extra_narrow {
	margin: 0 width(gutter-outer-s);
	max-width: width(tablet-s);
	@include gutter-l {
		margin: 0 width(gutter-outer-l);
	}
	@include breakpoint(tablet-s) {
		margin: 0 auto;
	}
}

.content_wrapper_narrow {
	margin: 0 width(gutter-outer-s);
	max-width: width(narrow-max-width);
	@include gutter-l {
		margin: 0 width(gutter-outer-l);
	}
	@include breakpoint(narrow-max-width-with-gutters) {
		margin: 0 auto;
	}
}

.content_wrapper_tight {
	margin: 0 width(gutter-inner-s);
	max-width: width(max-width);
	@include gutter-l {
		margin: 0 width(gutter-inner-l);
	}
	@include breakpoint(desktop-l) {
		margin: 0 width(gutter-outer-l);
	}
	@include breakpoint(max-width-with-gutters) {
		margin: 0 auto;
	}
}

.control {
	@extend .h6_sans_small;
	text-decoration: none;
	&, &:focus, &:visited {
		color: color(blue, light);
	}
	& + & {
		margin-left: 1.5em;
	}
	.reversed & {
		color: color(white);
	}
}

.cvc_hint_info {
	$img: '/wp-content/themes/lifevantage/img/home/ccv-tip-card-graphic';
	background: url($img+'.png') center center no-repeat;
	background-size: 320px auto;
	background-color: color(white);
	border: 1px solid color(gray, x-light);
	box-sizing: border-box;
	height: 220px;
	position: absolute;
	right: 0;
	top: 70px;
	width: 100%;
	z-index: 10;

	@include gutter-s {
		background-size: 240px auto;
		height: 180px;
	}

	&:before {
		@include svg-background;
		background-image: svg(home-box-tip-top);
		content: '';
		display: block;
		height: 8px;
		position: absolute;
		right: 4em;
		top: -8px;
		width: 12px;
		z-index: -1;
	}
}

.cvc_hint_link {
	$img: '/wp-content/themes/lifevantage/img/home/cvc-hint-visa';
	@extend .h7_sans;
	background: url($img+'.png') left center no-repeat;
	box-sizing: border-box;
	color: color(blue);
	display: inline-block;
	height: 58px;
	margin-left: 16px;
	padding: 23px 0 0 52px;
	text-align: left;
	text-decoration: none;
	vertical-align: top;

	@include bg_img_retina($img, png, 40px, 26px);

	// @include gutter-s {
	// 	background-position: center 8px;
	// 	line-height: 1;
	// 	padding: 42px 0 0 0;
	// }

	&.amex {
		$img: '/wp-content/themes/lifevantage/img/home/cvc-hint-amex';
		background: url($img+'.png') left center no-repeat;
		@include bg_img_retina($img, png, 40px, 26px);
	}
}

.downloads_list {
	@include font_size(fs_p_extra);
	padding-left: 0;
	a {
		@extend .arrow_link_blue, .arrow_link_hover_only, .control;
		margin-right: auto;
	}
	li {
		align-items: center;
		display: flex;
		list-style: none;
		padding-bottom: 1em;
		position: relative;
		&:before {
			background-image: svg(file);
			content: '';
			display: block;
			height: 20px;
			left: 0;
			position: absolute;
			top: -2px;
			width: 20px;
			@include breakpoint(tablet) {
				height: 25px;
				width: 25px;
			}
			@include breakpoint(desktop) {
				height: 30px;
				top: -3px;
				width: 30px;
			}
		}
	}
	.pdf {
		fill: color(blue);
		margin-right: 10px;
		width: 15px;
	}
}

%flex_grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	@include breakpoint(tablet-s) {
		margin-left: -10px;
		margin-right: -10px;
	}
}

%flex_grid_unit {
	flex-basis: calc(100%);
	margin-bottom: 20px;
	max-width: calc(100%);
	// If a min width isn't set, IE10 will make one long row with tiny grid units
	min-width: calc(25% - 20px);
	position: relative;
	@include breakpoint(tablet-s) {
		margin-left: 10px;
		margin-right: 10px;
		flex-basis: calc(50% - 20px);
	}
	@include breakpoint(desktop) {
		flex-basis: calc(33% - 20px);
	}
	@include breakpoint(desktop-xl) {
		flex-basis: calc(25% - 20px);
	}
}

.flex_grid_row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: width(gutter-inner-s) / -2;
	margin-right: width(gutter-inner-s) / -2;
	@include ie9 {
		clear: both;
		display: block;
	}
	@include ie10-up {
		clear: both;
	}
	@include breakpoint(tablet) {
		margin-left: width(gutter-inner-l) / -2;
		margin-right: width(gutter-inner-l) / -2;
	}
}

.flex_grid_unit_4 {
	display: block;
	@include ie9-up {
		float: left;
	}
	@include breakpoint(zero, desktop) {
		// @TODO, re-write or wrap flex mixin to include ie9-up conditionals
		flex: 1 1 calc(50% - #{width(gutter-inner-s)});
		margin-bottom: width(gutter-inner-s);
		margin-left: width(gutter-inner-s) / 2;
		margin-right: width(gutter-inner-s) / 2;
		@include ie9-up {
			width: calc(50% - #{width(gutter-inner-s)});
		}
	}
	@include breakpoint(desktop) {
		flex: 1 1 calc(25% - #{width(gutter-inner-l)});
		margin-left: width(gutter-inner-l) / 2;
		margin-right: width(gutter-inner-l) / 2;
		@include ie9-up {
			width: calc(25% - #{width(gutter-inner-l)});
		}
	}
}

.flex_grid {
	display: flex;
	flex-direction: column;
	@include breakpoint(tablet) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.column_full {
		flex-basis: 100%;
	}
	.column_half {
		@include breakpoint(tablet) {
			flex-basis: calc(50% - 20px);
		}
	}
	.desktop_order_1 {
		@include breakpoint(tablet) {
			order: 1;
		}
	}
	.desktop_order_2 {
		@include breakpoint(tablet) {
			order: 2;
		}
	}
	.desktop_order_3 {
		@include breakpoint(tablet) {
			order: 3;
		}
	}
}

.footer_simple {
	text-align: center;
}

.full_bg {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	&.focus_left_5 {
		background-position: left center;
	}
	&.focus_left_4 {
		background-position: 10% center;
	}
	&.focus_left_3 {
		background-position: 20% center;
	}
	&.focus_left_2 {
		background-position: 30% center;
	}
	&.focus_left_1 {
		background-position: 40% center;
	}
	&.focus_right_1 {
		background-position: 60% center;
	}
	&.focus_right_2 {
		background-position: 70% center;
	}
	&.focus_right_3 {
		background-position: 80% center;
	}
	&.focus_right_4 {
		background-position: 90% center;
	}
	&.focus_right_5 {
		background-position: 100% center;
	}
	&.bottom {
		background-position-y: bottom;
	}
	&.top {
		background-position-y: top;
	}
}

.grid:after {
	content: "";
	display: table;
	clear: both;
}

.image_compare {
	overflow: hidden;
	margin-bottom: 2em;
	position: relative;
	&.active {
		.handle {
			opacity: 1;
			transform: translate3d(0, 0, 0) scale(1);
			transition: transform .2s .4s;
		}
		.labels {
			transform: translate3d(0, 0, 0);
		}
		.wrapper.after {
			transition: width .4s easing(easeOutCirc);
			width: 50%;
		}
	}
	&.resizing {
		.wrapper.after {
			transition: none;
		}
	}
	img {
		display: block;
	}
	.handle {
		@include svg-background;
		background-color: transparentize(color(white), .2);
		background-image: svg(slide-control);
		border-radius: 50%;
		box-shadow: 0 0 10px rgba(0, 0, 0, .65);
		cursor: move;
		height: 40px;
		left: 50%;
		margin-left: -20px;
		margin-top: -20px;
		opacity: 0;
		position: absolute;
		top: 50%;
		transform: translate3d(0, 0, 0) scale(0);
		width: 40px;
		&.dragging {
			background-color: color(white);
		}
	}
	.wrapper {
		&.after {
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
			height: 100%;
			overflow: hidden;
			position: absolute;
			right: 0;
			top: 0;
			transform: translate3d(0, 0, 0);
			width: 0;
			img {
				display: block;
				height: 100%;
				max-width: none;
				position: absolute;
				right: 0;
				top: 0;
				width: auto;
			}
		}
	}
	.label {
		bottom: 0;
		padding: 1em 1.5em;
		position: absolute;
		&.after {
			right: 0;
		}
		&.before {
			left: 0;
		}
		&.hide {
			visibility: hidden;
		}
	}
	.labels {
		@extend %border_box, .h6_sans;
		background-image: linear-gradient( transparentize(color(black), 1), transparentize(color(black), .8) );
		bottom: 0;
		color: color(white);
		padding: 3em 0 1em 0;
		position: absolute;
		transform: translate3d(0, 3em, 0);
		transition: transform .2s ease-out .4s;
		width: 100%;
	}
}

.inline_spinner {
	&, &:after, &:before {
		animation: spinner .9s alternate infinite ease-in-out;
		animation-fill-mode: both;
		background-color: color(gray, light);
		border-radius: 50%;
		height: .5em;
		margin-right: .25em;
		vertical-align: middle;
		width: .5em;
	}
	& {
		animation-delay: .3s;
		display: inline-block;
		margin-left: .75em;
		margin-right: 1.5em;
		position: relative;
	}
	&:after, &:before {
		content: '';
		display: block;
		position: absolute;
	}
	&:after {
		animation-delay: .6s;
		right: -.95em;
	}
	&:before {
		left: -.75em;
	}
}

// The declaration block below removes IE functionality that places an 'X' to the right of a form field when it is in focus.
input::-ms-clear {
	display: none;
}

#instafeed {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 30px;
	@include breakpoint(desktop) {
		margin-bottom: 40px;
	}
	.instafeed_tile {
		margin-bottom: 20px;
		margin-right: 20px;
		overflow: hidden;
		@include breakpoint(zero, mobile-xl) {
			// 2 up
			flex-basis: calc(50% - 10px);
			&:nth-child(2n + 2) {
				margin-right: 0;
			}
		}
		@include breakpoint(mobile-xl, tablet) {
			// 3 up
			flex-basis: calc(33% - 13px);
			&:nth-child(3n + 3) {
				margin-right: 0;
			}
			// don't show 10th tile to keep the grid even
			&:nth-child(10) {
				display: none;
			}
		}
		@include breakpoint(tablet, desktop) {
			// 4 up
			flex-basis: calc(25% - 15px);
			&:nth-child(4n + 4) {
				margin-right: 0;
			}
			// don't show 9th and 10th tile to keep the grid even
			&:nth-child(9),
			&:nth-child(10) {
				display: none;
			}
		}
		@include breakpoint(desktop) {
			flex-basis: calc(20% - 16px);
			&:nth-child(5n + 5) {
				margin-right: 0;
			}
		}
		img {
			width: 100%;
		}
	}
}

.instafeed_tiles {
	.title_small_hr_container {
		margin-bottom: 20px;
		@include breakpoint(tablet) {
			margin-bottom: 50px;
		}
	}
}

.line {
	display: block;
}

.mfp-fade {
	&.mfp-bg {
		background-color: color(black);
		opacity: 0;
		transition: all .2s easing(ease-out-quad);
		&.mfp-ready {
			opacity: 1;
		}
		&.mfp-removing {
			opacity: 0;
			transition-delay: .2s;
		}
	}
	&.mfp-wrap {
		&.mfp-ready {
			.mfp-content {
				opacity: 1;
			}
		}
		&.mfp-removing {
			opacity: 0;
			transition-delay: 0;
		}
		.mfp-content {
			opacity: 0;
			transition: all .4s easing(ease-out-quad) .3s;
		}
	}
}

.mfp-fade-subtle {
	&.mfp-bg {
		&.mfp-ready {
			opacity: .8;
		}
	}
	.mfp-close {
		color: color(white);
		@include nav-condensed {
			top: 8px;
		}
		@include nav-full {
			top: -2px;
		}
	}
}

.micro_rule {
	&:after {
		background-color: color(gray, x-dark);
		content: '';
		display: block;
		height: 1px;
		margin: 30px auto 0;
		width: 30px;
	}
}

.parallax_bg {
	background-position-y: 50%;
	height: 120%;
}

.play_video {
	@include svg-background;
	@include tir;
	background-color: transparentize(color(white), 1);
	background-image: svg(home-play);
	border: 2px solid white;
	border-radius: 50%;
	display: block;
	height: 30px;
	overflow: hidden;
	position: relative;
	transition: background-color .2s ease-out;
	width: 30px;
	&:after {
		border: 1px solid white;
		border-radius: 50%;
		box-sizing: border-box;
		content: '';
		height: 90%;
		left: 5%;
		opacity: 0;
		position: absolute;
		top: 5%;
		transform: scale(.5);
		transition: all .2s easing(ease-out-back);
		width:90%;
	}
	&:hover {
		background-color: transparentize(color(white), .9);
		&:after {
			opacity: 1;
			transform: scale(1);
		}
	}
	@include breakpoint(mobile-xl) {
		height: 40px;
		width: 40px;
	}
	@include breakpoint(tablet) {
		height: 50px;
		width: 50px;
	}
	@include breakpoint(desktop) {
		height: 60px;
		width: 60px;
	}
	&.large {
		height: 50px;
		width: 50px;
		@include breakpoint(mobile-xl) {
			height: 75px;
			width: 75px;
		}
		@include breakpoint(tablet) {
			height: 75px;
			width: 75px;
		}
		@include breakpoint(desktop) {
			height: 100px;
			width: 100px;
		}
	}
}

.plural {
	display: none;
	&.is_plural {
		display: inline;
	}
}

.quick_nav {
	@extend .h6_sans;
	background-color: color(white);
	left: 0;
	margin-left: 1em;
	opacity: 0;
	padding: width(gutter-outer-s);
	pointer-events: none;
	position: absolute;
	text-align: left;
	transform: translateY(20px);
	transition: all .2s easing(ease-out-quad) .1s;
	top: 1.6em;
	&.show {
		opacity: 1;
		pointer-events: auto;
		transform: translateY(0);
	}
	@include breakpoint(desktop-l) {
		margin-left: 2em;
	}
	a {
		@extend .h7_sans;
		color: color(gray);
		display: block;
	// margin-bottom: 1em;
		line-height:2.5em;
		text-decoration: none;
		transition: color .1s ease-out;
		white-space: nowrap;
		&:last-of-type {
			margin-bottom: 0;
		}
		&:hover, &:active {
			color: color(blue);
		}
	}
}

.reverse {
	color: color(white);
}

.section_title {
	@extend .h6_sans;
	color: color(black, near);
	padding-bottom: 4em;
	padding-top: 4em;
	text-align: center;
}

.slider_tabs {
	@include clearfix;
	background-color: color(white);
	border: 2px solid color(blue);
	box-sizing: border-box;
	color: color(gray, light);
	height: 40px;
	margin: 1em auto 0;
	max-width: 396px;
	.slider_tab {
		@extend %form_element_base;
		border: 0;
		cursor: pointer;
		font-family: font(sans);
		font-weight: bold;
		letter-spacing: .2em;
		padding-left: 2em;
		padding-right: 2em;
		text-align: center;
		text-transform: uppercase;
		@include font_size(fs_h6);
		background-color: color(white);
		color: color(blue);
		cursor: pointer;
		display: block;
		height: 36px !important; // @chai says, we need to inlcude forms before layout to make this work with !important, need to test unintented consequences of that change
		float: left;
		line-height: 36px !important;
		padding: 0;
		width: 50%;
		&:hover {
			background-color: color(white);
			color: color(blue);
		}
		&.active {
			position: relative;
			&:after {
				border: 6px solid transparent;
				border-top: 6px solid color(blue);
				bottom: -12px;
				content: '';
				display: block;
				height: 0;
				left: 50%;
				margin-left: -5px;
				position: absolute;
				width: 0;
			}
			&, &:hover {
				background-color: color(blue);
				color: color(white);
			}
		}
	}
}

.spinner {
	display: inline;
	height: 40px;
	&.small {
		height: 30px;
		input, .minus, .plus {
			@include font_size(fs_p_note);
			height: 30px;
			line-height: 30px;
		}
		input {
			width: 30px;
		}
	}
	input, .minus, .plus {
		@extend %form_element_base;
		background-color: color(white, near);
		border: 1px solid color(gray, xx-light);
		float: left;
		text-align: center;
	}
	input {
		border-left: 0;
		border-right: 0;
		font-weight: 400;
		width: 40px;
	}
	input[type=number] {
		border-radius: 0;
		-moz-appearance: textfield;
		-webkit-appearance: none;
	}
	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.minus, .plus {
		@include tir;
		cursor: pointer;
		position: relative;
		user-select: none;
		width: 20px;
		&:after, &:before {
			background-color: color(gray);
			bottom: 0;
			content: '';
			display: block;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 0;
		}
		&:hover {
			background-color: color(gray, x-light);
		}
	}
	.minus {
		&:after {
			height: 1px;
			width: 5px;
		}
		&:before {
			display: none;
		}
	}
	.plus {
		&:after {
			height: 1px;
			width: 7px;
		}
		&:before {
			height: 7px;
			width: 1px;
		}
	}
}

.split_underline_link {
	@include font-smoothing;
	position: relative;
	transform: translate3d(0, 0, 0);
	transition: all .2s ease-out;
	&:after, &:before {
		bottom: -.5em;
		content: '';
		height: 1px;
		position: absolute;
		transition: all .15s easing(ease-out-back) .1s;
		width: 0;
	}
	&:after {
		left: 50%;
	}
	&:before {
		right: 50%;
	}
	&.active, &:hover {
		&:after, &:before {
			width: 50%;
		}
	}
}

.split_underline_link_blue {
	@extend .split_underline_link;
	&:after, &:before {
		background-color: color(blue);
	}
}

.split_underline_link_white {
	@extend .split_underline_link;
	&:after, &:before {
		background-color: color(white);
	}
}

.sticky_nav {
	width: 100%;
	&.stuck {
		box-shadow: 0 6px 6px -6px rgba(0, 0, 0, .2);
		position: fixed;
		top: 0;
		transition: all .1s ease-out;
		width: 100%;
		z-index: z-index(sticky-nav);
		&.header_is_shown {
			transition-delay: .1s;
			transition-duration: .2s;
			@include nav-condensed {
				transform: translateY(height(nav-condensed));
			}
			@include nav-full {
				transform: translateY(height(nav-full) - 20);
				@include vertical-breakpoint(1000px, false, false) {
					transform: translateY(height(nav-full));
				}
			}
		}
	}
}

.sticky_nav_shim {
	display: none;
	&.shimmed {
		display: block;
	}
}

.tab_nav {
	.select_wrapper {
		height: 40px;
		left: width(gutter-outer-s) * -1;
		position: relative;
		@include gutter-l {
			left: width(gutter-outer-l) * -1;
		}
		@include nav-full {
			display: none;
		}
		.decoy {
			@extend .h6_sans;
			@include font-smoothing;
			color: color(gray);
			left: 0;
			line-height: 40px;
			pointer-events: none;
			position: absolute;
			height: 100%;
			text-indent: 60px;
			width: 100%;
			&:before {
				background-image: svg(home-chevron-down-large);
				border-right: 1px solid color(white, off);
				content: '';
				display: block;
				height: 40px;
				position: absolute;
				width: 40px;
			}
		}
		select {
			height: 100%;
			opacity: 0;
			width: 100%;
			.ios & {
				font-size: 16px;
			}
		}
	}
	.tabs {
		display: none;
		height: 40px;
		text-align: center;
		@include nav-full {
			display: block;
		}
		ul {
			display: inline-block;
			margin: 0;
			padding: 0;
		}
		a {
			@extend %form_element_base;
			border: 0;
			cursor: pointer;
			font-family: font(sans);
			font-weight: bold;
			letter-spacing: .2em;
			padding-left: 2em;
			padding-right: 2em;
			text-align: center;
			text-transform: uppercase;
			@include font_size(fs_p_note);
			@extend .split_underline_link_blue;
			color: color(gray);
			font-weight: bold;
			letter-spacing: .1em;
			padding-left: width(gutter-outer-s);
			padding-right: width(gutter-outer-s);
			&.active, &:hover {
				color: color(blue);
			}
			&:after, &:before {
				bottom: 0;
			}
		}
		li {
			border-right: 1px solid color(white, off);
			float: left;
			list-style: none;
		}
		li:first-child {
			border-left: 1px solid color(white, off);
		}
	}
}

.tab_section {
	@include section_reset;
	height: 0;
	left: 0;
	margin-left: auto;
	margin-right: auto;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	transform: translate3d(0, 20px, 0);
	transition: all .2s ease-out;
	visibility: hidden;
	&.active {
		height: auto;
		opacity: 1;
		position: relative;
		transform: translate3d(0, 0, 0);
		transition-delay: .1s;
		transition-duration: .4s;
		transition-timing-function: easing(ease-out-back);
		visibility: visible;
	}
}

.ul {
	@include font_size(fs_p);
	list-style: disc outside;
	padding-left: 1em;
	li {
		padding-bottom: 1em;
	}
}

.ul_extra {
	@include font_size(fs_p_extra);
	list-style: disc outside;
	padding-left: 1em;
	li {
		margin-bottom: 1em;
	}
}

.ul_reset {
	list-style: none outside;
	padding: 0;
	li {
		padding: 0;
	}
}
