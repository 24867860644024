.dist_tile_list_small {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
	@include breakpoint(desktop-xl) {
		justify-content: flex-start;
	}
	.dist_tile_small {
		background-color: #efefef;
		flex-basis: 100%;
		margin-bottom: 15px;
		max-width: 400px;
		position: relative;
		@include breakpoint(tablet, desktop-xl) {
			flex-basis: calc(50% - 15px);
			margin-right: 15px;
			&:nth-child(2n + 2) {
				margin-right: 0;
			}
		}
		@include breakpoint(desktop-xl) {
			flex-basis: calc(33% - 6px);
			margin-right: 15px;
			max-width: none;
			&:nth-child(3n + 3) {
				margin-right: 0;
			}
		}
		&:after {
			background-image: url('../img/recognition/shadow-bottom.png');
			background-position: top center;
			background-size: 100% auto;
			background-repeat: no-repeat;
			bottom: 0;
			content: '';
			height: 100px;
			left: 0;
			position: absolute;
			transform: translate3d(0, 100%, 0);
			pointer-events: none;
			width: 100%;
		}
		&:hover {
			.dist_tile_small_copy {
				transform: translate3d(0, 20px, 0);
				transition-timing-function: easing(ease-in-quint);
			}
			.dist_tile_small_flag {
				transform: translate3d(0, -50px, 0);
				transition-timing-function: easing(ease-in-quint);
			}
			.dist_tile_small_stat {
				opacity: 1;
				transition-timing-function: easing(ease-in-quint);
			}
			.dist_url {
				opacity: 1;
				transform: translate3d(0, 0, 0);
				transition-timing-function: easing(ease-in-quint);
				transition-delay: .05s;
			}
		}
		&.pro_7 {
			.dist_tile_small_copy,
			.distributor_label,
			.distributor_value {
				color: color(rank_pro_7);
			}
			.dist_tile_small_flag {
				background-color: color(rank_pro_7);
				color: color(gray, xx-light);
			}
			.world {
				fill: color(rank_pro_7);
			}
		}
		&.pro_8 {
			background-color: color(rank_pro_8);
			.dist_tile_small_copy,
			.distributor_label,
			.distributor_value {
				color: color(rank_pro_8, light);
			}
			.dist_tile_small_flag {
				background-color: color(rank_pro_8, light);
				color: color(rank_pro_8);
			}
			.world {
				fill: color(rank_pro_8, light);
			}
		}
		&.pro_9 {
			background-color: color(rank_pro_9);
			.dist_tile_small_copy,
			.distributor_label,
			.distributor_value {
				color: color(rank_pro_9, light);
			}
			.dist_tile_small_flag {
				background-color: color(rank_pro_9, light);
				color: color(rank_pro_9);
			}
			.world {
				fill: color(rank_pro_9, light);
			}
		}
	}
	.dist_tile_small_copy {
		left: 20px;
		position: absolute;
		top: 0;
		transform: translate3d(0, 50%, 0);
		transition: transform .2s easing(ease-out-quart);
	}
	.dist_tile_small_flag {
		border-radius: 3px;
		font-size: 12px;
		left: 20px;
		letter-spacing: .1em;
		line-height: 22px;
		padding: 0 12px;
		position: absolute;
		text-transform: uppercase;
		top: 20px;
		transform: translate3d(0, 0, 0);
		transition: transform .2s easing(ease-out-quart);
	}
	.dist_tile_small_image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.dist_tile_small_stat {
		margin-bottom: 10px;
		opacity: 0;
		transition: opacity .2s easing(ease-out-quart);
		@include breakpoint(mobile) {
			margin-bottom: 20px;
		}
		.distributor_value {
			line-height: 1em;
		}
	}
	.dist_tile_small_title {
		font-size: 25px;
		font-weight: 100;
		line-height: 1em;
		margin-bottom: 10px;
		text-transform: uppercase;
		@include breakpoint(mobile) {
			margin-bottom: 20px;
		}
		@include breakpoint(tablet-l) {
			font-size: 36px;
			line-height: .8em;
		}
		.emphasis {
			font-weight: bold;
		}
	}
	.dist_tile_stage {
		@include constrain-proportions(100%, 66%);
		overflow: hidden;
		position: relative;
		@include breakpoint(zero, mobile-l) {
			@include constrain-proportions(100%, 76%);
		}
		@include breakpoint(tablet, tablet-l) {
			@include constrain-proportions(100%, 76%);
		}
	}
	.dist_url {
		bottom: 20px;
		height: 24px;
		left: 20px;
		opacity: 0;
		position: absolute;
		transform: translate3d(0, 50px, 0);
		transition: opacity .2s easing(ease-out-quart), transform .2s easing(ease-out-quart);
		width: 24px;
		.world {
			width: 100%;
		}
	}
}
