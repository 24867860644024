.app_page {
	background-color: color(white);
	.app_title {
		@include font_size(fs_h1_small);
		color: color(white);
		line-height: 1em;
		margin: 20px 0;
		text-transform: uppercase;
		&.page {
			width: 100%;
			@include breakpoint(tablet, tablet-l) {
				font-size: 40px;
			}
			@include breakpoint(tablet-l, desktop-xl) {
				font-size: 50px;
			}
			@include breakpoint(tablet) {
				margin: 0 0 0 20px;
				width: calc(50% - 20px);
			}
		}
	}
	.banner_stat_list {
		@include font_size(fs_p);
		margin: 40px 0 0;
		padding: 0;
		h4 {
			margin: 0 0 5px -8px;
			&:before {
				content: "_";
			}
		}
		li {
			list-style: none;
			margin-bottom: 25px;
			&:last-child {
				margin-bottom: 0;
				p {
					margin-bottom: 0;
				}
			}
		}
	}
	.banner_subtitle {
		@extend .h5_sans;
		color: color(white);
		letter-spacing: .1em;
		text-transform: uppercase;
	}
	.btn_new.notched {
		margin: 1em 0;
	}
	.banner_description {
		@include font_size(fs_p_extra);
	}
}

.app_banner_1 {
	border-top: 1px solid color(gray, x-light);
	display: flex;
	flex-direction: column;
	padding: 80px 0;
	@include breakpoint(tablet-s) {
		align-items: center;
		flex-direction: row;
	}
	.app_banner_1_icon {
		margin-bottom: 10px;
		// margin-right: 20px;
		height: 150px;
		width: 150px;
		@include breakpoint(mobile) {
			height: 225px;
			width: 225px;
		}
		@include breakpoint(tablet-s) {
			margin-bottom: 0;
			margin-right: 40px;
		}
		img {
			width: 100%;
		}
	}
	.app_banner_1_content {
		flex: 1;
		a {
			color: color(blue);
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 0.15em;
			text-decoration: none;
			text-transform: uppercase;
		}
	}
	.app_banner_copy {
		@extend .p_extra;
		@include font_size(fs_p_extra);
		margin-bottom: 30px;
	}
	.app_banner_title {
		@include font-smoothing;
		color: color(gray);
		font-family: font(serif);
		font-size: 42px;
		font-style: italic;
		margin-bottom: 20px;
		line-height:1em;
	}
}

.app_banner_2_wrapper {
	background: color(white) url('../img/app-page/dna-bg-gray.jpg') repeat-x bottom center;
	background-color: #f5f5f5;
	padding: 110px 0;
	position: relative;
	.app_banner_2_image_container {
		flex: 1;
		max-width: 600px;
		margin: 0 auto;
		order: 2;
		position: relative;
		@include breakpoint(tablet) {
			max-width: none;
			order: 1;
		}
	}
	.app_banner_2_image {
		display: none;
		@include constrain-proportions(100%, 100%);
		background: url('../img/app-page/app-banner-screenshots.png') no-repeat left top;
		background-size: contain;
		display: block;
		position: relative;
		@include breakpoint(tablet) {
			background-position: left center;
			bottom: 0;
			height: 150%;
			left: 0;
			margin: auto;
			margin-left: -70px;
			position: absolute;
			top: 0;
			width: calc(100% + 50px);
		}
	}
	.app_banner_2_copy {
		flex: 1;
		order: 1;
		padding-bottom: 40px;
		@include breakpoint(tablet) {
			order: 2;
			padding-bottom: 0;
		}
		@include breakpoint(desktop) {
			order: 2;
		}
		.line {
			@include breakpoint(zero, tablet-s) {
				display: inline-block;
			}
		}
	}
	.app_banner_2 {
		display: flex;
		flex-direction: column;
		position: relative;
		@include breakpoint(tablet) {
			flex-direction: row;
			justify-content: flex-end;
		}
	}
	.app_title {
		color: color(blue);
	}
	.banner_subtitle {
		color: color(blue, muted);
	}
}

.app_list {
	display: flex;
	flex-direction: column;
	@include breakpoint(tablet-s) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.app_cta {
		.coming_soon {
			background-color: color(gray, x-light);
			border-radius: 3px;
			color: color(blue, muted);
			display: inline-block;
			font-size: 12px;
			font-weight: 600;
			line-height: 40px;
			padding: 0 2em;
			text-align: center;
			text-transform: uppercase;
		}
		.download_apple,
		.download_google {
			display: inline-block;
			max-width: 100px;
			@include breakpoint(tablet-s) {
				width: calc(50% - 8px);
			}
			img {
				width: 100%;
			}
		}
		.download_apple {
			margin-right: 8px;
			@include breakpoint(tablet-s) {
				margin-right: 0;
			}
		}
	}
	.app_individual {
		margin-bottom: 60px;
		@include breakpoint(tablet-s) {
			flex-basis: calc(50% - 15px);
		}
		@include breakpoint(tablet) {
			flex-basis: calc(50% - 20px);
		}
		@include breakpoint(desktop) {
			margin-bottom: 120px;
		}
		.app_header {
			align-items: center;
			display: flex;
			flex-direction: column;
			@include breakpoint(tablet-s) {
				flex-direction: row;
			}
		}
		.app_header_text {
			flex: 1;
		}
		.app_icon {
			background: no-repeat center top;
			background-size: contain;
			height: 75px;
			width: 75px;
			@include breakpoint(tablet-s) {
				margin-bottom: 0;
				margin-right: 20px;
			}
			@include breakpoint(desktop-l) {
				height: 100px;
				width: 100px;
			}
		}
		.app_individual_content {
			@include breakpoint(desktop-l) {
				flex: 1;
			}
		}
		.app_tile_title {
			@extend .h5_sans;
			color: color(blue);
			font-size: 20px;
			margin-bottom: 20px;
			margin-top: 10px;
			text-align: center;
			text-transform: uppercase;
			@include breakpoint(tablet-s) {
				margin-bottom: 8px;
				margin-top: 0;
				text-align: left;
			}
		}
		.description {
			@extend .p_extra;
			margin: 0 auto;
			max-width: 375px;
			padding-bottom: 20px;
			padding-top: 20px;
			text-align: left;
			@include breakpoint(tablet-s) {
				max-width: none;
				padding-top: 0;
				text-align: left;
			}
		}
	}
}

.hero_wrapper_bg {
	background: color(white) url('../img/app-page/app-billboard-bg.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.hero_wrapper {
	align-items: center;
	color: color(white);
	display: flex;
	margin-bottom: 100px;
	padding: 60px 0;
	@include breakpoint(tablet) {
		margin-bottom: 240px;
	}
	.hero_copy {
		flex: 1;
	}
	.hero_description {
		@include font-smoothing;
		@extend .p_extra;
		@include font_size(fs_h5);
		letter-spacing: letter-spacing(serif);
		font-family: font(serif);
		font-weight: 300;
	}
	.hero_icons_list {
		margin-bottom: 10px;
		padding-top: 10px;
		border-bottom: 1px solid color(white);
		padding-bottom: 1.5em;
		display: inline-block;
		padding-right: 5%;
		@include breakpoint(tablet-s) {
			padding-bottom: 2em;
		}
		.hero_icon {
			height: auto;
			padding-right: 8px;
			width: 25px;
			float: left;
			@include breakpoint(tablet-s) {
				padding-right: 10px;
				width: 30px;
			}
			&:last-child {
				padding-right: 0;
			}
		}
	}
	.hero_image {
		align-items: center;
		display: none;
		justify-content: center;
		width: 300px;
		@include breakpoint(tablet-s) {
			display: flex;
			width: 300px;
		}
		@include breakpoint(tablet) {
			flex: 1;
		}
		img {
			height: auto;
			max-width: 380px;
			width: 100%;
		}
	}
	.hero_pricing_list {
		margin: 0;
		padding: 0;
		li {
			@include font-smoothing;
			list-style: none;
			position: relative;
			&:last-child {
				margin-right: 0;
				&:after {
					content: '';
				}
			}
			@include breakpoint(mobile) {
				display: inline-block;
				margin-right: 25px;
			}
			@include breakpoint(tablet-s, tablet) {
				margin-right: 10px;
			}
			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: 0;
				transform: translate3d(18px, 0, 0);
				@include breakpoint(mobile) {
					content: '/';
				}
				@include breakpoint(tablet-s, tablet) {
					transform: translate3d(8px, 0, 0);
				}
			}
		}
	}
	.hero_title {
		@extend .h1_sans;
		margin-bottom: .2em;
		text-transform: uppercase;
		line-height: .9em;
		@include breakpoint(mobile) {
			line-height: .8em;
		}
		// our version of proxima's line heights get wonky, this tries to sort for it
		.light + .name {
			margin-top: .03em;
		}
		// our version of proxima's line heights get wonky, this tries to sort for it
		.name + .light {
			margin-top: -.02em;
		}
		.name {
			color: color(blue);
			font-weight: 900;
			line-height: .9em;
			transform: translateY(.01em); // our version of proxima's line heights get wonky, this tries to sort for it
			sup {
				top:-.7em;
				font-size:50%;
			}
		}
	}
	.btn_new {
		margin: 2em 0;
	}
}
