$blog_mobile_breakpoint_lrg: tablet;
// $blog_sidebar_breakpoint_lrg: ;
$blog_sidebar_breakpoint_small: desktop-l;

%category {
	@include font_size(fs_p_note);
	background-color: color(gray, xx-light);
	color: color(blue);
	display: inline-block;
	font-weight: bold;
	letter-spacing: 1px;
	margin-bottom: 40px;
	padding: .5em 1em .4em;
	text-decoration: none;
	text-transform: uppercase;
}

%date {
	@include font_size(fs_p_note);
	font-weight: bold;
	letter-spacing: 1.5px;
	text-transform: uppercase;
}

%sidebar {
	background: color(gray, xxx-light);
	clear: both;
	float: none;
	margin: 50px 0 40px;
	width: 100%;
	@include breakpoint($blog_sidebar_breakpoint_small) {
		clear: none;
		float: left;
		margin: 0 0 40px;
		width: 300px;
	}
	.blog_sidebar_section {
		padding: 30px;
		border-top: 2px solid color(white);
		&:first-child {
			border-top: 0;
		}
	}
	.content {
		background-color: inherit;
		padding: 20px 40px 20px 20px;
		width: calc(100% - 180px);
		@include breakpoint($blog_sidebar_breakpoint_small) {
			padding: 0;
			width: 100%;
		}
	}
	.excerpt {
		display: none;
		@include breakpoint($blog_sidebar_breakpoint_small) {
			display: block;
		}
	}
	.excerpt {
		display: none;
		@include breakpoint($blog_sidebar_breakpoint_small) {
			display: block;
		}
	}
	.featured_image {
		background-repeat: no-repeat;
		background-size: cover;
		float: right;
		height: 120px;
		overflow: hidden;
		width: 120px;
		@include breakpoint($blog_sidebar_breakpoint_small) {
			float: none;
			height: 125px;
			width: 100%;
		}
		&.left {
			background-position: left;
		}
		&.right {
			background-position: right;
		}
		&.center {
			background-position: center;
		}
	}
	h4 {
		@include font_size(fs_p_note);
		color: color(blue);
		letter-spacing: 1px;
		margin-bottom: 20px;
		text-transform: uppercase;
	}
	.posts_list {
		margin: 0;
		padding: 0;
		a {
			color: inherit;
			text-decoration: none;
		}
		li {
			@include font_size(fs_p);
			background-color: color(white);
			list-style: none;
			margin-bottom: 20px;
			overflow: hidden;
			padding: 0;
			@include breakpoint($blog_sidebar_breakpoint_small) {
				background-color: inherit;
				padding: 5px 0 7px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		.title {
			color: color(black);
			font-size: inherit;
			font-style: bold;
			margin: 20px 0 15px;
			text-transform: none;
		}
	}
	.sidebar_newsletter_container {
		// display: inline-block;
		overflow: hidden;
	}
	.sidebar_btn {
		@extend .fs_legal;
		background-color: color(blue);
		border-radius: 0;
		box-shadow: none;
		color: color(white);
		cursor:pointer;
		display:block;
		float: left;
		font-weight: bold;
		letter-spacing: 1px;
		line-height:1.5em;
		padding: 17px 0 14px;
		text-align:center;
		text-transform: uppercase;
		width: 30%;
	}
	.sidebar_input {
		@include font_size(fs_h6);
		background-color: color(white);
		border: 1px solid color(gray, x-light);
		border-radius: 0;
		box-shadow: none;
		box-sizing: border-box;
		padding: 16px 14px 12px;
		width: 100%;
		&.sidebar_search {
			background: color(white) url('../img/blog/search-icon.png') no-repeat 15px 16px;
			padding-left: 34px;
		}
		&.sidebar_newsletter {
			float: left;
			width: 70%;
		}
	}
}

%top_dash_blog {
	@extend %top_dash;
	border-top: 0;
	@include breakpoint($blog_mobile_breakpoint_lrg) {
		border-top: 2px solid #fff;
		top: -3em;
		width: 3em
	}
}

.blog_container {
	background-color: color(white);
	.content_wrapper {
		@include breakpoint(zero, $blog_mobile_breakpoint_lrg) {
			margin: 0;
			.blog_content_wrapper {
				margin: 0 20px;
			}
		}
	}

	section {
		@include section_reset;
	}

}

.blog_post_container {
	clear: both;
	overflow: hidden;
	width: 100%;
	@include breakpoint($blog_mobile_breakpoint_lrg) {
		clip-path: polygon(20px 0, 100% 0, 100% 100%, 0 100%, 0 20px);
		padding-top: 60px;
	}
	.blog_hero {
		position:relative;
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
		color: color(white);
		height: auto;
		@include breakpoint($blog_mobile_breakpoint_lrg) {
			height: 500px;
		}
		&:after {
			content:"";
			position:absolute;
			top:0;
			left:0;
			z-index:1;
			display:block;
			height:100%;
			width:100%;
			background: -moz-linear-gradient(95deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.05) 45%, rgba(0,0,0,0) 100%);
			background: -webkit-linear-gradient(95deg, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.05) 45%,rgba(0,0,0,0) 100%);
			background: linear-gradient(95deg, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.05) 45%,rgba(0,0,0,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#24000000', endColorstr='#00000000',GradientType=1 );
		}
		.date {
			@extend %date;
			position: relative;
			&::before {
				@extend %top_dash_blog;
			}
		}
		.category {
			@extend %category;
		}
		.title {
			@extend .h2_serif;
			margin: 12px 0 40px;
			text-shadow:1px 1px 3px rgba(0,0,0,0.1);
		}
		.title_container {
			position:relative;
			z-index:2;
			padding: 90px 40px;
			width: calc(100% - 80px);
			@include breakpoint($blog_mobile_breakpoint_lrg) {
				padding: 110px 70px 70px;
				width: 420px;
			}
		}
	}
	.post_container {
		// This is removing some default styling from the current blog styles
		background: color(white);
		float: none;
		margin: 0;
		width: 100%;
		@include breakpoint($blog_sidebar_breakpoint_small) {
			background: color(gray, xxx-light);
			float: left;
			margin-bottom: 80px;
			margin-right: 2%;
			width: calc(98% - 300px);
		}
		.blog {
			margin-bottom: 0;
		}
	}
	.post_content {
		background: color(white);
		margin: 0;
		padding: 30px 0;
		@include breakpoint($blog_sidebar_breakpoint_small) {
			margin: 0 30px;
			padding: 20px 42px;
			&.extra_pad {
				margin-bottom: 30px;
			}
		}
		&.main {
			position:relative;
			z-index:2;
			margin-top: 0;
			@include breakpoint($blog_mobile_breakpoint_lrg) {
				margin-top: -75px;
			}
		}
		.excerpt p {
			@include font_size(fs_h4);
			font-weight: lighter;
		}
		h2 {
			@include font_size(fs_h5);
			color: color(black);
			letter-spacing: 3px;
			margin: 40px 0 0;
			text-transform: uppercase;
		}

//		Global Blog Content Styles
		h3 {
			@extend .h5_sans;
			text-transform: uppercase;
			margin: 40px 0 0;
		}
		p {
			@include font_size(fs_p_extra);
		}
		ol, ul {
			margin:1.5em 5px;
			padding-left:30px;
			li {
				margin-bottom:.825em;
			}
		}
		hr {
			margin-top:50px;
			margin-bottom:50px;
			border-color:color(gray, x-light);
		}
		.title_share {
			margin-bottom: 0;
		}
		label {
			@extend .h6_sans;
			display:block;
			color:color(blue);
			text-align:center;
		}
		.legal_copy {
			@include font_size(fs_p_note_extra);
		}
	}
	.blog_sidebar {
		@extend %sidebar;
	}
	.tab_nav {
		margin-bottom: 60px;
	}
}

.blog_top_story {
	margin: 0 0 80px;
	overflow: hidden;
	position: relative;
	@include breakpoint($blog_mobile_breakpoint_lrg) {
		margin: 60px 0 80px;
	}
	.bleed_wrapper {
		margin: 0 auto;
		position: relative;;
		height: 340px;
		left: 0;
		right: 0;
		top: 0;
		@include breakpoint($blog_mobile_breakpoint_lrg) {
			height: 100%;
			position: absolute;
		}
	}
	.category {
		@extend %category;
		color: color(gray);
	}
	.content {
		background-color: color(gray, xxx-light);
		clear: both;
		float: none;
		padding: 60px 20px;
		width: calc(100% - 40px);
		@include breakpoint($blog_mobile_breakpoint_lrg) {
			clear: none;
			float: right;
			padding: 2.5em 3em;
			width: 25em;
		}
	}
	.cta {
		margin-top: 30px;
	}
	.date {
		@extend %date;
		color: color(blue);
	}
	.featured_image {
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		@include breakpoint($blog_mobile_breakpoint_lrg) {
			width: 60%;
		}
		&.left {
			background-position: left;
		}
		&.right {
			background-position: right;
		}
		&.center {
			background-position: center;
		}
	}
	.title {
		@extend .h3_sans;
		color: color(gray);
		font-weight: bold;
		margin: 22px 0;
		a {
			color: inherit;
			text-decoration: none;
		}
	}
}
.blog_gradient {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		display: block;
		height: 100%;
		width: 100%;
		background: -moz-linear-gradient(95deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.05) 45%, rgba(0,0,0,0) 100%);
		background: -webkit-linear-gradient(95deg, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.05) 45%,rgba(0,0,0,0) 100%);
		background: linear-gradient(95deg, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.05) 45%,rgba(0,0,0,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#24000000', endColorstr='#00000000',GradientType=1 );
		}
}
// blog post call to action box presented from ACF
.blog_cta {
	background: color(gray, xxx-light);
	padding: 60px 0 75px;
	text-align: center;
	p {
		max-width:80%;
		margin: 36px auto 15px;
	}
	.title {
		@extend .h3_serif;
		color: color(black);
		display: inline-block;
		margin: 0 0 45px;
		position: relative;
		text-transform: none;
		&:after {
			border-bottom: 1px solid color(black);
			bottom: -40px;
			left: 50%;
			margin-left: -12px;
			content: '';
			position: absolute;
			width: 24px;
		}
	}
}
.blog_cta_middle {
	background: color(gray, xxx-light);
	padding: 2em 0;
	text-align: center;
	margin: 30px 0;
	@include breakpoint(desktop-l) {
		margin: 30px -42px;
	}
	p {
		max-width:80%;
		margin: 36px auto 15px;
	}
	.post_content & .title {
		@extend .h3_serif;
		color: color(black);
		display: inline-block;
		margin: 0 0 45px;
		position: relative;
		text-transform: none;
		&:after {
			border-bottom: 1px solid color(black);
			bottom: -40px;
			left: 50%;
			margin-left: -12px;
			content: '';
			position: absolute;
			width: 24px;
		}
	}
}

.blog_post_tiles {
	background: color(white);
	clear: both;
	margin-bottom: 80px;
	@include breakpoint($blog_mobile_breakpoint_lrg) {
		margin-bottom: 120px;
	}
	.post_tiles {
		display: block;
		margin: 0;
		padding: 0;
		@include breakpoint($blog_mobile_breakpoint_lrg) {
			display: flex;
		}
		.content {
			@include font_size(fs_p);
//			height: 100%;
			padding: 24px 22px;
			.title {
				font-weight: bold;
				line-height: 1.4em;
				text-transform: none;
			}
		}
		.featured_image {
			background-color: color(gray, xxx-light);
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 160px;
			overflow: hidden;
			position: relative;
			&.left {
				background-position: left;
			}
			&.right {
				background-position: right;
			}
			&.center {
				background-position: center;
			}
			.category {
				@include font_size(fs_p_note);
				background-color: color(white);
				bottom: 20px;
				color: color(blue);
				font-weight: bold;
				left: 0;
				letter-spacing: 1px;
				padding: 9px 16px 7px;
				position: absolute;
				text-transform: uppercase;
			}
		}
		li {
			background-color: color(gray, xxx-light);
			clear: both;
			list-style: none;
			margin: 20px 0;
			width: 100%;
			@include breakpoint($blog_mobile_breakpoint_lrg) {
				flex: 1;
				float: left;
				margin-left: 2%;
				max-width: 23.5%;
			}
			&:first-child {
				margin-left: 0;
			}
			a {
				color: inherit;
				text-decoration: none;
			}
			.content {
				background-color: inherit;
			}
		}
	}
	.sub_title {
		@extend .h5_sans;
		border-bottom: 1px solid color(gray, x-light);
		color: color(black);
		margin-bottom: 1em;
		margin-top: 2em;
		&:first-of-type {
			margin-top: 0;
		}
	}
}

.category_tiles {
	background-color: color(white);
	@include breakpoint($blog_mobile_breakpoint_lrg) {
		margin-bottom: 120px;
	}
	#beauty {
		background-image: url('../img/blog/beauty.jpg');
	}
	#biohacking {
		background-image: url('../img/blog/biohacking.jpg');
	}
	#entrepreneurship {
		background-image: url('../img/blog/entrepreneurship.jpg');
	}
	#fitness {
		background-image: url('../img/blog/fitness.jpg');
	}
	#health {
		background-image: url('../img/blog/health.jpg');
	}
	#motivation {
		background-image: url('../img/blog/motivation.jpg');
	}
	#science {
		background-image: url('../img/blog/science.jpg');
	}
	ul {
		margin: 0;
		padding: 0;
		.cat_tile_link {
			height: 100%;
			left: 0;
			overflow: hidden;
			position: absolute;
			top: 0;
			width: 100%;
		}
		li {
			background-position: center top;
			background-repeat: no-repeat;
			background-size: cover;
			clear: both;
			height: 190px;
			list-style: none;
			margin-bottom: 20px;
			position: relative;
			width: 100%;
			@include breakpoint($blog_mobile_breakpoint_lrg) {
				clear: none;
				float: left;
				margin-bottom: 2%;
				width: 49%;
			}
			&:nth-child(odd) {
				margin-right: 2%;
			}
			.title {
				@extend .h3_serif;
				font-size: 32px;
				left: 36px;
				position: absolute;
				top: 80px;
				.arrow_link_white {
					color: color(white);
					text-decoration: none;
					&:before {
						margin-top: .55em;
					}
				}
			}
		}
	}
}

.cat_post_list {
	clear: both;
	display: block;
	margin-top: 30px;
	.blog_cta_link {
		display: none;
		@include breakpoint($blog_mobile_breakpoint_lrg) {
			display: inline-block;

		}
	}
	.cat_blog_post {
		border-bottom: 1px solid color(gray, x-light);
		margin: 0;
		overflow: hidden;
		padding: 40px 0;
		@include breakpoint($blog_mobile_breakpoint_lrg) {
			border-bottom: 0;
			margin-bottom: 30px;
			padding: 0;
		}
	}
	.post_container {
		clear: both;
		float: none;
		width: 100%;
		@include breakpoint($blog_sidebar_breakpoint_small) {
			clear: none;
			float: left;
			width: calc(100% - 300px);
		}
		.date {
			@extend %date;
			display: none;
			@include breakpoint($blog_mobile_breakpoint_lrg) {
				display: block;
			}
		}
		.category {
			font-family: font(serif);
letter-spacing: letter-spacing(serif);
			display: none;
			font-style: italic;
			letter-spacing: .7px;
			@include breakpoint($blog_mobile_breakpoint_lrg) {
				display: block;
			}
			a {
				color: color(gray, light);
				text-decoration: none;
			}
		}
		.content {
			background-color: inherit;
			float: right;
			margin: 0;
			width: calc(100% - 140px);
			@include breakpoint($blog_mobile_breakpoint_lrg) {
				margin: 0;
				width: calc(92% - 300px);
			}
			@include breakpoint($blog_sidebar_breakpoint_small) {
				float: left;
				margin: .5em 7% .5em 0;
				width: calc(85% - 300px);
			}
		}
		.excerpt {
			display: none;
			margin: 25px 0 30px;
			@include breakpoint($blog_mobile_breakpoint_lrg) {
				display: block;
			}
		}
		.thumbnail {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			float: right;
			height: 120px;
			margin: 0;
			overflow: hidden;
			width: 120px;
			@include breakpoint($blog_mobile_breakpoint_lrg) {
				float: left;
				height: 300px;
				margin-right: 8%;
				width: 300px;
			}
			&.left {
				background-position: left;
			}
			&.right {
				background-position: right;
			}
		}
		.title {
			@extend .h4_sans;
			margin: 20px 0 15px;
			text-transform: none;
			a {
				color: inherit;
				text-decoration: none;
			}
		}
	}
	.blog_sidebar {
		@extend %sidebar;
	}
}

.cat_top_story {
	background-repeat: no-repeat;
	background-size: cover;
	color: color(white);
	min-height: 430px;
	margin: 0;
	position: relative;
	@include breakpoint($blog_mobile_breakpoint_lrg) {
		clip-path: polygon(20px 0, 100% 0, 100% 100%, 0 100%, 0 20px);
		margin: 60px 0 0;
	}
	&.left {
		background-position: left 0;
	}
	&.right {
		background-position: right 0;
	}
	&.center {
		background-position: center 0;
	}
	.category {
		@extend %category;
		background-color: color(white);
		clear: both;
	}
	.content {
		background-color: transparent;
		padding: 100px 0 100px 40px;
		z-index: 2;
		@include breakpoint($blog_mobile_breakpoint_lrg) {
			max-width: 22em;
		}
	}
	.control {
		color: color(white);
	}
	.blog_cta_link {
		clear: both;
	}
	.date {
		@extend %date;
		position: relative;
		&::before {
			@extend %top_dash_blog;
		}
	}
	.excerpt {
		margin-bottom: 30px;
		display: none;
		@include breakpoint($blog_mobile_breakpoint_lrg) {
			display: block;
		}
	}
	.title {
		@extend .h3_serif;
		a {
			color: inherit;
			text-decoration: none;
		}
	}
}

.blog_nav {
	box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.2);
	.pdp_tab_nav {
		.tabs {
			ul {
				float:right;
				@include breakpoint(desktop) {
					float:none;
				}
			}
		}
		.select_wrapper {
			left: -20px;
			@include breakpoint($blog_mobile_breakpoint_lrg) {
				left: auto;
			}
		}
		.controls {
			left: auto;
			right: 0;
			@include breakpoint(tablet-l) {
				left: 0;
				right: auto;
			}
			.btn_new {
				color: color(gray);
				font-weight: bold;
				height: 100%;
				line-height: 40px;
				padding-bottom: 0;
				padding-top: 0;
				&::before {
					background-color: color(gray, xx-light);
				}
			}
		}
	}
}

.pagination {
	float: none;
	text-align: center;
	a,
	span {
		display: inline-block;
		float: none;
	}
	a {
		color: color(gray, light);
		&:hover {
			background-color: color(blue, light);
			color: color(white);
		}
	}
	.pagination_nav {
		background-color: color(gray, xxx-light);
		border: 1px solid color(gray, x-light);
		&:hover {
			background-color: color(blue, light);
			border-color: color(blue);
			color: color(white);
		}
	}
	.pagination_number {
		background-color: color(blue, light);
	}
}
