// notes
// we lose 40 pixels in the top and bottom margin of the image
// to gain the height needed to show the absolute positioned button
// this will leave the remaining items to grow and shrink naturally with no
// set height

.product_wall {
	@include section_reset;
	background-color: color(white);
	border-bottom: 1px solid color(gray, x-light);
	overflow: visible;
	body.global_market &,
	body.sister_market & {
		border-bottom: 0;
	}
	&__no_results {
		padding-bottom: 30px;
		@include breakpoint(tablet) {
			padding-bottom: 60px;
		}
		p {
			@include font_size(fs_h4);
			line-height: 33px;
			margin-bottom: 0;
			margin-left: 0px;
			margin-right: 0px;
			margin-top: 24px;
			text-align: center;
		}
	}
	.bg {
		background-color: #f2f4f5;
		height: 100%;
		left: 50%;
		margin: auto;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: height .2s ease-out, top .2s ease-out, width .2s ease-out;
		width: 100%;
		z-index: 3;
		@include breakpoint(tablet) {
			border-radius: 5px;
		};
	}
	.category_group {
		@include breakpoint(tablet) {
			margin-right:-16px;
		}
	}
	.cart_mini_link {
		display: block;
		height: 40px;
		position: absolute;
		right: 0;
		text-indent: -9999px;
		top: 0;
		width: 40px;
		z-index: 5;
		@include breakpoint(tablet) {
			display: none;
		}
		&.disabled {
			opacity: .35;
		}
		.add_to_cart_mini {
			fill: #bbc3cc;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
	.product_list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		list-style: none;
		margin-bottom: 60px;
		margin-left: -20px;
		margin-right: -20px;
		margin-top: 0;
		padding: 0;
		@include breakpoint(mobile-xl) {
			margin-left: 0;
			margin-right: 0;
		}
		@include breakpoint(tablet) {
			justify-content: flex-start;
			margin-left: 0;
			margin-right: 0;
		}
		.controls {
			bottom: 0;
			left: 0;
			position: absolute;
			transition: transform .2s ease-out;
			width: 100%;
			z-index: 1;
			.product_btn {
				@include font_size(fs_p_note_extra);
				@include font_smoothing;
				background-color: color(blue);
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
				bottom: 5px;
				color: color(white);
				font-weight: 600;
				height: 40px;
				letter-spacing: .175em;
				line-height: 40px;
				margin-left: 1px;
				margin-right: 1px;
				position: absolute;
				text-align: center;
				text-transform: uppercase;
				transition: background-color .2s ease-out;
				width: calc(100% - 2px);
				z-index: 2;
				&.disabled,
				&[disabled] {
					&,
					&:hover {
						background-color: color(gray, x-light) !important;
						cursor: not-allowed;
					}
				}
				&:hover {
					background-color: color(blue, dark);
				}
				&__variant {
					&.active {
						@include small_shadow;
						background-color: #848c97;
						&:hover {
							background-color: #6b7583;
						}
					}
				}
				.chevron {
					bottom: 0;
					margin: auto;
					position: absolute;
					right: 15px;
					top: 0;
					transform: rotate(0deg);
					transition: transform .2s ease-out;
					width: 12px;
					polygon {
						fill: color(white);
					}
				}
			}
		}
		.product {
			box-sizing: border-box;
			margin-bottom: 4px;
			min-height: 340px;
			position: relative;
			width: calc(50% - 2px);
			z-index: 10;
			@include breakpoint(zero, tablet) {
				// make the tile heights all consistent on mobile
				display: flex;
			}
			@include breakpoint(tablet) {
				height: 1px; // old safari calc child can't inherit parent height bug fix?
				margin-bottom: 16px;
				margin-right: 16px;
				width: 280px;
			}
			@include breakpoint(max-width-with-gutters) {
				width: 308px;
			}
			&.lto {
				.bg {
					@include breakpoint(tablet) {
						border: 2px solid #ff6666;
						box-sizing: border-box;
					}
				}
				.product_btn {
					background-color: #ff6666;
				}
				.product_flag {
					background-color: #ff6666;
				}
			}
			&.variant_open {
				.product_btn__variant {
					@include small_shadow;
					background-color: #848c97;
					&:hover {
						background-color: #6b7583;
					}
				}
				.product_drawer {
					transform: translate3d(0, 100%, 0);
					transition: visibility 0s, transform .2s easing(ease-out-quad);
					visibility: visible;
				}
				.product_flag {
					display: none;
				}
			}
			.link {
				height: 100%;
				left: 0;
				opacity: 0;
				pointer-events: auto;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
		.product_content {
			@include font_smoothing;
			padding-bottom: 35px;
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 40px;
			pointer-events: none;
			position: relative;
			text-align: center;
			z-index: 4;
			height: calc(100% - 5px);
			box-sizing: border-box;
			@include breakpoint(tablet) {
				padding-bottom: 25px;
				padding-top: 20px;
			}
		}
		.product_flag {
			@include font_size(fs_p_note_extra);
			background-color: color(blue);
			border-radius: 3px;
			color: color(white);
			display: inline-block;
			font-weight: 600;
			letter-spacing: .175em;
			line-height: 1.8em;
			margin-bottom: 15px;
			padding-left: .75em;
			padding-right: .75em;
			text-transform: uppercase;
			white-space: nowrap;
			@include breakpoint(tablet) {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				left: 50%;
				margin-bottom: 0;
				position: absolute;
				top: 0;
				transform: translate3d(-50%, 0, 0);
				transition: top .2s ease-out;
			}
		}
		.product_image {
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 170px;
			padding-bottom: 10px;
			padding-top: 10px;
			transition: opacity .5s ease-out .1s, transform .2s ease-out;
			@include breakpoint(zero, mobile-xl) {
				margin-left: -20px;
				margin-right: -20px;
			}
			@include breakpoint(tablet) {
				padding-bottom: 30px;
				padding-top: 30px;
			}
		}
		.product_info {
			box-sizing: border-box;
			cursor: pointer;
			position: relative;
			transition: height .2s ease-out;
			z-index: 3;
			@include breakpoint(zero, tablet) {
				width: 100%;
			}
			@include breakpoint(tablet) {
				height: 100%;
			}
		}
		.product_subtitle,
		.product_title {
			@include font_size(fs_p);
			transition: transform .2s ease-out;
		}
		.product_subtitle {
			color: color(gray, light);
			display: none;
		}
		.product_title {
			color: color(gray, x-dark);
			font-weight: 600;
			margin-bottom: 20px;
			@include breakpoint(tablet) {
				margin-bottom: 0;
			}
		}
	}
	.product_drawer {
		@include small_shadow;
		border: 1px solid color(gray, xx-light);
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-top-width: 0;
		bottom: -40px;
		margin: 0 6px;
		position: absolute;
		transform: translate3d(0, 0, 0);
		transition: transform .2s ease-out, visibility 0s .2s easing(ease-in-quad);
		visibility: hidden;
		width: calc(100% - 12px);
		z-index: 1;
	}
}

// if cart is enabled, show these items
.product_wall.cart {
	.product {
		&.hover,
		&:hover,
		&.variant_open {
			z-index: z-index(product-variant-drawer);
			@include breakpoint(tablet) {
				.bg {
					height: calc(100% + 5px);
					top: calc(50% - 2px);
					width: calc(100% + 10px);
				}
				.product_btn {
					bottom: -40px;
					transition: background-color .2s ease-out, bottom .2s ease-out;
				}
				.product_content {
					& > .link {
						height: 100%;
					}
					& > .product_image {
						transform: translateY(-20px);
					}
					& > .product_subtitle,
					& > .product_title {
						transform: translateY(-40px);
					}
				}
				.product_flag {
					top: -4px;
				}
				.product_info {
					height: calc(100% - 40px);
				}
			}
		}
		&.variant_open {
			z-index: z-index(product-variant-drawer-focus);
			.bg {
				height: 125px;
				top: calc(50% - 92px);
			}
			.chevron {
				transform: rotate(180deg);
			}
			.controls {
				transform: translateY(-180px);
			}
			.product_btn {
				bottom: -40px;
				transition: background-color .2s ease-out, bottom .2s ease-out;
			}
			.product_content {
				& > .link {
					height: 120px;
				}
				& > .product_image {
					opacity: 0;
					transition: opacity .1s ease-out;
				}
				& > .product_subtitle,
				& > .product_title {
					transform: translateY(-212px);
				}
			}
		}
	}
	.product_subtitle {
		display: block;
	}
}

.variant_list {
	background-color: #f2f4f5;
	list-style: none;
	margin: 0;
	max-height: 300px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0;
	.link {
		cursor: pointer;
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.variant {
		background-color: transparent;
		border-bottom: 1px solid color(gray, xx-light);
		box-sizing: border-box;
		display: flex;
		height: 90px;
		position: relative;
		transition: background-color .2s ease-out;
		&.disabled {
			cursor: not-allowed;
			.link {
				display: none;
			}
      .product_title {
        color: color(gray, light);
      }
      .variant_image {
        filter: grayscale(1);
        opacity: .65;
      }
		}
		&:not(.disabled):hover {
			background-color: #e8e9eb;
		}
		&:last-child {
			border-bottom: 0;
		}
	}
	.variant_image {
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		min-width: 90px;
	}
	.variant_titles {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.product_subtitle,
	.product_title  {
		@include font_size(fs_p_extra);
	}
	.product_subtitle {
		color: color(gray, light);
	}
	.product_title {
		color: color(gray, x-dark);
		font-weight: 600;
	}
}

.product_wall_nav {
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	margin-bottom: 60px;
	.product_filter {
		transition: width .2s ease-out;
		width: calc(50% - 2px);
		@include breakpoint(zero, tablet) {
			&.condensed {
				width: 3em;
				.decoy {
					opacity: 0;
					transition: none;
				}
			}
		}
		@include breakpoint(tablet) {
			margin-left: 16px;
			width: 308px;
		}
		.custom_select {
			background-image: none;
			border: 1px solid color(gray, x-light);
			height: 60px;
			&.focus {
				border: 1px solid color(blue);
			}
		}
		.decoy {
			align-items: center;
			display: flex;
			padding-top: 0;
			transition: opacity .2s ease-out .2s;
			.text {
				@include font_size(fs_h4);
				color: color(gray, light);
				text-transform: none;
			}
		}
	}
	.search_bar {
		background-color: color(white);
		box-sizing: border-box;
		display: flex;
		position: relative;
		transition: width .2s ease-out;
		width: calc(50% - 2px);
		@include breakpoint(zero, tablet) {
			&.focused {
				width: calc(100% - 3em - 4px);
			}
		}
		@include breakpoint(tablet) {
			flex: 1;
		}
		input[type=submit] {
			height: 0 !important;
			left: 0;
			position: absolute;
			top: 0;
			user-select: none;
			visibility: hidden;
			width: 0 !important;
		}
		input[type=text] {
			@include font_size(fs_h4);
			background-color: color(white);
			background-image: svg(search-icon);
			background-repeat: no-repeat;
			background-size: 18px 18px;
			background-position: 21px center;
			border: 1px solid color(gray, x-light);
			border-radius: 0;
			box-shadow: none;
			box-sizing: border-box;
			flex: 1;
			height: 60px;
			max-width: 100%;
			padding: 0;
			padding-left: 60px;
			&:focus {
				border: 1px solid color(blue);
			}
			&::placeholder {
				@include font_size(fs_h4);
				color: color(gray, x-light);
				padding-top: .25em;
			}
			&.has-value {
				padding-right: 52px;
			}
		}
		.cancel {
			cursor: pointer;
			display: none;
			height: 25px;
			position: absolute;
			right: 1em;
			top: 50%;
			transform: translateY(-50%);
			width: 25px;
			html.no-touch & {
				&:hover,
				&:active {
					svg {
						fill: color(blue);
					}
				}
				svg {
					transition: fill .2s ease-out;
				}
			}
			&.show {
				display: block;
			}
			svg {
				fill: color(gray, x-light);
				height: 100%;
				width: 100%;
			}
		}
	}
}
