.billboard_simple {
	@include section_reset;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 80vh;
	min-height: 400px;
	padding-bottom: 100px;
	position: relative;
	@include breakpoint(desktop) {
		height: auto;
		min-height: 800px;
	}
}

.billboard_simple .bg {
	background-position: center 25%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	position: absolute;
	width: 100%;
}

.billboard_simple .content_wrapper_narrow {
	width: 100%;
}

.billboard_simple h1 {
	@include font_size(fs_h1_small);
	color: white;
	line-height: .825em;
	position: relative;
	text-transform: uppercase;
	z-index: 2;
	.heavy {
		font-weight: 800;
	}
}
