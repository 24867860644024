.banner_cta {
	@include section_reset;
	margin-bottom: 60px;
	overflow: hidden;
	@include breakpoint(tablet) {
		margin-bottom: 120px;
	}
	.banner_cta_content {
		max-width: 625px;
		padding-bottom: 40px;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 40px;
		position: relative;
		text-align: center;
		z-index: 2;
		@include breakpoint(tablet) {
			padding-bottom: 20px;
			padding-top: 20px;
		}
	}
	.banner_image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.banner_title {
		@include font_size(fs_h3_small);
		color: color(white);
		margin-bottom: 20px;
		margin-top: 0;
	}
	.chevron_right {
		display: inline;
		margin-left: 10px;
		width: 7px;
		path {
			fill: color(white);
		}
	}
	.banner_container {
		align-items: center;
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
		position: relative;
		@include breakpoint(tablet) {
			min-height: 460px;
		}
	}
	.copy {
		@include font_size(fs_h5);
		color: color(white);
		margin-bottom: 20px;
		margin-top: 0;
		&:last-child {
			margin-bottom: 0;
		}
		a {
			border-bottom: 0;
			text-decoration: none;
		}
	}
	.cta_text {
		color: color(white);
		font-weight: 600;
	}
	.legal {
		@include font_size(fs_p);
		color: #878f99;
		margin-bottom: 0;
		margin-top: 0;
		position: relative;
		text-align: center;
		&::before {
			content: '* ';
		}
	}
}

.banner_ctas {
	overflow: hidden;
}
