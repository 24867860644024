// typography, alphabetical by font-face, settings, element, placeholder, id, class

// @font-face's handles already via layout.css
/*
Proxima Nova W01 in regular 100, 400, bold, 900
Pictos (Icon font)
UI Font (Icon font)
*/

// @font-face's served from cloud.typography.com
/*
Quarto in regular & italic 300, 400
*/

// $font-size-base: 16; // don't switch base font size until ready for the ginormous cascade of tweaking

// fonts
$fonts: (
	sans: '"Proxima Nova W01", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif'
	,serif: "'Quarto A', 'Quarto B', Georgia, serif"
);
@function font($font) {
	@return unquote(map-get($fonts, $font));
}

$font_size_lists: (
	// examples
	// breakpoints from left to right
	// mobile-s, mobile, mobile-xl, tablet, desktop
	fs_h1: (34px, 50px, 66px, 82px, 100px)
	,fs_h1_small: (24px, 36px, 48px, 60px, 72px)
	,fs_h2: (36px, 36px, 40px, 44px, 48px)
	,fs_h3: (21px, 21px, 26px, 31px, 36px)
	,fs_h3_small: (16px, 16px, 20px, 24px, 30px)
	,fs_h4: (16px, 18px, 20px, 22px, 24px)
	,fs_h5: (16px, 16px, 16px, 18px, 20px)
	,fs_h6: (14px, 14px, 14px, 15px, 16px)
	,fs_h6_small: (12px, 12px, 12px, 13px, 14px)
	,fs_p: (15px, 15px, 15px, 15px, 16px)
	,fs_p_extra: (16px, 16px, 16px, 16px, 18px)
	,fs_p_small: (13px, 13px, 13px, 13px, 14px)
	,fs_p_note: (10px, 10px, 10px, 10px, 11px)
	,fs_p_note_extra: (11px, 11px, 11px, 11px, 12px)
);
@function font_size_list($font_size) {
	@if map-has-key($font_size_lists, $font_size) {
		@return map-get($font_size_lists, $font_size);
	}
	@else {
		@warn "font_size_lists(#{$font_size}) is not a valid font-size-list";
		@return null;
	}
}

// tracking (chai: consider whittling these out, wasn't as useful as I thought)
$letter-spacings: (
	sans: (
		base: 0
		,open: .15em
	)
	,serif: (
		base: .01em
	)
);
@function letter-spacing($family, $style: 'base') {
	@return map-get(map-get($letter-spacings, $family), $style);
}

// responsive content (only show text at given breakpoint)

%responsive_content_mobile_s {
	display: none;
	@include breakpoint(mobile-s) {
		display: inline;
	}
}

%responsive_content_mobile {
	display: none;
	@include breakpoint(mobile) {
		display: inline;
	}
}

%responsive_content_mobile_l {
	display: none;
	@include breakpoint(mobile-l) {
		display: inline;
	}
}

%responsive_content_mobile_xl {
	display: none;
	@include breakpoint(mobile-xl) {
		display: inline;
	}
}

%responsive_content_tablet_s {
	display: none;
	@include breakpoint(tablet-s) {
		display: inline;
	}
}

%responsive_content_tablet {
	display: none;
	@include breakpoint(tablet) {
		display: inline;
	}
}

%responsive_content_desktop {
	display: none;
	@include breakpoint(desktop) {
		display: inline;
	}
}

%responsive_content_desktop_l {
	display: none;
	@include breakpoint(desktop-l) {
		display: inline;
	}
}

%responsive_content_desktop_xl {
	display: none;
	@include breakpoint(desktop-xl) {
		display: inline;
	}
}

// font sizes, h1-h6, body, note

.fs_h1 {
	font-size: 34px;
	@include breakpoint(mobile) {
		font-size: 50px;
	}
	@include breakpoint(mobile-xl) {
		font-size: 66px;
	}
	@include breakpoint(tablet) {
		font-size: 82px;
	}
	@include breakpoint(desktop) {
		font-size: 100px;
	}
}

.fs_h1_small {
font-size: 24px;
	@include breakpoint(mobile) {
		font-size: 36px;
	}
	@include breakpoint(mobile-xl) {
		font-size: 48px;
	}
	@include breakpoint(tablet) {
		font-size: 60px;
	}
	@include breakpoint(desktop) {
		font-size: 72px;
	}
}

.fs_h2 {
	font-size: 36px;
	@include breakpoint(mobile-xl) {
		font-size: 40px;
	}
	@include breakpoint(tablet) {
		font-size: 44px;
	}
	@include breakpoint(desktop) {
		font-size: 48px;
	}
}

.fs_h3 {
	font-size: 21px;
	@include breakpoint(mobile-xl) {
		font-size: 26px;
	}
	@include breakpoint(tablet) {
		font-size: 31px;
	}
	@include breakpoint(desktop) {
		font-size: 36px;
	}
}

.fs_h3_small {
	font-size: 16px;
	@include breakpoint(mobile-xl) {
		font-size: 20px;
	}
	@include breakpoint(tablet) {
		font-size: 24px;
	}
	@include breakpoint(desktop) {
		font-size: 30px;
	}
}

.fs_h4 {
	font-size: 16px;
	@include breakpoint(mobile) {
		font-size: 18px;
	}
	@include breakpoint(mobile-xl) {
		font-size: 20px;
	}
	@include breakpoint(tablet) {
		font-size: 22px;
	}
	@include breakpoint(desktop) {
		font-size: 24px;
	}
}

.fs_h5 {
	font-size: 16px;
	@include breakpoint(tablet) {
		font-size: 18px;
	}
	@include breakpoint(desktop) {
		font-size: 20px;
	}
}

.fs_h6 {
	font-size: 14px;
	// @include breakpoint(mobile-xl) {
	// 	font-size: 14px;
	// }
	@include breakpoint(tablet) {
		font-size: 15px;
	}
	@include breakpoint(desktop) {
		font-size: 16px;
	}
}
.fs_h6_small {
	font-size: 12px;
	// @include breakpoint(mobile-xl) {
	// 	font-size: 14px;
	// }
	@include breakpoint(tablet) {
		font-size: 13px;
	}
	@include breakpoint(desktop) {
		font-size: 14px;
	}
}

.fs_p {
	font-size: 15px;
	@include breakpoint(desktop) {
		font-size: 16px;
	}
}

.fs_p_extra {
	font-size: 16px;
	@include breakpoint(desktop) {
		font-size: 18px;
	}
}

.fs_p_small {
	font-size: 13px;
	@include breakpoint(desktop) {
		font-size: 14px;
	}
}

.fs_p_note_extra {
	font-size: 11px;
	@include breakpoint(tablet) {
		font-size: 12px;
	}
}

.fs_p_note {
	font-size: 10px;
	@include breakpoint(tablet) {
		font-size: 11px;
	}
}

.fs_legal {
	color: color(gray, light);
	font-size: 10px;
}

// syles, by class

.bold {
	font-weight: 700;
}

.light {
	font-weight: 100;
}

.spacing_wide {
	letter-spacing:0.15em;
}

.spacing_normal {
	letter-spacing:0.05em;
}

.h1_sans {
	@include type_reset;
	@include font_size(fs_h1);
	font-family: font(sans);
	font-weight: 800;
	text-transform: uppercase;
	@include font-smoothing;
	line-height: .8em;
	.light {
		font-weight: 100;
	}
}

// .h2 {
//  @include font_size(fs_h2);
// }

.h2_sans {
	@include type_reset;
	@include font_size(fs_h2);
	font-family: font(sans);
}

.h2_serif {
	font-family: font(serif);
letter-spacing: letter-spacing(serif);
	@include font_size(fs_h2);
	@include font-smoothing;
	font-weight: 300;
}

.h3_sans {
	@include type_reset;
	@include font_size(fs_h3);
	font-family: font(sans);
}

.h3_serif {
	font-family: font(serif);
letter-spacing: letter-spacing(serif);
	@include font_size(fs_h3);
	@include font-smoothing;
	font-weight: 300;
}

.h4_sans {
	@include type_reset;
	@include font_size(fs_h4);
	font-family: font(sans);
}

.h4_serif {
	@include type_reset;
	@include font_size(fs_h4);
	@include font-smoothing;
	font-family: font(serif);
	font-weight: 300;
	letter-spacing: letter-spacing(serif);
}

.h5_sans {
	@include type_reset;
	@include font_size(fs_h5);
	color: color(gray, dark);
	font-family: font(sans);
	font-weight: bold;
	@include font-smoothing;
	letter-spacing: .2em;
}

.h5_serif {
	@include type_reset;
	@include font_size(fs_h5);
	font-family: font(serif);
	font-weight: 300;
	letter-spacing: letter-spacing(serif);
	@include font-smoothing;
	margin-bottom: 1em;
}

.h6_sans {
	@include type_reset;
	@include font_size(fs_h6);
	color: color(black, near);
	font-family: font(sans);
	font-weight: 700;
	letter-spacing: letter-spacing(sans, open);
	text-transform: uppercase;
}
.h6_sans_small {
	@include type_reset;
	@include font_size(fs_h6_small);
	color: color(black, near);
	font-family: font(sans);
	font-weight: 700;
	letter-spacing: letter-spacing(sans, open);
	text-transform: uppercase;
}

.h7_sans {
	@include type_reset;
	@include font_size(fs_p_note);
	color: color(black, near);
	font-family: font(sans);
	font-weight: 700;
	letter-spacing: letter-spacing(sans, open);
	text-transform: uppercase;
}

.p {
	@include font_size(fs_p);
	line-height: 1.8em;
}

.p_extra {
	@include font_size(fs_p_extra);
	line-height: 1.8em;
}

.sans {
	font-family: font(sans);
}

.serif {
	font-family: font(serif);
	letter-spacing: letter-spacing(serif);
}

.note {
	@include font_size(fs_p_note);
	line-height: 1.2em;
}

.statement {
	@extend .h3_serif;
	color: color(black);
	line-height: 1.2em;
	& + & {
		color: color(blue);
		margin-top: 1em;
	}
	a {
		color: color(blue);
		text-decoration: none;
		transition: color .2s ease-out;
		&:hover, &:active {
			color: color(blue, dark);
		}
	}
}

.sub_statement {
	@extend .h4_sans;
}

.title_global {
	@extend .h1_sans;
	color: color(gray, dark);
	margin-bottom: .2em;
	text-transform: uppercase;
	&.title_line_top {
		padding-top: 25px;
		position: relative;
		@include breakpoint(tablet) {
			padding-top: 50px;
		}
		&::before {
			background-color: color(gray, dark);
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			top: 0;
			width: 25px;
			@include breakpoint(tablet) {
				height: 2px;
				width: 50px;
			}
		}
	}
	// our version of proxima's line heights get wonky, this tries to sort for it
	.light + .name {
		margin-top: .03em;
	}
	// our version of proxima's line heights get wonky, this tries to sort for it
	.name + .light {
		margin-top: -.02em;
	}
	.name {
		color: color(blue);
		font-weight: 900;
		line-height: .8em;
		transform: translateY(.01em); // our version of proxima's line heights get wonky, this tries to sort for it
		sup {
			top:-.7em;
			font-size:50%;
		}
	}
}

.title_small {
	@include font_size(fs_h6);
	color: color(gray, dark);
	letter-spacing: .075em;
	margin-top: 0;
	text-transform: uppercase;
}

.title_small_hr_container {
	display: flex;
	.title_small_hr {
		flex: 1;
		position: relative;
		&::after {
			background-color: color(gray, x-light);
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			top: calc(50% - 1px);
			transform: translate3d(0, -50%, 0);
			width: 100%;
		}
	}
	.title_small {
		margin-right: .66em;
	}
}

.section_header {
	color: color(blue);
	font-size: 24spacpx;
	font-weight: 800;
	margin-bottom: 1.2em;
	text-transform: uppercase;
	@include breakpoint(tablet) {
		font-size: 40px;
		margin-bottom: 1.3em;
	}
}
