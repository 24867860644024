.recognition_sticky_nav {
	display: flex;
	font-size: 12px;
	font-weight: 600;
	height: 40px;
	justify-content: space-between;
	letter-spacing: .15em;
	position: relative;
	text-transform: uppercase;
	@include breakpoint(tablet) {
		height: 50px;
	}
	&__desktop {
		display: none;
		@include breakpoint(tablet) {
			display: flex;
		}
	}
	&__mobile {
		background-color: color(white);
		&:after {
			background-image: url("../img/recognition/tab-shadow.png");
			background-position: top center;
			background-repeat: no-repeat;
			background-size: contain;
			bottom: 0;
			content: '';
			height: 20vw;
			left: 0;
			opacity: .20;
			position: absolute;
			top: 0;
			transform: translate3d(0, 40px, 0);
			width: 100%;
		}
		@include breakpoint(tablet) {
			display: none;
		}
	}
	.sticky_nav.stuck & {
		background-color: color(white);
		box-shadow: none;
		.recognition_page__master & {
			background-color: color(metal, gold);
			box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.2);
		}
		.recognition_tab {
			&:first-child {
				padding-left: 10px;
			}
			&:last-child {
				padding-right: 10px;
			}
		}
	}
	.recognition_page__master & {
		background-color: color(metal, gold);
	}
	.cta {
		align-items: center;
		background-color: color(blue);
		color: color(white);
		display: flex;
		padding-left: 10px;
		padding-right: 10px;
		text-decoration: none;
		text-transform: uppercase;
		@include breakpoint(mobile) {
			padding-left: 20px;
			padding-right: 20px;
		}
		.recognition_page__master & {
			background-color: transparent;
			color: color(black);
			@include breakpoint(zero, tablet) {
				border-left: 1px solid color(black);
			}
		}
	}
	.logo_mark {
		align-items: center;
		background-color: color(blue);
		display: flex;
		height: 50px;
		justify-content: center;
		margin-right: 20px;
		width: 50px;
		svg {
			fill: color(white);
			height: 16px;
			width: 16px;
		}
		.recognition_page__master & {
			background-color: transparent;
			margin-right: 0;
			svg {
				fill: color(black);
			}
		}
	}
	.recognition_nav_title {
		align-items: center;
		color: color(blue);
		display: flex;
		.recognition_page__master & {
			color: color(black);
		}
	}
	.tab_nav {
		flex: 1;
		.select_wrapper {
			left: 0;
		}
		.decoy {
			font-size: 12px;
			.recognition_page__master & {
				color: color(black);
				&:before {
					background-image: svg(home-chevron-down-large-black);
					border-right-color: color(black, near);
				}
			}
		}
	}
}

.recognition_tabs {
	background-color: color(white);
	cursor: pointer;
	display: flex;
	height: 100%;
	justify-content: center;
	position: relative;
	z-index: 2;
	.recognition_page__master & {
		background-color: transparent;
	}
	.recognition_tab {
		align-items: center;
		color: color(gray, light);
		display: flex;
		padding-left: 10px;
		padding-right: 10px;
		overflow: hidden;
		position: relative;
		text-decoration: none;
		transition: color .2s ease-out;
		&:after, &:before {
			background-color: color(blue);
			bottom: 0;
			content: '';
			height: 3px;
			position: absolute;
			transition: all .15s easing(ease-out-back) .1s;
			width: 0;
		}
		&:after {
			left: 50%;
		}
		&:before {
			right: 50%;
		}
		&:hover {
			color: color(blue);
			&:after, &:before {
				width: 50%;
			}
		}
		&:first-child {
			padding-left: 20px;
		}
		&:last-child {
			padding-right: 20px;
		}
		.recognition_page__master & {
			color: color(gray);
			&:before {
				background: color(gradient, white-to-left);
			}
			&:after {
				background: color(gradient, white-to-right);
			}
			&:hover {
				color: color(black);
			}
		}
	}
}
.recognition_tabs_container {
	height: 100%;
	left: 50%;
	position: absolute;
	transform: translate3d(-50%, 0, 0);
	.tabs_shadow {
		background-image: url("../img/recognition/tab-shadow.png");
		background-position: top center;
		background-repeat: no-repeat;
		background-size: contain;
		content: '';
		height: 200%;
		opacity: .20;
		position: absolute;
		top: 0;
		transform: translate3d(0, 35%, 0);
		width: 100%;
		z-index: 1;
		.recognition_page__master &,
		.sticky_nav.stuck & {
			display: none;
		}
	}
}

// highlight page we're on in the sticky nav
.recognition_page__featured .recognition_tab__featured,
.recognition_page__elite .recognition_tab__elite,
.recognition_page__premier .recognition_tab__premier {
	color: color(blue);
	&:after, &:before {
		width: 50%;
	}
}

.recognition_page__master .recognition_tabs .recognition_tab__master {
	color: color(black);
	&:after, &:before {
		width: 50%;
	}
}

.single_distributor__featured {
	.recognition_tab__featured {
		color: color(blue);
	}
}
