@charset "UTF-8";

$input_border: 1px solid color(gray, x-light);
$input_pad: 16px; // Default padding for inputs

%form_element_base {
	box-sizing: border-box;
	display: inline-block;
	@include font-smoothing;
	height: 40px;
	line-height: 40px;
	padding-bottom: 0;
	padding-top: 0;
	text-decoration: none;
}

.compact_tooltip {
	$bg_color: rgba( color(gray, x-dark), 0.9 );
	@include font_size(fs_p);
	background-color: $bg_color;
	box-sizing: border-box;
	color: color(white);
	padding: 8px 15px;
	position: absolute;
	left: 0px;

	&:after {
		border: 6px solid transparent;
		border-top: 6px solid $bg_color;
		content: '';
		display: block;
		height: 0;
		pointer-events: none;
		position: absolute;
		left: 12px;
		top: 100%;
		width: 0;
	}

	&.right {
		left: auto;
		right: 0;
		&:after {
			left: auto;
			right: 12px;
		}
	}

}

// Custom Radio Style
.custom_radio {
	$size: 16;
	cursor: pointer;
	font-size: .9em;

	input[type="radio"] {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;

		& + .radio_value {
			position: relative;
			&:before {
				background-color: color(blue);
				border-radius: 50%;
				content: '';
				display: block;
				height: ($size - 8)+px;
				left: 4px;
				position: absolute;
				top: 4px;
				transition: transform .2s easing(ease-out-back);
				transform: scale(0);
				width: ($size - 8)+px;
				z-index: 2;
			}
		}

		&:checked + .radio_value {
			position: relative;

			&:before {
				transform: scale(1);
			}
		}

	}

	.radio_value {
		background-color: color(white, near);
		border: 1px solid color(gray, x-light);
		border-radius: 50%;
		display: inline-block;
		height: $size+px;
		margin-right: 0.25em;
		vertical-align: middle;
		width: $size+px;
	}

	// Block-level
	&.block {
		box-sizing: border-box;
		display: block;
		padding: 0 0 20px 40px;
		position: relative;
		width: 100%;

		&:last-child {
			padding-bottom: 0;
		}

		.radio {
			display: block;
			left: 0;
			position: absolute;
			top: 0px;
		}
	}

	.key, .value {
		color: color(gray, x-dark);
		font: {
			size: 1/05em;
			weight: 700;
		}
		display: inline-block;
		letter-spacing: 0.1em;
		text-transform: uppercase;;
	}

	.key_value {
		.key {
			width: 125px;
		}
	}

}

// Custom Checkbox
.custom_checkbox {
	$size: 16;
	cursor: pointer;
	font-size: .9em;

	input[type="checkbox"] {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;

		& + .checkbox_value {
			position: relative;

			&:before {
				background-image: svg(home-check-blue);
				content: '';
				display: block;
				height: 12px;
				left: 3px;
				position: absolute;
				top: 0px;
				transform: scale(0);
				transition: transform .2s easing(ease-out-back);
				width: 15px;
				z-index: 2;
			}
		}

		&:checked + .checkbox_value {
			&:before {
				transform: scale(1);
			}
		}
	}

	.checkbox_value {
		background-color: color(white, near);
		border: 1px solid color(gray, x-light);
		display: inline-block;
		height: $size+px;
		margin-right: 1em;
		position: relative;
		top: -2px;
		vertical-align: middle;
		width: $size+px;
	}

	// Block-level
	&.block {
		display: block;
		padding: 0 0 20px 40px;
		position: relative;
		width: 100%;
		box-sizing:border-box;
		&:last-child {
			padding-bottom: 0;
		}

		.checkbox {
			display: block;
			left: 0;
			position: absolute;
			top: 0px;
		}
	}

	a {
		text-decoration: none;
	}
}

.custom_select {
	background-image: linear-gradient(to bottom, color(white), color(white, near));
	box-sizing: border-box;
	height: 58px;
	position: relative;
	width: 100%;

	&:before {
		@include svg-background;
		background-image: svg(home-chevron-down-black);
		content: '';
		display: block;
		height: 7px;
		position: absolute;
		margin-top: -3.5px;
		opacity: 0.5;
		transition: opacity 0.2s easing(ease-in-out-quad);
		top: 50%;
		right: 1em;
		width: 11px;
		z-index: 2;
	}

	&:hover {

		&:before {
			opacity: 1.0;
		}
	}

	.column > & {
		height: 60px;
	}

	.has_error & {
		background-color: color(red, xx-light);
		background-image: none;
		&:before {
			display: none;
		}
	}

	select {
		box-sizing: border-box;
		display: block;
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;
		.ios & {
			font-size: 16px;
		}
	}

	.decoy {
		box-sizing: border-box;
		height: 58px;
		padding: 18px 16px 0 16px;
		position: relative;

		&.has_value {
			.text {
				transform: translate3d(0, 10px, 0);
			}
		}

		.text {
			transform: translate3d(0, 0, 0);
			transition: transform .2s ease-out;
		}
	}
}

.custom_select_tight {
	border: 1px solid color(gray, x-light);
	height: 40px;
	.decoy {
		height: 32px;
		padding: 8px 16px 0;
	}
}

// Compact Form styles (found in e-commerce checkout)
.form_compact {

	fieldset {
		margin-bottom: 3em;
		position: relative;
	}

	input[type="email"],
	input[type="number"],
	input[type="password"],
	input[type="tel"],
	input[type="text"],
	textarea {
		&:not(.spinner_input) {
			@include font-smoothing;
			background-color: transparent;
			border: none;
			border-radius: 0;
			box-shadow: none;
			color: color(gray, x-dark);
			height: 58px;
			padding: 0 $input_pad;
			transform: translate3d(0, 0, 0);
			transition: transform .2s ease-out;
			width: 100%;

			&.has_value {
				padding: 0 $input_pad !important;
				transform: translate3d(0, 10px, 0);
			}

			&:focus {
				color: color(black, near);
			}

			&::placeholder {
				color: color(gray);
				line-height: 18px;
			}

			&:-webkit-autofill {
				// hide autofill bg color
				-webkit-box-shadow: 0 0 0 30px white inset;
				// and reposition so it doesn't float out of the box
				// (normal style has a transparent BG so we dont need to worry about it)
				// (can't just use overflow on container or tooltips won't show)
				height: 39px;
				top: 9px;
				position: relative;
			}
		}
		&.hide {
			display: none;
		}
	}

	textarea {
		&:not(.spinner_input) {
			height: 118px;
			line-height: 1.4em;
			resize: none;
			transform: translate3d(0, 19px, 0);
			&.has_value {
				transform: translate3d(0, 29px, 0);
			}
		}
	}

	label {
		// floating label
		&[data-label="float"] {
			@include font-smoothing;
			color: color(blue);
			font-size: .65em;
			font-weight: 600;
			left: $input_pad;
			letter-spacing: .05em;
			opacity: 0;
			pointer-events: none;
			position: absolute;
			text-transform: uppercase;
			top: 0;
			transform: translateY(18px);
			z-index: 1;

			&.is_required:after {
				content: ' *';
			}
			&.is_required ~ input::placeholder:after {
				content: ' *';
			}

			&.is_visible {
				opacity: 1;
				transform: translateY(13px);
				transition: opacity .2s ease-out, transform .2s ease-out;
			}

		}
	}

	legend {
		color: color(gray, dark);
		display: block;
		font: {
			size: .85em;
			weight: 600;
		}
		letter-spacing: 0.1em;
		padding-bottom: 40px;
		position: relative;
		width: 100%;

		&:after {
			border-top: 1px solid color(gray, x-light);
			content: '';
			display: block;
			height: 0;
			left: 0;
			position: absolute;
			top: 10px;
			width: 100%;
			z-index: 1;
		}

		span {
			background-color: white;
			display: inline-block;
			padding-right: 15px;
			position: relative;
			z-index: 2;
		}
	}

	.conditional {
		&.hide {
			display: none;
		}
	}

	.error_global {
		margin: 0 auto 3em;
		text-align: center;
		.error_message {
			display: inline-block;
		}
	}

	.error_message {
		background-color: color(red);
		color: color(white);
		padding: 1em;
		&.margin_bottom {
			margin-bottom: 2em;
		}
		&.margin_top {
			margin-top: 1em;
		}
	}

	.form_row {
		@include clearfix;
		position: relative;

		// all border controls in here
		&.border {
			// sizing & whatnot
			background-color: color(white);
			border: 1px solid color(gray, x-light);
			border-width: 0 1px 1px 1px;
			box-sizing: border-box;
			height: 60px;
			z-index: 1;
			&.top {
				border-top-width: 1px;
			}
			&.two_up {
				border-width: 0;
				> .column {
					border: 1px solid color(gray, x-light);
					border-width: 0 1px 1px 1px;
					position: relative;
					z-index: 1;
					&:first-child {
						border-right: 0;
					}
					&.cvc {
						border-bottom-color: color(white);
						border-right-color: color(white);
					}
				}
				&.top > .column {
					border-top-width: 1px;
				}
			}
			&.textarea_row {
				height: 120px;
			}
			// states
			&.focus {
				border-color: transparent;
				box-shadow: 0 0 0 1px color(blue);
				z-index: 2;
			}
			&.column_focus {
				z-index: 2;
				.column.focus {
					border-color: transparent;
					box-shadow: 0 0 0 1px color(blue);
					z-index: 3;
				}
			}
			&.has_error, .column.has_error {
				background-color: color(red, xx-light);
			}
		}

		&.controls {
			padding: 25px 0;
			& .cta_or {
				@extend .h7_sans;
				color:color(gray, light);
				padding:1em 0;
			}
			.secondary {
				width:100%;
				margin-bottom:1em;
				@include breakpoint(mobile-xl) {
					margin-bottom:0;
					&.left {
						margin-right:10px;
					}
					&.right {
						margin-left:10px;
					}
					width: auto;
				}
			}
		}

		&.centered {
			text-align: center;
		}

		&.half {
			width: 50%;
			// width: calc(50% - 1px);
		}

		&.pad_b {
			margin-bottom: 1.5em;
			p {
				margin: 0;
			}
		}

		&.two_up {
			@include clearfix;
			> .column {
				box-sizing: border-box;
				display: block;
				height: 60px;
				float: left;
				overflow: hidden;
				position: relative;
				width: 50%;
			}
		}
	}
}

// Custom statuses

// Need to create a pseudo element for error icon which can't be done on an input so...
.form_row.border.full.has_error,
.form_row.border.half.has_error,
.form_row.border .column.has_error {

	&:after {
		@include svg-background;
		background-image: svg(home-error-red-x);
		border: none;
		content: '';
		display: block;
		height: 10px;
		margin-top: -5px;
		right: .8em;
		position: absolute;
		top: 50%;
		width: 10px;
	}

	&.textarea_row:after {
		top: 19px;
	}
}

// Same goes for success
.form_row.border.full.success,
.form_row.border.half.success,
.form_row.border .column.success {

	&:after {
		@include svg-background;
		background-image: svg(home-check-blue);
		border: none;
		content: '';
		display: block;
		height: 16px;
		margin-top: -8px;
		opacity: 0.75;
		right: .5em;
		position: absolute;
		top: 50%;
		width: 16px;
	}
}

.password_show_toggle {
	@extend .h7_sans;
	background: none;
	border: 0;
	color: color(gray, light);
	cursor: pointer;
	display: block;
	height: 32px;
	line-height: 32px;
	margin-top: -16px;
	position: absolute;
	text-decoration: none;
	right: 1em;
	top: 50%;

	&:hover {
		background-color: transparent;
	}

	&:focus,
	&:active {
		outline: none;
	}

	&:active {
		box-shadow: none;
		top: 50%;
	}

	.has_error & {
		display: none;
	}
}

// to be determined (use CSS for content to avoid localization in JS)
.form_tbd {
	&:before {
		color: color(gray, dark);
		content: '—';
		display: inline;
		font-weight: 400;
		letter-spacing: .1em;
	}
	.en &:before {
		content: 'TBD';
	}
}
