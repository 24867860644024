.distributor_tile_list {
	align-items: center;
	display: flex;
	flex-direction: column;
	// padding-left: 20px;
	// padding-right: 20px;
	@include breakpoint(tablet) {
		// padding-left: 0;
		// padding-right: 0;
	}
	// randomly stagger spacing of tiles in groups of 10
	&.stagger {
		.distributor_tile_single {
			margin-bottom: 20px;
			position: relative;
			@include breakpoint(desktop) {
				&:before {
					content: 'stellar';
					display: none;
				}
				&:nth-child(10n+1) {
					left: -5vw;
				}
				&:nth-child(10n+2),
				&:nth-child(10n+5),
				&:nth-child(10n+9) {
					left: 10vw;
				}
				&:nth-child(10n+3),
				&:nth-child(10n+6),
				&:nth-child(10n+8) {
					left: -10vw;
				}
				&:nth-child(10n+4),
				&:nth-child(10n+7),
				&:nth-child(10n+10) {
					// left: 0;
				}
			}
		}
	}
}

.distributor_tile_list_container {
	padding-top: 80px;
	@include breakpoint(tablet) {
		padding-top: 150px;
	}
	.recognition_page__master & {
		background: radial-gradient(ellipse at center, rgba(67,72,79,1) 0%,rgba(25,25,28,1) 100%)
	}
}

.distributor_tile_list_title {
	color: color(gray, light);
	font-family: font(sans);
	font-size: 15px;
	font-weight: bold;
	letter-spacing: .2em;
	margin-bottom: 40px;
	text-align: center;
	text-transform: uppercase;
	@include breakpoint(tablet) {
		margin-bottom: 75px;
	}
}

.distributor_tile_single {
	align-items: flex-start;
	display: flex;
	// 100% minus top nav
	height: calc(100vh - 50px);
	margin-bottom: 20px;
	margin-left: 20px;
	margin-right: 20px;
	max-height: 500px;
	max-width: 725px;
	position: relative;
	text-decoration: none;
	// transition: transform .2s ease-out;
	width: calc(100% - 40px);
	@include breakpoint(tablet) {
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}
	@include breakpoint(desktop) {
		height: 540px;
		margin-bottom: 100px;
		max-height: none;
		width: 850px;
	}
	@include breakpoint(desktop-xl) {
		max-width: none;
	}
	&:after {
		background-image: url('../img/recognition/tile-shadow.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: top center;
		bottom: 0;
		content: '';
		height: 200px;
		left: 0;
		opacity: .5;
		pointer-events: none;
		position: absolute;
		transform: translate3d(0, 100%, 0);
		transition: opacity .2s ease-out;
		width: 100%;
		z-index: -1;
	}
	&:hover {
		&:after {
			opacity: 1;
		}
		.dist_tile_gloss {
			opacity: .075;
		}
	}
	.dist_tile_content {
		overflow: hidden;
		padding-left: 20px;
		padding-top: 20px;
		position: relative;
		z-index: 1;
		@include breakpoint(mobile) {
			padding-left: 40px;
			padding-top: 40px;
		}
		@include breakpoint(tablet) {
			padding-left: 80px;
			padding-top: 0;
		}
	}
	.dist_tile_gloss {
		background-image: linear-gradient(-165deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 70%);
		height: 100%;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		transition: opacity .2s ease-out;
		width: 100%;
	}
	.dist_tile_image {
		background-color: color(white, near);
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: auto 140%;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		@include breakpoint(tablet) {
			background-position: 20% bottom;
			background-size: auto 165%;
		}
		@include breakpoint(desktop) {
			background-size: auto 150%;
		}
		@include breakpoint(desktop-xl) {
			background-size: auto 165%;
		};
	}
	.dist_tile_title {
		font-family: font(sans);
		font-size: 34px;
		font-weight: 100;
		line-height: .85em;
		margin-bottom: 30px;
		position: relative;
		text-transform: uppercase;
		@include breakpoint(mobile-l) {
			font-size: 48px;
		}
		@include breakpoint(tablet-s) {
			font-size: 60px;
		}
		@include breakpoint(tablet-l) {
			font-size: 48px;
		}
		@include breakpoint(desktop-xl) {
			font-size: 60px;
		}
		.dist_tile_logo_mark {
			// height is font-size times line-height
			// 34px X .85em = 30px
			height: 30px;
			left: 0;
			position: absolute;
			top: 0;
			transform: translate3d(-20px, 0, 0);
			visibility: hidden;
			width: 10px;
			@include breakpoint(mobile) {
				visibility: visible;
			}
			@include breakpoint(tablet) {
				// height is font-size times line-height
				// 60px X .85em = 51px
				height: 51px;
				transform: translate3d(-43px, 0, 0);
				width: 16px;
			}
			&:after {
				background-color: white;
				bottom: 0;
				content: '';
				height: 1px;
				margin: auto;
				margin-right: 20px;
				position: absolute;
				top: 0;
				transform: translate3d(-212px, 0, 0);
				width: 200px;
			}
			svg {
				bottom: 0;
				fill: color(white);
				left: 0;
				margin: auto;
				position: absolute;
				top: 0;
			}
		}
		.emphasis {
			font-weight: 800;
		}
	}
	// these rank specific styles are at the bottom to override
	// all of the default styles
	&.pro_1 {
		.dist_tile_image {
			background-color: color(rank_pro_1, light);
		}
		.dist_tile_logo_mark {
			&:after {
				background-color: color(rank_pro_1);
			}
			svg {
				fill: color(rank_pro_1);
			}
		}
		.dist_tile_title,
		.distributor_value {
			color: color(rank_pro_1);
		}
	}
	&.pro_2 {
		.dist_tile_image {
			background-color: color(rank_pro_2);
		}
		.dist_tile_logo_mark {
			&:after {
				background-color: color(rank_pro_2, light);
			}
			svg {
				fill: color(rank_pro_2, light);
			}
		}
		.distributor_label,
		.dist_tile_title,
		.distributor_value {
			color: color(rank_pro_2, light);
		}
	}
	&.pro_3 {
		.dist_tile_image {
			background-color: color(rank_pro_3);
		}
		.dist_tile_logo_mark {
			&:after {
				background-color: color(rank_pro_3, light);
			}
			svg {
				fill: color(rank_pro_3, light);
			}
		}
		.distributor_label,
		.dist_tile_title,
		.distributor_value {
			color: color(rank_pro_3, light);
		}
	}
	&.pro_4 {
		.dist_tile_image {
			background-color: color(rank_pro_4);
		}
		.dist_tile_logo_mark {
			&:after {
				background-color: color(rank_pro_4, light);
			}
			svg {
				fill: color(rank_pro_4, light);
			}
		}
		.distributor_label,
		.dist_tile_title,
		.distributor_value {
			color: color(rank_pro_4, light);
		}
	}
	&.pro_5 {
		.dist_tile_logo_mark {
			&:after {
				background-color: color(rank_pro_5);
			}
			svg {
				fill: color(rank_pro_5);
			}
		}
		.dist_tile_title,
		.distributor_value {
			color: color(rank_pro_5);
		}
	}
	&.pro_6 {
		.dist_tile_logo_mark {
			&:after {
				background-color: color(rank_pro_6);
			}
			svg {
				fill: color(rank_pro_6);
			}
		}
		.dist_tile_title,
		.distributor_value {
			color: color(rank_pro_6);
		}
	}
	&.pro_7 {
		.dist_tile_logo_mark {
			&:after {
				background-color: color(rank_pro_7);
			}
			svg {
				fill: color(rank_pro_7);
			}
		}
		.dist_tile_title,
		.distributor_value {
			color: color(rank_pro_7);
		}
	}
	&.pro_8 {
		.dist_tile_image {
			background-color: color(rank_pro_8);
		}
		.dist_tile_logo_mark {
			&:after {
				background-color: color(rank_pro_8, light);
			}
			svg {
				fill: color(rank_pro_8, light);
			}
		}
		.distributor_label,
		.dist_tile_title,
		.distributor_value {
			color: color(rank_pro_8, light);
		}
	}
	&.pro_9 {
		.dist_tile_image {
			background-color: color(rank_pro_9);
		}
		.dist_tile_logo_mark {
			&:after {
				background-color: color(rank_pro_9, light);
			}
			svg {
				fill: color(rank_pro_9, light);
			}
		}
		.distributor_label,
		.dist_tile_title,
		.distributor_value {
			color: color(rank_pro_9, light);
		}
	}
	&.pro_10,
	&.executive,
	&.presidential {
		background: color(gradient, gold);
		.dist_tile_content {
			margin-left: 10px;
			padding-left: 10px;
			@include breakpoint(mobile) {
				padding-left: 30px;
			}
			@include breakpoint(tablet) {
				padding-left: 70px;
			}
		}
		.dist_tile_gloss {
			height: calc(100% - 20px);
			margin: 10px;
			width: calc(100% - 20px);
		}
		.dist_tile_image {
			background-color: color(rank_pro_10, dark);
			box-shadow: 5px 0 10px rgba(0, 0, 0, 0.5);
			height: calc(100% - 20px);
			margin: 10px;
			width: calc(100% - 20px);
		}
		.dist_tile_logo_mark {
			&:after {
				background-color: color(rank_pro_10);
			}
			svg {
				fill: color(rank_pro_10);
			}
		}
		.distributor_label,
		.dist_tile_title, {
			color: color(rank_pro_10);
		}
		.distributor_label.flag {
			color: color(rank_pro_10, dark);
		}
		.distributor_value {
			color: color(white);
		}
	}
}
