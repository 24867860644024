.accordion_slider {
	.accordion_drawer {
		&.open {
			.ui_icon_chevron {
				fill: color(blue);
				transform: rotate(-180deg);
			}
			.drawer_title {
				color: color(black, near);
			}
			.drawer_content {
				display: block;
			}
		}
	}
	.drawer_content {
		display: none;
		&--padding {
			border-bottom: 1px solid color(gray, x-light);
			padding: 15px 0 10px;
		}
	}
	.drawer_title {
		align-items: center;
		border-bottom: 1px solid color(gray, x-light);
		color: color(gray, light);
		cursor: pointer;
		display: flex;
		font-size: 14px;
		font-weight: 700;
		position: relative;
		.ui_icon_chevron {
			margin: 10px;
			transition: transform .2s ease-out, fill .5s ease-out;
		}
	}
	input[type="text"],
	input[type="email"] {
		@include breakpoint(zero, mobile-xl) {
			font-size: 16px;
		}
		&::placeholder {
			padding-top: .25em;
		}
		&::-moz-placeholder {
			line-height: 60px !important;
			padding-top: 0;
		}
	}
}
