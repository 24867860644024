.three_column {
	@include section_reset;
	margin-bottom: 30px;
	padding-bottom: 40px;
	@include breakpoint(tablet-l) {
		padding-bottom: 80px;
	}
	&__margin_bottom_xl {
		margin-bottom: 80px;
		@include breakpoint(tablet-l) {
			margin-bottom: 200px;
		}
	}
	&.bg {
		position: relative;
		&::after {
			background-image: linear-gradient(color(white), #f2f4f6);
			bottom: 0;
			content: '';
			height: 900px;
			position: absolute;
			width: 100%;
			z-index: -1;
		}
	}
	.column_title {
		@include font-size(fs_h6);
		border-top: 1px solid #dee3eb;
		color: color(black);
		font-weight: 700;
		margin-bottom: 60px;
		margin-left: auto;
		margin-right: auto;
		max-width: 120px;
		padding-top: 1.6em;
		text-align: center;
	}
	.row {
		align-items: center;
		display: flex;
		flex-direction: column;
		@include breakpoint(tablet-l) {
			align-items: flex-start;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-left: auto;
			margin-right: auto;
			max-width: 1005px;
		}
		.column {
			margin-bottom: 60px;
			max-width: 300px;
			@include breakpoint(tablet-l) {
				flex-basis: calc(33% - 30px);
				margin-bottom: 40px;
			}
			@include breakpoint(desktop) {
				max-width: 300px;
			}
			&__text_center {
				text-align: center;
			}
			.column_copy {
				@include font-size(fs_h6);
				color: #878f99;
				font-weight: 100;
				margin-top: 0;
			}
			.column_header {
				@include font-size(fs_h6);
				font-weight: 700;
				margin-top: 2.75em;
			}
			.column_image {
				position: relative;
				@include column_shadow;
			}
		}
	}
}
