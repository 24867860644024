.image_cta {
	@include section_reset;
	align-items: center;
	display: flex;
	padding-bottom: 80px;
	padding-top: 80px;
	position: relative;
	@include breakpoint(tablet) {
		margin-left: 40px;
		margin-right: 40px;
		padding-bottom: 100px;
		padding-top: 100px;
	}
	@include breakpoint(desktop) {
		height: auto;
		min-height: 800px;
	}
}

.image_cta.full_width {
	@include breakpoint(tablet) {
		margin-left: 0;
		margin-right: 0;
	}
}

.image_cta.primary_nav_overlap {
	padding-top: 160px;
}

.image_cta .bg {
	background-position: center 25%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	position: absolute;
	top: 0;
	width: 100%;
}

.image_cta .content {
	background-color: transparent;
	color: white;
	justify-content: left;
	text-align: left;
	width: 100%;
	z-index: 2;
	@include breakpoint(zero, desktop) {
		max-width: calc(100vw - 40px;);
	}
	.main_title {
		@include font_size(fs_h1_small);
		line-height: 0.825em;
		margin-bottom: 0.5em;
		text-transform: uppercase;
		.heavy {
			font-weight: 800;
		}
	}
	.copy {
		@include font_size(fs_h5);
		font-weight: 400;
		margin-bottom: 30px;
		max-width: 32em;
		text-transform: none;
	}
}
