//
// table
//

.ea_table {
	@include section_reset;
	margin-bottom: 5em;
	margin-top: 5em;
	.ea_table_heading {
		line-height: .75em;
		margin-bottom: .5em;
	}
	.table td {
		&:first-of-type {
			background: color(white, near);
		}
	}
	.table_note {
		@include font-size(fs_p_note_extra);
		margin-top: -1.5em;
		text-align: left;
	}
	.paragraph {
		@include font_size(fs_h5);
		color: #878f99;
		line-height: 1.3em;
	}
}
