//
// terms and conditions
//

.ea_terms_conditions {
	@include section_reset;
	margin-bottom: 5em;
	margin-top: 5em;
	.ea_terms_conditions_heading {
		@include font_size(fs_h3_small);
		@include font-smoothing;
		color: #2f3133;
		font-weight: 600;
		line-height: 1.15em;
	}
	.ea_terms_conditions_list {
		@include font-size(fs_p_note_extra);
		margin-left: -1.75em;
		li {
			margin-bottom: 1.25em;
		}
	}
}
