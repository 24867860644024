// buttons

.btn_discretionary {
	@include font_size(fs_p_note);
	background-color: transparent;
	border: 0;
	box-shadow: none;
	color: color(blue);
	display: inline-block;
	font-weight: 700;
	letter-spacing: .15em;
	margin-top: 2.5em;
	padding-left: 0;
	padding-right: 0;
	text-decoration: none;
	text-transform: uppercase;
	&__center {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	&__light {
		color: color(gray, light);
		cursor: pointer;
		font-size: 10px;
	}
}

.btn_new,
a.btn_new,
input[type="submit"].btn_new {
	@include btn_new_colors((
		text: color(white),
		bg_color: color(blue),
		bg_color_active: darken(color(blue), 20%),
		bg_color_hover: color(blue, dark)
	));
	@include font_size(fs_h6_small);
	border: 0;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	font-weight: 700;
	letter-spacing: .075em;
	overflow: hidden;
	padding: 15px 25px;
	position: relative;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	user-select: none;
	white-space: nowrap;
	z-index: 1;
	&::before {
		$btn-notch-height: 10px;
		bottom: 0;
		content: '';
		height: 100%;
		position: absolute;
		right: 0;
		transition: background-color .2s ease-out;
		width: 100%;
		z-index: -1;
	}
	&__center {
		margin: 0 auto;
		@include breakpoint(mobile-xl) {
			max-width: 350px;
			width: 100%;
		}
	}
	&__desktop {
		display: none;
		@include breakpoint(tablet) {
			display: block;
		}
	}
	&.disabled, &.loading {
		@include btn_new_colors((
			bg_color: color(gray, x-light)
		));
		pointer-events: none;
	}
	// error-shake and error can be pruned at a later date
	// we will eventually just want to use btn_new__error
	&.error-shake,
	&.error,
	&.btn_new__error {
		@include btn_new_colors((
			text: color(white),
			bg_color: color(red),
			bg_color_hover: color(red)
		));
		animation: shake 1s ease-out forwards;
	}
	&__full {
		width: 100% !important;
	}
	&__icon {
		padding-left: 42px;
		position: relative;
		.loader_container,
		.ui-icon {
			bottom: 0;
			margin-bottom: auto;
			margin-top: auto;
			position: absolute;
			top: 0;
			left: 7px;
			width: 28px;
			height: 100%;
			z-index: 2;
		}
	}
	&__mobile {
		display: block;
		@include breakpoint(tablet) {
			display: none;
		}
	}
	// .muted and error can be pruned at a later date
	// we will eventually just want to use btn_new__muted
	&.muted,
	&__muted {
		@include btn_new_colors((
			bg_color: color(gray, x-light),
			bg_color_hover: darken(color(gray, x-light), 5%)
		));
	}
	&__notched {
		&::before {
			// the btn-notch-height is how far from the corner we push our notch,
			// we use the Pythagorean Theorem like bosses to get this height.
			// measure the distance from the notch to the corner of the element
			// (bottom of notch)² + (right side of notch)² = √(height)
			// 15² + 15² = √x
			// x = 21
			// 21 / 2 = 10.5 aka our hypotenuse
			// we now have another triangle by splitting the hypotenuse in half,
			// the height from the corner to the middle of the triangle is what we want.
			// 10.5² + 15² = √height
			// height = 10'ish
			$btn-notch-height: 10px;
			height: 2500%;
			transform-origin: bottom right;
			transform: rotate(-45deg) translate3d(50%, -$btn-notch-height, 0);
			transition: background-color .2s ease-out;
			width: 2500%;
		}
	}
	// .reverse and error can be pruned at a later date
	// we will eventually just want to use btn_new__reverse
	&.reverse,
	&__reverse {
		@include btn_new_colors((
			text: color(blue),
			text_hover: color(blue, dark),
			bg_color: color(white),
			bg_color_hover: color(white)
		));
	}
	&__stroked {
		@include btn_new_colors((
			text: color(blue),
			text_hover: color(white),
			bg_color: color(white),
			bg_color_hover: color(blue)
		));
		&::before {
			border: 2px solid color(blue);
			box-sizing: border-box;
		}
	}
	&__secondary {
		@include btn_new_colors((
			bg_color: color(blue),
			bg_color_hover: color(blue, light)
		));
	}
	&__short {
		height: 40px;
		line-height: 40px;
		padding-bottom: 0;
		padding-top: 0;
	}
	// .small and error can be pruned at a later date
	// we will eventually just want to use btn_new__small
	&.small,
	&__small {
		@include font_size(fs_p_note);
		letter-spacing: .1em;
		padding-left: width(gutter-outer-s);
		padding-right: width(gutter-outer-s);
		width: auto;
	}
	&__stealth {
		@include btn_new_colors((
			text: color(white),
			bg_color: color(black),
			bg_color_hover: color(black, near)
		));
	}
	&__white {
		@include btn_new_colors((
			text: color(black, near),
			text_hover: color(black),
			bg_color: color(white),
			bg_color_hover: color(white, near)
		));
	}
}

input[type="submit"].btn_new {
	border-radius: 0;
	box-shadow: none;
}

.btn_pair {
	display: flex;
	justify-content: space-between;
	.btn_new,
	a.btn_new {
		flex-basis: calc(50% - 5px);
		padding-left: 0;
		padding-right: 0;
	}
}

.btn_spinner {
	$easing: easing(ease-out-expo);
	overflow: hidden;
	position: relative;
	.btn_copy {
		opacity: 1;
		transition: opacity .5s $easing;
	}
	.loader {
		position: absolute;
		top: 6px;
		right: 0;
		left: 0;
		margin: auto;
		opacity: 0;
		transform: translate3d(0, 100%, 0);
		transition: opacity .5s $easing, transform .5s $easing;
	}
	&.disabled, &.loading {
		.btn_copy {
			opacity: 0;
		}
		.loader {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
}
