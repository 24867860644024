.disclaimers-container {
	@include section_reset;
	margin-bottom: 60px;
	.list {
		list-style-type: none;
		margin: 0;
		padding-left: 0;
		li {
			margin-bottom: .5em;
		}
	}
}
