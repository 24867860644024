// config
$recognition_billboard_padding: 10px;

.billboard_distributor_name,
.billboard_distributor_location {
	display: none;
	@include breakpoint(tablet-l) {
		display: inline;
	}
}

.recognition_billboard_image,
.recognition_billboard_distributor_image {
	background-repeat: no-repeat;
	position: relative;;
	width: 100%;
	height: 100%;
}

.recognition_billboard_image {
	background-position: left center;
	background-size: 130% auto;
	height: 100%;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: z-index(billboard);
	@include breakpoint(desktop) {
		background-position: 30% center;
		background-size: auto 100%;
	}
	@include breakpoint(desktop-xl) {
		background-position: center;
		background-size: auto 135%;
	}
	.recognition_page__elite & {
		background-image: url('../img/recognition/elite-pro-badges.png');
		max-width: width(max-width);
	}
	.recognition_page__master & {
		background-image: url('../img/recognition/master-badges.png');
		background-position: center 20vh;
		background-size: auto 25vh;
		max-width: width(max-width);
		@include breakpoint(tablet-l) {
			background-position: right center;
			background-size: 50vw auto;
		}
		@include breakpoint(desktop) {
			background-size: 56vw auto;
		}
		@include breakpoint(desktop-xl) {
			background-size: 60% auto;
		}
	}
	.recognition_page__premier & {
		background-image: url('../img/recognition/premier-pro-badges.png');
	}
	.recognition_page__featured & {
		background-image: url('../img/recognition/recognition-billboard.jpg');
		background-position: 45% center;
		background-size: auto 100%;
		@include breakpoint(mobile) {
			background-position: 32% center;
		}
		@include breakpoint(tablet) {
			background-size: cover;
		}
	}
}

.recognition_page {
	background-color: color(white);
	.recognition_billboard {
		background-color: color(white);
		height: 85vh;
		max-height: 800px;
		min-height: 300px;
		position: relative;
		@include breakpoint(tablet-l) {
			padding: $recognition_billboard_padding;
		}
		&__large {
			height: 100vh;
		}
		&__master {
			background: color(gradient, gold-bottom-to-top);
		}
		.content_wrapper {
			height: 100%;
		}
	}
	.recognition_billboard_distributor_image {
		background-position: bottom center;
		background-size: auto 150%;
		@include breakpoint(tablet-l) {
			background-position: 45% bottom;
		}
		@include breakpoint(max-recognition-billboard) {
			background-size: cover;
		}
	}
}

.recognition_billboard_distributor {
	background-color: color(white);
	height: 60vh;
	max-height: 780px;
	position: relative;
	@include breakpoint(tablet-l) {
		height: 50vw;
		min-height: 450px;
		padding: $recognition_billboard_padding;
	}
	.billboard_distributor_location {
		text-align: right;
	}
	.pretitle {
		@include font_size(fs_h6);
		@include font-smoothing;
		font-family: font(sans);
		font-size: 15px;
		font-weight: 400;
		letter-spacing: .075em;
		line-height: 1.75em;
		text-transform: uppercase;
		.single_distributor__master & {
			color: color(metal, gold);
		}
		&.distributor_rank__master {
			background: color(gradient, gold);
			border-radius: 3px;
			color: color(black, near);
			display: inline-block;
			font-weight: 400;
			padding: 0 8px;
		}
	}
	.title {
		@include font_size(fs_h3);
		font-family: font(serif);
		font-style: italic;
		font-weight: 300;
		margin-bottom: 30px;
		padding-top: 7px;
	}
	.distributor_info {
		color: color(white);
		display: flex;
		justify-content: space-between;
		position: relative;
		.logo_mark {
			align-items: center;
			display: flex;
			height: 100px;
			position: absolute;
			top: 0;
			width: 50vw;
			// logo mark icon
			svg {
				fill: color(white);
				height: 16px;
				width: 16px;
			}
			// horizontal rule
			&:before {
				background-color: color(white);
				bottom: 0;
				content: '';
				height: 1px;
				margin: auto;
				position: absolute;
				top: 0;
				width: 100%;
			}
			&__first {
				justify-content: flex-end;
				left: 0;
				padding-right: 10px;
				transform: translate3d(-100%, 0, 0);
				@include breakpoint(max-width-with-gutters) {
					padding-right: 50px;
				}
				&:before {
					right: 86px;
				}
			}
			&__last {
				justify-content: flex-start;
				padding-left: 10px;
				right: 0;
				transform: translate3d(100%, 0, 0);
				@include breakpoint(max-width-with-gutters) {
					padding-left: 50px;
				}
				&:before {
					left: 86px;
				}
			}
		}
		&.pro_1 {
			color: color(rank_pro_1);
			.logo_mark {
				svg {
					fill: color(rank_pro_1);
				}
				&:before {
					background-color: color(rank_pro_1);
				}
			}
		}
		&.pro_2 {
			color: color(rank_pro_2, light);
			.logo_mark {
				svg {
					fill: color(rank_pro_2, light);
				}
				&:before {
					background-color: color(rank_pro_2, light);
				}
			}
		}
		&.pro_3 {
			color: color(rank_pro_3, light);
			.logo_mark {
				svg {
					fill: color(rank_pro_3, light);
				}
				&:before {
					background-color: color(rank_pro_3, light);
				}
			}
		}
		&.pro_4 {
			color: color(rank_pro_4, light);
			.logo_mark {
				svg {
					fill: color(rank_pro_4, light);
				}
				&:before {
					background-color: color(rank_pro_4, light);
				}
			}
		}
		&.pro_5 {
			color: color(rank_pro_5);
			.logo_mark {
				svg {
					fill: color(rank_pro_5);
				}
				&:before {
					background-color: color(rank_pro_5);
				}
			}
		}
		&.pro_6 {
			color: color(rank_pro_6);
			.logo_mark {
				svg {
					fill: color(rank_pro_6);
				}
				&:before {
					background-color: color(rank_pro_6);
				}
			}
		}
		&.pro_7 {
			color: color(rank_pro_7);
			.logo_mark {
				svg {
					fill: color(rank_pro_7);
				}
				&:before {
					background-color: color(rank_pro_7);
				}
			}
		}
		&.pro_8 {
			color: color(rank_pro_8, light);
			.logo_mark {
				svg {
					fill: color(rank_pro_8, light);
				}
				&:before {
					background-color: color(rank_pro_8, light);
				}
			}
		}
		&.pro_9 {
			color: color(rank_pro_9, light);
			.logo_mark {
				svg {
					fill: color(rank_pro_9, light);
				}
				&:before {
					background-color: color(rank_pro_9, light);
				}
			}
		}
		&.pro_10,
		&.executive,
		&.presidential {
			.logo_mark {
				svg {
					fill: color(rank_pro_10);
				}
				&:before {
					background-color: color(rank_pro_10);
				}
			}
		}
	}
	.distributor_info_bar {
		width: 100%;
	}
	.recognition_billboard_distributor_image {
		align-items: flex-end;
		display: flex;
		height: 100%;
		overflow: hidden;
		position: relative;
	}
	.single_distributor__master & {
		background-color: color(metal, gold);
	}
}

.recognition_gradient {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	.recognition_page__elite &,
	.recognition_page__premier & {
		background: linear-gradient(135deg,  #f1f1f1 0%,#cfcfcf 100%);
	}
	.recognition_page__master & {
		background: #2e2f33;
		background: linear-gradient(200deg,  #2e2f33 0%,#1e1e21 100%);
	}
}

.recognition_billboard_image_container {
	height: 100%;
	position: relative;
	.recognition_page__master & {
		box-shadow: 5px 0 10px rgba(0, 0, 0, 0.5);
	}
}

.single_distributor {
	&__master {

	}
}

.billboard_copy_container {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	position: relative;
	z-index: z-index(billboard);
	.recognition_billboard__master & {
		@include breakpoint(zero, tablet-l) {
			text-align: center;
		}
	}
}

.recognition_page_description {
	@include font_size(fs_h5);
	color: color(gray, light);
	margin: 40px 0;
	max-width: 45vw;
	@include breakpoint(tablet-l) {
		max-width: 400px;
	}
	.recognition_billboard__master & {
		color: color(white);
		@include breakpoint(zero, tablet-l) {
			margin-left: auto;
			margin-right: auto;
			margin-top: 20px;
			max-width: 315px;
		}
	}
}

.recognition_page_title {
	@include font_size(fs_h1);
	color: color(gray, x-dark);
	line-height: .8em;
	margin-bottom: .2em;
	padding-top: 60px;
	position: relative;
	text-transform: uppercase;
	&:before {
		background-color: color(gray, dark);
		content: '';
		height: 2px;
		left: 0;
		position: absolute;
		top: 0;
		width: 50px;
	}
	.recognition_billboard__master & {
		@include breakpoint(zero, tablet-l) {
			padding-top: 35vh;
		}
		.line {
			@include breakpoint(zero, tablet-l) {
				display: inline-block;
			}
		}
		&:before {
			background: color(gradient, gold);
			@include breakpoint(zero, tablet-l) {
				display: none;
			}
		}
	}
	span.line {
		.recognition_billboard__master & {
			// background: -webkit-linear-gradient(45deg, #bfac73 0%,#dfd6a4 100%);
			color: #bfac73;
			// -webkit-background-clip: text;
			// -webkit-text-fill-color: transparent;
		}
	}
	// our version of proxima's line heights get wonky, this tries to sort for it
	.light + .name {
		margin-top: .03em;
	}
	// our version of proxima's line heights get wonky, this tries to sort for it
	.name + .light {
		margin-top: -.02em;
	}
	.name {
		font-weight: 900;
		line-height: .8em;
		transform: translateY(.01em); // our version of proxima's line heights get wonky, this tries to sort for it
		.recognition_billboard__general & {
			color: color(blue);
		}
		sup {
			top:-.7em;
			font-size:50%;
		}
	}
}
