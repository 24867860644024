.video_billboard_intro {
  @include section_reset;
  padding-top: 4em;
  overflow: hidden;
  .heading {
    @include font_size(fs_h1_small);
    line-height: .825em;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
    .heavy {
      color: color(black);
      font-weight: 800;
    }
    .light {
      color: #5e6266;
    }
  }
  .sub_heading {
    @include font_size(fs_h5);
    color: #0a82ba;color: #0a82ba;
    font-family: font(serif);
    font-style: italic;
    font-weight: 300;
    line-height: 1.4em;
    margin-bottom: 0;
    margin-top: 1em;
    text-transform: capitalize;;
    @include breakpoint(tablet) {
      max-width: 90%;
    };
  }
}

.video_billboard_player {
  padding-top: 3em;
}
