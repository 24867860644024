// vars

%success_quote {
	@extend .h4_serif;
	font-style: italic;
	font-weight: 400;
	line-height: 1.2em;
	margin: 0 10px 0 22px;
	position: relative;
	width: 100%;
	// the responsive h4_serif font sizes correctly except for this breakpoint window
	@include breakpoint(zero, mobile) {
		font-size: 12px;
	}
	@include breakpoint(mobile) {
		margin: 0 25px 0 35px;
	}
	@include breakpoint(mobile-xl) {
		margin: 0 40px 0 50px;
	}
	@include breakpoint(desktop-xl) {
		margin: 0 50px 0 70px;
	}
	&:before {
		@include svg-background;
		background-image: svg(success-quotes-beginning-white);
		content: "";
		display: block;
		height: 14px;
		left: -16px;
		position: absolute;
		top: 0;
		width: 11px;
		@include breakpoint(mobile-xl) {
			height: 28px;
			left: -37px;
			width: 22px;
		}
	}
	&:after {
		@include svg-background;
		content: "";
		display: inline-block;
		height: 10px;
		margin-bottom: 7px;
		width: 9px;
		@include breakpoint(mobile-xl) {
			background-image: svg(success-quotes-end-white);
		}
	}
}

.success_container,
.success_modal {
	.info_block {
		h4 {
			@include font_size(fs_p_small);
			font-weight: 400;
			letter-spacing: 0.2em;
			margin: 0;
			text-transform: uppercase;
		}
		h3 {
			@extend .h4_serif;
			font-style: italic;
			font-weight: 600;
			margin: 5px 0 15px;
			@include breakpoint(mobile-xl) {
				margin: 5px 0 15px;
			}
			@include breakpoint(desktop-xl) {
				margin: 10px 0 25px;
			}
		}
	}
	.member_title {
		@extend .h4_serif;
		font-weight: 400;
	}
	.stat_list {
		display: flex;
		justify-content: space-between;
		@include breakpoint(mobile-xl) {
			padding-top: 15px;
		}
		@include breakpoint(desktop) {
			padding-top: 25px;
		}
		@include breakpoint(desktop-xl) {
			padding-top: 30px;
		}
		.unit {
			font-weight: 200;
		}
		.number {
			font-size: 24px;
			font-weight: 600;
			line-height: 1em;
			position: relative;
			@include breakpoint(mobile) {
				font-size: 36px;
			}
			@include breakpoint(desktop) {
				font-size: 48px;
			}
			@include breakpoint(desktop-xl) {
				font-size: 72px;
				line-height: normal;
			}
			&:before {
				background: color(white);
				content: "";
				height: 0;
				left: 0;
				position: absolute;
				top: -10px;
				width: 40px;
				@include breakpoint(desktop) {
					height: 4px;
				}
			}
		}
		.stat {
			flex: 1;
		}
		.text {
			@include font_size(fs_p_extra);
			font-weight: 600;
			letter-spacing: .1em;
			text-transform: uppercase;
		}
	}
}
.success_column {
	flex: 1;
}
.success_container {
	background-color: color(white);
	.billboard {
		height: 560px;
	}
	.cta_footer {
		font-family: font(sans);
		background-color: color(blue);
		bottom: 0;
		box-sizing: border-box;
		color: color(white);
		font-size: 9px;
		font-weight: 600;
		left: 0;
		letter-spacing: .1em;
		line-height: 28px;
		padding-left: 10px;
		position: absolute;
		text-transform: uppercase;
		width: 100%;
		@include breakpoint(mobile-xl) {
			font-size: 14px;
			line-height: 40px;
			padding-left: 20px;
		}
		.arrow_link_white:before {
			@include breakpoint(mobile-xl) {
				top: 0;
			}
		}
	}
	.success_overlay {
		@extend %overlay;
		align-items: center;
		box-sizing: border-box;
		display: flex;
		padding-left: 10px;
		text-decoration: none;
		z-index: 10;
		@include breakpoint(mobile-xl) {
			padding-left: 20px;
		}
		.success_overlay_content {
			padding-bottom: 28px;
			@include breakpoint(mobile-xl) {
				padding-bottom: 40px;
			}
		}
	}
	.success_feature {
		.cta_footer {
			padding-left: 10px;
			@include breakpoint(tablet-l) {
				padding-left: 40px;
			}
		}
		.member_title {
			font-family: font(serif);
			letter-spacing: letter-spacing(serif);
			font-size: 24px;
			font-weight: 400;
			margin-bottom: 10px;
			margin-top: 0;
			@include breakpoint(desktop) {
				font-size: 32px;
				margin-bottom: 15px;
			}
			@include breakpoint(desktop-xl) {
				margin-bottom: 1em;
			}
		}
		.stat_list {
			@include breakpoint(mobile-xl) {
				padding-top: 10px;
			}
			.number {
				font-size: 24px;
				@include breakpoint(mobile) {
					font-size: 50px;
				}
				@include breakpoint(desktop) {
					line-height: 1.5em;
				}
				@include breakpoint(desktop-xl) {
					font-size: 90px;
					line-height: 1.3em;
				}
				&:before {
					top: 0;
				}
			}
		}
		.success_overlay {
			padding-left: 15px;
			@include breakpoint(tablet-l) {
				padding-left: 40px;
			}
		}
	}
	.success_subtitle {
		@extend .h2_sans;
		color: color(blue);
		font-weight: 200;
		line-height: 1em;
		margin: 30px 0;
		padding: 0 20px;
		text-align: center;
		@include breakpoint(mobile-xl) {
			margin: 40px 0;
		}
		.emphasis {
			@extend .h2_serif;
			font-style: italic;
			font-weight: 600;
		}
	}
	// some of this will be redundant when Managment Team is rolled out. when it does it may be a good time to make a global billboard class set
	.team_billboard {
		background: no-repeat center top;
		background-size: cover;
		clear: both;
		overflow: hidden;
		padding: 85px 0 75px;
		@include breakpoint(mobile-xl) {
			padding: 70px 0;
		}
		@include breakpoint(desktop) {
			padding: 200px 0 130px;
		}
		.title {
			@extend .h1_sans;
			color: color(white);
			text-transform: uppercase;
			// our version of proxima's line heights get wonky, this tries to sort for it
			.light + .name {
				margin-top: .03em;
			}
			// our version of proxima's line heights get wonky, this tries to sort for it
			.name + .light {
				margin-top: -.02em;
			}
			.name {
				font-weight: 900;
				line-height: .8em;
				transform: translateY(.01em); // our version of proxima's line heights get wonky, this tries to sort for it
			}
		}
	}
}
.success_feature {
	flex: 1;
	.success_unit {
		max-height: 340px;
		@include breakpoint(mobile-xl) {
			max-height: 582px;
		}
	}
}
.success_feature, .success_1, .success_2, .success_3 {
	cursor: pointer;
}
.success_grid {
	display: flex;
	flex-wrap: wrap;
}
// this is used to set the larger outside margin on the elements that need to align with it
$sucess_modal_margin: 150px;
.success_modal {
	box-sizing: border-box;
	display: block;
	height: calc(100vh - 10px);
	left: 0;
	margin: auto;
	max-width: 1280px;
	opacity: 0;
	overflow: scroll;
	position: fixed;
	right: 0;
	top: 10px;
	transform: translate3d(0, 50%, 0);
	transition: opacity .3s easing(ease-out-expo), transform .3s easing(ease-out-expo), visibility 0 .5s;
	visibility: hidden;
	width: calc(100vw - 20px);
	z-index: z-index(overlay-over-pre-header-content);
	@include breakpoint(mobile-xl) {
		height: calc(100vh - 40px);
		top: 40px;
		width: calc(100vw - 80px);
	}
	&.show {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition: opacity .5s easing(ease-out-expo) .2s, transform .5s easing(ease-out-expo) .2s, visibility 0;
		visibility: visible;
	}
	.close {
		@extend %remove_x;
		height: 30px;
		margin-left: calc(100% - 40px);
		margin-top: 13px;
		position: -webkit-sticky;
		top: 13px;
		width: 30px;
		z-index: 21;
	}
	.horizontal_rule {
		background: color(gray, x-light);
		height: 1px;
		margin: 30px 0;
		width: 100%;
		@include breakpoint(tablet-l) {
			margin: 30px 40px 30px 0;
			width: calc(100% - 40px);
		}
		@include breakpoint(desktop) {
			margin: 80px $sucess_modal_margin 90px 0;
			width: calc(100% - #{$sucess_modal_margin});
		}
	}
	.info_block {
		h4 {
			color: color(gray, light);
		}
	}
	.member_title {
		font-style: italic;
		margin-bottom: 40px;
		@include breakpoint(mobile-xl) {
			margin-bottom: 0;
		}
	}
	.modal_quote_desktop,
	.modal_quote_mobile {
		@include font_size(fs_h2);
		color: color(blue);
		font-weight: 200;
	}
	.modal_quote_desktop {
		display: none;
		@include breakpoint(tablet-l) {
			display: block;
			margin-top: 90px;
		}
	}
	.modal_quote_mobile {
		display: block;
		margin-top: 40px;
		@include breakpoint(tablet-l) {
			display: none;
		}
	}
	.slider {
		margin: 0 -10px;
		overflow: hidden;
		width: calc(100% + 20px);
		// lots of breakpoints to keep this sized with the correct aspect ratio while also being the correct size for the viewport width
		@include breakpoint(mobile-xl) {
			margin: 0 auto;
			width: 100%;
		}
		@include breakpoint(tablet-l) {
			float: left;
			width: 320px;
		}
		@include breakpoint(desktop) {
			width: 290px;
		}
		@include breakpoint(desktop-l) {
			width: 320px;
		}
		@include breakpoint(desktop-xl) {
			width: 460px;
		}
		.emphasis {
			font-family: font(serif);
letter-spacing: letter-spacing(serif);
			font-style: italic;
			text-transform: none;
		}
		.image_compare {
			height: 280px;
			margin: 0 auto;
			width: 280px;
			// more breakpoints for said aspect ratio
			@include breakpoint(mobile) {
				height: 335px;
				width: 335px;
			}
			@include breakpoint(mobile-l) {
				height: 374px;
				width: 374px;
			}
			@include breakpoint(mobile-xl) {
				height: 418px;
				width: 418px;
			}
			@include breakpoint(tablet) {
				height: 460px;
				width: 460px;
			}
			@include breakpoint(tablet-l) {
				height: 320px;
				width: 320px;
			}
			@include breakpoint(desktop) {
				height: 290px;
				width: 290px;
			}
			@include breakpoint(desktop-l) {
				height: 320px;
				width: 320px;
			}
			@include breakpoint(desktop-xl) {
				height: 460px;
				width: 460px;
			}
			.before img {
				width: 100%;
			}
		}
	}
	.stat_list {
		flex-direction: column;
		@include breakpoint(tablet-l) {
			flex-direction: row;
			margin-top: 90px;
		}
		.number {
			font-size: 50px;
			line-height: normal;
			@include breakpoint(desktop) {
				font-size: 70px;
			}
			@include breakpoint(desktop-xl) {
				font-size: 100px;
			}
		}
		.stat {
			margin: 20px 0;
			@include breakpoint(tablet-l) {
				margin: 0;
			}
		}
	}
	.subtitle_desktop,
	.subtitle_mobile {
		@extend .h5_sans;
		color: color(blue);
		letter-spacing: .1em;
		text-transform: uppercase;
	}
	.subtitle_desktop {
		display: none;
		@include breakpoint(tablet-l) {
			display: block;
			padding: 0 40px 35px;
		}
		@include breakpoint(desktop) {
			padding: 0 $sucess_modal_margin 35px;
		}
	}
	.subtitle_mobile {
		display: block;
		padding: 30px 0 25px;
		@include breakpoint(tablet-l) {
			display: none;
		}
	}
}
.success_modal_bg {
	background: color(white) no-repeat left top;
	background-size: contain;
	left: 0;
	overflow: hidden;
	padding-bottom: 100px;
	padding-top: 55px;
	position: absolute;
	top: 0;
	width: 100%;
	@include breakpoint(tablet-l) {
		padding-top: 150px;
	}
	&.athlete-smiling {
		background-image: url(../img/success/modal-bg/athlete-smiling.jpg);
	}
	&.jar {
		background-image: url(../img/success/modal-bg/jar.jpg);
	}
	&.woman-bottle {
		background-image: url(../img/success/modal-bg/woman-bottle.jpg);
	}
	&.athlete-camo {
		background-image: url(../img/success/modal-bg/athlete-camo.jpg);
	}
	&.athlete-earbuds {
		background-image: url(../img/success/modal-bg/athlete-earbuds.jpg);
	}
	&.oranges {
		background-image: url(../img/success/modal-bg/oranges.jpg);
	}
	&.water {
		background-image: url(../img/success/modal-bg/water.jpg);
	}
	&.vegetables {
		background-image: url(../img/success/modal-bg/vegetables.jpg);
	}
}
.success_modal_controls {
	text-align: center;
	@include breakpoint(mobile-xl) {
		margin-right: 80px;
		text-align: right;
	}
	.next_story a,
	.previous_story a {
		@include font_size(fs_p_small);
		color: color(blue);
		cursor: pointer;
		font-weight: 600;
		letter-spacing: .1em;
		text-decoration: none;
		text-transform: uppercase;
		@include breakpoint(mobile-xl) {
			font-weight: 400;
		}
	}
	.next_story {
		@extend .arrow_link_blue;
		padding-right: 45px;
		@include breakpoint(mobile-l) {
			padding-right: 55px;
		}
		&:before {
			top: 0;
		}
	}
	.previous_story {
		@extend .arrow_link_left_blue;
		margin-right: 10px;
		padding-left: 45px;
		padding-right: 10px;
		position: relative;
		@include breakpoint(mobile-l) {
			margin-right: 20px;
			padding-left: 55px;
			padding-right: 20px;
		}
		&:before {
			top: 0;
		}
		&:after {
			border-right: 1px solid color(blue);
			content: "";
			height: .64em;
			position: absolute;
			right: 0;
			top: .2em;
		}
	}
}
.success_modal_results {
	box-sizing: border-box;
	color: color(white);
	margin: 40px 0 40px 64px;
	overflow: visible;
	padding: 35px 0 50px 40px;
	position: relative;
	width: calc(100% - 64px);
	@include breakpoint(tablet-l) {
		margin: 185px 0 75px $sucess_modal_margin;
		padding: 80px 75px 265px;
		width: calc(100% - #{$sucess_modal_margin});
	}
	&:before {
		background: color(gray);
		content: "";
		left: -44px;
		height: 1px;
		position: absolute;
		top: 90px;
		width: 115px;
		z-index: 1;
		@include breakpoint(tablet-l) {
			left: -80px;
			top: 24%;
			width: 225px;
		}
	}
	.member_title {
		position: relative;
		z-index: 1;
	}
	// this needs it's own element because the clip path stops the left line from overlapping the container
	.results_bg {
		background: color(gray, light) no-repeat center top;
		background-size: cover;
		clip-path: none;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		@include breakpoint(desktop-xl) {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 3% 100%, 0 94%);
		}
		&.beets {
			background-image: url('../img/success/stats-bg/beets.jpg');
		}
		&.bowl {
			background-image: url('../img/success/stats-bg/bowl.jpg');
		}
		&.hikers {
			background-image: url('../img/success/stats-bg/hikers.jpg');
		}
		&.joggers {
			background-image: url('../img/success/stats-bg/joggers.jpg');
		}
		&.meditation {
			background-image: url('../img/success/stats-bg/meditation.jpg');
		}
		&.peaches {
			background-image: url('../img/success/stats-bg/peaches.jpg');
		}
		&.smoothie {
			background-image: url('../img/success/stats-bg/smoothie.jpg');
		}
		&.surfer {
			background-image: url('../img/success/stats-bg/surfer.jpg');
		}
	}
	.stat_list {
		position: relative;
		z-index: 1;
	}
}
.success_modal_row {
	display: flex;
	flex-direction: column;
	margin: 0 20px;
	@include breakpoint(tablet-l) {
		flex-direction: row;
		margin: 0 40px;
	}
	@include breakpoint(desktop) {
		margin: 0 $sucess_modal_margin;
	}
	.success_modal_column {
		flex: 1;
		&.primary {
			@include breakpoint(tablet-l) {
				margin-right: 30px;
			}
		}
		&.secondary {
			@include breakpoint(tablet-l) {
				margin-left: 30px;
			}
		}
		// these two columns are paired together and swap order based on viewport size
		&.slider_container {
			order: 1;
			@include breakpoint(tablet-l) {
				align-self: flex-end;
				order: 2;
			}
		}
		&.title_container {
			order: 2;
			@include breakpoint(tablet-l) {
				align-self: flex-end;
				order: 1;
			}
		}
		// these two columns are paired together and swap order based on viewport size
		&.testimonial_container {
			order: 2;
			@include breakpoint(tablet-l) {
				order: 1;
			}
		}
		&.info_container {
			order: 1;
			@include breakpoint(tablet-l) {
				order: 2;
			}
		}

	}
	.testimonial {
		@include font_size(fs_p_extra);
		line-height: 1.75em;
		margin: 0;
	}
	.title {
		@extend .h2_serif;
		color: color(blue);
		line-height: normal;
	}
}
#overlay.success_modal_overlay {
	// this has to be !important to override the ID overlay styling
	background-color: rgba(10, 130, 186, .9)!important;
	z-index: z-index(overlay-over-pre-header)
}
.info_box {
	align-items: center;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
	.tile_copy {
		padding: 0 10px;
		width: calc(100% - 20px);
	}
	&.ad_one {
		text-align: center;
		.subtitle {
			@extend .h4_serif;
			color: color(gray);
			font-style: italic;
			font-weight: 600;
			@include breakpoint(tablet-l, desktop) {
				font-size: 18px;
			}
			@include breakpoint(desktop, desktop-xl) {
				font-size: 24px;
			}
		}
		.title {
			@include font_size(fs_h1_small);
			color: color(blue);
			font-weight: 800;
			margin: 0 0 25px;
			text-transform: uppercase;
			@include breakpoint(tablet-l, desktop) {
				font-size: 36px;
			}
			@include breakpoint(desktop, desktop-xl) {
				font-size: 52px;
			}
		}
	}
	&.ad_two {
		box-sizing: border-box;
		padding: 0 20px;
		@include breakpoint(mobile-xl) {
			padding: 48px 40px 36px 30px;
		}
		.title {
			font-family: font(sans);
			color: color(blue);
			font-size: 16px;
			font-weight: 200;
			margin: 0;
			position: relative;
			@include breakpoint(mobile) {
				font-size: 20px;
			}
			@include breakpoint(mobile-xl) {
				font-size: 16px;
			}
			@include breakpoint(desktop) {
				font-size: 24px;
			}
			@include breakpoint(max-width-with-gutters) {
				font-size: 42px;
			}
			&:before {
				background-color: color(blue);
				content: "";
				height: 2px;
				left: 0;
				position: absolute;
				top: -12px;
				width: 20px;
				@include breakpoint(mobile-xl) {
					height: 4px;
					top: -20px;
					width: 40px;
				}
			}
			.emphasis {
				font-family: font(serif);
letter-spacing: letter-spacing(serif);
				font-style: italic;
				font-weight: 600;
			}
		}
	}
	&.success_quote {
		background-color: color(blue);
		color: color(white);
		.quote_desktop,
		.quote_mobile {
			@extend %success_quote;
			@include breakpoint(tablet-l, desktop-xl) {
				font-size: 16px;
			}
		}
		.quote_desktop {
			display: none;
			@include breakpoint(tablet-l) {
				display: block;
			}
		}
		.quote_mobile {
			display: block;
			@include breakpoint(tablet-l) {
				display: none;
			}
		}
	}
}
.success_row {
	align-items: stretch;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	@include breakpoint(zero, tablet-l) {
		.success_feature {
			order: 2;
			.photo {
				&.after {
					margin-left: 3px;
				}
				&.before {
					margin-right: 3px;
				}
			}
			.success_unit {
				margin-left: 0;
				margin-right: 0;
			}
		}
		.success_grid {
			.success_unit:nth-child(2n + 1) {
				margin-left: 0;
			}
			.success_unit:nth-child(2n + 2) {
				margin-right: 0;
			}
		}
		.success_small {
			order: 1;
		}
	}
	@include breakpoint(tablet-l) {
		flex-direction: row;
		// rows 1, 3, 5, etc
		&:nth-child(2n + 1) {
			.success_feature {
				.success_unit {
					flex-basis: calc(50% - 3px);
					margin-left: 0;
				}
			}
			.success_grid {
				.success_unit:nth-child(2n + 2) {
					flex-basis: calc(50% - 3px);
					margin-right: 0;
				}
			}
		}
		// rows 2, 4, 6, etc
		&:nth-child(2n + 2) {
			.success_feature {
				order: 2;
				.success_unit {
					flex-basis: calc(50% - 3px);
					margin-right: 0;
				}
			}
			.success_grid {
				.success_1, .info_box, {
					flex-basis: calc(50% - 3px);
					margin-left: 0;
				}
			}
			.success_small {
				order: 1;
			}
			.success_1 {
				order: 1;
			}
			.success_2 {
				order: 2;
			}
			.info_box {
				order: 3;
			}
			.success_3 {
				order: 4;
			}
		}
	}
}
.success_small {
	flex: 1.22;
	.success_unit {
		max-height: 145px;
		@include breakpoint(mobile-xl) {
			max-height: 288px;
		}
		@include breakpoint(tablet-l) {
			max-height: 288px;
		}
	}
}
// styling assocated to included teaser on other pages
.success_teaser {
	background-color: color(white);
	overflow: hidden;
	a {
		text-decoration: none;
	}
	.success_quote {
		align-items: center;
		background: color(blue);
		box-sizing: border-box;
		color: color(white);
		display: flex;
		margin: 6px 0;
		padding: 30px 30px 30px 40px;
		@include breakpoint(mobile-xl) {
			padding: 30px 50px 30px 60px;
		}
		@include breakpoint(tablet-l) {
			margin: 0;
			padding: 0 35px 0 55px;
		}
		.quote_teaser {
			h4 {
				@extend %success_quote;
				margin: 0;
				width: 100%;
			}
		}
	}
	.success_subtitle {
		@extend .h3_serif;
		color: color(blue);
		font-weight: 200;
		line-height: 1em;
		margin-bottom: 30px;
		padding: 0 20px;
		text-align: center;
		@include breakpoint(mobile-xl) {
			margin-bottom: 40px;
		}
		.emphasis {
			@extend .h3_serif;
			font-style: italic;
			font-weight: 600;
		}
	}
	.success_teaser_row {
		display: flex;
		flex-wrap: wrap;
	}
	.success_teaser_column {
		display: flex;
		flex: 1;
		@include breakpoint(zero, tablet-l) {
			&.teaser_individual {
				flex-basis: calc(50% - 3px);
				&:first-child {
					margin-right: 6px;
				}
			}
			&.teaser_quote {
				flex-basis: 100%;
			}
		}
		@include breakpoint(tablet-l) {
			margin: 0 3px 6px;
			&.teaser_individual {
				flex: 1;
			}
			&.teaser_quote {
				flex: 1.65;
			}
		}
		@include breakpoint(desktop-l) {
			margin: 0 3px 6px;
			&.teaser_quote {
				flex: 1.25;
			}
		}
		@include breakpoint(max-width) {
			&.teaser_quote {
				flex: 1;
			}
		}
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
		.after,
		.before {
			background: no-repeat center top;
			background-size: cover;
			flex: 1;
			min-height: 140px;
			position: relative;
			@include breakpoint(mobile-xl) {
				min-height: 180px;
			}
			@include breakpoint(tablet) {
				min-height: 250px;
			}
			img {
				width: 100%;
			}
			span {
				bottom: 18px;
				color: color(white);
				font-weight: 600;
				letter-spacing: .1em;
				position: absolute;
				text-align: center;
				text-transform: uppercase;
				@include breakpoint(zero, tablet-l) {
					width: 100%;
				}
			}
		}
		@include breakpoint(zero, tablet-l) {
			.after {
				margin-left: 1px;
				span {
					right: 0;
				}
			}
			.before {
				margin-right: 1px;
				span {
					left: 0;
				}
			}
		}
		@include breakpoint(tablet-l) {
			.after {
				margin-left: 1px;
				span {
					right: 25px;
				}
			}
			.before {
				margin-right: 1px;
				span {
					left: 25px;
				}
			}
		}
	}
	.cta_teaser {
		@include font_size(fs_h6);
		box-sizing: border-box;
		letter-spacing: .2em;
		margin-top: 50px;
		padding-right: 50px;
		text-align: right;
		text-transform: uppercase;
		width: 100%;
	}
}
.success_unit {
	display: flex;
	flex: 1;
	flex-basis: calc(50% - 6px);
	margin: 0 3px 6px;
	overflow: hidden;
	position: relative;
	&:hover {
		.success_overlay {
			pointer-events: auto;
			transform: translate3d(0, 0, 0);
			transition-delay: .1s;
		}
		.photo {
			-webkit-filter: blur(10px);
			transition-delay: .3s;
		}
	}
	.photo {
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
		flex: 1;
		overflow: hidden;
		position: relative;
		span {
			@include font_size(fs_p_small);
			bottom: 18px;
			color: color(white);
			font-weight: 600;
			left: 0;
			letter-spacing: .1em;
			position: absolute;
			right: 0;
			text-align: center;
			text-transform: uppercase;
		}
		&.before {
			margin-right: 1px;
			span {
				@include breakpoint(tablet-l) {
					left: 25px;
					text-align: left;
				}
			}
		}
		&.after {
			margin-left: 1px;
			span {
				@include breakpoint(tablet-l) {
					right: 25px;
					text-align: right;
				}
			}
		}
		img {
			width: 100%;
		}
	}
}
