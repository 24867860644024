// mixins, alphabetical

@mixin absolute-center() {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
}

@mixin arrow($direction, $color, $size){
		display: block;
		height: 0;
		width: 0;

		@if $direction == 'top' {
				border-left: $size solid transparent;
			border-right: $size solid transparent;
			border-bottom: $size solid $color;
		} @else if $direction == 'right' {
				border-top: $size solid transparent;
				border-bottom: $size solid transparent;
			border-left: $size solid $color;
		} @else if $direction == 'bottom' {
				border-top: $size solid $color;
				border-right: $size solid transparent;
				border-left: $size solid transparent;
		} @else if $direction == 'left' {
				border-top: $size solid transparent;
			border-right: $size solid $color;
			border-bottom: $size solid transparent;
		} @else if $direction == 'top-left' {
				border-top: $size solid $color;
				border-right: $size solid transparent;
		} @else if $direction == 'top-right' {
				border-top: $size solid $color;
				border-left: $size solid transparent;
		} @else if $direction == 'bottom-left' {
				border-bottom: $size solid $color;
				border-right: $size solid transparent;
		} @else if $direction == 'bottom-right' {
				border-bottom: $size solid $color;
				border-left: $size solid transparent;
		}
}

@mixin aspect-ratio-min($horizontal, $vertical) {
	@media only screen and (min-aspect-ratio: $horizontal #{"/"} $vertical) {
		@content;
	}
}

@mixin aspect-ratio-max($horizontal, $vertical) {
	@media only screen and (max-aspect-ratio: $horizontal #{"/"} $vertical) {
		@content;
	}
}

@mixin bg_img_retina($file, $type, $width, $height) {
	background-image: url($file + '.' + $type);
	@media only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (-moz-min-device-pixel-ratio: 2),
		only screen and (-o-min-device-pixel-ratio: 2/1),
		only screen and (min-device-pixel-ratio: 2),
		only screen and (min-resolution: 192dpi),
		only screen and (min-resolution: 2dppx){
		& {
			background-image: url($file + '@2x.' + $type);
			-webkit-background-size: $width $height;
			-moz-background-size: $width $height;
			-o-background-size: $width $height;
			background-size: $width $height;
		}
	}
}

@mixin breakpoint($min-width, $max-width: null, $use-named-widths: true) {
	@if ($use-named-widths) {
		$min-width: width($min-width);
		@if ($max-width) {
			$max-width: width($max-width);
		}
	}
	@if ($max-width) {
		$max-width: $max-width - 1;
		@media only screen and (min-width: $min-width) and (max-width: $max-width) {
			@content;
		}
	}
	@else {
		@media only screen and (min-width: $min-width) {
			@content;
		}
	}
}

@mixin btn_new_colors($color_map) {
	// configurable options to be used with this mixin
		// text
		// text_hover
		// text_active
		// bg_color
		// bg_color_hover
		// bg_color_active
	@if ( $color_map ) {
		// change text color
		@if map-get($color_map, text) {
			color: map-get($color_map, text);
		}
		// change text color on hover
		@if map-get($color_map, text_hover) {
			&:hover {
				color: map-get($color_map, text_hover);
			}
		}
		// change text color on active
		@if map-get($color_map, text_active) {
			&:active {
				color: map-get($color_map, text_active);
			}
		}
		// change background color
		@if map-get($color_map, bg_color) {
			&::before {
				background-color: map-get($color_map, bg_color);
			}
		}
		// change background color on hover
		@if map-get($color_map, bg_color_hover) {
			&:hover {
				&::before {
					background-color: map-get($color_map, bg_color_hover);
				}
			}
		}
		// change background color on active
		@if map-get($color_map, bg_color_active) {
			&:active {
				&::before {
					background-color: map-get($color_map, bg_color_active);
				}
			}
		}
		// if an active state isn't set, utilize default color darkened
		@else {
			$bg_color_active: map-get($color_map, bg_color);
			&:active {
				&::before {
					background-color: darken($bg_color_active, 20%);
				}
			}
		}
	}
	@else {
		@warn 'Oops, you didn\'t provide a btn_color parameter';
	}
}

@mixin clearfix {
	zoom:1;
	&:before, &:after {
		content: "\0020";
		display: block;
		height: 0;
		overflow: hidden;
	}
	&:after {
		clear: both;
	}
}

@mixin clip-path($clip) {
	-webkit-clip-path: $clip;
	clip-path: $clip;
}

@mixin constrain-proportions($width, $height-proporational-to-width) {
	display: block;
	width: $width;
	&:before {
		content: '';
		display: block;
		padding-top: $height-proporational-to-width;
	}
}

@mixin cursor($cursor) {
	cursor: pointer;
	cursor: -webkit-#{$cursor};
	cursor: $cursor;
}

@mixin firefox {
	@-moz-document url-prefix() {
		@content;
	}
}

@mixin font_size($font_size) {
	$font_size_list: font_size_list($font_size);
	@if $font_size_list {
		font-size: nth($font_size_list, 1);
		@include breakpoint(mobile) {
			font-size: nth($font_size_list, 2);
		}
		@include breakpoint(mobile-xl) {
			font-size: nth($font_size_list, 3);
		}
		@include breakpoint(tablet) {
			font-size: nth($font_size_list, 4);
		}
		@include breakpoint(desktop) {
			font-size: nth($font_size_list, 5);
		}
	}
}

@mixin font-smoothing($enable: true) {
	@if ($enable) {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
	}
	@else {
		-moz-osx-font-smoothing: auto;
		-webkit-font-smoothing: subpixel-antialiased;
	}
}

@mixin gutter-s {
	@include breakpoint(zero, tablet) {
		@content;
	}
}

@mixin gutter-l {
	@include breakpoint(tablet) {
		@content;
	}
}

@mixin mobile-ie10-up {
	@media screen and (-ms-high-contrast: active) and (max-width: 30em), (-ms-high-contrast: none) and (max-width: 30em) {
		@content;
	}
}

@mixin ie9 {
	.lt-ie10 & {
		@content;
	}
}

@mixin ie9-up {
	@include ie9 {
		@content;
	}
	@include ie10-up {
		@content;
	}
}

@mixin ie10-up {
	_:-ms-lang(x), & {
		@content;
	}
}

@mixin landscape() {
	@media only screen and (orientation: landscape) {
		@content;
	}
}

@mixin large_shadow {
	&::after {
		background-image: url('../img/shadow-bottom-large.png');
		background-position: left;
		background-repeat: no-repeat;
		background-size: contain;
		content: '';
		bottom: 0;
		height: 60px;
		left: 0;
		margin: auto;
		pointer-events: none;
		position: absolute;
		right: 0;
		transform: translate3d(0, 100%, 0);
		width: 95%;
	}
}

@mixin column_shadow {
	&::after {
		background-image: url('../img/column-shadow-bottom.png');
		background-position: left top;
		background-repeat: no-repeat;
		background-size: contain;
		content: '';
		bottom: 0;
		height: 60px;
		left: 0;
		margin: auto;
		pointer-events: none;
		position: absolute;
		right: 0;
		transform: translate3d(0, 95%, 0);
		width: 95%;
		z-index: -1;
	}
}

@mixin nav-condensed {
	@include breakpoint(zero, tablet-l) {
		@content;
	}
}

@mixin nav-full {
	@include breakpoint(tablet-l) {
		@content;
	}
}

@mixin retina {
	@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (-moz-min-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 2/1),
	only screen and (min-device-pixel-ratio: 2),
	only screen and (min-resolution: 192dpi),
	only screen and (min-resolution: 2dppx) {
		@content;
	}
}

@mixin section_reset {
	min-height: 0;
	overflow: auto;
	padding: 0;
}

@mixin small_shadow {
	&::after {
		background-image: url('../img/shadow-bottom.png');
		background-position: left;
		background-repeat: no-repeat;
		background-size: contain;
		content: '';
		bottom: 0;
		height: 30px;
		left: 0;
		margin: auto;
		pointer-events: none;
		position: absolute;
		right: 0;
		transform: translate3d(0, 100%, 0);
		width: 95%;
	}
}

@mixin svg-background {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 100%;
}

@mixin tablet-landscape {
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
		@content;
	}
}

@mixin tir {
	overflow: hidden;
	text-indent: -9999px;
}

@mixin type_reset { // this is temp style to unset any style collisions for the home page and new nav
	margin: 0;
}

@mixin vertical-breakpoint($min-height, $max-height: null, $use-named-heights: true) {
	@if ($use-named-heights) {
		$min-height: height($min-height);
		@if ($max-height) {
			$max-height: height($max-height);
		}
	}
	@if ($max-height) {
		$max-height: $max-height - 1;
		@media only screen and (min-height: $min-height) and (max-height: $max-height) {
			@content;
		}
	}
	@else {
		@media only screen and (min-height: $min-height) {
			@content;
		}
	}
}

@mixin vertical-center($offset-percent: 0, $position: relative) {
	position: $position;
	top: 50% + $offset-percent;
	transform: translateY(-50%);
}

@mixin vertical-center-3d($offset-percent: 0) {
	position: relative;
	top: 50% + $offset-percent;
	transform: translate3d(0, -50%, 0);
}

@mixin vertical-pad($property: 'padding', $top: true, $bottom: true, $scale: 1) {
	@if $bottom {
		#{$property}-bottom: 2em * $scale;
		@include breakpoint(tablet) {
			#{$property}-bottom: 3em * $scale;
		}
		@include breakpoint(desktop) {
			#{$property}-bottom: 4em * $scale;
		}
	}
	@if $top {
		#{$property}-top: 2em * $scale;
		@include breakpoint(tablet) {
			#{$property}-top: 3em * $scale;
		}
		@include breakpoint(desktop) {
			#{$property}-top: 4em * $scale;
		}
	}
}
