.pull_text {
	margin-bottom: 60px;
	@include breakpoint(tablet) {
		margin-bottom: 120px;
	}
	@include section_reset;
	.column_copy {
		@include font_size(fs_h3_small);
		box-sizing: border-box;
		color: #5e6266;
		font-weight: 100;
		line-height: 1.25em;
		@include breakpoint(tablet) {
			width: 55%;
			z-index: 2;
		}
		@include breakpoint(desktop) {
			padding-right: 100px;
			width: 65%;
		}
		b,
		strong {
			color: #2f3133;
		}
    p {
      margin-bottom: 10px;
      margin-top: 0;
    }
    .credit {
      @include font_size(fs_h6);
      color: #878f99;
      margin-bottom: 20px;
    }
	}
	.column_image {
		@include constrain-proportions(100%, 80%);
		background-position: center right;
		background-repeat: no-repeat;
		background-size: cover;
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
		max-width: 400px;
		@include breakpoint(tablet) {
			background-position: center 80%;
			bottom: 0;
			left: 0;
			margin-bottom: 0;
			max-width: 520px;
			position: absolute;
			top: 0;
			width: 40%;
			z-index: 1;
		}
	}
	.row {
		@include breakpoint(tablet) {
			align-items: center;
			display: flex;
			justify-content: flex-end;
			min-height: 460px;
			position: relative;
		}
	}
}
