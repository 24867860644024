// product category list (pcl)

.product_billboard {
	@include section_reset;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 80px 0 40px;
	@include breakpoint(mobile) {
		padding: 100px 0 60px;
	}
	@include breakpoint(tablet-s) {
		padding: 160px 0 260px;
	}
	@include breakpoint(tablet-l) {
		padding: 160px 0 280px;
	}
	@include breakpoint(desktop) {
		padding: 215px 0;
	}
	.page_title {
		@include font_size(fs_h1_small);
		color: color(white);
		margin: 0;
		text-align: center;
		text-transform: uppercase;
	}
}

#quick_shop {
	@include section_reset;
	display: none;
	&.open {
		display: block;
		.condensed {
			@include nav-condensed {
				display: block;
			}
		}
		.full {
			@include nav-full {
				display: block;
			}
		}
	}
	a.remove_x {
		@extend %remove_x;
		background-color: color(white);
		height: 35px;
		position: absolute;
		right: 5px;
		top: 5px;
		width: 35px;
		z-index: 1;
	}
	.btn_new {
		height: 40px;
		line-height: 40px;
		padding-bottom: 0;
		padding-top: 0;
	}
	.condensed,
	.full {
		background-color: color(white);
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 300;

	}
	.condensed {
		@extend %mobile_product_controls;
		width: 100%;
		max-width:500px;
		.heading {
			background-color: color(white);
		}
		.hero {
			background-color: color(white);
			border-bottom: 1px solid color(gray, light);
			padding-bottom: 20px;
			margin: 0 20px 40px;
			.img {
				@include constrain-proportions(100%, 100%);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;
				display: none;
				left: 0;
				top: 0;
				&.selected {
					display: block;
				}
			}
		}
		.menu {
			background-color: color(white);
		}
		.price {
			.amount {
				color: color(gray);
			}
			.type {
				color: color(gray, light);
			}
		}
	}
	.full {
		display:none;
		overflow: visible;
		width: 760px;
		.about {
			@extend %product_about;
			@include font_size(fs_p);
		}
		.next, .prev {
			@include tir;
			cursor: pointer;
			display: block;
			height: 17px;
			width: 34px;
		}
		.content {
			background-color: inherit;
			float: left;
			padding: 80px 50px 80px 0;
			width: calc(52% - 50px);

		}
		.controls {
			margin-top: 1em;
			transform: translate3d(0, 0, 0);
		}
		.hero {
			float: left;
			padding: 80px 60px 0 40px;
			width: calc(48% - 100px);
			img {
				display: none;
				&.selected {
					display: block;
				}
			}
		}
		.next, .prev {
			@include vertical-center(0, absolute);
			&.disabled {
				cursor: not-allowed;
				opacity: .5;
			}
		}
		.next {
			background: svg(home-arrow-right-white) center top no-repeat ;
			right: -64px;
		}
		.prev {
			background: svg(home-arrow-left-white) center top no-repeat ;
			left: -64px;
		}
		.price {
			margin-bottom: 1em;
		}
		.size {
			@extend %product_size;
			@include font_size(fs_h5);
		}
		.spinner {
			margin-right: 10px;
		}
		.title {
			@extend %product_title;
			@extend .h2_sans;
		}
	}
}
