/* Careers */
#careers {background-color:#fafafa;
	h3 {
		@extend .h5_sans;
		text-transform: uppercase;
		margin-top: 2em;
		margin-bottom: 1em;
		&:first-of-type {
			margin-top: 0;
		}
	} 
}
.career_nav {
	list-style-type: none;
	box-sizing: border-box;
	width: 100%;
	margin: 0 0 30px 0;
	padding: 0 0 30px 0;
	text-align: center;
	border: 1px solid #ddd;
	border-width: 0 0 1px 0;
	@include breakpoint(tablet) {
		width: 25%;
		margin-bottom: 30px;
		padding: 20px 30px 50px 0;
		text-align: left;
		border-width: 0 1px 0 0;
	}
}
.career_nav a {
	display: block;
	padding: 8px 0;
	text-decoration: none;
	color: #999;
	line-height: 1.2em;
}
.career_nav a.active {
	font-weight: bold;
}
.career_nav a.active,
.career_nav a:hover {
	color: #00adeb;
}
.career_content {
	@include font_size(fs_p_extra);
	line-height: 1.8em;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	@include breakpoint(tablet) {
		padding: 0 0 0 40px;
		width: 75%;
	}
	p {
		color: color(gray);
	}
}
.career_content .post {
	display: none;
	padding-top: 20px;
	h4 {
		margin: 2em 0 0;
	}
	ul li {
		line-height: 1.4em;
		margin-bottom: .8em;
		font-size: .9em;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.position_title {
	@extend .h3_serif;
	color: color(blue);
	margin-bottom: .5em;
	text-transform: capitalize;
}
