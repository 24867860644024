.blog_tiles {
	@include section_reset;
	padding-top: 50px;
	@include breakpoint(tablet) {
		padding-top: 80px;
	}
}

.blog_tiles .main_title {
	@include font_size(fs_p_note);
	color: #878f99;
	font-weight: 700px;
	letter-spacing: .15em;
	margin-bottom: 20px;
	padding-bottom: 40px;
	text-align: center;
	text-transform: uppercase;
	@include breakpoint(tablet) {
		margin-bottom: 20px;
		padding-bottom: 80px;
	}
}

.blog_tiles .row {
	@include breakpoint(tablet) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.columns {
		margin-bottom: 70px;
		@include breakpoint(tablet) {
			flex-basis: calc(50% - 40px);
		}
	}
	.column_image {
		max-width: 200px;
		padding-bottom: 25px;
	}
	.column_header {
		@include font_size(fs_h4);
		color: color(black);
	}
}

.blog_tiles .column_link {
	color: #0a82ba;
	a {
		border-bottom: 0;
	}
}
