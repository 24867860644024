.distributor_flex_content {
	p {
		@include font_size(fs_p_extra);
		line-height: 1.5em;
		.flex_content__master & {
			color: color(gray, light);
		}
	}
}

.distributor_quote {
	flex: 1;
	font-weight: 100;
	line-height: 1.1em;
	text-transform: uppercase;
	.flex_content__master & {
		color: color(white);
	}
	strong {
		.pro_1 & {
			color: color(rank_pro_1);
		}
		.pro_2 & {
			color: color(rank_pro_2);
		}
		.pro_3 & {
			color: color(rank_pro_3);
		}
		.pro_4 & {
			color: color(rank_pro_4);
		}
		.pro_5 & {
			color: color(rank_pro_5);
		}
		.pro_6 & {
			color: color(rank_pro_6);
		}
		.pro_7 & {
			color: color(rank_pro_7);
		}
		.pro_8 & {
			color: color(rank_pro_8);
		}
		.pro_9 & {
			color: color(rank_pro_9);
		}
		.flex_content__master & {
			color: color(rank_pro_10)
		}
	}
}

.distributor_pull_quote {
	align-items: center;
	display: flex;
	font-size: 24px;
	@include breakpoint(mobile) {
		transform: translate3d(-50px, 0, 0);
		width: calc(100% + 50px);
	}
	@include breakpoint(mobile-xl) {
		font-size: 30px;
		transform: translate3d(-100px, 0, 0);
		width: calc(100% + 100px);
	}
}

.distributor_rank_icon {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: none;
	height: 125px;
	width: 100px;
	@include breakpoint(mobile) {
		display: block;
	}
	@include breakpoint(mobile-xl) {
		height: 225px;
		width: 200px;
	}
	&.pro_1 {
		background-image: url('../img/recognition/pin-pro-1.png');
	}
	&.pro_2 {
		background-image: url('../img/recognition/pin-pro-2.png');
	}
	&.pro_3 {
		background-image: url('../img/recognition/pin-pro-3.png');
	}
	&.pro_4 {
		background-image: url('../img/recognition/pin-premier-pro-4.png');
	}
	&.pro_5 {
		background-image: url('../img/recognition/pin-premier-pro-5.png');
	}
	&.pro_6 {
		background-image: url('../img/recognition/pin-premier-pro-6.png');
	}
	&.pro_7 {
		background-image: url('../img/recognition/pin-elite-pro-7.png');
	}
	&.pro_8 {
		background-image: url('../img/recognition/pin-elite-pro-8.png');
	}
	&.pro_9 {
		background-image: url('../img/recognition/pin-elite-pro-9.png');
	}
	&.pro_10 {
		background-image: url('../img/recognition/pin-master.png');
	}
	&.executive {
		background-image: url('../img/recognition/pin-master-executive.png');
	}
	&.presidential {
		background-image: url('../img/recognition/pin-master-presidential.png');
	}
}

.flex_content {
	padding-bottom: 70px;
	@include breakpoint(tablet) {
		padding-bottom: 140px;
	}
	&__featured {
		background: color(gray, xxx-light);
	}
	&__master {
		background: radial-gradient(ellipse at center, rgba(67,72,79,1) 0%,rgba(25,25,28,1) 100%)
	}
}
