.two_column {
	$gray: #878f99;
	$near_black: #2f3133;
	@include section_reset;
	margin-bottom: 30px;
	.image_cta + & {
		margin-top: 100px;
	}
	&__border_top {
		margin-top: -40px;
		@include breakpoint(tablet) {
			margin-top: -60px;
		}
		.content_wrapper_narrow {
			border-top: 1px solid color(gray, xx-light);
			padding-top: 40px;
			@include breakpoint(tablet) {
				padding-top: 80px;
			}
		}
	}
	&.bg {
		overflow: visible;
		padding-bottom: 30px;
		position: relative;
		&::after {
			background-image: linear-gradient(color(white), #f2f4f6);
			bottom: 0;
			content: '';
			height: 900px;
			position: absolute;
			width: 100%;
			z-index: -2;
		}
	}
	.card {
		background-color: color(white, near);
		margin-bottom: 20px;
		padding: 1.9em;
		table {
			@include font_size(fs_p_extra);
			width: 100%;
		}
		td {
			padding-bottom: .5em;
			&:nth-child(1) {
				font-weight: 600;
				padding-right: .5em;
				text-align: left;
			}
			&:nth-child(2) {
				text-align: right;
			}
		}
		.card_heading {
			@include font_size(fs_h5);
			margin-bottom: 1.25em;
		}
	}
	.column_flow {
		margin-bottom: 30px;
		@include breakpoint(tablet) {
			column-count: 2;
			column-gap: 40px;
			margin-bottom: 60px;
		}
		@include breakpoint(desktop) {
			column-gap: 90px;
		}
	}
	.column_subtitle {
		@include font_size(fs_p_note_extra);
		color: $gray;
		font-weight: 700;
		letter-spacing: .15em;
		margin-bottom: 20px;
		text-transform: uppercase;
	}
	.column_title {
		@include font_size(fs_h3_small);
		@include font-smoothing;
		color: $near_black;
		font-weight: 600;
		line-height: 1.15em;
		margin-bottom: 20px;
		margin-top: 0;
		text-transform: none;
	}
	.copy {
		@include font_size(fs_h5);
		color: $gray;
		line-height: 1.3em;
		margin-top: 0;
		a {
			&,
			&:visited {
				color: color(blue);
				&:hover {
					color: color(blue, dark);
				}
			}
			border-bottom: 0;
			font-weight: bold;
			transition: color .2s ease-out;
		}
	}
	.row {
		@include breakpoint(tablet) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		.column {
			margin-bottom: 50px;
			@include breakpoint(tablet) {
				flex-basis: calc(50% - 20px);
				margin-bottom: 100px;
			}
			@include breakpoint(desktop) {
				flex-basis: calc(50% - 45px);
			}
		}
	}
	.stats_list {
		list-style: none;
		margin: 0;
		padding: 0;
		.stat {
			@include font_size(fs_h2);
			color: $near_black;
			font-weight: 400;
			line-height: 1em;
			margin-bottom: 10px;
			b {
				font-weight: 600;
			}
		}
		.stat_label {
			@include font_size(fs_p);
			color: $gray;
			.dark {
				color: $near_black;
			}
		}
		.stat_item {
			box-sizing: border-box;
			margin-bottom: 30px;
		}
	}
}

.two_column__list {
	@include breakpoint(zero, tablet) {
		.row .column:first-of-type {
			margin-bottom: 0;
		}
	}
	.row .column {
		margin-bottom: 0;
	}
	.column_title {
		margin-bottom: 2em;
		text-align: center;
		text-transform: uppercase;
	}
	.button {
		text-align: center;
		margin-bottom: 30px;
	}
	.copy {
		margin-bottom: 2em;
	}
	.heading {
		@include font_size(fs_h3_small);
		color: #2f3133;
		font-weight: 600;
		margin-bottom: .5em;
	}
}
