.billboard_hero {
	@include section_reset;
	.billboard_hero_main {
		@include large_shadow;
		background-color: #f2f4f5;
		background-position: top left;
		background-repeat: no-repeat;
		background-size: contain;
		border-radius: 5px;
		box-sizing: border-box;
		margin-bottom: 60px;
		margin-top: 30px;
		padding-bottom: 30px;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 30px;
		position: relative;
		@include breakpoint(zero, tablet-l) {
			background-image: none !important;
		}
		@include breakpoint(tablet) {
			padding-left: 30px;
			padding-right: 30px;
		}
		@include breakpoint(desktop) {
			margin-bottom: 120px;
			padding-bottom: 60px;
			padding-top: 60px;
		}
		@include breakpoint(desktop-xl) {
			padding-left: 150px;
		}
		&__image_left {
			.billboard_hero_content {
				max-width: none;
				@include breakpoint(tablet-l) {
					margin-left: 35%;
					// margin-left: 44%;
				}
			}
			.image_container {
				background-position: center left;
				left: 0;
				z-index: 2;
				@include breakpoint(tablet-l) {
					margin-left: 25px;
				}
				@include breakpoint(desktop-l) {
					margin-left: 50px;
				}
				@include breakpoint(desktop-xl) {
					margin-left: 100px;
				}
			}
		}
		&__image_left_edge {
			.billboard_hero_content {
				max-width: none;
				@include breakpoint(tablet-l) {
					margin-left: 35%;
					// margin-left: 44%;
				}
}
			.image_container {
				// &__overflow {
				// 	background-size: auto;
				// }
				background-position: bottom left;
				left: 0;
				width: 100%;
			}
		}
	}
	.billboard_hero_content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		z-index: 2;
		@include breakpoint(mobile-xl) {
			min-height: 125px;
		}
		@include breakpoint(tablet-l) {
			max-width: 60%;
			min-height: 200px;
		}
		@include breakpoint(desktop) {
			min-height: 330px;
		}
		@include breakpoint(desktop-xl) {
			max-width: 56%;
		}
	}
	.chevron_right {
		display: inline;
		margin-left: 5px;
		width: 7px;
		path {
			fill: color(blue, dark);
		}
	}
	.copy {
		@include font_size(fs_h5);
		color: #878f99;
		font-weight: 100;
		line-height: 1.4em;
		margin-bottom: 20px;
		margin-top: 40px;
		@include breakpoint(tablet) {
			max-width: 90%;
		};
	}
	.cta_text {
		.cta_link {
			@include font_size(fs_h5);
			text-decoration: none;
			&:hover {
				.chevron_right {
					path {
						fill: color(blue);
					}
				}
			}
		}
		.cta_title {
			@include font_size(fs_p_note_extra);
			color: #878f99;
			font-weight: 700;
			letter-spacing: .15em;
			text-transform: uppercase;
		}
	}
	.image_container {
		border-radius: 5px;
		display: none;
		background-position: center right;
		background-repeat: no-repeat;
		background-size: cover;
		@include breakpoint(tablet-l) {
			bottom: 0;
			display: block;
			height: 100%;
			position: absolute;
			right: 0;
			width: 45%;
		}
		&__overflow {
			background-position: bottom right;
			background-size: contain;
			@include breakpoint(tablet-l) {
				height: 100%;
				width: 55%;
			}
			@include breakpoint(desktop-xl) {
				height: 100%;
				max-width: 900px;
			}
		}
		&__overflow_both {
			background-position: bottom right;
			background-size: contain;
			transform: translate3d(0, 30px, 0);
			@include breakpoint(tablet-l) {
				height: calc(100% + 60px);
				width: 55%;
			}
			@include breakpoint(desktop-xl) {
				height: calc(100% + 60px);
				max-width: 900px;
			}
		}
	}
	.main_cta {
		@include breakpoint(mobile-l) {
			display: flex;
			margin-right: auto;
		}
	}
	.page_title {
		@include font_size(fs_h2);
		line-height: .825em;
		margin-bottom: 0;
		margin-top: 0;
		text-transform: uppercase;
		.heavy {
			color: color(black);
			font-weight: 800;
		}
		.light {
			color: #5e6266;
		}
	}
	.video_cta {
		@include breakpoint(mobile-l) {
			align-items: center;
			display: flex;
		}
	}
	.video_thumb {
		background-repeat: no-repeat;
		background-size: cover;
		height: 90px;
		margin-bottom: 10px;
		margin-right: 15px;
		position: relative;
		width: 160px;
		@include breakpoint(mobile-l) {
			margin-bottom: 0;
		}
		@include breakpoint(tablet) {
			margin-right: 30px;
		}
	}
	.video_overlay {
		background-color: rgba(0, 0, 0, 0.35);
		cursor: pointer;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		&:after {
			background-image: url('../img/recognition/play-button.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			bottom: 0;
			content: '';
			height: 50px;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 0;
			transition: transform .2s easing(ease-out-quart);
			width: 50px;
		}
		&:hover {
			&:after {
				transform: scale(1.1);
				transition-timing-function: easing(ease-in-quart);
			}
		}
	}
}
