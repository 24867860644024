.billboard_thin {
	@include section_reset;
	align-items: center;
	background-color: #f2f4f5;
	border-bottom: 1px solid #ebeef2;
	border-top: 1px solid #ebeef2;
	box-sizing: border-box;
	display: flex;
	height: 180px;
	justify-content: flex-start;
	margin-bottom: 60px;
	overflow: hidden;
	position: relative;
	@include breakpoint(tablet-s) {
		justify-content: center;
	}
	@include breakpoint(desktop) {
		height: 260px;
	}
	&.bookends {
		// when loading
		&.loading {
			.bg_left,
			.bg_right,
			.page_title {
				opacity: .5;
			}
		}
		// when this class is applied, turn on the left and right images
		.bg_left,
		.bg_right {
			transition: opacity .2s ease-out;
		}
		.bg_left {
			@include breakpoint(tablet-s) {
				display: block;
			}
		}
		.bg_right {
			display: block;
		}
		// change background image based on category class being set
		.us-en &,
		.us-es & {
			&.all {
				.bg_left {
					background-image: url('../img/categories/product-wall-billboard-left.png');
				}
				.bg_right {
					background-image: url('../img/categories/product-wall-billboard-right.png');
				}
			}
			&.beauty {
				.bg_left {
					background-image: url('../img/categories/product-wall-beauty-left.png');
				}
				.bg_right {
					background-image: url('../img/categories/product-wall-beauty-right.png');
				}
			}
		}
		&.rsl {
			background-image: url('../img/categories/rsl-product-wall-bg.jpg');
			background-position: center;
			background-repeat: repeat-x;
			background-size: auto 100%;
			.bg_left {
				background-image: url('../img/categories/rsl-product-wall-left.png');
				background-position: center;
				background-size: auto 100%;
				@include breakpoint(max-width-with-gutters) {
					background-position: right center;
				}
			}
			.bg_right {
				background-image: url('../img/categories/rsl-product-wall-right.png');
				background-size: auto 100%;
				@include breakpoint(0px, 559px, false) {
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					transform: translate3d(45%, 0, 0);
				}
				@include breakpoint(560px, 650px, false) {
					background-position: center right;
				}
			}
			.page_title {
				color: color(white);
				max-width: 7em;
				@include breakpoint(max-width) {
					max-width: none;
					white-space: nowrap;
				}
				b {
					color: #fab633;
				}
			}
		}
	}
	&.full {
		.bg_full {
			display: block;
		}
	}
	.billboard_badge {
		@include font_size(fs_p_small);
		background-color: #ff6666;
		border-radius: 3px;
		color: color(white);
		display: inline-block;
		font-weight: 600;
		line-height: 1.5em;
		margin-top: 1em;
		padding-left: .75em;
		padding-right: .75em;
	}
	.bg_container {
		align-items: center;
		display: flex;
		flex-direction: row;
		height: 100%;
		justify-content: space-between;
		left: 50%;
		position: absolute;
		top: 0;
		transform: translate3d(-50%, 0, 0);
		width: 100%;
		z-index: 2;
		@include breakpoint(tablet-s) {
			justify-content: center;
		}
	}
	.billboard_copy {
		max-width: 60%;
		padding-left: 20px;
		padding-right: 20px;
		text-align: center;
	}
	.bg_left,
	.bg_right {
		background-repeat: no-repeat;
		background-size: cover;
		flex: 1 1 auto;
		display: none;
		height: 100%;
		@include breakpoint(max-width-with-gutters) {
			background-size: contain;
		}
	}
	.bg_full {
		background-position: center;
		background-repeat: no-repeat;
		display: none;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		.cover {
			background-size: cover;
		}
		.repeat {
			background-repeat: repeat;
		}
	}
	.bg_left {
		background-position: right;
	}
	.bg_right {
		background-position: left;
	}
	.page_title {
		color: #5e6266;
		font-size: 28px;
		font-weight: 100;
		margin: 0;
		text-transform: uppercase;
		transition: opacity .2s ease-out;
		z-index: 3;
		@include breakpoint(mobile) {
			font-size: 36px;
		}
		@include breakpoint(mobile-xl) {
			min-width: 40%;
		}
		@include breakpoint(tablet-s) {
			font-size: 54px;
			text-align: center;
		}
		@include breakpoint(desktop) {
			font-size: 72px;
			max-width: 745px;
		}
		b,
		strong {
			color: color(black);
			font-weight: 800;
		}
	}
}
