.two_column_feature {
	@include section_reset;
	margin-bottom: 80px;
	@include breakpoint(tablet) {
		margin-bottom: 70px;
	}
	.column_title {
		@include font-size(fs_h6);
		border-top: 1px solid #dee3eb;
		color: color(black);
		font-weight: 700;
		margin-bottom: 60px;
		margin-left: auto;
		margin-right: auto;
		max-width: 120px;
		padding-top: 1.6em;
		text-align: center;
	}
	.content_wrapper_narrow {
		border-bottom: 1px solid #dee3eb;
	}
	.row {
		@include breakpoint(tablet) {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		.column {
			margin-bottom: 80px;
			text-align: center;
			@include breakpoint(tablet) {
				flex-basis: calc(50% - 20px);
			}
			@include breakpoint(desktop) {
				margin-bottom: 40px;
				flex-basis: calc(50% - 40px);
			}
			.column_copy {
				@include font-size(fs_h6);
				color: #878f99;
				font-weight: 100;
				margin-left: auto;
				margin-right: auto;
				margin-top: 0;
				max-width: 23em;
			}
			.column_header {
				@include font-size(fs_h6);
				font-weight: 700;
				margin-left: auto;
				margin-right: auto;
				margin-top: 2.75em;
				max-width: 25em;
			}
			.column_image {
				display: inline-block;
				max-width: 220px;
				position: relative;
				@include column_shadow;
			}
		}
	}
}
