//
// table simple
//

.table_simple {
	@include section_reset;
	margin-bottom: 5em;
	margin-top: 5em;
	text-align: center;
	.table_simple_heading {
		@include font_size(fs_h3_small);
		@include font-smoothing;
		color: #2f3133;
		font-weight: 600;
		line-height: 1.15em;
		margin-bottom: 2em;
		text-transform: uppercase;
	}
	.table_simple_note {
		@include font-size(fs_p_note_extra);
		margin-bottom: 3.25em;
		margin-top: -1.5em;
		text-align: left;
	}
}
