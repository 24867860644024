// promotions

.promo_billboard {
	min-height: 0;
	.page_title {
		@include font_size(fs_h1_small);
		color: color(gray);
		margin: 0;
		text-align: center;
		text-transform: uppercase;
	}
	.secondary_title {
		@extend .h5_serif;
		color: color(blue, mid);
		margin: 1em 0;
		text-align: center;
		text-transform: none;
		font-style: italic;
		font-size: 24px;
		@include breakpoint(desktop) {
			font-size: 28px;
		}
	}
}

.promos {
	min-height: 0;
	padding-top: 0;
	padding-right: 0;
	padding-left: 0;
	ul { 
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			border-bottom: solid 1px color(gray, xx-light);
			&:first-child {
				border-top: solid 1px color(gray, xx-light);
			}
			a {
				&:hover {
				        background-color:#f8f8f8;
						h3 {
							color:#0091b9;
						}
				}
				color: color(gray);
				display: block;
				text-decoration: none;
				padding: 1.2em 0;
				padding-right: calc(1.2em + 10px);
				background-image: url("../img/carat-right-blue.svg");
				background-size: 10px;
				background-position: right center;
				background-repeat: no-repeat;
				@include breakpoint(mobile-l) {
					padding: 1.2em;
					padding-right: calc(2.4em + 10px);
					background-position: right 1.2em center;
				}
			}
			h3 {
				@extend .h5_sans;
				margin: 0 0 5px;
				line-height: 1.2em;
				@include breakpoint(tablet-l) {
					float: left;
					margin: 0;
					max-width: 50%;
				}
			}
			label {
				@include font_size(fs_p_note);
				color: color(gray);
				display: block;
				letter-spacing: 1px;
				text-decoration: none;
				text-transform: uppercase;
				@include breakpoint(tablet-l) {
					float: left;
					margin-left: 15px;
					font-weight: bold;
					max-width: calc(50% - 15px);
				}
				span {
					display: inline-block;
					@include breakpoint(tablet-l) {
						background-color: rgba(0,0,0,.05);
						padding: 3px .7em;
					}
					@include breakpoint(desktop) {
						padding: .4em .7em;
					}
				}
				.for {
					white-space: nowrap;
				}
				.expire {
					color: color(blue, mid);
					white-space: nowrap;
				}
				.slash {
					padding-right: 1em;
					padding-left: 1em;
					@include breakpoint(tablet-l) {
						padding-right: .7em;
						padding-left: .7em;
					}
				}
			}
			p {
				@extend .p_extra;
				margin: .5em 0 0;
				clear: both;
				color: #6b6b6b;
				@include breakpoint(tablet-l) {
					margin: .8em 0 0;
			}
			}
		}
	}
}


