.trueconfidence {
	background-color: color(white);
	overflow: hidden;
	section {
		@include section_reset;
		overflow: visible;
	}
	.tc_copy {
		color: color(gray, light);
		strong {
			color: color(gray, dark);
		}
	}
	.tc_image {
		background-repeat: no-repeat;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}
}

.tc_billboard {
	position: relative;
	z-index: 2;
	@include breakpoint(tablet) {
		box-shadow: 0 10px 25px -10px color(gray, xx-light);
	}
	.content_wrapper {
		padding-bottom: 60px;
		padding-top: 40px;
		position: relative;
		z-index: 3;
		@include breakpoint(desktop) {
			padding-bottom: 80px;
			padding-top: 60px;
		}
	}
	.tc_copy {
		margin-bottom: 40px;
		max-width: 32em;
	}
	.tc_image {
		background-image: url('../img/trueconfidence/trueconfidence-bg.jpg');
		background-position: top left;
		background-size: cover;
		@include breakpoint(tablet) {
			background-position: top right;
			background-size: 70% auto;
			background-size: contain;
		}
		@include breakpoint(desktop-xl) {
			background-size: cover;
		}
	}
	.title_global {
		@include font_size(fs_h1_small);
		margin-bottom: 40px;
		max-width: 800px;
	}
}

.tc_body {
	margin-bottom: 40px;
	position: relative;
	@include breakpoint(tablet) {
		margin-bottom: 60px;
	}
	.content_wrapper {
		position: relative;
		z-index: 2;
		@include breakpoint(tablet) {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row-reverse;
			justify-content: space-between;
			margin-bottom: -6vw;
			transform: translate3d(0, -6vw, 0);
		}
	}
	.tc_image {
		background-image: url('../img/trueconfidence/trueconfidence-body-bg.jpg');
		background-position: top left;
		background-size: auto;
		display: none;
		max-width: width(max-width);
		z-index: 1;
		@include breakpoint(tablet) {
			background-size: 70% auto;
			display: block;
		}
	}
	.tc_prizes,
	.tc_social,
	.tc_video {
		@include breakpoint(zero, tablet) {
			margin-left: auto;
			margin-right: auto;
			max-width: 400px;
		}
		@include breakpoint(tablet) {
			flex-basis: calc(50% - 30px);
		}
		@include breakpoint(desktop) {
			flex-basis: calc(50% - 60px);
		}
	}
	.tc_prizes {
		position: relative;
		z-index: 4;
		@include breakpoint(tablet) {
			order: 3;
		}
		.prize_list {
			display: flex;
			margin: 0;
			padding: 0;
			@include breakpoint(zero, mobile-l) {
				display: block;
			}
			li {
				flex-basis: calc(50% - 10px);
				list-style: none;
				margin-right: 20px;
				@include breakpoint(zero, mobile-l) {
					flex-basis: auto;
					margin-bottom: 40px;
					margin-right: 0;
				}
				&:nth-child(2n + 2) {
					margin-right: 0;
				}
				.title_small {
					margin-bottom: 0;
				}
			}
			.tc_copy {
				margin: 0;
			}
		}
		.prize_thumb {
			@include constrain-proportions(100%, 100%);
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			margin-bottom: 20px;
			position: relative;
			width: 100%;
			z-index: 2;
		}
		.title_small_hr_container {
			margin-bottom: 20px;
		}
	}
	.tc_social {
		margin-bottom: 60px;
		position: relative;
		z-index: 4;
		@include breakpoint(tablet) {
			align-self: flex-end;
			margin-bottom: 0;
			order: 2;
			transform: translate3d(0, 50%, 0);
		}
		.tc_copy {
			max-width: 32em;
		}
	}
	.tc_video {
		margin-bottom: 60px;
		position: relative;
		z-index: 4;
		@include breakpoint(tablet) {
			order: 1;
		}
		@include breakpoint(desktop) {
			margin-bottom: 120px;
		}
		.tc_video_thumb {
			@include constrain-proportions(100%, 57%);
			background-image: url('../img/trueconfidence/video-thumb.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			cursor: pointer;
			flex-basis: 100%;
			margin-bottom: 40px;
			position: relative;
			z-index: 2;
			&::after {
				background-image: url('../img/recognition/shadow-bottom.png');
				background-position: top center;
				background-repeat: no-repeat;
				background-size: contain;
				bottom: 0;
				content: '';
				height: 100%;
				max-width: 300px;
				position: absolute;
				right: 0;
				transform: translate3d(0, 100%, 0) scale(-1, 1);
				width: 100%;
			}
		}
		.title_global {
			line-height: 1em;
			span {
				display: inline;
			}
		}
		.video_overlay {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			&:after {
				background-image: url('../img/trueconfidence/play-button.png');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				bottom: 0;
				content: '';
				height: 75px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				transition: transform .2s easing(ease-out-quart);
				width: 75px;
			}
			&:hover {
				&:after {
					transform: scale(1.1);
					transition-timing-function: easing(ease-in-quart);
				}
			}
		}
	}
	.title_global {
		@include font_size(fs_h3);
	}
}

.tc_legal {
	margin-bottom: 60px;
	@include breakpoint(tablet) {
		margin-bottom: 100px;
	}
	p {
		@extend .fs_legal;
	}
	.tc_title {
		color: color(gray, dark);
		letter-spacing: .075em;
		margin-bottom: 10px;
		margin-top: 20px;
		text-transform: uppercase;
	}
}

.tc_rules {
	margin-bottom: 50px;
	.content_wrapper_narrow {
		border-bottom: 1px solid color(gray, x-light);
		border-top: 1px solid color(gray, x-light);
		padding-bottom: 20px;
		padding-top: 40px;
		@include breakpoint(tablet) {
			padding-top: 60px;
		}
	}
	.tc_copy {
		@include font_size(fs_p_note_extra);
	}
	.tc_rules_list {
		margin: 0;
		padding: 0;
		@include breakpoint(tablet) {
			display: flex;
			flex-wrap: wrap;
		}
		li {
			list-style: none;
			margin-bottom: 20px;
			@include breakpoint(tablet) {
				flex-basis: calc(50% - 30px);
				margin-right: 60px;
			}
			@include breakpoint(desktop) {
				flex-basis: calc(50% - 50px);
				margin-right: 100px;
			};
			&:nth-child(2n + 2) {
				margin-right: 0;
			}
		}
	}
}

.tc_social_list {
	display: flex;
	margin: 0;
	margin-bottom: 20px;
	padding: 0;
	a {
		height: 15px;
		width: 15px;
		@include breakpoint(tablet) {
			height: 25px;
			width: 25px;
		}
		&:hover {
			animation: flash .2s easing(ease-out-back);
		}
	}
	svg {
		fill: color(black, near);
		max-height: 100%;
		max-width: 100%;
		transition: fill .2s easing(ease-out-quad);
		&:hover {
			fill: color(blue);
		}
	}
	li {
		align-items: center;
		display: flex;
		height: 15px;
		list-style: none;
		margin-right: 20px;
		width: 15px;
		@include breakpoint(tablet) {
			height: 25px;
			width: 25px;
		}
	}
}

.tc_banner_container {
	background-color: color(white);
	padding-bottom: 40px;
	padding-top: 20px;
	position: relative;
	z-index: 1;
	@include breakpoint(tablet) {
		padding-bottom: 60px;
	}
	.content_wrapper {
		position: relative;
		&::after {
			background-image: url(../img/recognition/tile-shadow.png);
			background-position: top left;
			background-repeat: no-repeat;
			background-size: 40% auto;
			content: '';
			height: 100%;
			left: 0;
			opacity: .5;
			pointer-events: none;
			position: absolute;
			top: 0;
			transform: translate3d(0, 100%, 0) scale(-1, 1);
			width: 100%;
		}
	}
	.tc_banner {
		height: auto;
		width: 100%;
		&__desktop {
			display: none;
			@include breakpoint(tablet) {
				display: inline;
			}
		}
		&__mobile {
			display: inline;
			@include breakpoint(tablet) {
				display: none;
			}
		}
	}
}
