.billboard_hero_xl {
	@include section_reset;
	margin-bottom: 60px;
	overflow: visible;
	padding-top: 110px;
	position: relative;
	@include breakpoint(tablet) {
		padding-top: 175px;
	}
	.billboard_app_list {
		display: flex;
		justify-content: center;
		margin-bottom: 0;
		margin-top: 30px;
		padding-left: 0;
		.app {
			list-style: none;
			margin-left: 5px;
			margin-right: 5px;
		}
	}
	.bg {
		background-image: url('../img/app-page/mountains-seamless.jpg');
		background-position: bottom center;
		background-repeat: repeat-x;
		background-size: auto 100%;
		min-height: calc(100% - 10px);
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		width: 100%;
		z-index: -1;
		&::after {
			background-image: linear-gradient(#e6e7e9, color(white));
			content: '';
			position: absolute;
			bottom: 0;
			height: 375px;
			transform: translate3d(0, 99%, 0);
			width: 100%;
		}
	}
	.content_container {
		background-color: transparent;
		color: #e5f1ff;
		position: relative;
		text-align: center;
		text-shadow: 0 2px 0 rgba(15, 0, 51, .35);
		z-index: 1;
		@include breakpoint(mobile-xl, desktop) {
			margin-left: auto;
			margin-right: auto;
			max-width: 63vw;
		}
		.cta {
			border-bottom: 0;
			color: color(white);
			font-weight: 400;
			&:hover {
				color: color(white, off);
			}
		}
		.heading {
			@include font_size(fs_h2);
			line-height: .9em;
			margin-bottom: .5em;
			text-transform: uppercase;
			@include breakpoint(zero, mobile-xl) {
				font-size: 7vw;
			}
		}
		.p {
			@include font_size(fs_h5);
			font-weight: 100;
			line-height: 1.3em;
		}
	}
	.image {
		width: 80px;
		@include breakpoint(tablet) {
			width: 155px;
		}
	}
	.image_cards {
		height: 330px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 250px;
		position: relative;
		width: 280px;
		@include breakpoint(mobile-xl) {
			width: calc(100% - 40px);
		}
		.card_1,
		.card_2,
		.card_3 {
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			@include breakpoint(zero, mobile-xl) {
				width: 450px;
			}
			.img {
				background-position: top center;
				background-repeat: no-repeat;
				background-size: contain;
				bottom: 0;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		.card_1 {
			z-index: 3;
		}
		.card_2 {
			z-index: 2;
		}
		.card_3 {
			z-index: 1;
			@include breakpoint(zero, mobile-xl) {
				display: none;
			}
		}
	}
	.play_link {
		position: relative;
		&::after {
			background-image: url('../img/recognition/play-button.png');
			background-position: center;
			background-size: contain;
			content: '';
			display: inline-block;
			height: 25px;
			margin-bottom: -7px;
			margin-left: 10px;
			width: 25px;
			@include breakpoint(zero, mobile-xl) {
				margin-top: 10px;
			}
		}
	}
}
