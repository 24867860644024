// Physiq Weight Loss Contest ()


// Common elements that need to be broken out
.form_width {
	max-width:600px;
}
.font_serif_new {font-family:'Quarto A', 'Quarto B', Georgia, serif;}
.lv_form {
	.structure {
		list-style-type:none;
		margin:0;
		padding:0;
		display:flex;
		flex-flow:row wrap;
		justify-content:space-between;
	}
	li {
		width:100%;
		box-sizing:border-box;
		margin-bottom:2em;
	}
	label {
		display:block;
		font-size:0.875rem;
		letter-spacing:0.1em;
		font-weight:bold;
		color:color(blue);
		text-transform:uppercase;
	}
	input, select, textarea {
		&:not([type=submit]):not([type=checkbox]) {
			appearance:none;
			width:100%;
			height:2.8rem;
			line-height:1;
			font-size:1rem;
			padding:.7rem 1em;
			background-color:transparent;
			border:1px solid rgba(0,0,0,0.3);
			border-radius:0;
			box-shadow:none;
			box-sizing: border-box;
			&:active, &:focus {
				border-color:color(blue, light);
			}
		}
	}
	.select {
		position:relative;
		&:after {
			position:absolute;
			right:10px;
			top:50%;
			display:block;
			content:"";
			width:12px;
			height:12px;
			margin-top:-6px;
			padding-left:0;
			pointer-events: none;
			background:url('../img/products/physiq/contest/icon-carat-down.svg') right center no-repeat;
		}
	}
	.error {
		label {
			color:#f00;
		}
		input, select, textarea {
			&:not([type=submit]):not([type=checkbox]) {
				border-color:#f00;
			}
		}
	}
	.sm {
		width:50%;
		&.left {
			padding-right:10px;
		}
//		&.middle {
//			padding-left:10px;
//		}
		&.right {
			padding-right:10px;
		}
		@include breakpoint(mobile-xl) {
			float:left;
			width:33.333%;
			&.left {
				padding-right:20px;
			}
			&.middle {
				padding-right:10px; padding-left:10px;
			}
			&.right {
				padding-left:20px;
			}
		}
	}
	.md {
		width:100%;
		&.button {
			margin-top:1em;
		}
		@include breakpoint(mobile-xl) {
			float:left;
			width:50%;
			&.left {
				padding-right:20px;
			}
			&.right {
				padding-left:20px;
			}
		}
	}
	.lg {
		width:50%;
		@include breakpoint(mobile-xl) {
			float:left;
			width:66.666%;
			flex:1 0;
			&.left {
				padding-right:10px;
			}
			&.right {
				padding-left:10px;
			}
		}
	}
	.btn_new {
		width:100%;
	}
	.forgot {
		a {
			margin-top:3rem;
			letter-spacing:0.1em;
			font-size:.875rem;
	//		text-transform:uppercase;
		}
	}
}
// .btn_new {
// 	position:relative;
// 	display:moz-inline-box;
// 	display:inline-block;
// 	zoom:1; *display:inline; /* Internet Exploder */
// 	text-align:center;
// 	font-size:.875rem;
// 	padding:17px 1.5em 16px;
// 	line-height:1;
// 	letter-spacing:0.15em;
// 	font-weight:bold;
// 	text-transform:uppercase;
// 	font-family:"Proxima Nova W01", "Helvetica Neue", Helvetica, Arial, sans-serif;
// 	text-decoration:none;
// 	white-space:nowrap;
// 	border-radius:0;
// 	border:0 none;
// 	box-shadow:0 none;
// 	box-sizing:border-box;
// 	transition:background-color 0.3s, color 0.3s, top 0s;
// 	@include clip-path(polygon(0 0, 100% 0, 100% 60%, 95% 100%, 0 100%));
// 	@include breakpoint(mobile-xl) {
// 		@include clip-path(polygon(0 0, 100% 0, 100% 63%, 93% 100%, 0 100%));
// 	}
// 	&.blue {
// 		background-color:color(blue);
// 		color:color(white);
// 		&:hover {
// 			background-color:color(blue, light);
// 		}
// 		&:active {
// 			background-color:color(gray, light);
// 		}
// 	}
// }

// Everything Weight Loss Contest
.physiq {
	.contest_banner {
		min-height:0;
		padding-top:2.25em;
		padding-bottom:2.25em;
		background-color:#f4f4f4;
		background:#f4f4f4
			url('../img/products/physiq/contest/molecular-pattern-left.png') left bottom no-repeat,
			url('../img/products/physiq/contest/molecular-pattern-right.png') 330px bottom no-repeat;
			box-shadow:inset 0 -6px 6px -6px rgba(0,0,0,0.2);
		@include breakpoint(mobile) {
			padding-top:3em;
			padding-bottom:3em;
		}
		@include breakpoint(tablet-l) {
			padding-top:5em;
			padding-bottom:5em;
		}
		@include breakpoint(desktop-xl) {
			background-position: left bottom, right bottom;
		}
		img {
			width:190px;
			height:auto;
			margin-bottom:-20px;
			@include breakpoint(mobile) {
				width:230px;
			}
			@include breakpoint(tablet-s) {
				width:272px;
			}
		}
		.descriptor {
			margin-top:4em;
			color: color(blue, light);
			font-weight:bold;
			letter-spacing:0.15em;
			line-height:1em;
			@include breakpoint(mobile) {
				margin-top:5.25em;
			}
			@include breakpoint(tablet-s) {
				margin-top:0em;
				line-height:52px;
			}
		}
	}
	.contest {
		padding-top:5em;
		padding-bottom:5em;
		letter-spacing:0.02em;
		line-height:1.8;
		.account_bar {
			position:absolute;
			left:0;
			top:0;
			width:100%;
			box-shadow:0 6px 6px -6px rgba(0,0,0,0.1);
			.quick_nav {
				margin-left:0;
			}
			.item>a {
				display:block;
				min-width:180px;
				padding:0 2em;
				text-align:right;
				line-height:4em;
				color:#0091b9;
				letter-spacing:.15em;
				font-weight:bold;
				text-decoration:none;
				.login {

				}
				.category {
					&:after {
						display:inline-block;
						content:"";
						width:.65em;
						height:.65em;
						padding-left:1.5em;
						background:url('../img/products/physiq/contest/icon-carat-down.svg') right center no-repeat;
					}
				}
			}
		}
		h2 {
			position:relative;
			margin-bottom:4em;
			font-size:1.875rem;
			letter-spacing:.015em;
			text-transform:none;
			&:after {
				border-top: 1px solid #000;
				content: '';
				left: 0;
				position: absolute;
				bottom: -2em;
				width: 1em
			}
		}
		h3 {
			font-size:1.5rem;
			letter-spacing:.1em;
			line-height:1.4;
		}
		h4 {
			font-size:0.875rem;
			letter-spacing:0.1em;
			text-transform:uppercase;
			color:color(blue);
		}
		.numbered_list {
			padding-left:2em;
			list-style-type:none;
			line-height:1.4;
			li {
				position:relative;
				margin-bottom:.65em;
				counter-increment:plain_numbers;
				&:before {position:absolute; left:-2em; width:2ex; text-align:center; content:counter(plain_numbers) "";}
			}
		}
		.structure.measure_list {
			justify-content:flex-start;
			li.sm {
				&:nth-child(2n+1) {
					padding-right:20px;
				}
				&:nth-child(2n+2) {
					padding-left:20px;
				}
				@include breakpoint(mobile-xl) {
					&:nth-child(3n+1) {
						padding-right:20px;
						padding-left:0px;
					}
					&:nth-child(3n+2) {
						padding-right:10px;
						padding-left:10px;
					}
					&:nth-child(3n+3) {
						padding-right:0px;
						padding-left:20px;
					}
				}
			}
		}
		.data_list {
			margin:0;
			padding:0;
			line-height:1.4;
			list-style-type:none;
			color:color(black);
			li {
				margin-bottom:.75em;
				&:last-child {margin-bottom:0;}
			}
			label {
				float:left;
				display:inline-block;
				width:8em;
				color:color(gray, light);
				@include breakpoint(mobile-l) {
					width:12em;
				}
			}
		}
		.measurements_list {
			list-style-type:none;
			margin:0;
			padding:0;
			li {
				a {
					display:block;
					padding:1.5em 1em 1.5em 0;
					line-height:1.6;
					border-bottom:1px solid color(gray, x-light);
					&:hover {
						background-color:rgba(0,0,0,0.02);
					}
					h4 {
						color:color(gray, light);
						font-weight:normal;
					}
				}
			}

		}
		.arrow_link_blue {
			&.small {
				color: color(blue, light);
//				letter-spacing:.05em;
				text-decoration:none;
			}
			&.header_link {
				margin-bottom:3em;
				margin-left:4em;
				@include breakpoint(mobile-xl) {
					margin-bottom:0;
				}
			}
		}
		.control {
			&.add {
				display:block;
				margin-top:2em;
				line-height:5em;
				&:before {
					display:inline-block;
					content:"";
					width:1em;
					height:1em;
					padding-right:1.5em;
					background:url('../img/products/physiq/contest/icon-add.svg') left center no-repeat;
				}
			}
		}
		.images {
			div {
				float:left;
				width:50%;
				box-sizing:border-box;
				&:nth-child(1) {
					padding-right:3px;
				}
				&:nth-child(2) {
					padding-left:3px;
				}
			}
		}
		.terms_cond {
			max-height:15em;
			padding:1.5em 2em;
			font-size:.75rem;
			overflow:scroll;
			border:1px solid rgba(0,0,0,0.1);
			h5 {
				margin:0;
				font-size:.75rem;
			}
			p:last-child {
				margin-bottom:0;
			}
		}
		.terms_check {
			label {
				display:inline;
			}
		}
	}
}
