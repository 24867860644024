.tile_list_container {
	$gray: #878f99;
	margin-bottom: 30px;
	@include breakpoint(tablet) {
		margin-bottom: 60px;
	}
	@include section_reset;
	.list_title {
		@include font_size(fs_p_note_extra);
		color: $gray;
		font-weight: 700;
		letter-spacing: .15em;
		margin-bottom: 20px;
		text-transform: uppercase;
	}
}

.tile_list {
	$gray: #878f99;
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin-bottom: 0;
	margin-top: 0;
	padding-left: 0;
	&__logos {
		margin-bottom: 25px;
		.logo {
			@include constrain-proportions(100%, 60%);
			background-color: #f2f4f5;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			margin-bottom: 10px;
		}
		.tile_title {
			@include font_size(fs_p);
			color: $gray;
			font-weight: 100;
		}
	}
	&__profiles {
		.image {
			@include constrain-proportions(100%, 130%);
			background-color: #f2f4f5;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			margin-bottom: 10px;
			position: relative;
			&:hover {
				.overlay {
					opacity: 1;
				}
			}
		}
		.overlay {
			@include font_size(fs_p);
			background-color: rgba(0, 0, 0, 0.8);
			box-sizing: border-box;
			color: color(white);
			height: 100%;
			left: 0;
			opacity: 0;
			padding: 10px 15px;
			position: absolute;
			top: 0;
			transition: opacity .1s ease-out;
			width: 100%;
		}
		.tile_subtitle {
			@include font_size(fs_p);
			color: $gray;
			font-weight: 100;
		}
		.tile_title {
			@include font_size(fs_p);
			color: #2f3133;
		}
	}
	.tile {
		@include breakpoint(zero, mobile-xl) {
			margin-bottom: 40px;
			margin-right: 20px;
			width: calc(50% - 10px);
			&:nth-child(2n + 2) {
				margin-right: 0;
			}
		}
		@include breakpoint(mobile-xl, tablet-l) {
			margin-bottom: 40px;
			margin-right: 20px;
			width: calc(33% - 13px);
			&:nth-child(3n + 3) {
				margin-right: 0;
			}
		}
		@include breakpoint(tablet-l, desktop) {
			margin-bottom: 40px;
			margin-right: 20px;
			width: calc(25% - 20px);
			&:nth-child(4n + 4) {
				margin-right: 0;
			}
		}
		@include breakpoint(desktop) {
			margin-bottom: 54px;
			margin-right: 27px;
			width: 170px;
			&:nth-child(5n + 5) {
				margin-right: 0;
			}
		}
	}
}
