.modal {
	background: color(white);
	box-sizing: border-box;
	margin: 0 auto;
	padding: 20px;
	position: relative;
	@include breakpoint(tablet-s) {
		max-width: 440px;
		padding: 40px;
	}
	fieldset {
		margin-bottom: 0;
	}
	input[name=protect] {
		display: none;
	}
	.controls {
		margin-top: 2em;
		text-align: center;
		a.block {
			display: block;
		}
		a.block,
		button {
			cursor: pointer;
		}
		.btn_discretionary {
			margin-bottom: 2.5em;
		}
		.modal_body_small {
			margin-bottom: 0;
			margin-top: 2em;
		}
	}
	.dash_under {
		position: relative;
		&:after {
			background-color: color(gray, xx-light);
			content: '';
			position: absolute;
			bottom: -20px;
			left: 0;
			height: 3px;
			width: 44px;
		}
		&--center {
			&:after {
				left: 0;
				right: 0;
				margin: auto;
			}
		}
	}
	.hide {
		display: none;
	}
	legend {
		padding-bottom: 20px;
	}
	.message {
		margin: 0 0 20px;
	}
	.mfp-close {
		@include tir;
		position: absolute;
		right: 0;
		top: 5px;
		&:after {
			@include svg-background;
			background-image: svg(home-close-dark);
			content: '';
			display: block;
			height: height(pre-header);
			left: 0;
			margin: auto;
			opacity: .4;
			position: absolute;
			right: 0;
			top: 0;
			transition: all .2s easing(ease-in-out-quad);
			width: 30px;
		}
	}
	.modal_list {
		@include font_size(fs_p);
		background-color: #f5f7fa;
		margin-bottom: 2em;
		margin-left: -20px;
		margin-right: -20px;
		margin-top: 2em;
		padding: 35px 40px 35px 60px;
		text-align: left;
		@include breakpoint(tablet-s) {
			margin-left: -40px;
			margin-right: -40px;
		}
	}
	.modal_title {
		// hidden and kept for SEO
		display: none;
		color: color(gray);
		font-size: 14px;
		margin-bottom: 1em;
		text-align: center;
	}
}

.modal_body {
	@include font_size(fs_p);
	text-align: center;
	.market {
		font-weight: 600;
	}
}
.modal_body_small {
	@include font_size(fs_p_note_extra);
	margin-bottom: 3em;
	margin-top: 1.5em;
	text-align: center;
}
.modal_header {
	text-align: center;
	.logo_mark_two_color {
		width: 70px;
	}
}
.modal_heading {
	@include font_size(fs_p_extra);
	color: color(blue);
	margin-top: 0;
	margin-bottom: 1.75em;
	text-align: center;
}

.modal.market {
	padding-bottom: 20px;
	&::after {
		background-image: linear-gradient(to bottom, rgba(228, 230, 234, 1), rgba(235, 238, 242, 0));
		bottom: 0;
		content: '';
		height: 5px;
		margin-left: auto;
		margin-right: auto;
		max-width: 430px;
		position: absolute;
		left: 0;
		right: 0;
		transform: translate3d(0, 100%, 0);
		width: 100%;
		z-index: 1;
	}
	&[data-state="wrongMarket"] {
		.wrong_market_slide {
			display: block;
		}
	}
	&[data-state="marketSelector"] {
		.market_selector_slide {
			display: block;
		}
	}
	.wrong_market_slide,
	.market_selector_slide {
		display: none;
	}
}

.modal.sponsor {
	text-align: center;
	padding-bottom: 30px;
	@include breakpoint(tablet-s) {
		max-width: 440px;
		padding-bottom: 60px;
	}
	&[data-state="search"] {
		.sponsor_modal_slide__1,
		.search_form {
			display: block;
		}
	}
	&[data-state="hasResults"] {
		.btn_new {
			@extend .btn_new__stroked;
		}
		.sponsor_modal_slide__1,
		.search_has_results {
			display: block;
		}
	}
	&[data-state="noResults"] {
		.sponsor_modal_slide__1,
		.search_no_results {
			display: block;
		}
	}
	&[data-state="noSponsor"] {
		.sponsor_modal_slide__2,
		.assign_sponsor {
			display: block;
		}
	}
	.assign_sponsor,
	.search_form,
	.search_has_results,
	.search_no_results,
	.sponsor_modal_slide__1,
	.sponsor_modal_slide__2 {
		display: none;
	}
	.sponsor_modal_slide {
		display: none;
	}
}

.modal.sponsor_success {
	padding-top: 0;
}
