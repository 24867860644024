// product category detail (pcd)

.pcd_billboard {
	background-color: color(white);
	color: color(white);
	&.bundles {
		background-color: #7a91a1;
		.full_bg {
			background-image: url(../img/products/pdp-billboard-bg-cat-bundles.jpg);
			background-position: 65% bottom;
		}
	}
	&.protandim {
		background-color: #7a91a1;
		.full_bg {
			background-image: url(../img/products/pdp-billboard-bg-cat-protandim.jpg);
			background-position: 65% bottom;
		}
	}
	&.axio {
		background-color: #aab7bc;
		padding-bottom: 0;
		padding-top: 0;
		.full_bg {
			background-image: url(../img/products/pdp-billboard-bg-cat-axio.jpg);
			background-position: 65% bottom;
		}
	}
	&.physiq {
		background-color: #7a91a1;
		.full_bg {
			background-image: url(../img/products/pdp-billboard-bg-cat-physiq.jpg);
			background-position: 65% bottom;
		}
	}
	&.truescience {
		.full_bg {
			background-image: url(../img/products/pdp-billboard-bg-cat-truescience.jpg);
			background-position: 65% bottom;
			.jp & {
				background-image: url(../../lifevantage-jp/img/products/pdp-billboard-bg-cat-truescience.jpg);
			}
		}
	}
	&.canine {
		background-color: #7a91a1;
		.full_bg {
			background-image: url(../img/products/pdp-billboard-bg-cat-canine.jpg);
			background-position: 65% bottom;
		}
	}
	p {
		@extend .h5_serif;
	}
	.about {
		width: 80%;
		@include breakpoint(tablet) {
			width: 60%;
		}
		@include breakpoint(desktop-l) {
			width: 45%;
		}
	}
	.bottom_rule {
		&:after {
			background-color: color(white);
		}
	}
	.full_bg {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.logo {
		width: 80%;
		@include nav-full {
			width: 60%;
		}
		@include vertical-breakpoint(0, 480px, false) {
			width: 50%;
		}
		img {
			width: 100%;
		}
	}
	.shadow {
		text-shadow: 0px 0px 23px rgba(1, 1, 1, 0.6);
	}
	.title {
		@extend .h3_serif;
		margin-top: .5em;
		text-transform: none;
		@include breakpoint(zero, mobile) {
			max-width: 10em;
		}
	}
}

.product_tiles {
	@include section_reset;
	@include clearfix;
	@include vertical-pad;
	background: color(white);
	position: relative;
	z-index: z-index(above-billboard);
	.cta {
		background-color: color(blue);
		bottom: 0;
		font-weight: bold;
		letter-spacing: 1.5px;
		padding: 15px 30px;
		position: absolute;
		text-transform: uppercase;
		width: 100%;
	}
	.coming_soon {
		bottom: 0;
		height: 160px;
		position: absolute;
		right: 0;
		width: 160px;
		z-index: 2;
		img {
			height: 160px;
			width: 160px;
		}
	}
	.description {
		@extend .h5_serif;
		display: none;
		@include breakpoint(desktop) {
			display: block;
		}
	}
	.header {
		margin-bottom: 0;
	}
	.more_info {
		left: 0;
		position: absolute;
		top: 0;
		z-index: 1;
		@include gutter-s {
			padding: width(gutter-outer-s) * 1.5;
		}
		@include gutter-l {
			padding: width(gutter-inner-l) * 1.5;
		}
		@include breakpoint(desktop) {
			padding: width(gutter-inner-l) * 2;
		}
	}
	.overlay {
		background-color: color(overlay);
		color: color(white);
		height: 100%;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		transform: translate3d(0, 100%, 0);
		transition: all .4s easing(ease-out-quint);
		width: 100%;
	}
	.page_title {
		@include font_size(fs_h2);
		color: color(blue);
		text-transform: uppercase;
		&.bottom_rule:after {
			margin-bottom: 35px;
			margin-top: 20px;
		}
	}
	.product_image {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: -webkit-filter .4s ease-out;
		z-index: 0;
		img {
			height: 100%;
			width: 100%;
		}
	}
	.product_name {
		position: absolute;
		transform: translate3d(0, 0, 0);
		transition: all .2s ease-out;
		width:100%;
		box-sizing:border-box;
		@include gutter-s {
			bottom: width(gutter-outer-s);
			padding: 0 width(gutter-outer-s);
		}
		@include gutter-l {
			bottom: width(gutter-inner-l) * 2;
			padding: 0 (width(gutter-inner-l) * 2);
		}
		.header {
			@include font_size(fs_h4);
			color: color(blue);
			text-transform: none;
		}
		.pre_header {
			@include font_size(fs_p);
			text-transform:uppercase;
			color: color(gray, dark);
		}
	}
	.product_tile {
		@extend %border_box;
		transform: translate3d(0, 0, 0);
		@include gutter-s {
			margin-bottom: width(gutter-outer-s);
		}
		@include gutter-l {
			margin-bottom: width(gutter-inner-l);
		}
		@include breakpoint(tablet-s) {
			width: 50%;
			width: calc(50% - #{ width(gutter-inner-l) / 2 });
			&:nth-of-type(odd) {
				clear: both;
				float: left;
			}
			&:nth-of-type(even) {
				float: right;
			}
		}
		&.active, &:hover {
			.overlay {
				pointer-events: auto;
				transform: translate3d(0, 0, 0);
				transition-delay: .1s;
			}
			.product_image {
				-webkit-filter: blur(10px);
				transition-delay: .2s;
			}
			.product_name {
				transform: translate3d(0, 100px, 0);
			}
		}
		&:first-of-type {
			.wrapper {
                @extend %clip_path_first;
			}
		}
		&:last-of-type {
			.wrapper {
                @extend %clip_path_last;
			}
		}
	}
	.pricing {
		@include font_size(fs_h5);
		color: color(white);
		display: none; // @temp until new store launches
		letter-spacing: .1em;
		position: absolute;
		text-transform: uppercase;
		@include gutter-s {
			bottom: width(gutter-outer-s) * 1.5;
			left: width(gutter-outer-s) * 1.5;
			right: width(gutter-outer-s) * 1.5;
		}
		@include gutter-l {
			bottom: width(gutter-inner-l) * 1.5;
			left: width(gutter-inner-l) * 1.5;
		}
		@include breakpoint(desktop) {
			bottom: width(gutter-inner-l) * 2;
			left: width(gutter-inner-l) * 2;
		}
		.amount {
			font-weight: bold;
			@include font-smoothing;
		}
		.price {
			@include breakpoint(desktop) {
				display: inline;
				&:first-of-type {
					&:after {
						content: ' / ';
					}
				}
			}
		}
	}
	.size {
		@extend .h4_serif;
	}
	.title {
		@extend %overlay_product_title;
	}
	a.wrapper {
		cursor: pointer;
	}
	.wrapper {
		@extend %wrapper;
		background: #f7f7f7;
	}
}

// Modernizr logic to change copy of overlay on mobile versus desktop
.touch {
	.overlay {
		.click {
			display: none;
		}
	}
}

.no-touch {
	.overlay {
		.tap {
			display: none;
		}
	}
}
