.market_flag {
	&::before {
		background:url('../img/flags/flag-sprite.png?v5.2') no-repeat 0 center;
		background-size: auto 11px;
		content: '';
		display: inline-block;
		height: 11px;
		margin-right: 1em;
		transform: translateY(1px);
		width: 16px;
		overflow: hidden;
	}
	&__bg_image::before {
		background-position: center;
		height: 100%;
		left: 0;
		margin-left: auto;
		margin-right: auto;
		position: absolute;
		right: 0;
		top: 0;
	}
	&.at::before {
		background-position:0px center;
	}
	&.au::before {
		background-position:-25px center;
	}
	&.be::before {
		background-position:-50px center;
	}
	&.ca::before {
		background-position:-75px center;
	}
	&.cn::before {
		background-position:-100px center;
	}
	&.de::before {
		background-position:-125px center;
	}
	&.do::before {
		background-position:-150px center;
	}
	&.es::before {
		background-position:-175px center;
	}
	&.fr::before {
		background-position:-200px center;
	}
	&.uk::before {
		background-position:-225px center;
	}
	&.hk::before {
		background-position:-250px center;
	}
	&.ie::before {
		background-position:-275px center;
	}
	&.it::before {
		background-position:-300px center;
	}
	&.jp::before {
		background-position:-325px center;
	}
	&.mx::before {
		background-position:-350px center;
	}
	&.nl::before {
		background-position:-375px center;
	}
	&.nz::before {
		background-position:-400px center;
	}
	&.th::before {
		background-position:-425px center;
	}
	&.tw::before {
		background-position:-450px center;
	}
	&.us::before {
		background-position:-475px center;
	}
}
