.legacy_page {
	background-color: color(white);
	overflow: hidden;
}

.legacy_billboard {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 50vh;
	margin-bottom: 60px;
	max-height: 700px;
	min-height: 300px;
	@include breakpoint(tablet) {
		margin-bottom: 100px;
	}
	.legacy_billboard_title {
		@include font_size(fs_h2);
		color: color(white);
		font-family: font(serif);
		padding-top: 10vh;
		@include breakpoint(tablet) {
			max-width: 500px;
		}
	}
}

.legacy_billboard,
.legacy_image_grid,
.legacy_gallery_desktop {
	margin-left: auto;
	margin-right: auto;
	max-width: 1500px;
}

.legacy_content_header {
	margin-bottom: 60px;
	.legacy_title_background {
		@include svg-background;
		background-image: svg(legacy);
		height: 90px;
		max-width: 375px;
		@include breakpoint(mobile) {
			height: 140px;
		}
		@include breakpoint(tablet) {
			bottom: 0;
			height: 200px;
			left: 0;
			margin: auto;
			max-width: none;
			opacity: .3;
			position: absolute;
			top: 0;
			width: 120%;
		}
		@include breakpoint(desktop) {
			width: 115%;
		}
	}
	p {
		margin-top: 0;
	}
}

.legacy_copy {
	line-height: 1.8em;
	@include breakpoint(desktop) {
		max-width: 480px;
	}
}

.legacy_cta_photo {
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: 0 0 80px color(gray, x-light);
	box-sizing: border-box;
	margin-bottom: 90px;
	padding: 30px 0;
	@include breakpoint(zero, tablet) {
		box-shadow: 0 0 40px color(gray, x-light);
	}
	@include breakpoint(tablet-l) {
		padding: 60px 0;
	}
	@include breakpoint(narrow-max-width-with-gutters) {
		padding: 100px 40px;
	}
	.legacy_cta_title {
		@include type_reset;
		@include font_size(fs_h1_small);
		font-family: font(sans);
		font-weight: 800;
		margin-top: 30px;
		padding-top: 30px;
		position: relative;
		text-transform: uppercase;
		white-space: nowrap;
		@include font-smoothing;
		line-height: .8em;
		@include breakpoint(mobile-xl) {
			padding-top: 50px;
		}
		@include breakpoint(tablet) {
			padding-right: 20px;
		}
		&:after {
			background-color: color(gray, light);
			content: '';
			height: 2px;
			left: 0;
			position: absolute;
			top: 0;
			width: 60px;
		}
		.light {
			font-weight: 100;
		}
		color: color(gray, dark);
		margin-bottom: .2em;
		text-transform: uppercase;
		// our version of proxima's line heights get wonky, this tries to sort for it
		.light + .name {
			margin-top: .03em;
		}
		// our version of proxima's line heights get wonky, this tries to sort for it
		.name + .light {
			margin-top: -.02em;
		}
		.name {
			font-weight: 900;
			line-height: .8em;
			transform: translateY(.01em); // our version of proxima's line heights get wonky, this tries to sort for it
			sup {
				top:-.7em;
				font-size:50%;
			}
		}
	}
}

.legacy_gallery_desktop {
	display: flex;
	padding: 0 20px;
	@include breakpoint(desktop) {
		padding: 0;
	}
	.legacy_gallery_desktop_column {
		flex: 1;
		&:first-child {
			align-items: flex-end;
			display: none;
			flex-direction: column;
			@include breakpoint(desktop) {
				display: flex;
			}
		}
	}
	.legacy_image {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		height: 100%;
		margin-bottom: 0;
		width: 100%;
		&__small {
			height: 100px;
			width: 100px;
			@include breakpoint(desktop-xl) {
				height: 200px;
				width: 200px;
			}
		}
		&__medium {
			height: 200px;
			max-width: 200px;
			margin-right: 100px;
			@include breakpoint(desktop-xl) {
				height: 300px;
				margin-right: 200px;
				max-width: 300px;
			}
		}
		&__large {
			height: 300px;
			margin-right: 100px;
			max-width: 300px;
			@include breakpoint(desktop-xl) {
				height: 400px;
				margin-right: 200px;
				max-width: 400px;
			}
		}
	}
	.legacy_title {
		margin-top: 60px;
	}
}

.legacy_gallery_mobile {
	display: flex;
	flex-direction: column;
	@include breakpoint(mobile-xl) {
		flex-direction: row;
	}
	.legacy_gallery_row {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		@include breakpoint(mobile-xl, desktop) {
			flex-direction: column;
		}
		&:first-child {
			@include breakpoint(mobile-xl) {
				margin-right: 20px;
				width: 100px;
			}
			@include breakpoint(tablet) {
				width: 150px;
			}
			@include breakpoint(desktop) {
				display: none;
			}
		}
		&:last-child {
			flex: 1;
		}
		.legacy_gallery_column {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			flex-basis: calc(33% - 10px);
			@include breakpoint(mobile-xl) {
				flex-basis: auto;
				margin-bottom: 20px;
			}
			&:last-child {
				margin-bottom: 0;
			}
			&__constrain {
				@include constrain-proportions(100%, 100%);
			}
			&__full {
				flex-basis: auto;
				@include breakpoint(zero, mobile-xl) {
					@include constrain-proportions(100%, 66%);
				}
				@include breakpoint(mobile-xl) {
					flex: 1;
				}
			}
			&__xlarge {
				@include breakpoint(desktop) {
					height: 300px;
				}
				@include breakpoint(desktop-xl) {
					height: 500px;
				}
			}
		}
	}
}

.legacy_row {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@include breakpoint(tablet) {
		flex-direction: row;
	}
	.legacy_column {
		position: relative;
		@include breakpoint(tablet) {
			flex-basis: 50%;
		}
	}
}

.legacy_stats_bar {
	position: relative;
	.legacy_title_background {
		@include svg-background;
		background-image: svg(one-million);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.legacy_stats_copy {
		@include font_size(fs_h3_small);
		box-sizing: border-box;
		font-family: font(serif);
		font-style: italic;
		font-weight: 100;
		padding: 80px 40px 110px;
		position: relative;
		text-align: center;
		z-index: 2;
		@include breakpoint(tablet-l) {
			margin: 0 auto;
			max-width: 75%;
			padding: 140px 0 190px;
		}
	}
}

.legacy_title {
	@include font_size(fs_h5);
	font-weight: 600;
	letter-spacing: .15em;
	text-transform: uppercase;
	width: 100%;
}
