.distributor_search_bar {
	background-color: color(white);
	box-shadow: 0px 5px 14px -4px  rgba(0, 0, 0, 0.15);
	display: flex;
	margin-bottom: 40px;
	position: relative;
	input[type=submit] {
		height: 0 !important;
		left: 0;
		position: absolute;
		top: 0;
		user-select: none;
		visibility: hidden;
		width: 0 !important;
	}
	input[type=text] {
		@include font_size(fs_h4);
		background-color: color(white);
		background-image: svg(search-icon);
		background-repeat: no-repeat;
		background-size: 18px 18px;
		background-position: 21px center;
		border: 0;
		box-shadow: none;
		flex: 1;
		height: 60px;
		padding: 0;
		padding-left: 60px;
		width: 100%;
		&::placeholder {
			@include font_size(fs_h4);
			color: color(gray, x-light);
			padding-top: .25em;
		}
	}
}

.dist_search_message {
	@include font_size(fs_h4);
	margin-top: 4em;
	margin-bottom: 4em;
	text-align: center;
}

.dist_shop_link {
	position: relative;
	&.loading {
		color: transparent;
		.loader {
			opacity: 1;
		}
		.website_icon {
			opacity: 0;
		}
	}
	.loader {
		opacity: 0;
		position: absolute;
		top: 0;
		right: -4px;
		transform: scale(.6) translate3d(0, -60%, 0);
		transition: opacity .2s ease-out;
	}
}

.dist_table {
	.dist_table_column {
		align-items: center;
		box-sizing: border-box;
		display: flex;
		min-height: 50px;
		// the following selectors are in order how they appear, not alphanumeric
		&.dist_avatar_column {
			width: 40px;
			@include breakpoint(tablet-s) {
				justify-content: center;
				width: 70px;
			}
		}
		&.dist_name {
			color: color(gray, dark);
			flex: .8;
			overflow: hidden;
			padding-right: 10px;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		&.dist_location {
			color: color(gray, light);
			display: none;
			// take up all remaining space in the row
			flex: 1;
			overflow: hidden;
			padding-right: 10px;
			text-overflow: ellipsis;
			white-space: nowrap;
			@include breakpoint(tablet-s) {
				display: flex;
			}
		}
		&.dist_flag_column {
			@include breakpoint(tablet-s) {
				flex-basis: 15%;
			}
			@include breakpoint(tablet) {
				flex-basis: 18%;
			}
			.dist_flag {
				background-color: color(blue);
				border-radius: 3px;
				color: color(gray, xxx-light);
				font-size: 12px;
				letter-spacing: .075em;
				line-height: 22px;
				padding: 0 10px;
				text-transform: uppercase;
				&.pro_4 {
					background-color: color(rank_pro_4);
				}
				&.pro_5 {
					background-color: color(rank_pro_5);
				}
				&.pro_6 {
					background-color: color(rank_pro_6);
				}
			}
		}
		&.dist_url {
			display: none;
			justify-content: center;
			width: 50px;
			@include breakpoint(tablet-s) {
				display: flex;
			}
			.compact_tooltip {
				&:after {
					right: 18px;
				}
			}
			.world {
				height: 24px;
				opacity: .5;
				transition: opacity .2s ease-out;
				width: 24px;
				&:hover {
					opacity: 1;
				}
			}
		}
		.dist_avatar {
			align-items: center;
			background-color: color(gray, xx-light);
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			border-radius: 50%;
			color: color(gray, x-dark);
			display: flex;
			font-size: 12px;
			font-weight: 600;
			height: 30px;
			justify-content: center;
			text-transform: uppercase;
			transition: background-color .2s ease-out;
			width: 30px;
		}
	}
	.dist_table_row {
		border-bottom: 1px solid color(gray, xx-light);
		display: flex;
		@include font-smoothing;
		justify-content: space-between;
		position: relative;
		transition: background-color .2s ease-out;
		&.featured {
			.dist_table_featured_link {
				@include breakpoint(tablet-s) {
					display: flex;
					&:hover {
						background-color: rgba(0, 0, 0, .05);
					}
				}
			}
		}
		&:last-child {
			border-bottom: 0;
		}
		.dist_table_featured_link,
		.dist_table_magnific {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
		.dist_table_featured_link {
			display: none;
		}
		.dist_table_magnific {
			display: block;
			@include breakpoint(tablet-s) {
				display: none;
			}
		}
	}
}

.dist_magnific {
	@extend .action_sheet;
	.dist_magnific_avatar {
		align-items: center;
		background-color: color(gray, xx-light);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 50%;
		color: color(gray, x-dark);
		display: flex;
		font-weight: 600;
		height: 50px;
		justify-content: center;
		margin-bottom: 10px;
		margin-left: auto;
		margin-right: auto;
		text-transform: uppercase;
		width: 50px;
	}
	.dist_magnific_btn {
		@extend .magnific_btn;
		.loader {
			bottom: 0;
			left: 0;
			margin: auto;
			opacity: 0;
			position: absolute;
			right: 0;
			top: -20px;
			transition: opacity .2s ease-out;
		}
		&.loading {
			color: transparent;
			.loader {
				opacity: 1;
			}
			&:hover {
				background-color: color(white);
				color: transparent;
			}
		}
	}
	.dist_magnific_avatar_bg {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		filter: blur(15px);
		height: 100%;
		left: 0;
		opacity: .2;
		position: absolute;
		top: 0;
		transform: scale(1.1);
		width: 100%;
		z-index: 1;
	}
	.dist_magnific_info {
		overflow: hidden;
		padding-bottom: 30px;
		padding-top: 30px;
		position: relative;
		&.inner-shadow {
			&:after {
				background-image: url('../img/recognition/inner-shadow.png');
				background-position: bottom center;
				background-repeat: no-repeat;
				background-size: contain;
				bottom: 0;
				content: '';
				height: 100%;
				left: 0;
				opacity: .35;
				position: absolute;
				width: 100%;
			}
		}
	}
	.dist_magnific_stat_list {
		display: flex;
		justify-content: center;
		margin: 0;
		padding: 0;
		z-index: 2;
		.dist_mangific_stat_item {
			list-style: none;
			padding: 0 15px;
			text-align: center;
		}
	}
	.dist_magnific_title {
		color: color(gray, x-dark);
		font-size: 24px;
		margin-bottom: 20px;
		text-align: center;
		z-index: 2;
	}
}

.page_premier {
	.mfp-container {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.recognition_cta {
	@include constrain-proportions(100%, 50%);
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	position: relative;
	width: 100%;
}

.recognition_cta_image {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	&:after {
		background-image: url('../img/recognition/tile-shadow.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: top center;
		bottom: 0;
		content: '';
		height: 200px;
		left: 0;
		opacity: .5;
		pointer-events: none;
		position: absolute;
		transform: translate3d(0, 100%, 0);
		width: 100%;
		@include breakpoint(tablet) {
			opacity: .5;
		}
	}
	&__desktop {
		display: none;
		@include breakpoint(tablet) {
			display: inline;
		}
	}
	&__mobile {
		@include breakpoint(tablet) {
			display: none;
		}
	}
	.recognition_cta_link {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

.recognition_page {
	&__elite,
	&__premier {
		@include breakpoint(zero, tablet-s) {
			.nav_hr {
				display: none;
			}
			.nav_item {
				flex: 1;
				text-align: center;
			}
		}
	}
}

.recognition_page_content {
	background-color: #f5f5f5;
	padding-bottom: 100px;
	position: relative;
	&.loading {
		.dist_wall {
			opacity: 0;
			transform: translate3d(0, 200px, 0);
		}
		.loading_message {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
	.dist_wall {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition: opacity .4s easing(ease-out-quart) .3s, transform .4s easing(ease-out-quart) .3s;
	}
	.content_wrapper_narrow {
		@include breakpoint(desktop) {
			min-height: 250px;
			// min-height: 1000px;
		}
	}
	.recognition_page__master & {
		background: radial-gradient(ellipse at center, rgba(67,72,79,1) 0%,rgba(25,25,28,1) 100%)
	}
	.loading_message {
		left: 0;
		margin-left: auto;
		margin-right: auto;
		opacity: 0;
		padding-top: 125px;
		position: absolute;
		right: 0;
		top: 0;
		transform: translate3d(0, 50px, 0);
		transition: opacity .2s easing(ease-in-quart), transform .2s easing(ease-in-quart);
	}
}

.recognition_pagination {
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
	display: flex;
	font-size: 12px;
	margin: 40px auto 80px;
	width: 240px;
	.recognition_pagination_step {
		align-items: center;
		background-color: color(white);
		color: color(gray, light);
		cursor: pointer;
		display: flex;
		flex: 1;
		height: 40px;
		justify-content: center;
		position: relative;
		user-select: none;
		&.active {
			color: color(blue);
			&:after, &:before {
				width: 50%;
			}
		}
		&:after, &:before {
			background-color: color(blue);
			bottom: 0;
			content: '';
			height: 2px;
			position: absolute;
			transition: all .15s easing(ease-out-back) .1s;
			width: 0;
		}
		&:after {
			left: 50%;
		}
		&:before {
			right: 50%;
		}
		&:hover {
			color: color(blue);
			&:after, &:before {
				width: 50%;
			}
			.chevron {
				fill: color(blue);
			}
		}
		&.chevron_left {
			.chevron {
				transform: rotate(90deg);
			}
		}
		&.chevron_right {
			.chevron {
				transform: rotate(270deg);
			}
		}
		.chevron {
			fill: color(gray, light);
			height: 10px;
			transition: fill .15s easing(ease-out-back) .1s;
			width: 10px;
		}
	}
}
