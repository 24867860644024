/* Default link color */
a,
a:visited,
a:focus {color:#005C8F;}
a:hover,
a:active {color:#00adeb;}

/* Border Colors */
.border_1 {border:1px solid #AAA;}
.border_1_t {border-top:1px solid #AAA;}
.border_1_r {border-right:1px solid #AAA;}
.border_1_b {border-bottom:1px solid #AAA;}
.border_1_l {border-left:1px solid #AAA;}

.border_2 {border:1px solid #DDD;}
.border_2_t {border-top:1px solid #DDD;}
.border_2_r {border-right:1px solid #DDD;}
.border_2_b {border-bottom:1px solid #DDD;}
.border_2_l {border-left:1px solid #DDD;}

/* Success, Error, and Basic Messages */
.status_msg {margin:1em 0; padding:8px 1em; font-size:1em; text-transform:none; line-height:1.3em; text-align:center; border:2px solid #bbb; background-color:#ddd; border-radius:2px;}
.status_msg.success {border-color:#90b35f; background-color:#ebfae1;}
.status_msg.error {border-color:#d41525; background-color:#fad9d4;}

/* Horizontal Rule Colors */
.hr_white_1 {border-color:rgba(255,255,255,0.2);}
.hr_white_2 {border-color:rgba(255,255,255,0.15);}
.hr_white_3 {border-color:rgba(255,255,255,0.1);}

.hr_black_1 {border-color:rgba(0,0,0,0.2);}
.hr_black_2 {border-color:rgba(0,0,0,0.15);}
.hr_black_3 {border-color:rgba(0,0,0,0.1);}

.hr_grad_white_lt,
.hr_grad_white_1,
.hr_grad_white_2,
.hr_grad_black_1,
.hr_grad_black_2 {height:1px; border:0 none;}
.hr_grad_white_lt {
	background: #fff; /* fallback */
	background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.2)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%); /* IE10+ */
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%); /* W3C */
}
.hr_grad_white_1 {
	background: #fff; /* fallback */
	background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 30%, rgba(255,255,255,0.2) 70%, rgba(255,255,255,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(30%,rgba(255,255,255,0.2)), color-stop(70%,rgba(255,255,255,0.2)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.2) 30%,rgba(255,255,255,0.2) 70%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.2) 30%,rgba(255,255,255,0.2) 70%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.2) 30%,rgba(255,255,255,0.2) 70%,rgba(255,255,255,0) 100%); /* IE10+ */
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.2) 30%,rgba(255,255,255,0.2) 70%,rgba(255,255,255,0) 100%); /* W3C */
}
.hr_grad_white_2 {
	background: #fff; /* fallback */
	background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 30%, rgba(255,255,255,0.4) 70%, rgba(255,255,255,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(30%,rgba(255,255,255,0.4)), color-stop(70%,rgba(255,255,255,0.4)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.4) 30%,rgba(255,255,255,0.4) 70%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.4) 30%,rgba(255,255,255,0.4) 70%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.4) 30%,rgba(255,255,255,0.4) 70%,rgba(255,255,255,0) 100%); /* IE10+ */
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.4) 30%,rgba(255,255,255,0.4) 70%,rgba(255,255,255,0) 100%); /* W3C */
}
.hr_grad_black_1 {
	background: #000; /* fallback */
	background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 30%, rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,0,0,0)), color-stop(30%,rgba(0,0,0,0.1)), color-stop(70%,rgba(0,0,0,0.1)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 30%,rgba(0,0,0,0.1) 70%,rgba(0,0,0,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 30%,rgba(0,0,0,0.1) 70%,rgba(0,0,0,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 30%,rgba(0,0,0,0.1) 70%,rgba(0,0,0,0) 100%); /* IE10+ */
	background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 30%,rgba(0,0,0,0.1) 70%,rgba(0,0,0,0) 100%); /* W3C */
}
.hr_grad_black_2 {
	background: #000; /* fallback */
	background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 30%, rgba(0,0,0,0.2) 70%, rgba(0,0,0,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,0,0,0)), color-stop(30%,rgba(0,0,0,0.2)), color-stop(70%,rgba(0,0,0,0.2)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 30%,rgba(0,0,0,0.2) 70%,rgba(0,0,0,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 30%,rgba(0,0,0,0.2) 70%,rgba(0,0,0,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 30%,rgba(0,0,0,0.2) 70%,rgba(0,0,0,0) 100%); /* IE10+ */
	background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 30%,rgba(0,0,0,0.2) 70%,rgba(0,0,0,0) 100%); /* W3C */
}

/* Text Colors */
 .text_white,
a.text_white,
a.text_white:hover {color:#fff;}

 .text_black,
a.text_black,
a.text_black:hover {color:#000;}

 .text_white_50,
a.text_white_50,
a.text_white_50:hover {color:#fff; color:rgba(255,255,255,0.5);}
 .text_white_60,
a.text_white_60,
a.text_white_60:hover {color:#fff; color:rgba(255,255,255,0.6);}
 .text_white_70,
a.text_white_70,
a.text_white_70:hover {color:#fff; color:rgba(255,255,255,0.7);}
 .text_white_80,
a.text_white_80,
a.text_white_80:hover {color:#fff; color:rgba(255,255,255,0.8);}
 .text_white_90,
a.text_white_90,
a.text_white_90:hover {color:#fff; color:rgba(255,255,255,0.9);}

 .text_red,
a.text_red,
a.text_red:hover {color:#ab2e22;}

 .text_gray_1,
a.text_gray_1,
a.text_gray_1:link,
a.text_gray_1:visited,
a.text_gray_1:focus,
a.text_gray_1:hover,
a.text_gray_1:active {color:#585659;}
 .text_gray_2,
a.text_gray_2,
a.text_gray_2:link,
a.text_gray_2:visited,
a.text_gray_2:focus,
a.text_gray_2:hover,
a.text_gray_2:active {color:#939597;}
 .text_gray_3,
a.text_gray_3,
a.text_gray_3:link,
a.text_gray_3:visited,
a.text_gray_3:focus,
a.text_gray_3:hover,
a.text_gray_3:active {color:#D1D2D4;}

 .text_berry_1,
a.text_berry_1,
a.text_berry_1:link,
a.text_berry_1:visited,
a.text_berry_1:focus,
a.text_berry_1:hover,
a.text_berry_1:active {color:#62003F;}
 .text_berry_2,
a.text_berry_2,
a.text_berry_2:link,
a.text_berry_2:visited,
a.text_berry_2:focus,
a.text_berry_2:hover,
a.text_berry_2:active {color:#BB1654;}
 .text_berry_3,
a.text_berry_3,
a.text_berry_3:link,
a.text_berry_3:visited,
a.text_berry_3:focus,
a.text_berry_3:hover,
a.text_berry_3:active {color:#F0DAE3;}

 .text_tangerine_1,
a.text_tangerine_1,
a.text_tangerine_1:link,
a.text_tangerine_1:visited,
a.text_tangerine_1:focus,
a.text_tangerine_1:hover,
a.text_tangerine_1:active {color:#F47E1F;}
 .text_tangerine_2,
a.text_tangerine_2,
a.text_tangerine_2:link,
a.text_tangerine_2:visited,
a.text_tangerine_2:focus,
a.text_tangerine_2:hover,
a.text_tangerine_2:active {color:#FDB813;}
 .text_tangerine_3,
a.text_tangerine_3,
a.text_tangerine_3:link,
a.text_tangerine_3:visited,
a.text_tangerine_3:focus,
a.text_tangerine_3:hover,
a.text_tangerine_3:active {color:#FFDF86;}

 .text_lime_1,
a.text_lime_1,
a.text_lime_1:link,
a.text_lime_1:visited,
a.text_lime_1:focus,
a.text_lime_1:hover,
a.text_lime_1:active {color:#6E9933;}
 .text_lime_2,
a.text_lime_2,
a.text_lime_2:link,
a.text_lime_2:visited,
a.text_lime_2:focus,
a.text_lime_2:hover,
a.text_lime_2:active {color:#C2D840;}
 .text_lime_3,
a.text_lime_3,
a.text_lime_3:link,
a.text_lime_3:visited,
a.text_lime_3:focus,
a.text_lime_3:hover,
a.text_lime_3:active {color:#E9EC92;}

 .text_blue_1,
a.text_blue_1,
a.text_blue_1:link,
a.text_blue_1:visited,
a.text_blue_1:focus,
a.text_blue_1:hover,
a.text_blue_1:active {color:#075e87;}
 .text_blue_2,
a.text_blue_2,
a.text_blue_2:link,
a.text_blue_2:visited,
a.text_blue_2:focus,
a.text_blue_2:hover,
a.text_blue_2:active {color:#0091b9;}
 .text_blue_3,
a.text_blue_3,
a.text_blue_3:link,
a.text_blue_3:visited,
a.text_blue_3:focus,
a.text_blue_3:hover,
a.text_blue_3:active {color:#80caff;}

 .text_teal_1,
a.text_teal_1,
a.text_teal_1:link,
a.text_teal_1:visited,
a.text_teal_1:focus,
a.text_teal_1:hover,
a.text_teal_1:active {color:#00b0ca;}

/* for all manually applied text colors, underline on hover since a different hover color isn't set */
a.text_white:hover,
a.text_black:hover,
a.text_red:hover,
a.text_white_50:hover, a.text_white_60:hover, a.text_white_70:hover, a.text_white_80:hover, a.text_white_90:hover,
a.text_gray_1:hover, a.text_gray_2:hover, a.text_gray_3:hover,
a.text_berry_1:hover, a.text_berry_2:hover, a.text_berry_3:hover,
a.text_tangerine_1:hover, a.text_tangerine_2:hover, a.text_tangerine_3:hover,
a.text_lime_1:hover, a.text_lime_2:hover, a.text_lime_3:hover,
a.text_blue_1:hover, a.text_blue_2:hover, a.text_blue_3:hover,
a.text_teal_1:hover {text-decoration:underline;}


/* Background Colors */
.bg_white {background-color:#FFF;}
.bg_white_90 {background-color:#FFF; background-color:rgba(255,255,255,0.9);}
.bg_white_80 {background-color:#FFF; background-color:rgba(255,255,255,0.8);}
.bg_white_70 {background-color:#FFF; background-color:rgba(255,255,255,0.7);}
.bg_red {background-color:#AB2E22;}

.bg_gray_1 {background-color:#585659;}
.bg_gray_2 {background-color:#939597;}
.bg_gray_3 {background-color:#D1D2D4;}

.bg_berry_1 {background-color:#62003F;}
.bg_berry_2 {background-color:#BB1654;}
.bg_berry_3 {background-color:#F0DAE3;}

.bg_tangerine_1 {background-color:#F47E1F;}
.bg_tangerine_2 {background-color:#FDB813;}
.bg_tangerine_3 {background-color:#FFDF86;}

.bg_lime_1 {background-color:#6E9933;}
.bg_lime_2 {background-color:#C2D840;}
.bg_lime_3 {background-color:#E9EC92;}

.bg_blue_1 {background-color:#005C8F;}
.bg_blue_2 {background-color:#00adeb;}
.bg_blue_3 {background-color:#BCE2F5;}

/* Background Gradients */
/*.bg_gradient_1 {zoom:1; background:#f7f7f7; background: -moz-linear-gradient(top, #fcfcfc 0%, #ebebeb 100%); background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fcfcfc), color-stop(100%,#ebebeb)); background: -webkit-linear-gradient(top, #fcfcfc 0%,#ebebeb 100%); background: -o-linear-gradient(top, #fcfcfc 0%,#ebebeb 100%); background: -ms-linear-gradient(top, #fcfcfc 0%,#ebebeb 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfcfc', endColorstr='#ebebeb',GradientType=0 ); background: linear-gradient(top, #fcfcfc 0%,#ebebeb 100%);}
.bg_gradient_2 {zoom:1; background:#f1f1f1; background: -moz-linear-gradient(top, #ebebeb 0%, #d9d9d9 100%); background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ebebeb), color-stop(100%,#d9d9d9)); background: -webkit-linear-gradient(top, #ebebeb 0%,#d9d9d9 100%); background: -o-linear-gradient(top, #ebebeb 0%,#d9d9d9 100%); background: -ms-linear-gradient(top, #ebebeb 0%,#d9d9d9 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebebeb', endColorstr='#d9d9d9',GradientType=0 ); background: linear-gradient(top, #ebebeb 0%,#d9d9d9 100%);}
.bg_gradient_3 {zoom:1; background:#e9eff5; background: -moz-linear-gradient(top, #e9eff5 0%, #d5e1ed 100%); background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e9eff5), color-stop(100%,#d5e1ed)); background: -webkit-linear-gradient(top, #e9eff5 0%,#d5e1ed 100%); background: -o-linear-gradient(top, #e9eff5 0%,#d5e1ed 100%); background: -ms-linear-gradient(top, #e9eff5 0%,#d5e1ed 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9eff5', endColorstr='#d5e1ed',GradientType=0 ); background: linear-gradient(top, #e9eff5 0%,#d5e1ed 100%);}
.bg_gradient_4 {zoom:1; background:#262626; background: -moz-linear-gradient(top, #262626 0%, #0d0d0d 100%); background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#262626), color-stop(100%,#0d0d0d)); background: -webkit-linear-gradient(top, #262626 0%,#0d0d0d 100%); background: -o-linear-gradient(top, #262626 0%,#0d0d0d 100%); background: -ms-linear-gradient(top, #262626 0%,#0d0d0d 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#262626', endColorstr='#0d0d0d',GradientType=0 ); background: linear-gradient(top, #262626 0%,#0d0d0d 100%);}
*/
/* Button Basic Styles */
.btn {
	position:relative;
	display:moz-inline-box;
	display:inline-block;
	zoom:1; *display:inline; /* Internet Exploder */
	max-width:100%;
	padding:0px 1.5em 0 1.5em;
	line-height:2.75;
	font-family:"Proxima Nova W01", "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-decoration:none;
	/*text-shadow:0 1px 2px rgba(0,0,0,0.3);*/
	overflow:hidden;
	white-space:nowrap;
	text-overflow:ellipsis;
	-webkit-border-radius:2px;
	-moz-border-radius:2px;
	border-radius:2px;
	border:0 none;
	-webkit-box-shadow:0 2px 0 #000;
	-moz-box-shadow:0 2px 0 #000;
	box-shadow:0 2px 0 #000;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	*behavior:url('../js/vendor/boxsizing.htc'); /* polyfill for IE */
	-webkit-transition:background-color 0.3s, color 0.3s, top 0s;
	-moz-transition:background-color 0.3s, color 0.3s, top 0s;
	-o-transition:background-color 0.3s, color 0.3s, top 0s;
	transition:background-color 0.3s, color 0.3s, top 0s;
}
.btn.text_uppercase {
	padding-top:3px;
}
.btn.font_title,
.btn.font_title.text_uppercase {
	padding-top:0;
}
.btn:hover {
	border:0 none;
	-webkit-transition:background-color 0.1s, color 0.1s, top 0s;
	-moz-transition:background-color 0.1s, color 0.1s, top 0s;
	-o-transition:background-color 0.1s, color 0.1s, top 0s;
	transition:background-color 0.1s, color 0.1s, top 0s;
}
.btn:active {
	top:1px;
	-webkit-box-shadow:0 1px 0 rgba(0,0,0,1);
	-moz-box-shadow:0 1px 0 rgba(0,0,0,1);
	box-shadow:0 1px 0 rgba(0,0,0,1);
	-webkit-transition:top 0;
	-moz-transition:top 0;
	-o-transition:top 0;
	transition:top 0;
}

.btn.outline {
	text-shadow:none;
	border-width:1px !important; border-style:solid !important;
	background:transparent !important;
	-webkit-box-shadow:none;
	-moz-box-shadow:none;
	box-shadow:none;
	-webkit-transition:border-color 0.3s, color 0.3s, top 0s;
	-moz-transition:border-color 0.3s, color 0.3s, top 0s;
	-o-transition:border-color 0.3s, color 0.3s, top 0s;
	transition:border-color 0.3s, color 0.3s, top 0s;
}
.btn.outline:hover {
	border-width:1px !important; border-style:solid !important;
	background:transparent !important;
	-webkit-transition:border-color 0.1s, color 0.1s, top 0s;
	-moz-transition:border-color 0.1s, color 0.1s, top 0s;
	-o-transition:border-color 0.1s, color 0.1s, top 0s;
	transition:border-color 0.1s, color 0.1s, top 0s;
}
.btn.outline:active {
	border-width:1px !important; border-style:solid !important;
	background:transparent !important;
}

/* Button Colors */
.btn.gray_1							{color:#ffffff; background-color:#585659; -webkit-box-shadow:0 2px 0 #262526; -moz-box-shadow:0 2px 0 #262526; box-shadow:0 2px 0 #262526;}
.btn.gray_1:hover					{color:#ffffff; background-color:#726e73; -webkit-box-shadow:0 2px 0 #262526; -moz-box-shadow:0 2px 0 #262526; box-shadow:0 2px 0 #262526;}
.btn.gray_1:active					{color:#ffffff; background-color:#726e73; -webkit-box-shadow:0 1px 0 #262526; -moz-box-shadow:0 1px 0 #262526; box-shadow:0 1px 0 #262526;}

.btn.gray_2							{color:#ffffff; background-color:#939597; -webkit-box-shadow:0 2px 0 #606263; -moz-box-shadow:0 2px 0 #606263; box-shadow:0 2px 0 #606263;}
.btn.gray_2:hover					{color:#ffffff; background-color:#a6a8ab; -webkit-box-shadow:0 2px 0 #606263; -moz-box-shadow:0 2px 0 #606263; box-shadow:0 2px 0 #606263;}
.btn.gray_2:active					{color:#ffffff; background-color:#a6a8ab; -webkit-box-shadow:0 1px 0 #606263; -moz-box-shadow:0 1px 0 #606263; box-shadow:0 1px 0 #606263;}

.btn.gray_3							{color:#444444; background-color:#D1D2D4; -webkit-box-shadow:0 2px 0 #9fa0a1; -moz-box-shadow:0 2px 0 #9fa0a1; box-shadow:0 2px 0 #9fa0a1;}
.btn.gray_3:hover					{color:#444444; background-color:#e6e7e8; -webkit-box-shadow:0 2px 0 #9fa0a1; -moz-box-shadow:0 2px 0 #9fa0a1; box-shadow:0 2px 0 #9fa0a1;}
.btn.gray_3:active					{color:#444444; background-color:#e6e7e8; -webkit-box-shadow:0 1px 0 #9fa0a1; -moz-box-shadow:0 1px 0 #9fa0a1; box-shadow:0 1px 0 #9fa0a1;}

.btn.outline.gray_1					{color:#585659; border-color:#585659; -webkit-box-shadow:0 2px 0 #585659; -moz-box-shadow:0 2px 0 #585659; box-shadow:0 2px 0 #585659; border-color:rgba(88,86,89,0.6);}
.btn.outline.gray_1:hover			{color:#585659; border-color:#726e73; -webkit-box-shadow:0 2px 0 #585659; -moz-box-shadow:0 2px 0 #585659; box-shadow:0 2px 0 #585659;}
.btn.outline.gray_1:active			{color:#585659; border-color:#726e73; -webkit-box-shadow:0 1px 0 #585659; -moz-box-shadow:0 1px 0 #585659; box-shadow:0 1px 0 #585659;}

.btn.outline.gray_2					{color:#939597; border-color:#939597; -webkit-box-shadow:0 2px 0 #939597; -moz-box-shadow:0 2px 0 #939597; box-shadow:0 2px 0 #939597; border-color:rgba(147,149,151,0.6);}
.btn.outline.gray_2:hover			{color:#939597; border-color:#a6a8ab; -webkit-box-shadow:0 2px 0 #939597; -moz-box-shadow:0 2px 0 #939597; box-shadow:0 2px 0 #939597;}
.btn.outline.gray_2:active			{color:#939597; border-color:#a6a8ab; -webkit-box-shadow:0 1px 0 #939597; -moz-box-shadow:0 1px 0 #939597; box-shadow:0 1px 0 #939597;}

.btn.outline.gray_3					{color:#D1D2D4; border-color:#D1D2D4; -webkit-box-shadow:0 2px 0 #D1D2D4; -moz-box-shadow:0 2px 0 #D1D2D4; box-shadow:0 2px 0 #D1D2D4; border-color:rgba(209,210,212,0.6);}
.btn.outline.gray_3:hover			{color:#D1D2D4; border-color:#e6e7e8; -webkit-box-shadow:0 2px 0 #D1D2D4; -moz-box-shadow:0 2px 0 #D1D2D4; box-shadow:0 2px 0 #D1D2D4;}
.btn.outline.gray_3:active			{color:#D1D2D4; border-color:#e6e7e8; -webkit-box-shadow:0 1px 0 #D1D2D4; -moz-box-shadow:0 1px 0 #D1D2D4; box-shadow:0 1px 0 #D1D2D4;}


.btn.berry_1						{color:#ffffff; background-color:#62003F; -webkit-box-shadow:0 2px 0 #2e001e; -moz-box-shadow:0 2px 0 #2e001e; box-shadow:0 2px 0 #2e001e;}
.btn.berry_1:hover					{color:#ffffff; background-color:#870053; -webkit-box-shadow:0 2px 0 #2e001e; -moz-box-shadow:0 2px 0 #2e001e; box-shadow:0 2px 0 #2e001e;}
.btn.berry_1:active					{color:#ffffff; background-color:#870053; -webkit-box-shadow:0 1px 0 #2e001e; -moz-box-shadow:0 1px 0 #2e001e; box-shadow:0 1px 0 #2e001e;}

.btn.berry_2						{color:#ffffff; background-color:#BB1654; -webkit-box-shadow:0 2px 0 #6e0d32; -moz-box-shadow:0 2px 0 #6e0d32; box-shadow:0 2px 0 #6e0d32;}
.btn.berry_2:hover					{color:#ffffff; background-color:#d41961; -webkit-box-shadow:0 2px 0 #6e0d32; -moz-box-shadow:0 2px 0 #6e0d32; box-shadow:0 2px 0 #6e0d32;}
.btn.berry_2:active					{color:#ffffff; background-color:#d41961; -webkit-box-shadow:0 1px 0 #6e0d32; -moz-box-shadow:0 1px 0 #6e0d32; box-shadow:0 1px 0 #6e0d32;}

.btn.berry_3						{color:#62003F; background-color:#F0DAE3; -webkit-box-shadow:0 2px 0 #bd99a8; -moz-box-shadow:0 2px 0 #bd99a8; box-shadow:0 2px 0 #bd99a8;}
.btn.berry_3:hover					{color:#62003F; background-color:#ffebf3; -webkit-box-shadow:0 2px 0 #bd99a8; -moz-box-shadow:0 2px 0 #bd99a8; box-shadow:0 2px 0 #bd99a8;}
.btn.berry_3:active					{color:#62003F; background-color:#ffebf3; -webkit-box-shadow:0 1px 0 #bd99a8; -moz-box-shadow:0 1px 0 #bd99a8; box-shadow:0 1px 0 #bd99a8;}

.btn.outline.berry_1				{color:#62003F; border-color:#62003F; -webkit-box-shadow:0 2px 0 #62003F; -moz-box-shadow:0 2px 0 #62003F; box-shadow:0 2px 0 #62003F; border-color:rgba(98,0,63,0.6);}
.btn.outline.berry_1:hover			{color:#62003F; border-color:#75004c; -webkit-box-shadow:0 2px 0 #62003F; -moz-box-shadow:0 2px 0 #62003F; box-shadow:0 2px 0 #62003F;}
.btn.outline.berry_1:active			{color:#62003F; border-color:#75004c; -webkit-box-shadow:0 1px 0 #62003F; -moz-box-shadow:0 1px 0 #62003F; box-shadow:0 1px 0 #62003F;}

.btn.outline.berry_2				{color:#BB1654; border-color:#BB1654; -webkit-box-shadow:0 2px 0 #BB1654; -moz-box-shadow:0 2px 0 #BB1654; box-shadow:0 2px 0 #BB1654; border-color:rgba(187,22,84, 0.6);}
.btn.outline.berry_2:hover			{color:#BB1654; border-color:#cf195e; -webkit-box-shadow:0 2px 0 #BB1654; -moz-box-shadow:0 2px 0 #BB1654; box-shadow:0 2px 0 #BB1654;}
.btn.outline.berry_2:active			{color:#BB1654; border-color:#cf195e; -webkit-box-shadow:0 1px 0 #BB1654; -moz-box-shadow:0 1px 0 #BB1654; box-shadow:0 1px 0 #BB1654;}

.btn.outline.berry_3				{color:#F0DAE3; border-color:#F0DAE3; -webkit-box-shadow:0 2px 0 #F0DAE3; -moz-box-shadow:0 2px 0 #F0DAE3; box-shadow:0 2px 0 #F0DAE3; border-color:rgba(240,218,227, 0.6);}
.btn.outline.berry_3:hover			{color:#F0DAE3; border-color:#ffebf3; -webkit-box-shadow:0 2px 0 #F0DAE3; -moz-box-shadow:0 2px 0 #F0DAE3; box-shadow:0 2px 0 #F0DAE3;}
.btn.outline.berry_3:active			{color:#F0DAE3; border-color:#ffebf3; -webkit-box-shadow:0 1px 0 #F0DAE3; -moz-box-shadow:0 1px 0 #F0DAE3; box-shadow:0 1px 0 #F0DAE3;}


.btn.tangerine_1					{color:#ffffff; background-color:#F47E1F; -webkit-box-shadow:0 2px 0 #ad4e00; -moz-box-shadow:0 2px 0 #ad4e00; box-shadow:0 2px 0 #ad4e00;}
.btn.tangerine_1:hover				{color:#ffffff; background-color:#ff9633; -webkit-box-shadow:0 2px 0 #ad4e00; -moz-box-shadow:0 2px 0 #ad4e00; box-shadow:0 2px 0 #ad4e00;}
.btn.tangerine_1:active				{color:#ffffff; background-color:#ff9633; -webkit-box-shadow:0 1px 0 #ad4e00; -moz-box-shadow:0 1px 0 #ad4e00; box-shadow:0 1px 0 #ad4e00;}

.btn.tangerine_2					{color:#ffffff; background-color:#FDB813; -webkit-box-shadow:0 2px 0 #c98000; -moz-box-shadow:0 2px 0 #c98000; box-shadow:0 2px 0 #c98000;}
.btn.tangerine_2:hover				{color:#ffffff; background-color:#ffc421; -webkit-box-shadow:0 2px 0 #c98000; -moz-box-shadow:0 2px 0 #c98000; box-shadow:0 2px 0 #c98000;}
.btn.tangerine_2:active				{color:#ffffff; background-color:#ffc421; -webkit-box-shadow:0 1px 0 #c98000; -moz-box-shadow:0 1px 0 #c98000; box-shadow:0 1px 0 #c98000;}

.btn.tangerine_3					{color:#e86800; background-color:#FFDF86; -webkit-box-shadow:0 2px 0 #cca558; -moz-box-shadow:0 2px 0 #cca558; box-shadow:0 2px 0 #cca558;}
.btn.tangerine_3:hover				{color:#e86800; background-color:#ffe6a1; -webkit-box-shadow:0 2px 0 #cca558; -moz-box-shadow:0 2px 0 #cca558; box-shadow:0 2px 0 #cca558;}
.btn.tangerine_3:active				{color:#e86800; background-color:#ffe6a1; -webkit-box-shadow:0 1px 0 #cca558; -moz-box-shadow:0 1px 0 #cca558; box-shadow:0 1px 0 #cca558;}

.btn.outline.tangerine_1			{color:#F47E1F; border-color:#F47E1F; -webkit-box-shadow:0 2px 0 #F47E1F; -moz-box-shadow:0 2px 0 #F47E1F; box-shadow:0 2px 0 #F47E1F; border-color:rgba(244,126,31,0.6);}
.btn.outline.tangerine_1:hover		{color:#F47E1F; border-color:#ff9633; -webkit-box-shadow:0 2px 0 #F47E1F; -moz-box-shadow:0 2px 0 #F47E1F; box-shadow:0 2px 0 #F47E1F;}
.btn.outline.tangerine_1:active		{color:#F47E1F; border-color:#ff9633; -webkit-box-shadow:0 1px 0 #F47E1F; -moz-box-shadow:0 1px 0 #F47E1F; box-shadow:0 1px 0 #F47E1F;}

.btn.outline.tangerine_2			{color:#FDB813; border-color:#FDB813; -webkit-box-shadow:0 2px 0 #FDB813; -moz-box-shadow:0 2px 0 #FDB813; box-shadow:0 2px 0 #FDB813; border-color:rgba(253,184,19,0.6);}
.btn.outline.tangerine_2:hover		{color:#FDB813; border-color:#ffc421; -webkit-box-shadow:0 2px 0 #FDB813; -moz-box-shadow:0 2px 0 #FDB813; box-shadow:0 2px 0 #FDB813;}
.btn.outline.tangerine_2:active		{color:#FDB813; border-color:#ffc421; -webkit-box-shadow:0 1px 0 #FDB813; -moz-box-shadow:0 1px 0 #FDB813; box-shadow:0 1px 0 #FDB813;}

.btn.outline.tangerine_3			{color:#FFDF86; border-color:#FFDF86; -webkit-box-shadow:0 2px 0 #FFDF86; -moz-box-shadow:0 2px 0 #FFDF86; box-shadow:0 2px 0 #FFDF86; border-color:rgba(255,223,134,0.6);}
.btn.outline.tangerine_3:hover		{color:#FFDF86; border-color:#ffe6a1; -webkit-box-shadow:0 2px 0 #FFDF86; -moz-box-shadow:0 2px 0 #FFDF86; box-shadow:0 2px 0 #FFDF86;}
.btn.outline.tangerine_3:active		{color:#FFDF86; border-color:#ffe6a1; -webkit-box-shadow:0 1px 0 #FFDF86; -moz-box-shadow:0 1px 0 #FFDF86; box-shadow:0 1px 0 #FFDF86;}


.btn.lime_1							{color:#ffffff; background-color:#6E9933; -webkit-box-shadow:0 2px 0 #344d12; -moz-box-shadow:0 2px 0 #344d12; box-shadow:0 2px 0 #344d12;}
.btn.lime_1:hover					{color:#ffffff; background-color:#7dad39; -webkit-box-shadow:0 2px 0 #344d12; -moz-box-shadow:0 2px 0 #344d12; box-shadow:0 2px 0 #344d12;}
.btn.lime_1:active					{color:#ffffff; background-color:#7dad39; -webkit-box-shadow:0 1px 0 #344d12; -moz-box-shadow:0 1px 0 #344d12; box-shadow:0 1px 0 #344d12;}

.btn.lime_2							{color:#ffffff; background-color:#C2D840; -webkit-box-shadow:0 2px 0 #84990f; -moz-box-shadow:0 2px 0 #84990f; box-shadow:0 2px 0 #84990f;}
.btn.lime_2:hover					{color:#ffffff; background-color:#cce539; -webkit-box-shadow:0 2px 0 #84990f; -moz-box-shadow:0 2px 0 #84990f; box-shadow:0 2px 0 #84990f;}
.btn.lime_2:active					{color:#ffffff; background-color:#cce539; -webkit-box-shadow:0 1px 0 #84990f; -moz-box-shadow:0 1px 0 #84990f; box-shadow:0 1px 0 #84990f;}

.btn.lime_3							{color:#5d9113; background-color:#E9EC92; -webkit-box-shadow:0 2px 0 #b7ba4e; -moz-box-shadow:0 2px 0 #b7ba4e; box-shadow:0 2px 0 #b7ba4e;}
.btn.lime_3:hover					{color:#5d9113; background-color:#fcff9e; -webkit-box-shadow:0 2px 0 #b7ba4e; -moz-box-shadow:0 2px 0 #b7ba4e; box-shadow:0 2px 0 #b7ba4e;}
.btn.lime_3:active					{color:#5d9113; background-color:#fcff9e; -webkit-box-shadow:0 1px 0 #b7ba4e; -moz-box-shadow:0 1px 0 #b7ba4e; box-shadow:0 1px 0 #b7ba4e;}

.btn.outline.lime_1					{color:#6E9933; border-color:#6E9933; -webkit-box-shadow:0 2px 0 #6E9933; -moz-box-shadow:0 2px 0 #6E9933; box-shadow:0 2px 0 #6E9933; border-color:rgba(110,153,51,0.6);}
.btn.outline.lime_1:hover			{color:#6E9933; border-color:#7dad39; -webkit-box-shadow:0 2px 0 #6E9933; -moz-box-shadow:0 2px 0 #6E9933; box-shadow:0 2px 0 #6E9933;}
.btn.outline.lime_1:active			{color:#6E9933; border-color:#7dad39; -webkit-box-shadow:0 1px 0 #6E9933; -moz-box-shadow:0 1px 0 #6E9933; box-shadow:0 1px 0 #6E9933;}

.btn.outline.lime_2					{color:#C2D840; border-color:#C2D840; -webkit-box-shadow:0 2px 0 #C2D840; -moz-box-shadow:0 2px 0 #C2D840; box-shadow:0 2px 0 #C2D840; border-color:rgba(194,216,64,0.6);}
.btn.outline.lime_2:hover			{color:#C2D840; border-color:#cce539; -webkit-box-shadow:0 2px 0 #C2D840; -moz-box-shadow:0 2px 0 #C2D840; box-shadow:0 2px 0 #C2D840;}
.btn.outline.lime_2:active			{color:#C2D840; border-color:#cce539; -webkit-box-shadow:0 1px 0 #C2D840; -moz-box-shadow:0 1px 0 #C2D840; box-shadow:0 1px 0 #C2D840;}

.btn.outline.lime_3					{color:#E9EC92; border-color:#E9EC92; -webkit-box-shadow:0 2px 0 #E9EC92; -moz-box-shadow:0 2px 0 #E9EC92; box-shadow:0 2px 0 #E9EC92; border-color:rgba(233,236,146,0.6);}
.btn.outline.lime_3:hover			{color:#E9EC92; border-color:#fcff9e; -webkit-box-shadow:0 2px 0 #E9EC92; -moz-box-shadow:0 2px 0 #E9EC92; box-shadow:0 2px 0 #E9EC92;}
.btn.outline.lime_3:active			{color:#E9EC92; border-color:#fcff9e; -webkit-box-shadow:0 1px 0 #E9EC92; -moz-box-shadow:0 1px 0 #E9EC92; box-shadow:0 1px 0 #E9EC92;}


.btn.blue_1							{color:#ffffff; background-color:#005C8F; -webkit-box-shadow:0 2px 0 #002b42; -moz-box-shadow:0 2px 0 #002b42; box-shadow:0 2px 0 #002b42;}
.btn.blue_1:hover					{color:#ffffff; background-color:#006fb0; -webkit-box-shadow:0 2px 0 #002b42; -moz-box-shadow:0 2px 0 #002b42; box-shadow:0 2px 0 #002b42;}
.btn.blue_1:active					{color:#ffffff; background-color:#006fb0; -webkit-box-shadow:0 1px 0 #002b42; -moz-box-shadow:0 1px 0 #002b42; box-shadow:0 1px 0 #002b42;}

.btn.blue_2							{color:#ffffff; background-color:#00adeb; -webkit-box-shadow:0 2px 0 #007dab; -moz-box-shadow:0 2px 0 #007dab; box-shadow:0 2px 0 #007dab;}
.btn.blue_2:hover					{color:#ffffff; background-color:#52cbff; -webkit-box-shadow:0 2px 0 #007dab; -moz-box-shadow:0 2px 0 #007dab; box-shadow:0 2px 0 #007dab;}
.btn.blue_2:active					{color:#ffffff; background-color:#52cbff; -webkit-box-shadow:0 1px 0 #007dab; -moz-box-shadow:0 1px 0 #007dab; box-shadow:0 1px 0 #007dab;}

.btn.blue_3							{color:#005C8F; background-color:#BCE2F5; -webkit-box-shadow:0 2px 0 #82acc2; -moz-box-shadow:0 2px 0 #82acc2; box-shadow:0 2px 0 #82acc2;}
.btn.blue_3:hover					{color:#005C8F; background-color:#bde9ff; -webkit-box-shadow:0 2px 0 #82acc2; -moz-box-shadow:0 2px 0 #82acc2; box-shadow:0 2px 0 #82acc2;}
.btn.blue_3:active					{color:#005C8F; background-color:#bde9ff; -webkit-box-shadow:0 1px 0 #82acc2; -moz-box-shadow:0 1px 0 #82acc2; box-shadow:0 1px 0 #82acc2;}

.btn.outline.blue_1					{color:#005C8F; border-color:#005C8F; -webkit-box-shadow:0 2px 0 #005C8F; -moz-box-shadow:0 2px 0 #005C8F; box-shadow:0 2px 0 #005C8F; border-color:rgba(0,92,143, 0.6); background:transparent;}
.btn.outline.blue_1:hover			{color:#005C8F; border-color:#006aa3; -webkit-box-shadow:0 2px 0 #005C8F; -moz-box-shadow:0 2px 0 #005C8F; box-shadow:0 2px 0 #005C8F;}
.btn.outline.blue_1:active			{color:#005C8F; border-color:#006aa3; -webkit-box-shadow:0 1px 0 #005C8F; -moz-box-shadow:0 1px 0 #005C8F; box-shadow:0 1px 0 #005C8F;}

.btn.outline.blue_2					{color:#00adeb; border-color:#00adeb; -webkit-box-shadow:0 2px 0 #00adeb; -moz-box-shadow:0 2px 0 #00adeb; box-shadow:0 2px 0 #00adeb; border-color:rgba(75,186,235, 0.6); background:transparent;}
.btn.outline.blue_2:hover			{color:#00adeb; border-color:#52cbff; -webkit-box-shadow:0 2px 0 #00adeb; -moz-box-shadow:0 2px 0 #00adeb; box-shadow:0 2px 0 #00adeb;}
.btn.outline.blue_2:active			{color:#00adeb; border-color:#52cbff; -webkit-box-shadow:0 1px 0 #00adeb; -moz-box-shadow:0 1px 0 #00adeb; box-shadow:0 1px 0 #00adeb;}

.btn.outline.blue_3					{color:#BCE2F5; border-color:#BCE2F5; -webkit-box-shadow:0 2px 0 #BCE2F5; -moz-box-shadow:0 2px 0 #BCE2F5; box-shadow:0 2px 0 #BCE2F5; border-color:rgba(188,226,245, 0.6); background:transparent;}
.btn.outline.blue_3:hover			{color:#BCE2F5; border-color:#bde9ff; -webkit-box-shadow:0 2px 0 #BCE2F5; -moz-box-shadow:0 2px 0 #BCE2F5; box-shadow:0 2px 0 #BCE2F5;}
.btn.outline.blue_3:active			{color:#BCE2F5; border-color:#bde9ff; -webkit-box-shadow:0 1px 0 #BCE2F5; -moz-box-shadow:0 1px 0 #BCE2F5; box-shadow:0 1px 0 #BCE2F5;}


.btn.red							{color:#ffffff; background-color:#c22525; -webkit-box-shadow:0 2px 0 #751616; -moz-box-shadow:0 2px 0 #751616; box-shadow:0 2px 0 #751616;}
.btn.red:hover						{color:#ffffff; background-color:#f51616; -webkit-box-shadow:0 2px 0 #751616; -moz-box-shadow:0 2px 0 #751616; box-shadow:0 2px 0 #751616;}
.btn.red:active						{color:#ffffff; background-color:#f51616; -webkit-box-shadow:0 1px 0 #751616; -moz-box-shadow:0 1px 0 #751616; box-shadow:0 1px 0 #751616;}

.btn.infra							{color:#ffffff; background-color:#ff2247; -webkit-box-shadow:0 2px 0 #c72e47; -moz-box-shadow:0 2px 0 #c72e47; box-shadow:0 2px 0 #c72e47;}
.btn.infra:hover					{color:#ffffff; background-color:#ff2247; -webkit-box-shadow:0 2px 0 #c72e47; -moz-box-shadow:0 2px 0 #c72e47; box-shadow:0 2px 0 #c72e47;}
.btn.infra:active					{color:#ffffff; background-color:#ff2247; -webkit-box-shadow:0 1px 0 #c72e47; -moz-box-shadow:0 1px 0 #c72e47; box-shadow:0 1px 0 #c72e47;}

.btn.outline.infra					{color:#ff2247; border-color:#ff2247; -webkit-box-shadow:0 2px 0 #ff2247; -moz-box-shadow:0 2px 0 #ff2247; box-shadow:0 2px 0 #ff2247; border-color:rgba(255,34,71, 0.6); background:transparent;}
.btn.outline.infra:hover			{color:#ff2247; border-color:#ff2247; -webkit-box-shadow:0 2px 0 #ff2247; -moz-box-shadow:0 2px 0 #ff2247; box-shadow:0 2px 0 #ff2247;}
.btn.outline.infra:active			{color:#ff2247; border-color:#ff2247; -webkit-box-shadow:0 1px 0 #ff2247; -moz-box-shadow:0 1px 0 #ff2247; box-shadow:0 1px 0 #ff2247;}


.btn.white							{color:#444444; background-color:#ffffff; -webkit-box-shadow:0 2px 0 #cccccc; -moz-box-shadow:0 2px 0 #cccccc; box-shadow:0 2px 0 #cccccc;}
.btn.white:hover					{color:#4bbaeb; background-color:#ffffff; -webkit-box-shadow:0 2px 0 #cccccc; -moz-box-shadow:0 2px 0 #cccccc; box-shadow:0 2px 0 #cccccc;}
.btn.white:active					{color:#4bbaeb; background-color:#ffffff; -webkit-box-shadow:0 1px 0 #cccccc; -moz-box-shadow:0 1px 0 #cccccc; box-shadow:0 1px 0 #cccccc;}

.btn.outline.white					{color:#ffffff; border-color:#ffffff; -webkit-box-shadow:0 2px 0 rgba(255,255,255,1); -moz-box-shadow:0 2px 0 rgba(255,255,255,1); box-shadow:0 2px 0 rgba(255,255,255,1); border-color:rgba(255,255,255,0.6);}
.btn.outline.white:hover			{color:#ffffff; border-color:#ffffff; -webkit-box-shadow:0 2px 0 rgba(255,255,255,1); -moz-box-shadow:0 2px 0 rgba(255,255,255,1); box-shadow:0 2px 0 rgba(255,255,255,1);}
.btn.outline.white:active			{color:#ffffff; border-color:#ffffff; -webkit-box-shadow:0 1px 0 rgba(255,255,255,1); -moz-box-shadow:0 1px 0 rgba(255,255,255,1); box-shadow:0 1px 0 rgba(255,255,255,1);}


.btn.teal_1							{color:#ffffff; background-color:#00b0ca; -webkit-box-shadow:0 2px 0 #008296; -moz-box-shadow:0 2px 0 #008296; box-shadow:0 2px 0 #008296;}
.btn.teal_1:hover					{color:#ffffff; background-color:#00c5e3; -webkit-box-shadow:0 2px 0 #008296; -moz-box-shadow:0 2px 0 #008296; box-shadow:0 2px 0 #008296;}
.btn.teal_1:active					{color:#ffffff; background-color:#00c5e3; -webkit-box-shadow:0 1px 0 #008296; -moz-box-shadow:0 1px 0 #008296; box-shadow:0 1px 0 #008296;}

.btn.outline.teal_1					{color:#00b0ca; border-color:#00b0ca; -webkit-box-shadow:0 2px 0 rgba(0,176,202,1); -moz-box-shadow:0 2px 0 rgba(0,176,202,1); box-shadow:0 2px 0 rgba(0,176,202,1); border-color:rgba(0,176,202,0.4);}
.btn.outline.teal_1:hover			{color:#00b0ca; border-color:#00b0ca; -webkit-box-shadow:0 2px 0 #00b0ca; -moz-box-shadow:0 2px 0 #00b0ca; box-shadow:0 2px 0 #00b0ca;}
.btn.outline.teal_1:active			{color:#00b0ca; border-color:#00b0ca; -webkit-box-shadow:0 1px 0 #00b0ca; -moz-box-shadow:0 1px 0 #00b0ca; box-shadow:0 1px 0 #00b0ca;}


/* Arrows for buttons */
.btn.arrow:after {content:"]"; font-family:"Pictos"; margin-left:0.8em;}
.btn.arrow.left:before {content:"["; font-family:"Pictos"; margin-right:0.8em;}
.btn.arrow.left:after {content:""; margin:0;}


@media only screen and (max-width:499px) {
	.btn .more {display:none;}
}
