.distributor_content {
	flex: 1;
}

.distributor_info_row {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	max-width: 400px;
	@include breakpoint(tablet-l) {
		justify-content: flex-start;
		margin-bottom: 40px;
		max-width: none;
	}
	.distributor_info_column {
		display: flex;
		flex-direction: column;
		&__desktop {
			display: none;
			@include breakpoint(tablet-l) {
				display: inline;
			}
		}
		@include breakpoint(tablet-l) {
			margin-right: 50px;
		}
	}
}

.distributor_label {
	@include font_size(fs_p_note_extra);
	align-self: flex-start;
	color: color(gray, light);
	letter-spacing: .25em;
	text-transform: uppercase;
	.flex_content__master & {
		color: color(rank_pro_10);
	}
	&.flag {
		background: color(gradient, gold);
		border-radius: 3px;
		color: color(rank_pro_10, dark);
		display: inline-block;
		font-weight: 400;
		padding: 0 8px;
		vertical-align: top;
	}
}

.distributor_page_simple_title {
	color: color(black, near);
	font-family: font(sans);
	font-size: 40px;
	font-weight: 100;
	line-height: .75em;
	margin-bottom: 60px;
	padding-top: 90px;
	text-transform: uppercase;
	.emphasis {
		color: color(blue);
		font-weight: 800;
	}
}

.distributor_page_title {
	color: color(black, near);
	font-family: font(sans);
	font-size: 34px;
	font-weight: 100;
	line-height: .75em;
	margin-bottom: 20px;
	margin-top: 0;
	padding-top: 20px;
	text-transform: uppercase;
	@include breakpoint(mobile) {
		font-size: 42px;
	}
	@include breakpoint(mobile-xl) {
		font-size: 66px;
		margin-bottom: 40px;
		padding-top: 40px;
	}
	@include breakpoint(tablet) {
		font-size: 82px;
		margin-bottom: 50px;
		padding-top: 140px;
	}
	@include breakpoint(desktop) {
		font-size: 100px;
	}
	.flex_content__master & {
		color: color(white);
	}
	.emphasis {
		font-weight: 800;
		.pro_1 & {
			color: color(rank_pro_1);
		}
		.pro_2 & {
			color: color(rank_pro_2);
		}
		.pro_3 & {
			color: color(rank_pro_3);
		}
		.pro_4 & {
			color: color(rank_pro_4);
		}
		.pro_5 & {
			color: color(rank_pro_5);
		}
		.pro_6 & {
			color: color(rank_pro_6);
		}
		.pro_7 & {
			color: color(rank_pro_7);
		}
		.pro_8 & {
			color: color(rank_pro_8);
		}
		.pro_9 & {
			color: color(rank_pro_9);
		}
		.flex_content__master & {
			color: color(rank_pro_10);
		}
	}
}

.distributor_sidebar {
	margin-left: auto;
	margin-right: auto;
	max-width: 375px;
	min-width: 250px;
	width: 100%;
	@include breakpoint(tablet) {
		margin-left: 60px;
		min-width: 0;
		width: 260px;
	}
	@include breakpoint(tablet-l) {
		width: 310px;
	}
	@include breakpoint(desktop) {
		margin-left: 120px;
	}
}

.distributor_simple_nav {
	display: flex;
	margin-top: 17px;
	position: relative;
	@include breakpoint(tablet) {
		margin-top: 0;
	}
	.recognition_page__premier &,
	.recognition_page__elite & {
		margin-bottom: 40px;
	}
	.nav_hr {
		border-bottom: 1px solid color(gray, xx-light);
		flex: 1;
		.flex_content__master & {
			border-color: rgba(191, 172, 115, .35);
		}
	}
	.nav_item {
		border-bottom: 1px solid color(gray, xx-light);
		color: color(gray, light);
		cursor: pointer;
		font-size: 12px;
		letter-spacing: .1em;
		padding: 0 15px 15px;
		position: relative;
		text-transform: uppercase;
		transition: color .2s ease-out;
		.flex_content__master & {
			border-color: rgba(191, 172, 115, .35);
			color: rgba(191, 172, 115, .35);
		}
		&:after, &:before {
			background-color: color(black, near);
			bottom: -1px;
			content: '';
			height: 3px;
			position: absolute;
			transition: all .15s easing(ease-out-back) .1s;
			width: 0;
			.flex_content__master & {
				background-color: color(metal, gold);
			}
		}
		&:after {
			left: 50%;
		}
		&:before {
			right: 50%;
		}
		&:hover,
		&.active {
			color: color(black, near);
			&:after, &:before {
				width: 50%;
			}
			.flex_content__master & {
				color: color(metal, gold);
			}
		}
	}
}

.distributor_single_layout {
	display: flex;
	flex-direction: column;
	@include breakpoint(tablet) {
		flex-direction: row;
	}
}

.distributor_value {
	@include font_size(fs_h4);
	color: color(black, near);
	font-family: font(serif);
	font-style: italic;
	vertical-align: top;
	.flex_content__master & {
		color: color(white);
	}
}

.no-sidebar {
	@include breakpoint(tablet) {
		margin-left: auto;
		margin-right: auto;
		max-width: 728px;
	}
}

.sidebar_cta {
	background-color: transparent;
	border: 2px solid color(black, near);
	color: color(black, near);
	display: inline-block;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: .2em;
	line-height: 36px;
	margin-bottom: 20px;
	margin-left: 50%;
	padding: 0 18px;
	text-decoration: none;
	text-transform: uppercase;
	transform: translateX(-50%);
	transition: background-color .15s easing(ease-out-quart), color .15s easing(ease-out-quart);
	white-space: nowrap;
	&:hover {
		transition-timing-function: easing(ease-in-quart);
	}
	&.pro_1 {
		border-color: color(rank_pro_1);
		color: color(rank_pro_1);
		&:hover {
			background-color: color(rank_pro_1);
			color: color(rank_pro_1, light);
		}
	}
	&.pro_2 {
		border-color: color(rank_pro_2);
		color: color(rank_pro_2);
		&:hover {
			background-color: color(rank_pro_2);
			color: color(rank_pro_2, light);
		}
	}
	&.pro_3 {
		border-color: color(rank_pro_3);
		color: color(rank_pro_3);
		&:hover {
			background-color: color(rank_pro_3);
			color: color(rank_pro_3, light);
		}
	}
	&.pro_4 {
		border-color: color(rank_pro_4);
		color: color(rank_pro_4);
		&:hover {
			background-color: color(rank_pro_4);
			color: color(rank_pro_4, light);
		}
	}
	&.pro_5 {
		border-color: color(rank_pro_5);
		color: color(rank_pro_5);
		&:hover {
			background-color: color(rank_pro_5);
			color: color(rank_pro_5, light);
		}
	}
	&.pro_6 {
		border-color: color(rank_pro_6);
		color: color(rank_pro_6);
		&:hover {
			background-color: color(rank_pro_6);
			color: color(rank_pro_6, light);
		}
	}
	&.pro_7 {
		border-color: color(rank_pro_7);
		color: color(rank_pro_7);
		&:hover {
			background-color: color(rank_pro_7);
			color: color(rank_pro_7, light);
		}
	}
	&.pro_8 {
		border-color: color(rank_pro_8);
		color: color(rank_pro_8);
		&:hover {
			background-color: color(rank_pro_8);
			color: color(rank_pro_8, light);
		}
	}
	&.pro_9 {
		border-color: color(rank_pro_9);
		color: color(rank_pro_9);
		&:hover {
			background-color: color(rank_pro_9);
			color: color(rank_pro_9, light);
		}
	}
	.flex_content__master & {
		border-color: color(rank_pro_10);
		color: color(rank_pro_10);
		&:hover {
			background-color: color(rank_pro_10);
			color: color(rank_pro_10, dark);
		}
	}
}

.distributor_simple_nav_content {
	display: none;
	&.active {
		display: block;
	}
}

.sidebar_link_list {
	.link_icon {
		height: 24px;
		margin-left: 10px;
		margin-right: 30px;
		width: 24px;
		@include breakpoint(tablet, tablet-l) {
			margin-left: 10px;
			margin-right: 10px;
		}
		svg {
			fill: color(gray, light);
			transition: fill .2s ease-out;
		}
	}
	.link_item {
		@include font-smoothing;
		align-items: center;
		border-bottom: 1px solid color(gray, x-light);
		color: color(gray, light);
		display: flex;
		height: 80px;
		position: relative;
		text-decoration: none;
		transition: color .2s ease-out;
		@include breakpoint(tablet, tablet-l) {
			height: 60px;
		}
		&:after {
			background-image: url('../img/recognition/shadow-top.png');
			background-position:  bottom center;
			background-repeat: no-repeat;
			background-size: 100% auto;
			content: '';
			height: 75px;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			transform: translate3d(0, -100%, 0);
			transition: opacity .2s ease-out;
			pointer-events: none;
			width: 100%;
		}
		&:before {
			background-image: url('../img/recognition/shadow-bottom.png');
			background-position: top center;
			background-repeat: no-repeat;
			background-size: 100% auto;
			bottom: 0;
			content: '';
			height: 75px;
			left: 0;
			opacity: 0;
			position: absolute;
			transform: translate3d(0, 100%, 0);
			transition: opacity .2s ease-out;
			pointer-events: none;
			width: 100%;
		}
		&:first-child {
			&:after {
				height: 0;
			}
		}
		&:hover {
			&:after {
				opacity: 1;
			}
			&:before {
				opacity: 1;
			}
			.pro_1 & {
				color: color(rank_pro_1);
				svg {
					fill: color(rank_pro_1);
				}
			}
			.pro_2 & {
				color: color(rank_pro_2);
				svg {
					fill: color(rank_pro_2);
				}
			}
			.pro_3 & {
				color: color(rank_pro_3);
				svg {
					fill: color(rank_pro_3);
				}
			}
			.pro_4 & {
				color: color(rank_pro_4);
				svg {
					fill: color(rank_pro_4);
				}
			}
			.pro_5 & {
				color: color(rank_pro_5);
				svg {
					fill: color(rank_pro_5);
				}
			}
			.pro_6 & {
				color: color(rank_pro_6);
				svg {
					fill: color(rank_pro_6);
				}
			}
			.pro_7 & {
				color: color(rank_pro_7);
				svg {
					fill: color(rank_pro_7);
				}
			}
			.pro_8 & {
				color: color(rank_pro_8);
				svg {
					fill: color(rank_pro_8);
				}
			}
			.pro_9 & {
				color: color(rank_pro_9);
				svg {
					fill: color(rank_pro_9);
				}
			}
			.pro_10 &,
			.executive &,
			.presidential & {
				color: color(rank_pro_10);
				svg {
					fill: color(rank_pro_10);
				}
			}
		}
		&:last-child {
			border-bottom: 0;
		}
	}
	.link_url {
		@include font_size(fs_h6);
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		.facebook,
		.instagram,
		.twitter {
			&:before {
				content: '@';
			}
		}
	}
}

.sidebar_media {
	color: color(black, near);
	display: flex;
	flex-wrap: wrap;
	font-size: 12px;
	justify-content: space-between;
	margin-bottom: 25px;
	&:hover {
		.media_title,
		.video_length {
			transform: translate3d(0, 0, 0);
			transition-timing-function: easing(ease-in-quart);
		}
	}
	.media_image_link {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.media_thumb {
		@include constrain-proportions(100%, 66%);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow: 15px 10px 25px -10px rgba(0, 0, 0, 0.35);
		cursor: pointer;
		flex-basis: 100%;
		position: relative;
		z-index: 2;
	}
	.video_overlay {
		background-color: rgba(0, 0, 0, 0.35);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		&:after {
			background-image: url('../img/recognition/play-button.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			bottom: 0;
			content: '';
			height: 50px;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 0;
			transition: transform .2s easing(ease-out-quart);
			width: 50px;
		}
		&:hover {
			&:after {
				transform: scale(1.2);
				transition-timing-function: easing(ease-in-quart);
			}
		}
	}
	.video_length {
		color: color(gray, light);
		padding-left: 10px;
	}
	.video_length,
	.media_title {
		margin-top: 1em;
		transform: translate3d(0, -30px, 0);
		transition: transform .2s easing(ease-out-quart);
		z-index: 1;
	}
}

.sidebar_media_list {
	margin: 0;
	padding: 0;
}

.sidebar_media_list_container {
	margin-top: 40px;
}
