

// placeholders, vars
// shared resources throughout eCommerce pages

.loader_container {
	// IMPORTANT! all measurements must be even for everything to poperly center
	// variables to set the overall width of the loader spinner, as well as it's container
	$spinner_width: 26px;
	// border for the larger spinner
	$spinner_border: 4px;
	// border for the smaller spinner
	$bg_border: 2px;
	// these are for inside the btn_spinner class:
	$btn_spinner_spinner_width: 14px;
	$btn_spinner_spinner_border: 4px;
	$btn_spinner_bg_border: 2px;
	height: ($spinner_width + ($spinner_border * 2)) * 3;
	margin: 0 auto;
	position: relative;
	width: $spinner_width + ($spinner_border * 2);
	.btn_spinner & {
		height: 34px;
		width: $btn_spinner_spinner_width + ($btn_spinner_spinner_border * 2);
	}
	.bg {
		// background-color: rgba(0, 0, 0, 0.3);
		border: $bg_border solid color(blue);
		bottom: 0;
		font-size: 10px;
		position: relative;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		text-indent: -9999em;
		top: 0;
		z-index: 0;
		.btn_spinner & {
			border: $btn_spinner_bg_border solid color(blue);
		}
	}
	.bg,
	.bg:after {
		border-radius: 50%;
		height: $spinner_width + $spinner_border - $bg_border + $spinner_border;
		width: $spinner_width + $spinner_border - $bg_border + $spinner_border;
		.btn_spinner & {
			height: $btn_spinner_spinner_width + $btn_spinner_spinner_border - $btn_spinner_bg_border + $btn_spinner_spinner_border;
			width: $btn_spinner_spinner_width + $btn_spinner_spinner_border - $btn_spinner_bg_border + $btn_spinner_spinner_border;
		}
	}
	.spinner {
		// background-color: rgba(3, 3, 3, 0.3);
		animation: loader 1.85s infinite easing(ease-in-out-quad);
		border-top: $spinner_border solid rgba(0, 0, 0, 0.0);
		border-right: $spinner_border solid rgba(0, 0, 0, 0.0);
		border-bottom: $spinner_border solid rgba(0, 0, 0, 0.0);
		border-left: $spinner_border solid color(blue);
		bottom: 0;
		font-size: 10px;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		text-indent: -9999em;
		top: 0;
		transform: translateZ(0);
		z-index: 1;
		.btn_spinner & {
			border-top: $btn_spinner_spinner_border solid rgba(0, 0, 0, 0.0);
			border-right: $btn_spinner_spinner_border solid rgba(0, 0, 0, 0.0);
			border-bottom: $btn_spinner_spinner_border solid rgba(0, 0, 0, 0.0);
			border-left: $btn_spinner_spinner_border solid color(blue);
		}
	}
	.spinner,
	.spinner:after {
		border-radius: 50%;
		height: $spinner_width;
		width: $spinner_width;
		.btn_spinner & {
			height: $btn_spinner_spinner_width;
			width: $btn_spinner_spinner_width;
		}
	}
}

%overlay_product_title {
	@include font_size(fs_h2);
	margin-bottom: .1em;
	margin-top: .6em;
	position: relative;
	line-height:.9em;
	&:before {
		border-top: 4px solid color(white);
		content: '';
		left: 0em;
		position: absolute;
		top: -.6em;;
		width: 1em;
	}
	.name {
		line-height:.9em;
		text-transform:uppercase;
		font-weight:bold;
	}
}

%pdp_nav_shadow {
	box-shadow: inset 1px 4px 9px -6px rgba(0, 0, 0, .2);
}

%product_about {
	@include font-smoothing;
	font-family: font(serif);
letter-spacing: letter-spacing(serif);
	@include font_size(fs_h5);
	font-weight: 300;
	max-width: 22em;
}

%product_size {
	@include font-smoothing;
	@extend .bottom_rule, .fs_h4;
	font-family: font(serif);
	font-weight: 300;
	letter-spacing: letter-spacing(serif);
}

%product_tile_clip_path_bottom_right {
	clip-path: polygon(0 0, 100% 0, 100% 93%, 93% 100%, 0 100%);
}

%product_tile_clip_path_top_left {
	clip-path: polygon(7% 0, 100% 0, 100% 100%, 0 100%, 0 7%);
}

%product_title {
	@extend .h1_sans;
	color: color(gray, dark);
	margin-bottom: .2em;
	text-transform: uppercase;
	// our version of proxima's line heights get wonky, this tries to sort for it
	.light + .name {
		margin-top: .03em;
	}
	// our version of proxima's line heights get wonky, this tries to sort for it
	.name + .light {
		margin-top: -.02em;
	}
	.name {
		color: color(blue);
		font-weight: 900;
		line-height: .8em;
		transform: translateY(.01em); // our version of proxima's line heights get wonky, this tries to sort for it
	}
}

%top_dash {
	border-top: 4px solid #fff;
	content: '';
	left: 0;
	position: absolute;
	top: -.4em;
	width: .35em
}

%overlay {
	background-color: color(overlay);
	color: color(white);
	height: 100%;
	left: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	transform: translate3d(0, 101%, 0);
	transition: all .4s easing(ease-out-quint);
	width: 100%;
}

%remove_x {
	@include svg-background;
	@include tir;
	background-image: svg(home-close-dark);
	cursor: pointer;
	// height: 30px;
	opacity: .4;
	// position: absolute;
	// right: 0;
	// top: 10px;
	transition: all .2s easing(ease-in-out-quad);
	// width: 30px;
	&:hover {
		opacity: 1;
	}
}

%wrapper {
	@include constrain-proportions(100%, 100%);
	display: block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
}

// merge this shit in like a boss, por favor

%mobile_product_controls {
	@include section_reset;
	background-color: color(blue, muted);
	overflow: visible;
	@include nav-full {
		display: none;
	}
	.heading {
		background-color: color(blue, muted);
		height: 50px;
		position: relative;
		z-index: 2;
		a.action {
			display: block;
			height: 50px;
			line-height: 50px;
			overflow: hidden;
			position: absolute;
			right: 0;
			top: 0;
			&:hover {
				background-color: color(orange);
			}
		}
	}
	.menu {
		background-color: color(blue, muted);
		padding-bottom: 20px;
		padding-top: 10px;
		width: 100%;
		z-index: 1;
		.add_to_cart {
			display: block;
			float: right;
			width: calc(100% - 100px);
			html.no-touch & {
				&:before {
					background: linear-gradient(315deg, transparent 8px, color(orange) 0) bottom right;
				}
			}
		}
		.content_wrapper {
			@include breakpoint((374 + width(gutter-outer-s) * 2), false, false) {
				margin-left: auto;
				margin-right: auto;
				padding-bottom: 20px;
				padding-top: 20px;
				width: 374px;
			}
		}
		.controls {
			@include clearfix;
		}
		.custom_select {
			margin-bottom: 20px;
		}
		.spinner {
			display: block;
			float: left;
		}
	}
	.note {
		@extend .h6_sans;
		@include font-smoothing;
		color: color(white);
		line-height: 50px;
		padding-left: width(gutter-outer-s);
	}
	.price {
		display: inline-block;
		line-height: 1;
		padding-left: width(gutter-outer-s);
		.amount {
			@extend .h4_sans;
			color: color(white);
			display: block;
			@include font-smoothing;
			line-height: .8em;
		}
		.type {
			@include font_size(fs_p_note);
			color: color(white, off);
			letter-spacing: .1em;
			text-transform: uppercase;
		}
	}
	.pricing {
		@include vertical-center(5%);
		&.scrunch .price {
			@include breakpoint(zero, mobile-l) {
				padding-left: width(gutter-outer-s) / 2;
			}
		}
		&.scrunch .price:first-child {
			@include breakpoint(zero, mobile-l) {
				padding-left: width(gutter-outer-s);
			}
		}

	}
}
