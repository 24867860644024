// colors
$colors: (
	black: (
		base: black
		,near: #161a1d
	)
	,blue: (
		base: #0a82ba
		,dark: #075e87
		,light: #67b2e8
		,mid: #0091b9
		,muted: #4d5158
		,x-light: #80caff
	)
	,gradient: (
		gold: linear-gradient(35deg, rgba(191,172,115,1) 0%,rgba(223,214,164,1) 100%)
		,gold-bottom-to-top: linear-gradient(0deg, rgba(191,172,115,1) 0%,rgba(223,214,164,1) 100%)
		,white-to-left: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)
		,white-to-right: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)
	)
	,gray: (
		base: #616060
		,dark: #5a6066
		,x-dark: #2e3134
		,light: #939191
		,x-light: #cccccc
		,xx-light: #d9d9d9
		,xxx-light: #f7f7f7
	)
	,metal: (
		gold: #bfac73
	)
	,orange: (
		base: #e57b00
	)
	,yellow: (
		base: #ffff00
	)
	,overlay: (
		base: rgba(23, 29, 38, .8)
	)
	,rank_pro_1: (
		base: #00a8e1
		,light: #ffffff
	)
	,rank_pro_2: (
		base: #f7cd56
		,light: #f2eeda
	)
	,rank_pro_3: (
		base: #c6590e
		,light: #f2eeda
	)
	,rank_pro_4: (
		base: #427954
		,light: #cad8c1
	)
	,rank_pro_5: (
		base: #194c71
		,light: #ffffff
	)
	,rank_pro_6: (
		base: #5e3a70
		,light: #ffffff
	)
	,rank_pro_7: (
		base: #84634a
		,light: #ffffff
	)
	,rank_pro_8: (
		base: #7c192f
		,light: #e5d9dc
	)
	,rank_pro_9: (
		base: #303c42
		,light: #c4ccd0
	)
	,rank_pro_10: (
		base: #bfac73
		,dark: #000006
	)
	,red: (
		base: #ff0000
		,xx-light: #ffe7e9
	)
	,white: (
		base: white
		,near: #f2f2f2
		,off: #e5e5e5
	)
);
@function color($color, $value: base) {
	@if map-has-key($colors, $color) and map-has-key(map-get($colors, $color), $value) {
		@return map-get(map-get($colors, $color), $value);
	}
	@else {
		@warn "color(#{$color}, #{$value}) is not valid";
	}
}

// easings
$easings: (
	ease-in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045)
	,ease-in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335)
	,ease-in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190)
	,ease-in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035)
	,ease-in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530)
	,ease-in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220)
	,ease-in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060)
	,ease-in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715)
	,ease-in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550)
	,ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860)
	,ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000)
	,ease-in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000)
	,ease-in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955)
	,ease-in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000)
	,ease-in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000)
	,ease-in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950)
	,ease-out-back: cubic-bezier(0.175, 0.885, 0.320, 1.275)
	,ease-out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000)
	,ease-out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000)
	,ease-out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000)
	,ease-out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940)
	,ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000)
	,ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000)
	,ease-out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000)
);
@function easing($easing) {
	@return map-get($easings, $easing);
}

// named heights
$heights: (
	nav-condensed: 60px
	,nav-full: 100px
	,nav-full-extra: 120px
	,pre-header: 40px
	,wp-admin-bar-mobile: 46px
	,wp-admin-bar-desktop: 32px
	,pdp-tabs: 40px
);
@function height($height) {
	@return map-get($heights, $height);
}

// named widths
$widths: (
	zero: 0
	,mobile-s: 320px
	,mobile: 375px
	,mobile-l: 414px
	,mobile-xl: 532px
	,tablet-s: 650px
	,tablet: 768px
	,tablet-l: 896px
	,desktop: 1024px
	,desktop-l: 1146px
	,desktop-xl: 1268px
	,gutter-inner-s: 10px
	,gutter-inner-l: 20px
	,gutter-outer-s: 20px
	,gutter-outer-l: 40px
	,narrow-max-width: 960px
	,narrow-max-width-with-gutters: 1040px
	,max-width: 1280px
	,max-width-with-gutters: 1360px
	,max-recognition-billboard: 1900px
	,wp-admin-bar-desktop: 782px
);
@function width($width) {
	@return map-get($widths, $width);
}

// z-indexes
$z-indexes: (
	condensed-nav-drawer: 1
	,condensed-cart: 2
	,shiftable: 3
	,page: 10
	,billboard: 11
	,above-billboard: 12
	,product-variant-drawer: 13
	,product-variant-drawer-focus: 14
	,sticky-nav: 19
	,header: 20
	,header-sub-nav: 21
	,header-top-nav: 22
	,overlay: 30
	,pre-header: 40
	,pre-header-distributor-menu: 42
	,pre-header-your-distributor: 43
	,overlay-over-pre-header: 50
	,overlay-over-pre-header-content: 51
);
@function z-index($z-index) {
	@return map-get($z-indexes, $z-index);
}
